import { ChangeEvent, useEffect, useState } from 'react'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'

import Card from 'components/card'
import ProIcon from 'components/upgrade-plan/ProIcon'
import UpgradeCard from 'components/upgrade-plan/UpgradeCard'
import WhatsAppButton from 'components/whatsapp-button'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { checkDnsResolution } from 'services/certificates'
import { attachCustomDomain } from 'services/organization'

const CustomDomain = () => {
  const { organization } = useAuth()
  const { showSnackbar } = useSnackbar()

  const [isCustomDomainSupported, setIsCustomDomainSupported] = useState(false)
  const [customDomain, setCustomDomain] = useState('')
  const [isValidDomain, setIsValidDomain] = useState(true)
  const [isDNSVerified, setIsDNSVerified] = useState(false)
  const [captionText, setCaptionText] = useState('')
  const [captionTextColor, setCaptionTextColor] = useState('info')

  const [isEditMode, setIsEditMode] = useState(false)

  const DOMAIN_REGEX =
    /^(?=.{1,253}$)(?!-)(?!.*-$)(?!.*?\.\.)[a-zA-Z0-9-]{1,63}(\.[a-zA-Z]{2,})+$/

  const handleDomainChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsDNSVerified(false)

    const domain = e.target.value
    setCustomDomain(domain)

    const isValid = DOMAIN_REGEX.test(domain)
    setIsValidDomain(isValid)

    if (isValid) {
      setCaptionTextColor('info')
      setCaptionText('Verifying DNS Records...')
      // Trigger your handler here if domain is valid
      const dnsCheck = await checkDnsResolution(domain)
      if (dnsCheck && dnsCheck.data.matches) {
        setIsDNSVerified(true)
        setCaptionTextColor('green')
        setCaptionText('DNS Records Verified!')
      } else {
        setCaptionTextColor('error')
        setCaptionText(
          'DNS Records not found, please check your domain provider settings!'
        )
      }

      // e.g., call a function to save domain, validate backend availability, etc.
    } else {
      setCaptionText('Please enter a valid domain (e.g., example.com)')
      setCaptionTextColor('error')
    }
  }

  useEffect(() => {
    if (organization && organization.currentPricingPlanId > 1) {
      setIsCustomDomainSupported(true)
    }
  }, [organization])

  const copyToClipboardButton = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy)
      showSnackbar(textToCopy + ' copied to clipboard', 'info')
    } catch (err) {
      console.error('Failed to copy!', err)
    }
  }

  const handleCancelClick = () => {
    setIsEditMode(false)
    setCustomDomain('')
    setCaptionText('')
  }

  const handleSaveClick = async () => {
    try {
      const result = await attachCustomDomain(customDomain, organization!.id)
      if (result?.status === 'successful' && result.data) {
        setIsEditMode(false)
      } else {
        setCaptionText(
          'Error attaching custom domain, please try again later or contact support.'
        )
        setCaptionTextColor('error')
      }
    } catch (error) {
      setCaptionText(
        'Error attaching custom domain, please try again later or contact support.'
      )
      setCaptionTextColor('error')
    }
  }

  const handleVisitStore = () => {
    if (organization?.customDomain) {
      window.open(
        `https://${organization?.customDomain}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }

  return (
    <Box flex={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p className="text-lg font-semibold ml-5 flex items-center">
          Custom Domain
          <span className="ml-2">
            <ProIcon />
          </span>
        </p>
      </Box>

      {!isCustomDomainSupported && (
        <UpgradeCard>
          <p>
            This feature lets you use your own custom domain in addition to{' '}
            <Link
              to={`${organization?.slug}.haulistic.io`}
              className="underline"
            >
              {organization?.slug}.haulistic.io
            </Link>
            . A personalized domain not only enhances your brand&apos;s
            credibility and SEO but also drives more sales.
          </p>
        </UpgradeCard>
      )}

      {isCustomDomainSupported && !organization?.customDomain && (
        <Card>
          <Box
            sx={{
              backgroundColor: 'white',
              border: '1px solid lightgray',
              borderRadius: 1,
              padding: 2,
              mb: 2,
            }}
          >
            <div className="text-sm">
              This feature lets you host your website on your custom domain
              alongside{' '}
              <Link
                to={`https://${organization?.slug}.haulistic.io`}
                target="_blank"
              >
                <span className="font-bold underline">
                  {organization?.slug}.haulistic.io
                </span>
              </Link>
              . Before proceeding, please complete these steps:
              <div className="mt-4 flex items-center">
                <CheckCircleRoundedIcon
                  fontSize="small"
                  color="success"
                  className="mr-2"
                />
                Log in to your domain provider and access your DNS settings.
              </div>
              <div className="mb-4 flex items-center">
                <CheckCircleRoundedIcon
                  fontSize="small"
                  color="success"
                  className="mr-2"
                />{' '}
                Add two A records with the following IP addresses:{' '}
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    color: '#00897b',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 1,
                  }}
                >
                  172.66.0.162
                  <IconButton
                    className="ml-1"
                    size="small"
                    onClick={() => copyToClipboardButton('172.66.0.162')}
                  >
                    <ContentCopyIcon fontSize="small" color="info" />
                  </IconButton>
                </Box>{' '}
                and{' '}
                <Box
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    color: '#00897b',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 1,
                  }}
                >
                  162.159.140.164
                  <IconButton
                    className="ml-1"
                    size="small"
                    onClick={() => copyToClipboardButton('162.159.140.164')}
                  >
                    <ContentCopyIcon fontSize="small" color="info" />
                  </IconButton>
                </Box>{' '}
              </div>
              If you run into any issues, feel free to{' '}
              <WhatsAppButton
                buttonText="text us"
                phoneNumber="+919790469245"
                message="Hello! I need help setting up my custom domain on Haulistic"
              />{' '}
              for assistance or watch these videos from{' '}
              <Link
                to={
                  'https://www.youtube.com/watch?v=QwkX7-ab09A&ab_channel=Freshsales'
                }
                target="_blank"
                className="underline"
              >
                GoDaddy
              </Link>{' '}
              or{' '}
              <Link
                to={
                  'https://www.youtube.com/watch?v=iSRz75xEd3c&ab_channel=KnowledgeByMarcus'
                }
                target="_blank"
                className="underline"
              >
                Cloudflare
              </Link>{' '}
              .
            </div>
          </Box>
          {!isEditMode && (
            <div className="mt-4 flex justify-center">
              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: 'none', fontWeight: 'bold' }}
                onClick={() => setIsEditMode(true)}
              >
                {' '}
                Add Custom Domain
              </Button>
            </div>
          )}
          {isEditMode && (
            <>
              {' '}
              <TextField
                id="customDomainValue"
                placeholder="Enter your own domain, for eg: threadtales.com"
                variant="outlined"
                value={customDomain}
                fullWidth
                className="bg-white my-3"
                size="small"
                InputProps={{
                  readOnly: !isEditMode,
                  startAdornment: (
                    <InputAdornment position="start">https://</InputAdornment>
                  ),
                }}
                onChange={handleDomainChange}
                error={!isValidDomain && customDomain !== ''}
              />
              <Typography variant="caption" color={captionTextColor}>
                {captionText}
              </Typography>
              <div className="flex flex-row mt-3">
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleCancelClick}
                    disableElevation
                  >
                    Cancel
                  </Button>
                </div>
                <div className="ml-2">
                  <Button
                    variant="contained"
                    size="small"
                    disabled={!isDNSVerified}
                    onClick={handleSaveClick}
                    disableElevation
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          )}
        </Card>
      )}

      {isCustomDomainSupported && organization?.customDomain && (
        <Card>
          <Box
            sx={{
              backgroundColor: 'white',
              border: '1px solid lightgray',
              borderRadius: 1,
              padding: 2,
              mb: 2,
            }}
          >
            <p className="text-sm text-center">
              Your custom domain,{' '}
              <span className="font-bold">{organization?.customDomain}</span>{' '}
              has been successfully configured! 🎉 Your store is now accessible
              through your own domain, making it easier for customers to find
              and remember your brand. Share your new link with your audience
              and start selling effortlessly!
            </p>
            <div className="flex justify-center mt-4">
              <Button
                variant="outlined"
                size="small"
                onClick={handleVisitStore}
              >
                Visit {organization?.customDomain}
                <OpenInNewIcon fontSize="small" className="ml-2" />{' '}
              </Button>
            </div>
          </Box>
        </Card>
      )}
    </Box>
  )
}

export default CustomDomain
