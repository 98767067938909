import { Box } from '@mui/material'

import UpgradeCard from 'components/upgrade-plan/UpgradeCard'
import { useAuth } from 'hooks/useAuth'
import CheckList from 'pages/dashboard/components/CheckList'

const Home = () => {
  const { organization } = useAuth()
  return (
    <>
      {organization?.currentPricingPlanId === 1 && (
        <UpgradeCard flex={true}>
          <p>
            You are currently on our FREE plan. Upgrade to the{' '}
            <Box component="span" sx={{ color: '#00897b', fontWeight: 'bold' }}>
              {' '}
              Lite or Growth
            </Box>{' '}
            plan to unlock more features and grow your business.
          </p>
        </UpgradeCard>
      )}

      <CheckList />
    </>
  )
}

export default Home
