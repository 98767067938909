import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Box, Button, InputAdornment, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'
import { convertRupeeToPaisa, formatPrice } from 'utils/helper'

interface PricingDetailsProps {
  product: Product
  onUpdate: (data?: Product) => void
}

const PricingDetails: FC<PricingDetailsProps> = ({ product, onUpdate }) => {
  const [values, setValues] = useState({ price: '', quantity: '' })
  const [error, setError] = useState({ price: '' })
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setValues({
      price: (product.price / 100).toString(),
      quantity: (product.stock ?? '').toString(),
    })
  }, [product])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'price') {
      let value = e.target.value
      value = value.replace(/[^0-9.]/g, '')

      const decimalCount = (value.match(/\./g) || []).length

      if (decimalCount > 1) {
        value = value.substring(0, value.lastIndexOf('.'))
      }

      if (value.includes('.')) {
        const [integer, decimal] = value.split('.')
        value = integer + '.' + (decimal ? decimal.slice(0, 2) : '')
      }

      setValues((prev) => ({ ...prev, price: value }))

      if (value.trim() !== '' && error.price !== '') {
        setError((prev) => ({ ...prev, price: '' }))
      }
    } else if (e.target.name === 'quantity') {
      let value = e.target.value
      value = value.replace(/[^0-9]/g, '')

      setValues((prev) => ({ ...prev, quantity: value }))
    }
  }

  const handleSave = async (): Promise<void> => {
    if (values.price === '') {
      setError({
        price: values.price === '' ? '*Price is required.' : '',
      })
      return
    }

    const newPricePaisa = convertRupeeToPaisa(values.price)

    // Check if no changes have been made
    if (newPricePaisa === product.price && +values.quantity === product.stock) {
      setEdit(false)
      return
    }

    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_SAVE_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }

    const data = {
      price: newPricePaisa,
      quantity: values.quantity === '' ? null : Number(values.quantity),
    }

    setIsSubmitting(true)
    const res = await updateProduct({
      productId: product.id,
      dataUpdate: data,
    })

    if (res.status === 'successful') {
      showSnackbar('Product updated.', 'success')
      onUpdate(res.data)
    }
    setIsSubmitting(false)
    setEdit(!edit)
    setError({
      price: '',
    })
  }

  const handleCancel = (): void => {
    setValues({
      price: (product.price / 100).toString(),
      quantity: product.stock !== null ? product.stock.toString() : '',
    })
    setError({
      price: '',
    })
    setEdit(false)
  }

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_EDIT_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  return (
    <div className="mb-5">
      <div className="mx-5">
        <SubTabHeader
          title="Product Level Pricing & Stock"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
      </div>
      <Card>
        <Box display={'flex'} columnGap={2} rowGap={1}>
          <Box flex={1}>
            <Label text="Selling Price" color="secondary" />
            {edit ? (
              <CustomTextField
                name="price"
                type="text"
                value={values.price}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                error={Boolean(error.price)}
                helperText={error.price}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {formatPrice(product.price)}
              </Typography>
            )}
          </Box>
          <Box flex={1}>
            <Label text="Quantity in Stock" color="secondary" />
            {edit ? (
              <CustomTextField
                name="quantity"
                type="text"
                value={values.quantity}
                onChange={handleChange}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {product.stock ?? 'N/A'}
              </Typography>
            )}
          </Box>
        </Box>
        {edit && (
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default PricingDetails
