const certificatesBasePath = `${process.env.REACT_APP_API_BASE_PATH}/certificates`

export interface DnsResolutionResult {
  data: {
    matches: boolean
    resolvedIps: string[]
  }
}

export const checkDnsResolution = async (
  domain: string
): Promise<DnsResolutionResult | undefined> => {
  try {
    const response = await fetch(
      `${certificatesBasePath}/check-dns-resolution?domain=${domain}`
    )

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const result: DnsResolutionResult = await response.json()
    return result
  } catch (err) {
    console.error('Error checking DNS resolution:', err)
    throw err // or return a default value, based on your use-case
  }
}
