export const pricingPlans = [
  {
    id: 1,
    title: 'STARTER (Free)',
    description: 'For Getting Started',
    price: '₹ 0',
    commission: '3',
    features: [
      '1 Staff Account',
      'Upto 20 Products',
      'Mobile-Optimized Store',
      'Email Notifications',
      'Basic Store Analytics',
    ],
  },
  {
    id: 2,
    title: 'LITE',
    description: 'For Small Businesses',
    price: '₹ 499',
    commission: '2',
    features: [
      'Everything In Starter',
      'Custom Domain Integration',
      '3 Staff Accounts',
      'Upto 50 Products',
      'Email Support Within 24 Hours',
    ],
  },
  {
    id: 3,
    title: 'GROWTH',
    description: 'As Your Business Scales',
    price: '₹ 1499',
    commission: '1',
    features: [
      'Everything In Lite',
      '5 Staff Accounts',
      'Unlimited Products',
      'Enhanced Analytics',
      'Email + Whatsapp Support Within 12 Hours',
    ],
  },
]
