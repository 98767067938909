import { Box, Grid, Typography } from '@mui/material'

import { Product } from 'services/products/index.types'

import CustomPagination from './CustomPagination'
import ProductCard from './ProductCard'
import ProductToolbar from './ProductToolbar'

interface ProductCardViewProps {
  products: Product[]
  totalProducts: number
  highestPrice?: number
}

const ProductCardView: React.FC<ProductCardViewProps> = ({
  products,
  totalProducts,
  highestPrice,
}) => {
  return (
    <Box className="my-0 mb-8 px-5">
      <Box className="hidden md:block ">
        <ProductToolbar
          totalProducts={totalProducts}
          highestPrice={highestPrice}
        />
      </Box>

      <Grid
        container
        rowSpacing={{ xs: 0.5, sm: 2, md: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {products.length > 0 ? (
          products.map((product) => (
            <Grid item xs={6} sm={4} md={4} xl={2} key={product.id}>
              <ProductCard item={product} />
            </Grid>
          ))
        ) : (
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              textAlign: 'center',
              mt: 10,
              ml: 5,
              color: 'text.secondary',
            }}
          >
            No products found
          </Typography>
        )}
      </Grid>

      {totalProducts !== null && totalProducts > 0 && (
        <div className="flex items-center justify-center mt-10">
          <CustomPagination totalProducts={totalProducts ?? 0} />
        </div>
      )}
    </Box>
  )
}

export default ProductCardView
