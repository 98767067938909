import { FC, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { Product } from 'types/product'

import ProductFiles from './Files'

interface FilesDetailsProps {
  media?: string[]
  productId?: number
  onUpdate?: (p?: Product) => void
  productCode?: string
  product?: Product
}

const FilesDetails: FC<FilesDetailsProps> = ({
  media,
  productId,
  productCode,
  onUpdate,
  product,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const { organizationId } = useAuth()
  const [updatedFiles, setFilesUpdated] = useState<string[]>([])
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    if (media && media.length > 0) {
      setFilesUpdated(media)
    }
  }, [media])

  const handleEdit = (): void => {
    if (productId && organizationId) {
      Amplitude.trackEvent('PRODUCT_IMAGES_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setOpenDialog(true)
  }
  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  const handleUpdate = (data: Product) => {
    setFilesUpdated(data.media)
    if (product) {
      product.media = data.media
      onUpdate?.(product)
    }
  }

  const handleImageUpload = () => {
    setUploading((prev) => !prev)
  }

  return (
    <div className="mb-5 mx-5">
      <SubTabHeader
        title="Product Images"
        buttonText="Edit"
        onClick={handleEdit}
      />
      <Typography variant="caption" color="textSecondary" gutterBottom>
        Upload high-quality images to showcase your product.
      </Typography>
      <Box
        className="my-2 flex flex-wrap gap-1"
        sx={{ scrollbarWidth: 'none' }}
      >
        {updatedFiles &&
          updatedFiles.map((url, index) => (
            <Card
              key={index}
              className="w-24 h-40 sm:h-52 sm:w-36 inline-block mr-2 rounded"
            >
              <CardMedia
                component="img"
                image={url}
                alt={`media-${index}`}
                className="w-full h-40 sm:h-52 object-contain"
              />
            </Card>
          ))}
      </Box>
      {openDialog && (
        <Dialog open onClose={handleCloseDialog} maxWidth={'md'} fullWidth>
          <DialogContent>
            <Box>
              <ProductFiles
                productId={productId}
                onUpdateProduct={handleUpdate}
                productCode={productCode}
                onUploading={handleImageUpload}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              sx={{ textTransform: 'none' }}
              disabled={uploading}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default FilesDetails
