import { ChangeEvent, JSX, MouseEvent, useEffect, useState } from 'react'

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import CardMUI from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { format } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom'

import Card from 'components/card'
import Label from 'components/forms/Label'
import LoadingSpinner from 'components/spinner'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { getOrderById, updateStatus } from 'services/orders'
import { Order } from 'services/orders/index.type'
import { AddressJsonType } from 'services/organization/index.types'
import { dateTimeFormat, shortDateFormat } from 'utils/dateFormat'
import { formatPrice } from 'utils/helper'

import srcImg from '../../../../../assests/img/sunrise-placeholder.png'
import { formatAddress } from '../../customers/components/CustomersList'
import CourierServiceDialog from '../components/CourierServiceDialog'
import OrderDelivery from '../components/Delivery'
import InvoiceViewDialog from '../components/GenerateInvoice'
import OrderNotesDetail from '../components/NotesDetail'
import OrderTimeLine from '../components/Timeline'

const statusList = [
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Shipped', value: 'SHIPPED' },
  { label: 'Delivered', value: 'DELIVERED' },
  { label: 'Return Initiated', value: 'RETURN_INITIATED' },
  { label: 'Refunded', value: 'REFUNDED' },
]

const OrderDetailsPage = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState<string | null>(null)
  const [order, setOrder] = useState<Order | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [updatePage, setUpdatePage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { showSnackbar } = useSnackbar()
  const { organization } = useAuth()
  const [generateInvoice, setGenerateInvoice] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openCourierDialog, setOpenCourierDialog] = useState(false)
  const [showNotesIndex, setShowNotesIndex] = useState<number | null>(null)
  const { organizationId } = useAuth()

  const openMenu = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleDialogClose = (): void => {
    setOpen(false)
  }

  const handleOpenCancelDialog = (): void => {
    setOpenCancelDialog((prev) => !prev)
    setAnchorEl(null)
    setStatus('CANCELLED')
  }

  const handleCancelDialogClose = (): void => {
    setOpenCancelDialog(false)
    setStatus(null)
  }

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setStatus(e.target.value)
  }

  const openInvoiceDialog = (): void => {
    setGenerateInvoice(true)
    handleClose()
  }
  const handleReasonChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCancellationReason(e.target.value)
  }

  useEffect(() => {
    const get = async (): Promise<void> => {
      setIsLoading(true)
      const result =
        orderId && organizationId
          ? await getOrderById(orderId, organizationId)
          : { status: 'failed' }

      if (result?.status === 'successful' && result.data) {
        setOrder(result.data)
      } else if (result?.status === 'failed' && result.message) {
        setError(result.message)
      }
      setIsLoading(false)
      setUpdatePage(false)
    }
    get()
  }, [orderId, updatePage, organizationId])

  const handleStatusSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()
    setIsSubmitting(true)
    if (status) {
      const result =
        orderId && organizationId
          ? await updateStatus(orderId, organizationId, {
              status,
              description:
                cancellationReason.trim() !== ''
                  ? cancellationReason
                  : undefined,
            })
          : { status: 'failed' }
      if (result?.status === 'successful') {
        showSnackbar('Status updated', 'success')
        setUpdatePage(true)
      } else if (result?.status === 'failed' && result.message) {
        showSnackbar(result.message, 'error')
      }
      setIsSubmitting(false)
    }
    setStatus(null)
    handleDialogClose()
    setOpenCancelDialog(false)
    setCancellationReason('')
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error || !order) {
    return (
      <Typography textAlign={'center'} fontWeight={700}>
        {error}
      </Typography>
    )
  }

  const orderDate = dateTimeFormat(order.createdAt)

  const subTotal = order.items.reduce((acc: number, item) => {
    return acc + item.quantity * item.price
  }, 0)

  const orderStages = order.orderStatuses
    .map((el) => ({
      label: el.status.split('_').join(' '),
      date: shortDateFormat(el.createdAt),
    }))
    .reverse()

  const customer = {
    id: order.customer.orgSeqNo,
    name: order.customer.firstName
      ? order.customer.firstName +
        (order.customer.lastName ? ' ' + order.customer.lastName : '')
      : 'Not provided',
    contacts: order.customer.phoneNumber
      ? order.customer.phoneNumber + ', ' + order.customer.email
      : order.customer.email,
    shippingAddress: formatAddress(order.shippingAddress),
    billingAddress: formatAddress(order.billingAddress),
  }

  const address: AddressJsonType | null =
    organization && organization.address
      ? JSON.parse(organization.address)
      : null

  const invoiceData = {
    billFrom: address && {
      address: address.addressLine2 ?? '' + ', ' + address.addressLine1,
      city: address.city,
      state: address.state,
      pinCode: address.pinCode,
      country: address.country,
      name: organization ? organization.name : '',
      email: organization ? organization.email : '',
    },
    billTo: {
      name: order.customer.firstName
        ? order.customer.firstName + (order.customer.lastName ?? ' ')
        : '',
      address: `${order.billingAddress.addressLine2 ?? ''}, ${order.billingAddress.addressLine1}`,
      city: order.billingAddress.city,
      state: order.billingAddress.state,
      pinCode: order.billingAddress.pinCode,
      country: order.billingAddress.country,
      email: order.customer.email,
    },
    invoiceNumber: '01',
    invoiceDate: format(new Date(order.createdAt), 'dd-MM-yyyy'),
    amountDue: order.totalPrice / 100,
    items: order.items.map((item) => ({
      name: item.name,
      unitPrice: item.price / 100,
      // Todo -- This will need to fix for the invoice
      // color: item.color,
      // size: item.size,
      selectedOptions: item.selectedOptions,
      quantity: item.quantity,
    })),
    totalPrice: order.totalPrice / 100,
    taxRate: 0,
    shippingCost: (order.totalPrice - subTotal) / 100,
  }

  const updateOrder = (data: Order) => {
    setOrder(data)
  }

  return (
    <div>
      <div className=" mx-5 flex justify-between items-center">
        <Box display={'flex'} alignItems={'center'}>
          <IconButton sx={{ color: 'gray' }} onClick={() => navigate(-1)}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <p className="text-xl font-bold">{`Order#${order.orgSeqNo}`}</p>
        </Box>
        <Button
          size="small"
          variant="outlined"
          sx={{
            paddingInline: '1rem',
            textTransform: 'none',
            fontSize: '0.8rem',
          }}
          id="action-button"
          aria-controls={open ? 'order-action' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Actions
        </Button>
        <Menu
          id="order-action"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'action-button',
          }}
          sx={{
            '& .MuiMenuItem-root': {
              '&:hover': {
                backgroundColor: 'lightgray',
              },
            },
          }}
        >
          <MenuItem onClick={openInvoiceDialog}>Generate Invoice</MenuItem>
          <MenuItem
            onClick={handleClickOpen}
            disabled={order.status === 'CANCELLED'}
          >
            Change Status
          </MenuItem>
          <MenuItem
            onClick={handleOpenCancelDialog}
            sx={{
              display: order.status === 'CANCELLED' ? 'none' : 'block',
            }}
          >
            Cancel Order
          </MenuItem>
        </Menu>
      </div>
      <Box className="m-5 p-5 rounded" sx={{ border: '1px solid lightgray' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Label text="Order UID" color="secondary" />
            <Typography fontWeight={700}>{order.uid}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Total" color="secondary" />
            <Typography fontWeight={700}>
              {order.items.length} items, {formatPrice(order.totalPrice)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Status" color="secondary" />
            <Typography fontWeight={700}>{order.status}</Typography>
            <ButtonBase
              sx={{
                color: 'text.secondary',
                fontSize: '0.75rem',
                fontWeight: '600',
                borderBottom: '1px solid',
                display: order.status === 'CANCELLED' ? 'none' : 'inline-flex',
              }}
              onClick={handleClickOpen}
            >
              Update
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Created Date" color="secondary" />
            <Typography fontWeight={700}>{orderDate}</Typography>
          </Grid>
        </Grid>
      </Box>
      <div className="mb-4">
        <div className="mx-5">
          <SubTabHeader title="Timeline" />
        </div>
        <Card>
          <OrderTimeLine
            stages={orderStages}
            currentStage={orderStages.length - 1}
          />
        </Card>
      </div>
      <OrderDelivery
        onOpenCourierDialog={() => setOpenCourierDialog(true)}
        currentValues={{
          shippingDate: order.shippingDate,
          expectedDeliveryDate: order.expectedDeliveryDate,
          shippingProvider: order.shippingProvider,
          trackingId: order.trackingId,
          trackingLink: order.trackingLink,
        }}
        orderId={order.orgSeqNo}
        organizationId={order.organizationId}
        onUpdateOrder={updateOrder}
      />
      <div className="mb-4">
        <div className="mx-5">
          <SubTabHeader title="Order Summary" />
        </div>
        <Card>
          {order.items.map((item, index) => (
            <CardMUI elevation={0} sx={{ padding: 0, mb: 2 }} key={index}>
              <CardContent>
                <Box
                  display={'flex'}
                  justifyContent={{ md: 'space-between' }}
                  alignItems={{ md: 'flex-start' }}
                  flexDirection={{ xs: 'column', md: 'row' }}
                  rowGap={3}
                >
                  <Box flex={1} display={'flex'} alignItems={'start'}>
                    <img
                      src={item.media ? item.media[0] : srcImg}
                      width={'70px'}
                      height={'70px'}
                      alt="product img"
                    />
                    <div className=" ml-5">
                      <ButtonBase
                        sx={{
                          fontWeight: '700',
                          textDecoration: 'underline',
                          mb: 1,
                        }}
                        onClick={() =>
                          navigate(`/dashboard/products/${item.productCode}`)
                        }
                      >
                        {item.name}
                      </ButtonBase>
                      {item.selectedOptions?.map((option) => (
                        <Typography
                          key={option.name}
                          variant="body2"
                          className="text-gray-700"
                          sx={{
                            fontWeight: 500,
                            fontSize: '0.75rem',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {option.name}:{' '}
                          {option.name.toLowerCase() === 'color' ? (
                            <span
                              className="inline-block h-4 w-4 rounded-full border border-gray-300 mx-1"
                              style={{ backgroundColor: option.value.value }}
                            ></span>
                          ) : (
                            option.value.value
                          )}
                        </Typography>
                      ))}
                      {item.notes && (
                        <Box
                          className="pr-4"
                          display={{ xs: 'block', md: 'none' }}
                        >
                          <Typography className="text-gray-600" fontSize="14px">
                            <span className="text-gray-800 text-sm">
                              Notes:{' '}
                            </span>
                            {showNotesIndex === index
                              ? item.notes
                              : `${item.notes.slice(0, 100)}...`}
                          </Typography>

                          {item.notes.length > 100 && (
                            <Button
                              size="small"
                              onClick={() =>
                                setShowNotesIndex((prevIndex) =>
                                  prevIndex === index ? null : index
                                )
                              }
                              sx={{
                                textTransform: 'none',
                                fontSize: '0.75rem',
                              }}
                            >
                              {showNotesIndex === index
                                ? 'See Less'
                                : 'See More'}
                            </Button>
                          )}
                        </Box>
                      )}
                    </div>
                  </Box>
                  <Box
                    flex={1}
                    display={'flex'}
                    justifyContent={{ xs: 'flex-end', md: 'space-between' }}
                    alignItems={'center'}
                    columnGap={{ xs: 10, md: 0 }}
                    marginTop={{ sm: 4 }}
                  >
                    <Typography fontWeight={700}>
                      {item.quantity} &times; {formatPrice(item.price)}
                    </Typography>
                    <Typography fontWeight={700}>
                      {formatPrice(item.quantity * item.price)}
                    </Typography>
                  </Box>
                </Box>
                {item.notes && (
                  <Box
                    className="pr-4 ml-20 w-1/2"
                    display={{ xs: 'none', md: 'flex' }}
                  >
                    <Typography className="text-gray-600 pl-1" fontSize="14px">
                      <span className="text-gray-800 text-sm">Notes: </span>
                      {showNotesIndex === index
                        ? item.notes
                        : `${item.notes.slice(0, 100)}...`}
                      {item.notes.length > 100 && (
                        <Button
                          size="small"
                          onClick={() =>
                            setShowNotesIndex((prevIndex) =>
                              prevIndex === index ? null : index
                            )
                          }
                          sx={{
                            textTransform: 'none',
                            fontSize: '0.75rem',
                            padding: 0,
                          }}
                        >
                          {showNotesIndex === index ? 'See Less' : 'See More'}
                        </Button>
                      )}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </CardMUI>
          ))}
          <div className=" flex justify-end items-center px-4 gap-12">
            <div>
              <Typography fontWeight={700}>Shipping Cost</Typography>

              <Typography fontWeight={700}>Total</Typography>
            </div>
            <div>
              <Typography fontWeight={700} textAlign={'right'}>
                {formatPrice(order.totalPrice - subTotal)}
              </Typography>
              <Typography fontWeight={700}>
                {formatPrice(order.totalPrice)}
              </Typography>
            </div>
          </div>
        </Card>
      </div>
      <div className="mb-4">
        <div className="mx-5">
          <SubTabHeader title="Customer Details" />
        </div>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Label text="Name" color="secondary" />
              <ButtonBase
                sx={{
                  fontWeight: '700',
                  borderBottom: '1px solid',
                }}
                onClick={() => navigate(`/dashboard/customers/${customer.id}`)}
              >
                {customer.name}
              </ButtonBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Contact" color="secondary" />
              <Typography fontWeight={700}>{customer.contacts}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Shipping Address" color="secondary" />
              <Typography fontWeight={700}>
                {customer.shippingAddress}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Billing Address" color="secondary" />
              <Typography fontWeight={700}>
                {customer.billingAddress}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
      <OrderNotesDetail
        note={order.note}
        orderId={order.orgSeqNo}
        customerId={customer.id}
        organizationId={order.organizationId}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleStatusSubmit,
        }}
        fullWidth
      >
        <DialogTitle color={'primary'} fontWeight={700}>
          Change Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Label text="Current Status" color="secondary" />
            <Typography color={'text.primary'} fontWeight={700}>
              {order.status}
            </Typography>
            <div className="mt-5"></div>
            <Label text="New status" color="secondary" />
            <TextField
              SelectProps={{ style: { backgroundColor: 'white' } }}
              onChange={handleStatusChange}
              name={'status'}
              value={status}
              sx={{ marginTop: 0 }}
              fullWidth
              id="state"
              variant="outlined"
              margin="normal"
              size="small"
              select
            >
              {statusList.map((status) => (
                <MenuItem key={status.label} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </TextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Updating...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <InvoiceViewDialog
        invoiceData={invoiceData}
        open={generateInvoice}
        onClose={() => setGenerateInvoice(false)}
      />
      <div>
        <Dialog
          open={openCancelDialog}
          onClose={handleCancelDialogClose}
          PaperProps={{
            component: 'form',
            onSubmit: handleStatusSubmit,
          }}
          fullWidth
        >
          <DialogTitle color={'primary'}>Confirm Cancellation</DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to cancel? If you proceed, the order amount
              will be automatically <strong>refunded</strong> to the customer.
              Please provide a reason:
            </p>
            <TextField
              name="cancel-reason"
              variant="outlined"
              margin="normal"
              size="small"
              value={cancellationReason}
              onChange={handleReasonChange}
              multiline
              fullWidth
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelDialogClose}
              color="primary"
              sx={{ textTransform: 'none' }}
              size="small"
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: 'none' }}
              size="small"
              disabled={isSubmitting || !cancellationReason.trim()}
            >
              {isSubmitting ? 'Updating...' : 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>
        <CourierServiceDialog
          isOpen={openCourierDialog}
          onClose={() => setOpenCourierDialog(false)}
          pickupCode={address?.pinCode ?? ''}
          deliveryCode={order.shippingAddress.pinCode ?? ''}
        />
      </div>
    </div>
  )
}

export default OrderDetailsPage
