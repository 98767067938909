import { FC, useState } from 'react'

import { Box, Card, Chip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import ImageComponent from 'components/ImgPlaceholder'
import { Product } from 'services/products/index.types'
import { formatPrice } from 'utils/helper'

import placeholderImage from '../../../../../../assests/img/sunrise-placeholder.png'
import { isOfferValid } from '../List'

export interface ProductCardProps {
  item: Product
}

const ProductCard: FC<ProductCardProps> = ({ item }) => {
  const router = useNavigate()
  const [elevation, setElevation] = useState(1)
  const [hover, setHover] = useState(false)

  const getCardImages = (media: string[]) => {
    if (!media || media.length === 0) return [placeholderImage]
    return media.slice(0, 2)
  }

  const cardImages = getCardImages(item.media)

  const isOffer = isOfferValid(item.startOfferDate, item.endOfferDate)

  const handleProductCardClick = () => {
    router(`/dashboard/products/${item.productCode}`)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        transition: 'transform 0.3s, box-shadow 0.3s',
        transform: hover ? 'translateY(-10px)' : 'translateY(0)',
        width: '100%',
        maxWidth: {
          xs: '180px',
          md: '250px',
        },
        margin: 'auto',
        paddingTop: 3,
      }}
      onMouseEnter={() => {
        setElevation(12)
        setHover(true)
      }}
      onMouseLeave={() => {
        setElevation(1)
        setHover(false)
      }}
    >
      <Card
        elevation={elevation}
        sx={{
          cursor: 'pointer',
          width: '100%',
          position: 'relative',
        }}
        onClick={handleProductCardClick}
      >
        <Box
          sx={{
            width: '100%',
            aspectRatio: '3 / 4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <ImageComponent
            imageUrl={hover && cardImages[1] ? cardImages[1] : cardImages[0]}
            altText={item.name}
          />
        </Box>
        {!item.isAvailable && (
          <Chip
            label="Out of Stock"
            size="small"
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              backgroundColor: 'gray',
              color: 'white',
              fontSize: '0.6rem',
            }}
          />
        )}
      </Card>
      <Box
        sx={{
          padding: '8px 0',
        }}
        onClick={handleProductCardClick}
      >
        <p
          className={`text-sm text-gray-700 ${hover ? 'underline cursor-pointer' : ''}`}
        >
          {item.name} <span className="ml-1">({item.productCode})</span>
        </p>

        {isOffer ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'baseline',
              gap: '4px',
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              {formatPrice(item.discountedPrice)}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textDecoration: 'line-through' }}
            >
              {formatPrice(item.price)}
            </Typography>
            <Typography variant="body2" color="orange">
              ({item.discountPercentage}% OFF)
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1" fontWeight="bold">
            {formatPrice(item.lowestPrice)}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default ProductCard
