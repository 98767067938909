import { useEffect } from 'react'

const useRazorpay = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://checkout.razorpay.com/v1/checkout.js'
    script.async = true
    // eslint-disable-next-line no-console
    script.onload = () => console.info('Razorpay script loaded')
    document.body.appendChild(script)
  }, [])
}

export default useRazorpay
