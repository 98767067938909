import { Drawer } from '@mui/material'
import Box from '@mui/material/Box'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import DashboardSidebar from 'components/navbar/Sidebar'
import { useAuth } from 'hooks/useAuth'

const Dashboard = () => {
  const { organization } = useAuth()
  return (
    <div>
      <Helmet>
        <title>
          Dashboard
          {organization && organization.name ? ` | ${organization.name}` : ''}
        </title>
      </Helmet>

      <Box sx={{ display: { xs: 'block', sm: 'flex' }, minHeight: '100vh' }}>
        <Drawer
          variant="permanent"
          sx={{
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              backgroundColor: '#F4F6F7',
              display: { xs: 'none', sm: 'block' },
              paddingRight: 2,
              maxWidth: 240,
              boxSizing: 'border-box',
              border: 'none',
            },
          }}
        >
          <DashboardSidebar />
        </Drawer>

        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            pt: 10,
            minHeight: '100vh',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'hidden',
              mr: { md: 4 },
              minHeight: '100vh',
              maxWidth: '100%',
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Dashboard
