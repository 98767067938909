import { FC } from 'react'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { InputAdornment, TextField } from '@mui/material'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface DateTimeInputProps {
  name?: string
  value: Date | null
  onChange: (date: Date | null) => void
  disabled?: boolean
  error?: boolean
  helperText?: string
  showTime?: boolean
}

const DateTimeInput: FC<DateTimeInputProps> = ({
  name,
  value,
  onChange,
  disabled,
  error,
  helperText,
  showTime = true,
}) => {
  return (
    <div className="flex flex-col">
      <DatePicker
        name={name}
        selected={value ? new Date(value) : null}
        onChange={(date) => onChange(date ? new Date(date) : null)}
        showTimeSelect={showTime}
        dateFormat={showTime ? 'dd MMMM yyyy h:mm aa' : 'dd MMMM yyyy'}
        isClearable={true}
        disabled={disabled}
        customInput={
          <TextField
            name={name}
            variant="outlined"
            size="small"
            margin="normal"
            sx={{
              marginTop: 0,
              width: '100%',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
              className: ' bg-white',
            }}
            error={error}
            helperText={helperText}
          />
        }
      />
    </div>
  )
}

export default DateTimeInput
