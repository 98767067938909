import { getToken } from 'utils/authCookies'

const checkoutBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/checkout`

export const getCheckoutSessions = async (query: {
  orgId: number
  limit?: number
  offset?: number
  status?: string
}) => {
  const token = await getToken()

  const { orgId, offset, limit, status } = query

  let url = `${checkoutBasePath}?orgId=${orgId}`
  if (limit && typeof offset === 'number') {
    url = url + `&offset=${offset}&limit=${limit}`
  }

  if (status) {
    url = url + `&status=${status}`
  }

  try {
    const res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    if (res.ok) {
      const { data } = await res.json()
      return data
    }
  } catch (err) {
    console.error(err)
  }
}

export const getCheckoutSession = async (orgId: number, uid: string) => {
  const token = await getToken()

  try {
    const res = await fetch(`${checkoutBasePath}/${uid}?orgId=${orgId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    const { data, message } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    } else {
      return { status: 'failed', message }
    }
  } catch (err) {
    console.error(err)
  }
}
