import React, { useEffect, useState } from 'react'

import { Button, Grid } from '@mui/material'

import Card from 'components/card'
import FileUpload from 'components/files/FileUpload'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import DisplayImage from 'pages/dashboard/components/DisplayImage'
import { updateProductOptionValue } from 'services/products'
import { Product } from 'types/product'

interface ProductFilesProps {
  productId?: number
  productCode: string
  productOptionId?: {
    optionId: number | null
    valueId: number | null
    media: string[] | null
  }
  onFilesAdd?: (file?: string) => void
  productMedia?: string[]
  onCloseDialog?: () => void
  onUpdateProduct?: (p: Product) => void
}
const fileUrl = `${process.env.REACT_APP_FILE_URL}`

const OptionValueFileUpload: React.FC<ProductFilesProps> = ({
  productCode,
  productOptionId,
  onCloseDialog,
  productMedia = [],
  onFilesAdd,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
  const [selectedImages, setSelectedImages] = useState<string[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()

  const uploadedImages = uploadedFiles.map((fname) => `${fileUrl}/${fname}`)
  const allMediaFiles = [
    ...new Set([
      ...productMedia,
      ...uploadedImages,
      ...(productOptionId?.media ?? []),
    ]),
  ]

  const toggleImageSelection = (fileName: string) => {
    if (productOptionId) {
      if (selectedImages.includes(fileName)) {
        setSelectedImages([])
      } else if (selectedImages.length < 1) {
        setSelectedImages([fileName])
      } else {
        showSnackbar('You can only select one image per variant.', 'error')
      }
    } else {
      setSelectedImages((prev) =>
        prev.includes(fileName)
          ? prev.filter((img) => img !== fileName)
          : [...prev, fileName]
      )
    }
  }

  useEffect(() => {
    if (productOptionId?.media && productOptionId.media.length > 0) {
      setSelectedImages(productOptionId.media)
    }
  }, [productOptionId])

  const updateOptionValue = async (): Promise<void> => {
    if (organizationId && productOptionId) {
      if (
        productOptionId.optionId !== null &&
        productOptionId.valueId !== null
      ) {
        const uploadOptionImage = selectedImages

        setIsUploading(true)
        const res = await updateProductOptionValue(
          organizationId,
          productCode,
          {
            optionValues: [
              {
                optionId: productOptionId.optionId,
                valueId: productOptionId.valueId,
                media: uploadOptionImage.length > 0 ? uploadOptionImage : [],
              },
            ],
          }
        )

        if (res.status === 'successful') {
          onCloseDialog?.()
          onFilesAdd?.(uploadOptionImage[0])
        }
        setIsUploading(false)
      }
    }
  }

  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-5">Product Images</h2>
      <p className="text-sm text-gray-500 mb-2 ml-5">
        Select images of the product from your device, or simply drag and drop
        them here.
      </p>
      <Card>
        {allMediaFiles && allMediaFiles.length > 0 && (
          <Grid container spacing={1} marginBottom={2}>
            {allMediaFiles.map((fileName, index) => (
              <Grid item key={index} xs={3} sm={2} md={1.5}>
                <DisplayImage
                  imageUrl={fileName}
                  height={120}
                  {...(productOptionId && {
                    isSelectedImage: selectedImages.includes(fileName),
                    onSelectImage: () => toggleImageSelection(fileName),
                  })}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {allMediaFiles && allMediaFiles.length >= 10 ? (
          <p>
            Image limit reached! You can upload up to 10 images for this
            product. Please choose from the existing images or delete one to add
            a new image.
          </p>
        ) : (
          <FileUpload
            onClose={() => {}}
            onSuccess={(file: string) => setUploadedFiles((f) => [...f, file])}
            uploadedCount={allMediaFiles.length}
            onUploading={() => setIsUploading((prev) => !prev)}
          />
        )}
      </Card>
      <div className="flex justify-end mt-4">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          sx={{ textTransform: 'none', marginRight: '8px' }}
          onClick={() => onCloseDialog?.()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ textTransform: 'none' }}
          onClick={updateOptionValue}
          disabled={isUploading}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default OptionValueFileUpload
