import { FC, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { updateProduct } from 'services/products'
import { Product, ProductOption } from 'types/product'

import FilesDetails from './FilesDetails'
import ManageStatusDetail from './ManageStatusDetail'
import NotesDetails from './NotesDetails'
import PricingDetails from './PricingDetails'
import BasicDetails from './ProductDetails'
import TagsDetails from './TagsDetails'
import VariantsDetails from './VariantsDetails'

interface ProductReviewProps {
  productDetails: Product
  variantOptions: ProductOption[]
  onEditStep: (step: number) => void
  onPrevStep: (step?: number) => void
}

const ProductReview: FC<ProductReviewProps> = ({ productDetails }) => {
  const [product, setProduct] = useState<Product>(productDetails)
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { organization } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleNextStep = async (): Promise<void> => {
    setIsSubmitting(true)
    const res = await updateProduct({
      productId: product.id,
      dataUpdate: {
        status: 'ACTIVE',
      },
    })
    if (res.status === 'successful') {
      showSnackbar('Product added successfully', 'success')
      navigate('/dashboard/products/all')
    } else {
      showSnackbar('Failed to update product status', 'error')
    }
    setIsSubmitting(false)
  }

  const handlePreviewButtonClick = (): void => {
    if (organization && organization.customDomain) {
      window.open(
        `https://${organization.customDomain}/products/preview/${product.route}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else if (organization) {
      const productRoute = `${organization.website}/products/preview/${product.route}`

      window.open(productRoute, '_blank', 'noopener,noreferrer')
    }
  }

  const handleUpdate = (data?: Product): void => {
    if (data) {
      setProduct(data)
    }
  }

  return (
    <div className="mb-5">
      <Box
        className="m-5 p-5 rounded"
        sx={{ border: '1px solid', borderColor: 'accents.lightGrayAccent' }}
      >
        <Typography variant="h6" gutterBottom>
          Preview and Verify Your Product Details
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Before finalizing, take a moment to review all the details of your
          product. Ensure the information is accurate, images are correctly
          displayed, and pricing is as intended. Once you&apos;re satisfied, you
          can proceed to save and add the product. If you need to make changes,
          you can go back and edit any section.
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ textTransform: 'none' }}
          onClick={handlePreviewButtonClick}
        >
          Preview Product
        </Button>
      </Box>

      <BasicDetails product={product} onUpdate={handleUpdate} />
      <PricingDetails product={product} onUpdate={handleUpdate} />

      <FilesDetails
        media={product.media}
        productId={product.id}
        productCode={product.productCode}
      />

      <VariantsDetails
        variants={product.ProductOption}
        productCode={product.productCode}
        onUpdate={handleUpdate}
        product={product}
      />
      <NotesDetails
        productId={product.id}
        notesData={product.notes}
        onUpdate={handleUpdate}
      />
      <TagsDetails
        productId={product.id}
        tagIds={product.tagIds}
        onUpdate={handleUpdate}
      />
      <ManageStatusDetail product={product} onUpdate={handleUpdate} />
      <div className="m-5">
        <div className="flex items-center justify-center">
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={handleNextStep}
          >
            {isSubmitting ? 'Adding' : 'Add Product'}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default ProductReview
