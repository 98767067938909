import { FC, ChangeEvent, useState, useEffect, JSX } from 'react'

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import PhoneNumberInput from 'pages/dashboard/components/PhoneNumberInput'
import { updateOrganization } from 'services/organization'
import { Organization } from 'services/organization/index.types'
import { getRestrictedCountriesList } from 'utils/countries'
import { phoneNumberUtil, validatePhoneNumber } from 'utils/phoneNumber'

type Socials = {
  platform: string
  value: string
  label: string
}

interface SocialProps {
  social?: string
  onUpdate?: (org?: Organization) => void
}

const platforms = ['Whatsapp', 'Youtube', 'Instagram', 'Facebook', 'Twitter']

const countriesList = getRestrictedCountriesList()

const Social: FC<SocialProps> = ({ social, onUpdate }): JSX.Element => {
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [edit, setEdit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [whatsappNumber, setWhatsappNumber] = useState({
    countryCode: '',
    phoneNumber: '',
  })
  const [error, setError] = useState({
    countryCode: '',
    phoneNumber: '',
    message: '',
  })
  const [socialData, setSocialData] = useState({
    youtube: '',
    facebook: '',
    twitter: '',
    instagram: '',
  })

  const handleEdit = () => {
    setEdit(true)
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_SOCIAL_EDIT_CLICKED', {
        organizationId,
      })
    }
  }

  const socialArray: Socials[] = social ? JSON.parse(social) : []

  const socialViewFormat = platforms.map((platform) => {
    const obj = socialArray.find((obj) => obj.label === platform.toLowerCase())

    if (obj) {
      return { platform, value: obj.value }
    }
    return { platform, value: 'N/A' }
  })

  useEffect(() => {
    if (social) {
      const resData: Socials[] = JSON.parse(social)
      const whatsappNumber = resData.find((obj) => obj.label === 'whatsapp')

      if (whatsappNumber) {
        const countryCode =
          phoneNumberUtil.parse(whatsappNumber.value).getCountryCode() ?? ''
        const phoneNo =
          phoneNumberUtil.parse(whatsappNumber.value).getNationalNumber() ?? ''
        setWhatsappNumber({
          countryCode: '+' + countryCode.toString(),
          phoneNumber: phoneNo.toString(),
        })
      }

      platforms.forEach((platform) => {
        const obj = resData.find((obj) => obj.label === platform.toLowerCase())

        if (obj && obj.label !== 'whatsapp') {
          setSocialData((prev) => ({
            ...prev,
            [obj.label]: obj.value.split('/')[3],
          }))
        }
      })
    }
  }, [social, edit])

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'countryCode') {
      setWhatsappNumber((prev) => ({ ...prev, countryCode: e.target.value }))
    } else {
      setWhatsappNumber((prev) => ({ ...prev, phoneNumber: e.target.value }))
    }
  }

  const handlePhoneNumberBlur = (): void => {
    if (
      whatsappNumber.phoneNumber === '' &&
      whatsappNumber.countryCode !== ''
    ) {
      setError((prev) => ({
        ...prev,
        phoneNumber: 'Invalid',
        message: '*Whatspp number is required.',
      }))
      return
    }

    if (
      whatsappNumber.phoneNumber !== '' &&
      whatsappNumber.countryCode === ''
    ) {
      setError((prev) => ({
        ...prev,
        countryCode: 'Invalid',
        phoneNumber: '',
        message: '*Country code is required.',
      }))
      return
    }

    setError((prev) => ({
      ...prev,
      phoneNumber: '',
      countryCode: '',
      message: '',
    }))
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.replace(/\s/g, '')
    setSocialData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }))
  }

  const handleSubmit = async () => {
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_SOCIAL_SAVE_CLICKED', {
        organizationId,
      })
    }
    if (error.countryCode === 'Invalid' || error.phoneNumber === 'Invalid')
      return

    if (
      whatsappNumber.countryCode !== '' &&
      whatsappNumber.phoneNumber !== ''
    ) {
      const isValid = validatePhoneNumber(
        whatsappNumber.countryCode,
        whatsappNumber.phoneNumber
      )
      if (!isValid) {
        setError((prev) => ({
          ...prev,
          phoneNumber: 'Invalid',
          countryCode: '',
          message: '*Invalid whatsapp number',
        }))
        return
      }
    }

    const data: Socials[] = []

    if (socialData.youtube !== '') {
      data.push({
        platform: 'Youtube',
        value: 'https://www.youtube.com/' + socialData.youtube,
        label: 'youtube',
      })
    }

    if (socialData.facebook !== '') {
      data.push({
        platform: 'Facebook',
        value: 'https://www.facebook.com/' + socialData.facebook,
        label: 'facebook',
      })
    }

    if (socialData.instagram !== '') {
      data.push({
        platform: 'Instagram',
        value: 'https://www.instagram.com/' + socialData.instagram,
        label: 'instagram',
      })
    }
    if (socialData.twitter !== '') {
      data.push({
        platform: 'Twitter',
        value: 'https://www.x.com/' + socialData.twitter,
        label: 'twitter',
      })
    }
    if (
      whatsappNumber.countryCode !== '' &&
      whatsappNumber.phoneNumber !== ''
    ) {
      data.push({
        platform: 'Whatsapp',
        value: whatsappNumber.countryCode + whatsappNumber.phoneNumber,
        label: 'whatsapp',
      })
    }

    if (data.length === 0 && socialArray.length === 0) return

    setIsSubmitting(true)
    const res = organizationId
      ? await updateOrganization(organizationId, { social: data })
      : { status: 'failed' }

    if (res?.status === 'successful') {
      onUpdate?.(res.data)
      showSnackbar('Social fields updated successfully.', 'success')
    }
    setIsSubmitting(false)
    setEdit(false)
  }

  const handleCancel = (): void => {
    setEdit(false)
    setError({ countryCode: '', phoneNumber: '', message: '' })
  }

  return (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <h2 className="text-lg font-semibold ml-5 mr-2">Social</h2>
        {!edit && (
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleEdit}
            sx={{
              backgroundColor: '#e0e0e0',
              color: 'text.primary',
              textTransform: 'none',
              padding: '0.1rem 0.75rem',
              ':hover': { backgroundColor: '#bdbdbd' },
              marginRight: '20px',
            }}
          >
            Edit
          </Button>
        )}
      </Box>
      <Card>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {!edit &&
            socialViewFormat.map((el, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                    fontWeight: '600',
                  }}
                >
                  {el.platform}
                </Typography>
                <Typography sx={{ fontWeight: '700' }}>{el.value}</Typography>
              </Grid>
            ))}
          {edit && (
            <>
              <Grid item xs={12} md={6}>
                <Label htmlFor="whatsapp" text="Whatsapp" />
                <PhoneNumberInput
                  countryCodeName="countryCode"
                  phoneNumberName="phoneNumber"
                  onChange={handlePhoneNumberChange}
                  onBlur={handlePhoneNumberBlur}
                  value={{
                    countryCode: whatsappNumber.countryCode,
                    phoneNumber: whatsappNumber.phoneNumber,
                  }}
                  countriesList={countriesList}
                  error={{
                    countryCode: error.countryCode,
                    phoneNumber: error.phoneNumber,
                  }}
                  helperText={{
                    countryCode: error.countryCode && error.message,
                    phoneNumber: error.phoneNumber && error.message,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label htmlFor="youtube" text="Youtube" />
                <TextField
                  sx={{ marginTop: 0 }}
                  fullWidth
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  name="youtube"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        https://www.youtube.com/
                      </InputAdornment>
                    ),
                  }}
                  value={socialData.youtube}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label htmlFor="instagram" text="Instagram" />
                <TextField
                  sx={{ marginTop: 0 }}
                  fullWidth
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  name="instagram"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        https://www.instagram.com/
                      </InputAdornment>
                    ),
                  }}
                  value={socialData.instagram}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label htmlFor="facebook" text="Facebook" />
                <TextField
                  sx={{ marginTop: 0 }}
                  fullWidth
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  name="facebook"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        https://www.facebook.com/
                      </InputAdornment>
                    ),
                  }}
                  value={socialData.facebook}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label htmlFor="twitter" text="Twitter" />
                <TextField
                  sx={{ marginTop: 0 }}
                  fullWidth
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  name="twitter"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        https://x.com/
                      </InputAdornment>
                    ),
                  }}
                  value={socialData.twitter}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        {edit && (
          <Box className=" mt-6 flex items-center gap-4">
            <Button variant="outlined" size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </Box>
        )}
      </Card>
    </Box>
  )
}

export default Social
