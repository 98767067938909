import { Navigate } from 'react-router-dom'

import ForgotPasswordForm from 'components/forms/ForgotPasswordForm'
import OnboardingContainer from 'components/forms/OnboardingContainer'
import { useAuth } from 'hooks/useAuth'

const ForgotPassword = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Navigate to="/dashboard/home" replace={true} />

  return (
    <OnboardingContainer
      title="Forgot Password?"
      description="Enter your email address associated with this account"
    >
      <ForgotPasswordForm />
    </OnboardingContainer>
  )
}

export default ForgotPassword
