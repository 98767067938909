import { Product } from 'types/product'
import { getToken } from 'utils/authCookies'

import {
  CreateProductParams,
  GetProductProps,
  ProductOption,
  ProductOptionUpdateParams,
  ProductOptionValue,
  ProductOptionValueUpdateParams,
  UpdateProductProps,
} from './index.types'

interface ProductQueryParams {
  orgId: number
  offset?: string
  limit?: string
  q?: string
  min_price?: string
  max_price?: string
  color?: string
  sort?: string
  order?: 'asc' | 'desc'
  availability?: string
}

const productBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/products`

const getProduct = async ({ productCode, organizationId }: GetProductProps) => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${productBasePath}/dashboard/${productCode}?orgId=${organizationId}`,
      {
        method: 'GET',
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      }
    )

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}

const getAllProducts = async (query: ProductQueryParams) => {
  const token = await getToken()

  const params = new URLSearchParams()

  if (query.offset) params.append('offset', query.offset)
  if (query.limit) params.append('limit', query.limit)
  if (query.q) params.append('q', query.q)
  if (query.min_price) params.append('min_price', query.min_price)
  if (query.max_price) params.append('max_price', query.max_price)
  if (query.color) params.append('color', query.color)
  if (query.sort) params.append('sort', query.sort)
  if (query.order) params.append('order', query.order)
  if (query.availability) params.append('availability', query.availability)

  params.append('orgId', query.orgId.toString())

  const url = `${productBasePath}?${params.toString()}`

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (res.ok) {
      const result = await res.json()
      return {
        status: 'successful',
        data: result.data.products,
        total: result.data.total,
        highestPrice: result.data.highestPrice,
      }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting products:', error)
    throw error
  }
}

const updateProduct = async ({ productId, dataUpdate }: UpdateProductProps) => {
  const token = await getToken()
  try {
    const res = await fetch(`${productBasePath}/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify(dataUpdate),
    })

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error updating product', error)
    throw error // Rethrow the error to be handled by the caller
  }
}

export const createProduct = async (
  newProduct: Partial<CreateProductParams>
): Promise<{ status: string; data?: Product; message?: string }> => {
  const token = await getToken()
  try {
    const res = await fetch(productBasePath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify(newProduct),
    })

    const { data, message } = await res.json()

    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed', message }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}

const createCategory = async (createData: {
  name: string
  organizationId: number
}) => {
  const token = await getToken()

  try {
    const res = await fetch(`${productBasePath}/category`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify(createData),
    })
    const { data } = await res.json()
    if (res.ok) return { status: 'successful', data: data }
  } catch (error) {
    console.error('Error', error)
  }
}

const getCategories = async (organizationId: number) => {
  const token = await getToken()
  try {
    const res = await fetch(`${productBasePath}/categories/${organizationId}`, {
      method: 'GET',
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    })
    const { data } = await res.json()

    if (res.ok) return { status: 'successful', data: data }
  } catch (error) {
    console.error('Error', error)
  }
}

const deleteProduct = async (
  id: number
): Promise<{
  status: string
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${productBasePath}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'DELETE',
    })

    const { error } = await res.json()

    if (res.ok) {
      return { status: 'successful' }
    }
    return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const createProductOption = async (
  productCode: string,
  organizationId: number,
  createData: {
    name: string
    values: {
      value: string
      caption?: string
    }[]
    type: string
  }
): Promise<{ status: string; data?: ProductOption; message?: string }> => {
  const token = await getToken()

  try {
    const res = await fetch(
      `${productBasePath}/${productCode}/option?orgId=${organizationId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(createData),
      }
    )

    const { data, message } = await res.json()

    if (res.ok) return { status: 'successful', data: data }
    return {
      status: 'failed',
      message: message || 'Failed to create product option',
    }
  } catch (error) {
    console.error('Error', error)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const deleteProductOption = async (
  organizationId: number,
  productCode: string,
  optionId: number
): Promise<{
  status: string
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(
      `${productBasePath}/${productCode}/option/${optionId}?orgId=${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
      }
    )

    if (res.ok)
      return {
        status: 'successful',
        message: 'Product option deleted successfully',
      }
    return { status: 'failed', message: 'Failed to delete product option' }
  } catch (error) {
    console.error('Error', error)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const updateProductOptionValue = async (
  organizationId: number,
  productCode: string,
  updateData: {
    optionValues: {
      optionId: number
      valueId: number
      price?: number
      priceAdjustment?: number
      stock?: number | null
      media?: string[]
    }[]
  }
): Promise<{ status: string; data?: ProductOptionValue; message?: string }> => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${productBasePath}/${productCode}/multiple-option?orgId=${organizationId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      }
    )
    const { data } = await res.json()
    if (res.ok) return { status: 'successful', data: data }
    return {
      status: 'failed',
      message: 'Failed to update product variant value',
    }
  } catch (error) {
    console.error('Error', error)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const updateProductOption = async (
  organizationId: number,
  productCode: string,
  optionId: number,
  updateData: ProductOptionUpdateParams
): Promise<{ status: string; data?: ProductOption; message?: string }> => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${productBasePath}/${productCode}/option/${optionId}?orgId=${organizationId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      }
    )
    const { data } = await res.json()
    if (res.ok) return { status: 'successful', data: data }
    return {
      status: 'failed',
      message: 'Failed to update product option.',
    }
  } catch (error) {
    console.error('Error', error)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const updateOptionValue = async (
  organizationId: number,
  productCode: string,
  optionId: number,
  valueId: number,
  updateData: ProductOptionValueUpdateParams
): Promise<{ status: string; data?: ProductOptionValue; message?: string }> => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${productBasePath}/${productCode}/option/${optionId}/value/${valueId}?orgId=${organizationId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      }
    )
    const { data } = await res.json()
    if (res.ok) return { status: 'successful', data: data }
    return {
      status: 'failed',
      message: 'Failed to update product variant value.',
    }
  } catch (error) {
    console.error('Error', error)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const deleteOptionValue = async (
  organizationId: number,
  productCode: string,
  optionId: number,
  valueId: number
): Promise<{ status: string; data?: ProductOptionValue; message?: string }> => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${productBasePath}/${productCode}/option/${optionId}/value/${valueId}?orgId=${organizationId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { data } = await res.json()
    if (res.ok) return { status: 'successful', data: data }
    return {
      status: 'failed',
      message: 'Failed to update product variant value.',
    }
  } catch (error) {
    console.error('Error', error)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

export {
  getProduct,
  getAllProducts,
  updateProduct,
  createCategory,
  getCategories,
  deleteProduct,
  createProductOption,
  deleteProductOption,
  updateProductOptionValue,
  updateProductOption,
  updateOptionValue,
  deleteOptionValue,
}
