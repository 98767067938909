import { FC, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useSnackbar } from 'hooks/useSnackbar'
import { updateProduct } from 'services/products'

interface CancelDialogProps {
  open: boolean
  onClose: () => void
  productId: number | null
}

const CancelDialog: FC<CancelDialogProps> = ({ open, onClose, productId }) => {
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSaveDraft = () => {
    navigate('/dashboard/products/all', { replace: true })
    onClose()
  }

  const handleDiscardProduct = async () => {
    if (!productId) {
      navigate('/dashboard/products/all', { replace: true })
      onClose()
      return
    }
    setIsSubmitting(true)
    const res = await updateProduct({
      productId,
      dataUpdate: { isDeleted: true },
    })

    if (res.status === 'successful') {
      navigate('/dashboard/products/all', { replace: true })
      onClose()
    } else if (res.status === 'failed') {
      showSnackbar('Something went wrong. Try again.', 'error')
    }
    setIsSubmitting(false)
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Unsaved Changes - Keep or Discard?</DialogTitle>
      <DialogContent>
        <p className="text-sm">
          Are you sure that you want to cancel adding this product? Your changes
          will be lost if you discard them.
        </p>
      </DialogContent>

      <DialogActions>
        <div className="w-full flex items-center justify-center gap-2 mb-4">
          <Button
            variant={'outlined'}
            color="error"
            size="small"
            disableElevation
            sx={{ textTransform: 'none' }}
            onClick={handleDiscardProduct}
            disabled={isSubmitting}
          >
            Discard Product
          </Button>
          <Button
            variant={'contained'}
            size="small"
            disableElevation
            sx={{ textTransform: 'none' }}
            onClick={handleSaveDraft}
          >
            Save Draft
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default CancelDialog
