import React, { useEffect, useState } from 'react'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
} from '@mui/material'
import Select, { SingleValue } from 'react-select'

import UpgradeDialog from 'components/upgrade-plan/UpgradeDialog'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { deletePendingInvitation } from 'services/organization'
import { getPricingPlan } from 'services/pricingPlans'
import { PricingPlan } from 'services/pricingPlans/index.type'
import {
  changeRoleInOrganization,
  getStoreUsers,
  inviteAsMember,
} from 'services/store'

import MultiEmailField from './MultiEmailField'

interface User {
  firstName: string | null
  lastName: string | null
  email: string
}

interface OrganizationUser {
  userId: number
  organizationId: number
  role: 'ADMIN' | 'MEMBER' | 'OWNER'
  createdAt: string
  updatedAt: string
  invitedBy: number | null
  acceptedAt: string | null
  declinedAt: string | null
  isActive: boolean
  user: User
}

type AcceptingUser = {
  userId: number
  fullName: string
  role: string
  email: string
}

type PendingUser = {
  userId: number
  role: string
  email: string
}

interface RoleOption {
  value: string
  label: string
}

const roleOptions: RoleOption[] = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'MEMBER', label: 'Member' },
  { value: 'OWNER', label: 'Owner' },
]

const ManageAccess: React.FC = () => {
  const [storeUsers, setStoreUsers] = useState<OrganizationUser[]>([])
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false)
  const [inviteEmails, setInviteEmails] = useState<
    { label: string; value: string }[]
  >([])
  const [invitedUsers, setInvitedUsers] = useState<
    { email: string; role: string }[]
  >([])

  const [roleSelections, setRoleSelections] = useState<Record<number, string>>(
    {}
  )
  const [currentPlanDetails, setCurrentPlanDetails] =
    useState<PricingPlan | null>(null)
  const { organizationId, user, organization } = useAuth()
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    async function fetchOrgMembers(): Promise<void> {
      if (organizationId) {
        try {
          const result = await getStoreUsers(organizationId)

          if (result.status === 'successful' && result.data) {
            setStoreUsers(result.data?.users ?? [])
          }
        } catch (error) {
          console.error('Error fetching organization members:', error)
        }
      }
    }

    fetchOrgMembers()
  }, [organizationId])

  useEffect(() => {
    async function fetchPlanDetails(): Promise<void> {
      if (organization && organization.currentPricingPlanId) {
        try {
          const planResult = await getPricingPlan(
            organization.currentPricingPlanId
          )
          if (planResult && planResult.data) {
            setCurrentPlanDetails(planResult.data)
          }
        } catch (error) {
          console.error('Error fetching plan details', error)
        }
      }
    }

    fetchPlanDetails()
  }, [organization])

  const handleUsersInvite = async (emails: string[]) => {
    try {
      if (!organizationId) throw new Error('Organization ID Not Found')

      const role = 'MEMBER'
      const inviteData = { emails, role, organizationId }

      const response = await inviteAsMember(inviteData)

      if (response.status !== 'successful') {
        throw new Error(response.message || 'Failed')
      }

      const updatedUsers = await getStoreUsers(organizationId)
      if (updatedUsers.status === 'successful' && updatedUsers.data) {
        setStoreUsers(updatedUsers.data?.users ?? [])
      }
    } catch (err) {
      console.error('Error in invitation process:', err)
    }
  }

  const handleUserRoleChange = async (
    userId: number,
    newRole: string
  ): Promise<boolean> => {
    try {
      if (!organizationId) throw new Error('Organization ID Not Found')

      const response = await changeRoleInOrganization({
        userId,
        role: newRole,
        organizationId,
      })

      if (response.status === 'successful') {
        showSnackbar('Role updated successfully.', 'success')
        return true
      } else {
        showSnackbar(response.message || 'Failed to update role.', 'error')
        return false
      }
    } catch (error) {
      showSnackbar(
        'Error changing role: ' + (error || 'Unknown error'),
        'error'
      )
      return false
    }
  }

  const handleInvite = () => {
    if (organizationId) {
      Amplitude.trackEvent('STORE_MANAGEACCESS_INVITE_CLICKED', {
        orgId: organizationId,
      })
    }

    if (
      currentPlanDetails &&
      storeUsers.length >= currentPlanDetails.maxStaffAccounts
    ) {
      setIsUpgradeDialogOpen(true)
    }
    if (inviteEmails.length > 0) {
      const newInvites = inviteEmails.map((email) => ({
        email: email.value,
        role: 'Member',
      }))
      setInvitedUsers([...invitedUsers, ...newInvites])
      handleUsersInvite(inviteEmails.map((email) => email.value))
      setInviteEmails([])
    }
  }

  const handleCloseUpgradeDialog = () => {
    setIsUpgradeDialogOpen(false)
  }

  const handleRoleChange = async (userId: number, newRole: string) => {
    try {
      const response = await handleUserRoleChange(userId, newRole)
      if (response) {
        setRoleSelections((prevSelections) => ({
          ...prevSelections,
          [userId]: newRole,
        }))
      }
    } catch (error) {
      console.error('Failed to update role:', error)
    }
  }

  const acceptingUsers: AcceptingUser[] = storeUsers
    .filter((storeUser) => storeUser.acceptedAt !== null)
    .map((storeUser) => ({
      userId: storeUser.userId,
      fullName:
        `${storeUser.user?.firstName || ''} ${storeUser.user?.lastName || ''} ${
          storeUser.userId === user?.id ? '(You)' : ''
        }`.trim(),
      role: storeUser.role,
      email: storeUser.user ? storeUser.user.email : 'Unknown Email',
    }))

  const pendingUsers: PendingUser[] = storeUsers
    .filter(
      (user) =>
        user.acceptedAt === null &&
        user.declinedAt === null &&
        user.role !== 'OWNER'
    )
    .map((user) => ({
      userId: user.userId,
      role: user.role,
      email: user.user ? user.user.email : 'Unknown Email',
    }))

  const handleDeleteUser = async (userId: number) => {
    if (organizationId) {
      const result = await deletePendingInvitation(organizationId, userId)
      if (result.status === 'successful') {
        setStoreUsers((prevUsers) =>
          prevUsers.filter((user) => user.userId !== userId)
        )
        showSnackbar('User invitation deleted successfully.', 'success')
      } else {
        showSnackbar('Failed to delete invitation. Please try again.', 'error')
      }
    }
  }

  return (
    <div>
      <h2 className="text-lg font-semibold ml-5 mb-5">Members</h2>
      <div className=" mx-5">
        <Box
          className="flex flex-col sm:flex-row mb-4 sm:items-center"
          sx={{ width: '100%' }}
        >
          <Box sx={{ flex: 1 }} className="mb-3 sm:mb-0">
            <MultiEmailField value={inviteEmails} onChange={setInviteEmails} />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleInvite}
            disableElevation
            className="h-8"
            sx={{
              marginLeft: { sm: '1rem' },
              textTransform: 'none',
            }}
          >
            Invite
          </Button>
        </Box>
      </div>

      <Box className="rounded border mx-5" sx={{ overflowX: 'auto' }}>
        <List sx={{ backgroundColor: 'secondary.main' }}>
          {acceptingUsers.length > 0 &&
            acceptingUsers.map((user, index) => (
              <React.Fragment key={user.userId}>
                <ListItem
                  sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'flex-start', md: 'center' },
                  }}
                >
                  <ListItemText
                    primary={user.fullName}
                    secondary={user.email}
                    sx={{ flex: '1 1 auto' }}
                  />
                  <Box className="md:text-center  md:w-[144px] md:mr-10">
                    <Typography fontWeight={700}>{user.role}</Typography>
                  </Box>
                </ListItem>
                {(index < acceptingUsers.length - 1 ||
                  pendingUsers.length > 0) && <Divider />}
              </React.Fragment>
            ))}
        </List>

        {pendingUsers.length > 0 && (
          <List sx={{ backgroundColor: 'secondary.main' }}>
            {pendingUsers.map((user, index) => (
              <React.Fragment key={user.userId}>
                <ListItem
                  sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'flex-start', md: 'center' },
                  }}
                >
                  <ListItemText
                    primary={user.email}
                    secondary="Pending Invite"
                  />
                  <Box className="flex items-center justify-between w-full md:w-auto">
                    <Select
                      value={
                        roleOptions.find(
                          (option) =>
                            option.value ===
                            (roleSelections[user.userId] || user.role)
                        ) ||
                        roleOptions.find((option) => option.value === 'Member')
                      }
                      onChange={(selectedOption: SingleValue<RoleOption>) =>
                        handleRoleChange(
                          user.userId,
                          selectedOption?.value || 'MEMBER'
                        )
                      }
                      options={roleOptions}
                      styles={{
                        control: (base) => ({
                          ...base,
                          fontSize: '0.875rem',
                          width: '144px',
                        }),
                        menu: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      isSearchable={false}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: theme.colors.neutral20,
                        },
                      })}
                      menuPortalTarget={document.body}
                    />
                    <IconButton
                      onClick={() => handleDeleteUser(user.userId)}
                      sx={{
                        color: 'black',
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                </ListItem>
                {index < pendingUsers.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>
      <UpgradeDialog
        open={isUpgradeDialogOpen}
        onClose={handleCloseUpgradeDialog}
        title="Staff Limit Reached"
        description="You've reached the maximum number of staff accounts allowed under your current plan. To add more staff members to your store, please upgrade your plan."
      />
    </div>
  )
}

export default ManageAccess
