import { useEffect, useRef } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { getAuthTokensFromGoogle } from 'services/auth'
import { getUserOrganizations } from 'services/organization'

const SigninCallback = () => {
  const { signInWithGoogle } = useAuth()
  const [searchParams] = useSearchParams()
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const code = searchParams.get('code')
  const hasCalledAuth = useRef(false)

  const getAuthTokens = async () => {
    if (code && code.length > 0 && !hasCalledAuth.current) {
      hasCalledAuth.current = true
      const response = await getAuthTokensFromGoogle(code)

      if (response?.status === 'successful') {
        signInWithGoogle(response?.data)
        const { data, status } = await getUserOrganizations()
        if (status === 'successful' && data) {
          if (data.length === 0) navigate('/create-your-store')
          else {
            navigate('/dashboard/home')
          }
        } else {
          showSnackbar('Something went wrong, please try again!', 'error')
          navigate('/')
        }
      }
    }
  }

  useEffect(() => {
    getAuthTokens()
  }, [code])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="w-full"
    >
      <CircularProgress /> <p>Signing you in...</p>
    </Box>
  )
}

export default SigninCallback
