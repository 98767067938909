import { JSX } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Card from 'components/card'

const SetupStorePrompt = (): JSX.Element => {
  const naviagte = useNavigate()

  const handleNavigate = (): void => {
    naviagte('/dashboard/home')
  }
  return (
    <Card>
      <Box textAlign={'center'}>
        <Typography mb={1}>
          It appears that no store has been set up yet. Please complete the
          setup to begin managing your store.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ textTransform: 'none' }}
          onClick={handleNavigate}
        >
          Set Up Your Store
        </Button>
      </Box>
    </Card>
  )
}

export default SetupStorePrompt
