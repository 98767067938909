import { FC, useState, useEffect, useRef } from 'react'

import { Typography } from '@mui/material'
import DOMPurify from 'dompurify'

interface TextCollapseProps {
  text?: string | null
}

const TextCollapse: FC<TextCollapseProps> = ({ text }) => {
  const [showFullText, setShowFullText] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(
        textRef.current.scrollHeight > textRef.current.clientHeight
      )
    }
  }, [text])

  const handleToggleText = (): void => {
    setShowFullText((prev) => !prev)
  }

  if (!text || text.trim() === '') {
    return <Typography>N/A</Typography>
  }

  return (
    <div>
      <Typography
        ref={textRef}
        sx={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: showFullText ? 'none' : 3,
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(text),
        }}
      />
      {isTruncated && (
        <button
          onClick={handleToggleText}
          className="border border-solid rounded text-xs px-2 py-1 mt-2 bg-white hover:bg-slate-50"
        >
          {showFullText ? 'See Less' : 'See More'}
        </button>
      )}
    </div>
  )
}

export default TextCollapse
