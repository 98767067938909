import { useEffect, useState } from 'react'

import CircleIcon from '@mui/icons-material/Circle'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { getProduct } from 'services/products'
import { ProductOption } from 'services/products/index.types'
import { Product } from 'types/product'

import CancelDialog from '../components/CancelDialog'
import ProductDetails from '../components/Details'
import ProductFiles from '../components/Files'
import NotesAndTags from '../components/NotesAndTags'
import ProductReview from '../components/ProductReview'
import ProductStatus from '../components/Status'
import VariantOptions from '../components/VariantOptions'
import OptionPriceAndStock from '../components/VariantPriceStock'
import ProductVariants from '../components/Variants'

interface AddProductPageProps {
  productId?: number
}
export interface Variants {
  name: string
  type: string
  price: string
  quantity: string
  costPerUnit: string
  discountedPrice: string
  discountPercentage: string
  startOfferDate: Date | null
  endOfferDate: Date | null
}

export interface ProductDetailFormValues {
  name: string
  category: number[]
  description: string
  price: string
  quantity: string
  costPerUnit: string
  variants: Variants[]
  discountedPrice: string
  discountPercentage: string
  startOfferDate: Date | null
  endOfferDate: Date | null
  seoTitle: string
  seoDescription: string
  seoLink: string
  notes: string
  tagIds: number[]
  isActive: boolean
  isListed: boolean
}

const stepsTitle = [
  'Add Basic Details',
  'Upload Product Images',
  'Variants',
  'Define Variant Options',
  'Assign Variant Price & Stock',
  'Add Notes & Tags',
  'Manage Status',
  'Review',
]

const AddProductPage: React.FC<AddProductPageProps> = () => {
  const { productCode: code } = useParams<{ productCode: string }>()
  const navigate = useNavigate()
  const [variantOptions, setVariantOptions] = useState<ProductOption[]>([])
  const [productId, setProductId] = useState<number | null>(null)
  const [productCode, setProductCode] = useState<string | null>(null)
  const [product, setProduct] = useState<Product | null>(null)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const { organizationId } = useAuth()
  const [imageUploading, setImageUploading] = useState(false)

  const [step, setStep] = useState(1)

  useEffect(() => {
    async function fetchProductDetails(code?: string): Promise<void> {
      if (!code || !organizationId) return

      const result = await getProduct({ productCode: code, organizationId })

      if (result.status === 'successful' && result.data) {
        const { id, productCode, status, ProductOption } = result.data

        if (status === 'DRAFT') {
          setProductId(id)
          setProductCode(productCode)
          setProduct(result.data)
          setVariantOptions(ProductOption)
          if (ProductOption.length > 0) {
            setStep(4)
          }
        } else if (status === 'ACTIVE') {
          navigate(`/products/${productCode}`)
        }
      }
    }

    fetchProductDetails(code)
  }, [code, organizationId, navigate])

  const handleVariantOptions = (option: ProductOption): void => {
    setVariantOptions((prev) => [...prev, option])
  }

  const handleOptionsUpdate = (options: ProductOption[]): void => {
    setVariantOptions(options)
  }

  const handleRemoveVariantOption = (optionId: number): void => {
    setVariantOptions((prev) => prev.filter((option) => option.id !== optionId))
  }

  const handleCreateProduct = (id: number, code: string): void => {
    setProductId(id)
    setProductCode(code)
    handleNextStep()
  }

  const handleNoVariantsStep = (): void => {
    setStep(6)
  }

  const handleNextStep = (): void => {
    setStep(step + 1)
  }

  const handleProductUpdate = (product: Product): void => {
    setProduct(product)
  }

  const handlePrevStep = (stp?: number): void => {
    if (stp) {
      setStep(stp)
    } else setStep((prev) => prev - 1)
  }

  const handleEditStep = (stepToEdit: number): void => {
    setStep(stepToEdit)
  }

  return (
    <>
      <div className="flex items-center mx-5">
        <CircleIcon sx={{ fontSize: 11, color: 'text.secondary', mr: 0.5 }} />
        <Typography variant="body1" fontSize={12} color={'text.secondary'}>
          Step {step}/{stepsTitle.length}
        </Typography>
      </div>
      <Box
        display="flex"
        alignItems={'center'}
        marginBottom={2}
        justifyContent={'space-between'}
        className="mx-5"
      >
        <div className="flex items-center">
          <Typography variant="body1">
            <Typography
              component="span"
              color="text.primary"
              fontSize={20}
              fontWeight={700}
            >
              Add New Product :{' '}
            </Typography>
            <Typography
              component="span"
              color="primary"
              fontSize={20}
              fontWeight={700}
            >
              {stepsTitle[step - 1]}
            </Typography>
          </Typography>
        </div>
        {productId ? (
          <Button
            variant="outlined"
            size="small"
            sx={{
              textTransform: 'none',
              color: 'black',
              borderColor: '#d1d5db',
              '&:hover': {
                borderColor: '#6b7280',
              },
            }}
            onClick={() => setOpenCancelDialog(true)}
          >
            Cancel
          </Button>
        ) : (
          <Button
            variant="outlined"
            size="small"
            sx={{
              textTransform: 'none',
              color: 'black',
              borderColor: '#d1d5db',
              '&:hover': {
                borderColor: '#6b7280',
              },
            }}
            onClick={() =>
              navigate('/dashboard/products/all', { replace: true })
            }
          >
            Cancel
          </Button>
        )}
      </Box>
      <Box className="mb-5">
        {step === 1 && (
          <ProductDetails
            onProductCreate={handleCreateProduct}
            onCreate={handleProductUpdate}
            product={product}
            onNextStep={handleNextStep}
          />
        )}
        {step === 2 && productId && (
          <ProductFiles
            onNextStep={handleNextStep}
            productId={productId}
            productCode={productCode ?? undefined}
            onPrevStep={handlePrevStep}
            onUploading={() => setImageUploading((prev) => !prev)}
            uploadingImage={imageUploading}
            onUpdateProduct={handleProductUpdate}
          />
        )}
        {step === 3 && (
          <ProductVariants
            handleNoVariantsStep={handleNoVariantsStep}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        )}
        {step === 4 && productCode && (
          <VariantOptions
            productCode={productCode}
            onNextStep={handleNextStep}
            handleVariantOptions={handleVariantOptions}
            handleNoVariantsStep={handleNoVariantsStep}
            onRemoveVariantOption={handleRemoveVariantOption}
            variantOptions={variantOptions}
            onPrevStep={handlePrevStep}
          />
        )}
        {step === 5 && productId && productCode && (
          <OptionPriceAndStock
            productId={productId}
            productCode={productCode}
            variantOptions={variantOptions}
            handleNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
            product={product}
            onUpdateOptions={handleOptionsUpdate}
            onProductUpdate={handleProductUpdate}
          />
        )}

        {step === 6 && productId && (
          <NotesAndTags
            onNextStep={handleNextStep}
            productId={productId}
            productNotes={product?.notes}
            productTags={product?.tagIds}
            onProductUpdate={handleProductUpdate}
            onPrevStep={handlePrevStep}
            variantOptionsLength={variantOptions.length}
          />
        )}
        {step === 7 && productId && (
          <ProductStatus
            productId={productId}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
            onProductUpdate={handleProductUpdate}
          />
        )}
        {step === 8 && product && variantOptions && (
          <ProductReview
            productDetails={product}
            variantOptions={variantOptions}
            onEditStep={handleEditStep}
            onPrevStep={handlePrevStep}
          />
        )}
      </Box>
      <CancelDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        productId={productId}
      />
    </>
  )
}

export default AddProductPage
