import { JSX } from 'react'

import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

const Products = (): JSX.Element => {
  const { organization } = useAuth()
  return (
    <>
      <Helmet>
        <title>
          Products
          {organization && organization.name ? ` | ${organization.name}` : ''}
        </title>
      </Helmet>
      <Outlet />
    </>
  )
}

export default Products
