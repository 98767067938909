import { FC, useState } from 'react'

import { Button, FormControlLabel, Switch, Typography } from '@mui/material'

import Card from 'components/card'
import { useSnackbar } from 'hooks/useSnackbar'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'

interface ProductStatusProps {
  productId: number
  onPrevStep: (step?: number) => void
  onNextStep: () => void
  onProductUpdate: (product: Product) => void
}

const ProductStatus: FC<ProductStatusProps> = ({
  productId,
  onPrevStep,
  onNextStep,
  onProductUpdate,
}) => {
  const [isListed, setIsListed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()

  const handleStatusChange = (isListed: boolean): void => {
    setIsListed(isListed)
  }

  const handleNextStep = async (): Promise<void> => {
    setIsSubmitting(true)
    const res = await updateProduct({
      productId,
      dataUpdate: {
        isListed,
      },
    })
    if (res.status === 'successful') {
      showSnackbar('Product status updated successfully', 'success')
      onProductUpdate(res.data)
      onNextStep()
    } else {
      showSnackbar('Failed to update product status', 'error')
    }
    setIsSubmitting(false)
  }

  return (
    <div className=" mb-5">
      <h2 className="text-lg font-semibold ml-5">Manage Status</h2>
      <Typography variant="body2" color="textSecondary">
        Turn this on to make the product visible and searchable on the website
        upon creation.
      </Typography>
      <Card>
        <div className="mb-4">
          <FormControlLabel
            control={
              <Switch
                checked={isListed}
                onChange={() => handleStatusChange(!isListed)}
              />
            }
            label="List Product on Website"
          />
        </div>
      </Card>
      <div className="mx-5">
        <div className="flex items-center w-full">
          <div className="flex-1 basis-1/2">
            <Button
              size="small"
              variant="outlined"
              sx={{
                textTransform: 'none',
              }}
              onClick={() => onPrevStep(undefined)}
            >
              Prev: Add Notes & Tags
            </Button>
          </div>
          <div className="flex-1 basis-3/5">
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleNextStep}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Adding...' : 'Next : Review'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductStatus
