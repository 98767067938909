import React, { useState } from 'react'

import { Button } from '@mui/material'
import { SubmitHandler, Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'

import OnboardingContainer from './OnboardingContainer'
import { resendCode } from '../../services/auth'

interface FormValues {
  email: string
  password: string
  confirmationCode: string
}

const SignUpConfirmationForm = () => {
  const location = useLocation()
  const { email, password } = location.state || {}
  const { confirmSignUp } = useAuth()
  const navigate = useNavigate()
  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      email: email,
      password: password,
      confirmationCode: '',
    },
  })

  const [resendCodeSuccess, setResendCodeSuccess] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { showSnackbar } = useSnackbar()

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      const result = await confirmSignUp(
        values.email,
        values.password,
        values.confirmationCode
      )

      if (result.status === 'successful') {
        navigate('/create-your-store')
      } else if (result.status === 'failed' && result.message) {
        showSnackbar(result.message, 'error')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(
        error.message || 'An error occurred while confirming registration.'
      )
    }
  }

  const handleResendCode = async (): Promise<void> => {
    try {
      await resendCode({ email })
      setResendCodeSuccess(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(
        error.message || 'An error occurred while resending confirmation code.'
      )
    }
  }

  return (
    <OnboardingContainer
      title="Verify Your Email to Get Started"
      description="We've sent a verification code to your email. Enter the code below to complete your signup!"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <div className="mt-4 text-red-600 text-center">{error}</div>}
        <div className="mt-8 space-y-5">
          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="text-base font-medium text-gray-900"
            >
              Email Address
            </label>
            <div className="mt-2">
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder-text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
                    type="email"
                    placeholder="Email"
                    id="email"
                    disabled
                  />
                )}
              />
            </div>
          </div>
          {/* Confirmation Code */}
          <div>
            <label
              htmlFor="confirmationCode"
              className="text-base font-medium text-gray-900"
            >
              Confirmation Code
            </label>
            <div className="mt-2">
              <Controller
                name="confirmationCode"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    onChange={(e) => {
                      onChange(e.target.value.replace(/\s+/g, ''))
                    }}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
                    type="text"
                    placeholder="Confirmation Code"
                    id="confirmationCode"
                    autoComplete="off"
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-8">
          <Button
            variant="text"
            onClick={handleResendCode}
            disabled={resendCodeSuccess || formState.isSubmitting}
            className="mr-5"
          >
            {resendCodeSuccess ? 'Code Resent' : 'Resend Code'}{' '}
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="small"
            disabled={formState.isSubmitting}
          >
            Confirm
          </Button>
        </div>
      </form>
    </OnboardingContainer>
  )
}

export default SignUpConfirmationForm
