import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from '@mui/material'

import UpgradeButton from './UpgradeButton'

interface UpgradeDialogProps {
  open: boolean
  title?: string
  description?: string
  onClose: () => void
}

const UpgradeDialog: React.FC<UpgradeDialogProps> = ({
  open,
  onClose,
  title,
  description,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title ?? 'Upgrade Required'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description ?? 'Please upgrade your plan to perform this action.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className="flex justify-center w-full">
          <UpgradeButton />
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default UpgradeDialog
