import { ChangeEvent, FC, useState } from 'react'

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import { MuiColorInput } from 'mui-color-input'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { deleteOptionValue, updateOptionValue } from 'services/products'
import { Product, ProductOptionValue } from 'types/product'
import { formatPrice, isValidHex } from 'utils/helper'

import OptionValueFileUpload from './OptionFileUpload'

interface VariantRowProps {
  optionName?: string
  data: ProductOptionValue
  productMedia: string[]
  productCode: string
  onDelete?: (valueId: number) => void
  onUpdateProduct: (p: Product) => void
  product: Product
}

type FormValues = {
  value: string
  price: string
  stock: string
  caption: string
}

type Errors = Partial<Record<keyof FormValues, string>>

const PrimaryVariantRow: FC<VariantRowProps> = ({
  data,
  productCode,
  productMedia,
  onDelete,
  optionName,
  onUpdateProduct,
  product,
}) => {
  const [edit, setEdit] = useState(false)
  const [rowData, setRowData] = useState({
    value: data.value,
    price: data.price !== null ? formatPrice(data.price) : 'N/A',
    stock: data.stock !== null ? data.stock : 'N/A',
    media: data.media,
    caption: data.caption ?? '',
  })
  const [formValues, setFormValues] = useState({
    value: data.value,
    price: data.price !== null ? (data.price / 100).toString() : '',
    stock: data.stock !== null ? data.stock.toString() : '',
    media: data.media,
    caption: data.caption ?? '',
  })
  const [errors, setErrors] = useState({
    value: '',
    price: '',
    caption: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)

  const [currentOptionIds, setCurrentOptionIds] = useState<{
    optionId: number | null
    valueId: number | null
    media: string[] | null
  }>({
    optionId: null,
    valueId: null,
    media: null,
  })
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value

    setFormValues((prev) => ({ ...prev, value: value }))

    if (value !== '' && errors.value !== '') {
      setErrors((prev) => ({ ...prev, value: '' }))
    }
  }

  const handleColorValueChange = (color: string): void => {
    setFormValues((prev) => ({ ...prev, value: color }))

    if (color !== '' && errors.value !== '') {
      setErrors((prev) => ({ ...prev, value: '' }))
    }
  }
  const handleCaptionChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value

    setFormValues((prev) => ({ ...prev, caption: value }))

    if (value !== '' && errors.caption !== '') {
      setErrors((prev) => ({ ...prev, caption: '' }))
    }
  }

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value
    value = value.replace(/[^0-9.]/g, '')

    const decimalCount = (value.match(/\./g) || []).length

    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf('.'))
    }

    if (value.includes('.')) {
      const [integer, decimal] = value.split('.')
      value = integer + '.' + (decimal ? decimal.slice(0, 2) : '')
    }
    setFormValues((prev) => ({ ...prev, price: value }))

    if (value !== '' && errors.price !== '') {
      setErrors((prev) => ({ ...prev, price: '' }))
    }
  }

  const handleStockChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')
    setFormValues((prev) => ({ ...prev, stock: value }))
  }

  const handleOpenDialog = (
    optionId: number,
    valueId: number,
    media: string[] | null
  ): void => {
    setCurrentOptionIds({ optionId, valueId: valueId, media })
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
    setCurrentOptionIds({
      optionId: null,
      valueId: null,
      media: null,
    })
  }

  const handleValueImageAdd = (uploadUrl?: string): void => {
    setRowData((prev) => ({
      ...prev,
      media: uploadUrl ? [uploadUrl] : [],
    }))
    const updatedProductMedia = uploadUrl
      ? [...new Set([...(product.media ?? []), uploadUrl])]
      : [...(product.media ?? [])]

    const updatedProduct = {
      ...product,
      media: updatedProductMedia,
    }
    onUpdateProduct(updatedProduct)
  }

  const handleSave = async () => {
    if (!organizationId) return
    const newErrors: Errors = {}

    if (formValues.value.trim() === '') {
      newErrors.value = '*Variant value is required'
    }

    if (
      optionName?.toLowerCase() === 'color' &&
      !isValidHex(formValues.value)
    ) {
      newErrors.value = '*Invalid color value'
    }

    if (
      optionName?.toLowerCase() === 'color' &&
      formValues.caption.trim() === ''
    ) {
      newErrors.caption = '*Variant name is required'
    }

    if (formValues.price === '') {
      newErrors.price = '*Price is required'
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors((prev) => ({ ...prev, ...newErrors }))
      return
    }

    setIsSubmitting(true)
    const caption =
      optionName?.toLowerCase() === 'color' && formValues.caption !== ''
        ? formValues.caption
        : undefined
    const updateData = {
      value: formValues.value.trim().toUpperCase(),
      price: Number(formValues.price) * 100,
      stock: formValues.stock === '' ? null : Number(formValues.stock),
      caption: caption,
    }

    const res = await updateOptionValue(
      organizationId,
      productCode,
      data.optionId,
      data.id,
      updateData
    )

    if (res.status === 'successful' && res.data) {
      const resultData = res.data
      setRowData((prev) => ({
        ...prev,
        value: resultData.value,
        price: resultData.price !== null ? formatPrice(resultData.price) : '',
        stock: resultData.stock !== null ? resultData.stock : 'N/A',
        caption: resultData.caption ?? '',
      }))
      setFormValues({
        value: resultData.value,
        price:
          resultData.price !== null ? (resultData.price / 100).toString() : '',
        stock: resultData.stock !== null ? resultData.stock.toString() : '',
        media: resultData.media,
        caption: resultData.caption ?? '',
      })
      showSnackbar('Option updated successfully', 'success')
      setEdit(false)
    } else if (res.status === 'failed' && res.message) {
      showSnackbar(res.message ?? 'Please try again', 'success')
    }
    setIsSubmitting(false)
    setErrors((prev) => ({
      ...prev,
      value: '',
      price: '',
    }))
  }

  const handleCancel = (): void => {
    setFormValues({
      value: data.value,
      price: data.price !== null ? (data.price / 100).toString() : '',
      stock: data.stock !== null ? data.stock.toString() : '',
      media: data.media,
      caption: data.caption ?? '',
    })
    setErrors((prev) => ({
      ...prev,
      value: '',
      priceAdjustment: '',
      stock: '',
    }))
    setEdit(false)
  }

  const handleDeleleOptionValue = async () => {
    if (!organizationId) return
    setIsDeleting(true)
    const res = await deleteOptionValue(
      organizationId,
      productCode,
      data.optionId,
      data.id
    )
    if (res.status === 'successful') {
      onDelete?.(data.id)
      showSnackbar('Variant Value Deleted Successfully', 'success')
    }
    setIsDeleting(false)
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={1}>
          <Label text="Image" />
          {rowData.media && rowData.media.length > 0 ? (
            <img
              src={rowData.media[0]}
              alt="Option Media"
              style={{
                width: '50px',
                height: '60px',
                objectFit: 'cover',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() =>
                handleOpenDialog(data.optionId, data.id, rowData.media)
              }
            />
          ) : (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="button"
              onClick={() =>
                handleOpenDialog(data.optionId, data.id, rowData.media)
              }
            >
              <AddAPhotoIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
        {optionName?.toLowerCase() !== 'color' && (
          <Grid item xs={6} md={3}>
            <Label text="Option*" />
            {!edit ? (
              <Typography fontWeight={600}>{rowData.value}</Typography>
            ) : (
              <CustomTextField
                name={`value`}
                value={formValues.value}
                onChange={handleValueChange}
                error={Boolean(errors.value)}
                helperText={Boolean(errors.value) && errors.value}
              />
            )}
          </Grid>
        )}
        {optionName?.toLowerCase() === 'color' && (
          <>
            <Grid item xs={6} md={2}>
              <Label text="Option*" />
              {!edit ? (
                <Box
                  sx={{
                    display: 'inlineblock',
                    backgroundColor: rowData.value,
                    height: '20px',
                    width: '20px',
                    borderRadius: 1,
                  }}
                ></Box>
              ) : (
                <MuiColorInput
                  value={formValues.value}
                  name="displayColor"
                  format="hex"
                  onChange={(value) => handleColorValueChange(value)}
                  size="small"
                  inputProps={{
                    style: {
                      backgroundColor: 'white',
                    },
                  }}
                  sx={{
                    maxWidth: '140px',
                  }}
                  label="Color"
                  error={Boolean(errors.value)}
                  helperText={Boolean(errors.value) && errors.value}
                />
              )}
            </Grid>
            <Grid item xs={6} md={2}>
              <Label text="Name*" />
              {!edit ? (
                <Typography fontWeight={600}>{rowData.caption}</Typography>
              ) : (
                <CustomTextField
                  name={`caption`}
                  value={formValues.caption}
                  onChange={handleCaptionChange}
                  error={Boolean(errors.caption)}
                  helperText={Boolean(errors.caption) && errors.caption}
                />
              )}
            </Grid>
          </>
        )}
        <Grid item xs={6} md={3}>
          <Label text="Selling Price" />
          {!edit ? (
            <Typography fontWeight={600}>{rowData.price}</Typography>
          ) : (
            <CustomTextField
              type="text"
              name={`priceAdjustment`}
              value={formValues.price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              onChange={handlePriceChange}
              error={Boolean(errors.price)}
              helperText={Boolean(errors.price) && errors.price}
            />
          )}
        </Grid>
        <Grid item xs={6} md={optionName?.toLowerCase() === 'color' ? 2 : 3}>
          <Label text="Quantity" />
          {!edit ? (
            <Typography fontWeight={600}>{rowData.stock}</Typography>
          ) : (
            <CustomTextField
              name={`stock`}
              type="text"
              value={formValues.stock}
              onChange={handleStockChange}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          justifyItems={'end'}
          alignSelf={!edit ? 'auto' : 'center'}
        >
          {!edit ? (
            <div>
              <IconButton onClick={() => setEdit(true)}>
                <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
              </IconButton>
              <IconButton
                onClick={handleDeleleOptionValue}
                disabled={isDeleting}
              >
                <DeleteOutlineOutlinedIcon
                  fontSize="small"
                  sx={{ color: 'text.primary' }}
                />
              </IconButton>
            </div>
          ) : (
            <div className=" flex items-center gap-2">
              <Button
                variant="outlined"
                size="small"
                onClick={handleCancel}
                disableElevation
                sx={{
                  p: '1px',
                  fontSize: '0.8rem',
                  fontWeight: 700,
                  textTransform: 'none',
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleSave}
                disableElevation
                disabled={isSubmitting}
                sx={{
                  p: '1px',
                  fontSize: '0.8rem',
                  fontWeight: 700,
                  textTransform: 'none',
                }}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <OptionValueFileUpload
            productOptionId={currentOptionIds}
            productCode={productCode}
            onCloseDialog={handleCloseDialog}
            onFilesAdd={handleValueImageAdd}
            productMedia={productMedia ?? []}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PrimaryVariantRow
