import { FC, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'

import Card from 'components/card'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { Product, ProductOption } from 'types/product'

import AddNewVariantForm from './AddNewVariantForm'
import PrimaryTable from './VariantPrimaryTable'
import VariantsTable from './VariantsTable'

interface VariantDetailsProps {
  variants?: ProductOption[]
  productCode: string
  onUpdate: (data?: Product) => void
  product: Product
}

const VariantsDetails: FC<VariantDetailsProps> = ({
  variants = [],
  productCode,
  onUpdate,
  product,
}) => {
  const [openAddVariantDialog, setOpenAddVariantDialog] = useState(false)

  const primaryOptions = variants.find((obj) => {
    if (obj.type.toLowerCase() === 'primary') {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  const sizeOption = variants.filter((obj) => {
    if (
      obj.name.toLowerCase() === 'size' &&
      obj.type.toLowerCase() === 'secondary'
    ) {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  const colorOptions = variants.filter((obj) => {
    if (
      obj.name.toLowerCase() === 'color' &&
      obj.type.toLowerCase() === 'secondary'
    ) {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  const otherOptions = variants.filter((obj) => {
    if (
      obj.name.toLowerCase() !== 'color' &&
      obj.name.toLowerCase() !== 'size' &&
      obj.type.toLowerCase() === 'secondary'
    ) {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  return (
    <div>
      <div className="mx-5 flex items-center justify-between">
        <div>
          <SubTabHeader title="Variants" />
          <Typography variant="caption" color="textSecondary" gutterBottom>
            Define different options for your product, such as colors or sizes.
          </Typography>
        </div>
        {product.ProductOption.length > 0 && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setOpenAddVariantDialog(true)}
          >
            Add New Variant Option
          </Button>
        )}
      </div>

      {product.ProductOption.length === 0 && (
        <Box
          className=" m-5 p-5 rounded flex flex-col items-center justify-center"
          sx={{ border: '1px solid', borderColor: 'accents.lightGrayAccent' }}
        >
          <p className="text-sm text-center mb-4 font-semibold">
            Create size, color, or any other variants to offer multiple options
            for your product. For example. a T-shirt can have variants like Red,
            Blue, or Black in sizes Small, Medium, and Large.
          </p>
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={() => setOpenAddVariantDialog(true)}
          >
            Add Variants
          </Button>
        </Box>
      )}
      {primaryOptions && (
        <PrimaryTable
          option={primaryOptions}
          productCode={productCode}
          onUpdate={onUpdate}
          product={product}
        />
      )}

      {sizeOption.length > 0 &&
        sizeOption.map((op) => (
          <Card key={op.id}>
            <VariantsTable
              optionData={op}
              productCode={productCode}
              onUpdate={onUpdate}
              product={product}
            />
          </Card>
        ))}

      {colorOptions.length > 0 &&
        colorOptions.map((op) => (
          <Card key={op.id}>
            <VariantsTable
              optionData={op}
              productCode={productCode}
              onUpdate={onUpdate}
              product={product}
            />
          </Card>
        ))}

      {otherOptions.length > 0 &&
        otherOptions.map((option) => (
          <Card key={option.id}>
            <VariantsTable
              optionData={option}
              productCode={productCode}
              onUpdate={onUpdate}
              product={product}
            />
          </Card>
        ))}

      <AddNewVariantForm
        isPrimaryPresent={primaryOptions ? true : false}
        openDialog={openAddVariantDialog}
        onCloseDialog={() => setOpenAddVariantDialog(false)}
        product={product}
        onUpdate={onUpdate}
      />
    </div>
  )
}

export default VariantsDetails
