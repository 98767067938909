import { ChangeEvent, FC, useEffect, useState } from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import DateRangeInput from 'pages/dashboard/components/DateTimeInput'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'
import { dateTimeFormat } from 'utils/dateFormat'
import { convertRupeeToPaisa, formatPrice } from 'utils/helper'

interface PricingDetailsProps {
  productId: number
  price: number
  discountedPrice: number | null
  discountPercentage: number | null
  startOfferDate: Date | null
  endOfferDate: Date | null
  onUpdate: (data?: Product) => void
}

interface FormType {
  discountedPrice: string
  discountPercentage: string
  startOfferDate: Date | null
  endOfferDate: Date | null
}

const OffersDetails: FC<PricingDetailsProps> = ({
  productId,
  price,
  discountedPrice,
  discountPercentage,
  startOfferDate,
  endOfferDate,
  onUpdate,
}) => {
  const [values, setValues] = useState<FormType>({
    discountPercentage: '',
    discountedPrice: '',
    startOfferDate: null,
    endOfferDate: null,
  })
  const [error, setError] = useState({
    discountPercentage: '',
    discountedPrice: '',
    startOfferDate: '',
    endOfferDate: '',
  })
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setValues({
      discountPercentage: discountPercentage
        ? discountPercentage.toString()
        : '',
      discountedPrice:
        typeof discountedPrice === 'number'
          ? (discountedPrice / 100).toString()
          : '',
      startOfferDate,
      endOfferDate,
    })
  }, [discountPercentage, discountedPrice, startOfferDate, endOfferDate])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    if (name === 'discountPercentage') {
      const percentage = Math.min(Math.max(+value, 0), 100)
      const disPrice = (price - (percentage * price) / 100) / 100
      setValues((prev) => ({
        ...prev,
        discountPercentage: percentage.toString(),
        discountedPrice: disPrice.toFixed(2),
      }))
    } else {
      const discountedPrice = Math.min(Math.max(+value, 0), price / 100)
      const percentage =
        discountedPrice >= 0
          ? ((price - convertRupeeToPaisa(discountedPrice)) / price) * 100
          : 0

      setValues((prev) => ({
        ...prev,
        discountedPrice: discountedPrice.toString(),
        discountPercentage: percentage.toFixed(2),
      }))

      if (discountedPrice > price / 100) {
        setError((prev) => ({
          ...prev,
          discountedPrice: '*Should be less than price',
        }))
      } else {
        setError({
          discountPercentage: '',
          discountedPrice: '',
          endOfferDate: '',
          startOfferDate: '',
        })
      }
    }
  }

  const handleStartOfferDateChange = (value: Date | null): void => {
    setValues((prev) => ({ ...prev, startOfferDate: value }))
    if (value) {
      setError((prev) => ({
        ...prev,
        startOfferDate: '',
      }))
    }
  }

  const handleEndOfferDateChange = (value: Date | null): void => {
    setValues((prev) => ({ ...prev, endOfferDate: value }))
    if (value) {
      setError((prev) => ({
        ...prev,
        endOfferDate: '',
      }))
    }
  }

  const handleSave = async (): Promise<void> => {
    if (
      values.discountPercentage.trim() === '' ||
      values.discountedPrice.trim() === ''
    ) {
      setError((prev) => ({
        ...prev,
        discountPercentage:
          values.discountPercentage === '' ? '*Percentage is required.' : '',
        discountedPrice:
          values.discountedPrice === '' ? '*Discounted price is required.' : '',
      }))
      return
    }

    if (price / 100 < +values.discountedPrice) {
      setError({
        discountedPrice: '*Discounted price should be less than price',
        discountPercentage: '',
        startOfferDate: '',
        endOfferDate: '',
      })
      return
    }

    // Date Validations
    if (!values.startOfferDate && !values.endOfferDate) {
      setError((prev) => ({
        ...prev,
        startOfferDate: '*Atleast one date is required.',
      }))
      return
    }

    if (values.startOfferDate && values.endOfferDate) {
      const startDate = new Date(values.startOfferDate)
      const endDate = new Date(values.endOfferDate)

      if (startDate >= endDate) {
        setError((prev) => ({
          ...prev,
          startOfferDate: '*Start date should be earlier than End date.',
          endOfferDate: '*End date should be later than Start date.',
        }))
        return
      }
    }

    const newdiscountedPricePaisa = convertRupeeToPaisa(values.discountedPrice)

    if (
      +values.discountPercentage === discountPercentage &&
      newdiscountedPricePaisa === discountedPrice &&
      values.startOfferDate === startOfferDate &&
      values.endOfferDate === endOfferDate
    ) {
      setEdit(false)
      return
    }

    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_Offers_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }

    setIsSubmitting(true)

    const res = await updateProduct({
      productId,
      dataUpdate: {
        discountedPrice: newdiscountedPricePaisa,
        discountPercentage: Number((+values.discountPercentage).toFixed(2)),
        startOfferDate: values.startOfferDate,
        endOfferDate: values.endOfferDate,
      },
    })

    if (res.status === 'successful') {
      showSnackbar('Product offers updated.', 'success')
      onUpdate(res.data)
    }

    setIsSubmitting(false)
    setEdit(!edit)
  }

  const handleDelete = async (): Promise<void> => {
    const res = await updateProduct({
      productId,
      dataUpdate: {
        discountedPrice: null,
        discountPercentage: null,
        startOfferDate: null,
        endOfferDate: null,
      },
    })

    if (res.status === 'successful') {
      onUpdate(res.data)
    }

    setIsSubmitting(false)
    setValues({
      discountPercentage: '',
      discountedPrice: '',
      startOfferDate: null,
      endOfferDate: null,
    })
    setEdit(false)
  }

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleCancel = (): void => {
    setValues({
      discountPercentage: discountPercentage
        ? discountPercentage.toString()
        : '',
      discountedPrice: discountedPrice
        ? (discountedPrice / 100).toString()
        : '',
      startOfferDate,
      endOfferDate,
    })
    setEdit(false)
  }

  const offerStartDate = startOfferDate
    ? dateTimeFormat(startOfferDate.toString())
    : 'N/A'
  const offerEndDate = endOfferDate
    ? dateTimeFormat(endOfferDate.toString())
    : 'N/A'

  return (
    <div className="mb-5">
      <div className="mx-5">
        <SubTabHeader
          title="Offers & Discounts"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
        <Typography variant="caption" color="textSecondary" gutterBottom>
          Create special offers and discounts to attract customers.
        </Typography>
      </div>

      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Discounted Price
            </Typography>
            {edit ? (
              <CustomTextField
                name="discountedPrice"
                type="number"
                min={0}
                step={0.01}
                value={values.discountedPrice ?? ''}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                error={error.discountedPrice !== ''}
                helperText={error.discountedPrice}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {typeof discountedPrice === 'number'
                  ? formatPrice(discountedPrice)
                  : 'N/A'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Discount Percentage
            </Typography>
            {edit ? (
              <CustomTextField
                name="discountPercentage"
                type="number"
                min={0}
                max={100}
                step={0.01}
                value={values.discountPercentage ?? ''}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={Boolean(error.discountPercentage)}
                helperText={error.discountPercentage}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {discountPercentage ?? 'N/A'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Offer Start Date
            </Typography>
            {edit ? (
              <DateRangeInput
                name="startOfferDate"
                value={values.startOfferDate}
                onChange={handleStartOfferDateChange}
                error={Boolean(error.startOfferDate)}
                helperText={error.startOfferDate}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {offerStartDate}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              marginLeft: { xs: 0, md: 2 },
            }}
          >
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Offer End Date
            </Typography>
            {edit ? (
              <DateRangeInput
                name="endOfferDate"
                value={values.endOfferDate}
                onChange={handleEndOfferDateChange}
                error={Boolean(error.endOfferDate)}
                helperText={error.endOfferDate}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>{offerEndDate}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            {edit && (
              <div className="mt-5 md:ml-4">
                <IconButton onClick={handleDelete}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>

        {edit && (
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default OffersDetails
