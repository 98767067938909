import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import * as z from 'zod'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import theme from 'styles/muiTheme'

interface FormData {
  email: string
  password: string
}

const FormSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Invalid email'),
  password: z
    .string()
    .min(1, 'Password is required')
    .min(8, 'Password must have at least 8 characters'),
})

const LoginForm = () => {
  const { signIn, refreshAuthState } = useAuth()
  const navigate = useNavigate()

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const registerEmail = register('email')
  const registerPassword = register('password')

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  const onSubmit: SubmitHandler<FormData> = async (values) => {
    if (values) {
      Amplitude.trackEvent('GET_STARTED_LOGIN_CLICKED', {
        userEmail: values.email,
      })
    }
    try {
      const res = await signIn(values.email, values.password.trim())
      if (
        res.status === 'successful' &&
        res.data?.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        navigate('/new-password', {
          state: {
            email: values.email,
            session: res.data?.session,
          },
        })
      } else if (res.status === 'successful') {
        refreshAuthState()
        navigate('/dashboard/home')
      }
    } catch (error) {
      console.error('Login failed:', error)
      setSnackbarMessage('User not found or invalid credentials')
      setSnackbarOpen(true)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <div className="space-y-6 mt-10">
          <div>
            <label
              htmlFor="email"
              className="text-base font-medium text-gray-900"
            >
              Email Address
            </label>
            <div className="mt-2">
              <input
                id="email"
                onChange={registerEmail.onChange}
                onBlur={registerEmail.onBlur}
                name={registerEmail.name}
                ref={registerEmail.ref}
                className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="email"
                placeholder="yourname@example.com"
                autoComplete="email"
              />
              {errors.email && (
                <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                  *{errors.email?.message}
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="text-base font-medium text-gray-900"
              >
                Password
              </label>
              <IconButton
                sx={{ color: theme.palette.text.primary, padding: 0 }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
            <div className="mt-2">
              <input
                id="password"
                onChange={registerPassword.onChange}
                onBlur={registerPassword.onBlur}
                name={registerPassword.name}
                ref={registerPassword.ref}
                className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                autoComplete="current-password"
              />
              {errors.password && (
                <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                  *{errors.password?.message}
                </p>
              )}

              <Link
                to="/forgot-password"
                className="text-xs underline text-gray-500"
              >
                Forgot Password?
              </Link>
            </div>
          </div>

          <div>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              sx={{ width: '100%', textTransform: 'none' }}
            >
              SIGN IN
              <ArrowForwardIcon className="ml-2" component={ArrowForwardIcon} />
            </Button>
          </div>
        </div>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </section>
  )
}

export default LoginForm
