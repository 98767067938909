import React, { ChangeEvent, useState } from 'react'

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import EditButton from 'pages/dashboard/components/EditButton'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import theme from 'styles/muiTheme'

import StockTableRow from './StockTableRow'

export interface VariantValues {
  valueId: number
  value: string
  stock: number | null
}

export interface Variant {
  id: number
  name: string
  type: string
  optionValues: VariantValues[]
}

interface StocksList {
  id: number
  productCode: string
  name: string
  totalVariants: string
  lastUpdated: string
  lastUpdatedBy: string
  stock: string | null
  variants: Variant[]
}

interface StockDetailsTableProps {
  stocks: StocksList
}

const StockDetailsTable: React.FC<StockDetailsTableProps> = ({ stocks }) => {
  const [variants, setVariants] = useState<Variant[]>(stocks.variants)
  const [baseStock, setBaseStock] = useState<string>(stocks.stock ?? '')
  const [originalBaseStock, setOriginalBaseStock] = useState<string>(
    stocks.stock ?? ''
  )
  const [editing, setEditing] = useState(false)
  const { organizationId } = useAuth()

  const handleEditClick = () => {
    if (organizationId) {
      Amplitude.trackEvent('STOCK_ITEM_EDIT_CLICKED', {
        productId: stocks.id,
        orgId: organizationId,
      })
    }
    setEditing(true)
  }

  const handleSaveClick = async () => {
    if (organizationId) {
      Amplitude.trackEvent('BASE_STOCK_ITEM_SAVED_CLICKED', {
        productId: stocks.id,
        orgId: organizationId,
      })
      if (originalBaseStock === baseStock) {
        setEditing(false)
        return
      }
      const result = await updateProduct({
        productId: stocks.id,
        dataUpdate: { quantity: baseStock !== '' ? Number(baseStock) : null },
      })

      if (result.status === 'successful' && result.data) {
        setOriginalBaseStock(result.data.stock)
        setBaseStock(result.data.stock)
      }
      setEditing(false)
    } else {
      throw new Error('Error || undefined.')
    }
  }

  const handleCancelClick = () => {
    setBaseStock(originalBaseStock)
    setEditing(false)
  }

  const handleStockChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')

    setBaseStock(value)
  }

  const isPrimaryStockAvailable = stocks.variants.find(
    (vl) => vl.type.toUpperCase() === 'PRIMARY'
  )
  return (
    <>
      {(stocks.variants.length === 0 || !isPrimaryStockAvailable) && (
        <TableContainer className="lg:px-6">
          <Table className="rounded-lg bg-white">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box
                    component="span"
                    className="font-bold"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    BASE STOCK
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    component="span"
                    className="font-bold"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    ITEMS SOLD
                  </Box>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {editing ? (
                    <CustomTextField
                      type="text"
                      value={baseStock}
                      onChange={handleStockChange}
                    />
                  ) : (
                    (originalBaseStock ?? 'Not Set')
                  )}
                </TableCell>
                <TableCell> 0</TableCell>
                <TableCell align="right">
                  {editing ? (
                    <>
                      <div className="flex flex-row justify-end">
                        <div>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={handleCancelClick}
                            disableElevation
                            sx={{
                              p: '1px',
                              fontSize: '0.8rem',
                              fontWeight: 700,
                              textTransform: 'none',
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="ml-2">
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleSaveClick}
                            disableElevation
                            sx={{
                              p: '1px',
                              fontSize: '0.8rem',
                              fontWeight: 700,
                              textTransform: 'none',
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <EditButton buttonText="Edit" onClick={handleEditClick} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {variants.map((variant) => (
        <div key={variant.id}>
          <div className="lg:px-6 py-2">
            <SubTabHeader title={variant.name} />
          </div>
          <TableContainer className="lg:px-6" key={variant.id}>
            <Table className="rounded-lg bg-white">
              <TableHead>
                <TableRow>
                  {variants.some((variant) => variant.name) && (
                    <TableCell>
                      <Box
                        component="span"
                        className="font-bold"
                        sx={{ color: theme.palette.primary.main }}
                      >
                        VALUE
                      </Box>
                    </TableCell>
                  )}
                  <TableCell>
                    <Box
                      component="span"
                      className="font-bold"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Stock
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      component="span"
                      className="font-bold"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      ITEMS SOLD
                    </Box>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variant.optionValues
                  .sort((a, b) => a.valueId - b.valueId)
                  .map((vl) => (
                    <StockTableRow
                      optionName={variant.name}
                      productCode={stocks.productCode}
                      optionId={variant.id}
                      key={vl.valueId}
                      variant={vl}
                      setVariants={setVariants}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
    </>
  )
}

export default StockDetailsTable
