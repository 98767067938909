import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Button, Grid, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import DateRangeInput from 'pages/dashboard/components/DateTimeInput'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateOrder } from 'services/orders'
import { Order } from 'services/orders/index.type'
import { dateTimeFormat } from 'utils/dateFormat'

interface FormValues {
  shippingDate: Date | null
  expectedDeliveryDate: Date | null
  shippingProvider: string
  trackingId: string
  trackingLink: string
}

interface OrderDeliveryProps {
  onOpenCourierDialog: () => void
  currentValues: FormValues
  orderId: number
  organizationId: number
  onUpdateOrder: (data: Order) => void
}

const OrderDelivery: FC<OrderDeliveryProps> = ({
  onOpenCourierDialog,
  currentValues,
  orderId,
  organizationId,
  onUpdateOrder,
}) => {
  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState<FormValues>({
    shippingDate: null,
    expectedDeliveryDate: null,
    shippingProvider: '',
    trackingId: '',
    trackingLink: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    setValues({
      shippingDate: currentValues.shippingDate,
      expectedDeliveryDate: currentValues.expectedDeliveryDate,
      shippingProvider: currentValues.shippingProvider ?? '',
      trackingId: currentValues.trackingId ?? '',
      trackingLink: currentValues.trackingLink ?? '',
    })
  }, [currentValues])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const name = e.target.name

    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleShippingDateChange = (value: Date | null): void => {
    setValues((prev) => ({ ...prev, shippingDate: value }))
  }

  const handleExpectedDateChange = (value: Date | null): void => {
    setValues((prev) => ({ ...prev, expectedDeliveryDate: value }))
  }

  const handleCancel = () => {
    setValues({
      shippingDate: currentValues.shippingDate,
      expectedDeliveryDate: currentValues.expectedDeliveryDate,
      shippingProvider: currentValues.shippingProvider ?? '',
      trackingId: currentValues.trackingId ?? '',
      trackingLink: currentValues.trackingLink ?? '',
    })
    setEdit(false)
  }

  const handleSave = async () => {
    const data = {
      organizationId,
      shippingDate: values.shippingDate,
      expectedDeliveryDate: values.expectedDeliveryDate,
      shippingProvider:
        values.shippingProvider.trim() === ''
          ? null
          : values.shippingProvider.trim(),
      trackingId:
        values.trackingId.trim() === '' ? null : values.trackingId.trim(),
      trackingLink:
        values.trackingLink.trim() === '' ? null : values.trackingLink.trim(),
    }
    setIsSubmitting(true)
    const res = await updateOrder(orderId, data)

    if (res.status === 'successful' && res.data) {
      onUpdateOrder(res.data)
      setEdit(false)
    } else if (res.status === 'failed' && res.message) {
      showSnackbar(res.message, 'error')
    }
    setIsSubmitting(false)
  }

  return (
    <div>
      <div className="mx-5">
        <SubTabHeader
          title="Delivery"
          buttonText={edit ? undefined : 'Edit'}
          onClick={() => setEdit(true)}
        />
      </div>
      {!edit && (
        <Card>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <Label text="Shipping Date" color="secondary" />
              <Typography fontWeight={700}>
                {currentValues.shippingDate
                  ? dateTimeFormat(currentValues.shippingDate.toString())
                  : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Expected Delivery Date" color="secondary" />
              <Typography fontWeight={700}>
                {currentValues.expectedDeliveryDate
                  ? dateTimeFormat(
                      currentValues.expectedDeliveryDate.toString()
                    )
                  : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Shipping Provider" color="secondary" />
              <Typography fontWeight={700}>
                {currentValues.shippingProvider ?? 'N/A'}
              </Typography>
              <Button
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  paddingInline: 0,
                }}
                onClick={onOpenCourierDialog}
              >
                Check Courier Serviceability
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Tracking ID" color="secondary" />
              <Typography fontWeight={700}>
                {currentValues.trackingId ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Tracking Link" color="secondary" />
              <Typography fontWeight={700}>
                {currentValues.trackingLink ?? 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
      {edit && (
        <Card>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <Label text="Shipping Date" color="secondary" />
              <DateRangeInput
                name="shippingDate"
                value={values.shippingDate}
                onChange={handleShippingDateChange}
                showTime={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Expected Delivery Date" color="secondary" />
              <DateRangeInput
                name="expectedDeliveryDate"
                value={values.expectedDeliveryDate}
                onChange={handleExpectedDateChange}
                showTime={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Shipping Provider" color="secondary" />
              <CustomTextField
                id="shippingProvider"
                name="shippingProvider"
                value={values.shippingProvider}
                onChange={handleChange}
              />
              <Button
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  paddingInline: 0,
                }}
                onClick={onOpenCourierDialog}
              >
                Check Courier Serviceability
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Tracking ID" color="secondary" />
              <CustomTextField
                id="trackingId"
                name="trackingId"
                value={values.trackingId}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Tracking Link" color="secondary" />
              <CustomTextField
                id="trackingLink"
                name="trackingLink"
                value={values.trackingLink}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {'Save'}
            </Button>
          </div>
        </Card>
      )}
    </div>
  )
}

export default OrderDelivery
