import React, { JSX, useState } from 'react'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
} from '@mui/material'

import theme from 'styles/muiTheme'
import { Product } from 'types/product'
import { dateTimeFormat } from 'utils/dateFormat'

// import SearchFilter from './SearchFilter'
import StockAccordionHeader from './StockAccordionHeader'
import StockDetailsTable from './StockDetailsTable'

interface StockListProps {
  orgId: number
  products: Product[]
}

const StockList: React.FC<StockListProps> = ({ products }): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const stockProducts = products.map((product) => {
    return {
      id: product.id,
      productCode: product.productCode,
      name: product.name,
      totalVariants: product.ProductOption.length.toString(),
      lastUpdated: product.updatedAt
        ? dateTimeFormat(product.updatedAt)
        : 'N/A',
      lastUpdatedBy: 'N/A',
      stock: product.stock ? product.stock.toString() : null,
      variants: product.ProductOption.map((stockItem) => ({
        id: stockItem.id,
        name: stockItem.name ? stockItem.name : '',
        type: stockItem.type,
        optionValues: stockItem.ProductOptionValue.map((val) => ({
          valueId: val.id,
          value: val.value,
          stock: val.stock,
        })),
      })),
    }
  })

  return (
    <Box>
      <div className="mb-4 mx-5">
        <p className="text-xl font-bold">Stock</p>
      </div>
      {/* Commented the Search Bar for Now  */}
      {/* <SearchFilter />  */}
      <Paper
        className="mx-5 mt-4"
        sx={{
          backgroundColor: 'secondary.main',
          boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
        }}
      >
        {stockProducts.map((product) => (
          <Accordion
            key={product.id}
            expanded={expanded === `panel${product.id}`}
            onChange={handleAccordionChange(`panel${product.id}`)}
            sx={{
              marginY: 0,
              '&.Mui-expanded': {
                marginY: 0,
              },
              '&.Mui-expanded::before': {
                opacity: 1,
              },
            }}
          >
            <AccordionSummary
              aria-controls={`panel${product.id}-content`}
              id={`panel${product.id}-header`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <StockAccordionHeader
                productCode={product.productCode}
                totalVariants={product.totalVariants}
                productName={product.name}
                expanded={expanded === `panel${product.id}`}
              />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <StockDetailsTable stocks={product} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Box>
  )
}

export default StockList
