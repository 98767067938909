import { ChangeEvent, FC, useState } from 'react'

import { Typography } from '@mui/material'
import imageCompression from 'browser-image-compression'

import DisplayImage from 'pages/dashboard/components/DisplayImage'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { deleteFile, getPresignedUrl, uploadFile } from 'services/files'
import { validateSizeChartFile } from 'utils/validation'

interface SizeChartProps {
  sizeChartUrl?: string
  onSizeChartChange: (f: string | undefined) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const SizeChart: FC<SizeChartProps> = ({ onSizeChartChange, sizeChartUrl }) => {
  const [error, setError] = useState<string>()
  const [uploadProgress, setUploadProgress] = useState({
    fileName: '',
    progress: 0,
  })
  const [isDeleting, setIsDeleting] = useState(false)

  const handleSave = async (file: File): Promise<void> => {
    const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      fileName: file.name,
      progress: 0,
    }))

    const result = await uploadFile(file, signedUrl, (progress) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: progress,
      }))
    })

    if (result.status === 'successful') {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        fileName: '',
        progress: 0,
      }))

      const sizeChartUrl = fileUrl + '/' + uuid

      onSizeChartChange(sizeChartUrl)
      setError(undefined)
    }
  }

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    const file = e.target.files[0]

    const validate = validateSizeChartFile(file)

    if (!validate.valid && validate.error) {
      setError(validate.error)
      return
    }

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      }

      const compressedFile = await imageCompression(file, options)

      handleSave(compressedFile)
    } catch (error) {
      console.error('Image compression failed:', error)
      setError('Image compression failed. Please try again.')
    }
  }

  const onDelete = async (url: string): Promise<void> => {
    setIsDeleting(true)
    const fileName = url.split('/')[3]

    const result = await deleteFile(fileName)

    if (result.status === 'successful') {
      onSizeChartChange(undefined)
    }
    setIsDeleting(false)
  }

  return (
    <div className="mb-5">
      <div className=" mx-5">
        <SubTabHeader title="Size Chart" />
        <Typography variant="caption" color="textSecondary" gutterBottom>
          Only an image file (JPG, PNG) is supported.
        </Typography>
      </div>

      <div className="p-5">
        {sizeChartUrl && (
          <>
            <DisplayImage
              imageUrl={sizeChartUrl}
              width={100}
              height={150}
              onDelete={() => onDelete(sizeChartUrl)}
              isDeleting={isDeleting}
            />
          </>
        )}
        {!sizeChartUrl && (
          <InputFileUpload
            id="sizeChart"
            name="sizeChart"
            onChange={handleFileChange}
            buttonText="Upload File"
          />
        )}

        {uploadProgress.fileName !== '' && (
          <Typography
            sx={{
              fontSize: '0.8rem',
              color: 'accents.secondaryAccent',
            }}
          >
            {uploadProgress.fileName} {uploadProgress.progress}
          </Typography>
        )}

        {error && (
          <Typography fontSize={'0.75rem'} color={'error'}>
            *{error}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default SizeChart
