import { useEffect, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import OnboardingContainer from 'components/forms/OnboardingContainer'
import { useAuth } from 'hooks/useAuth'
import { acceptInvitation, getPendingInvitations } from 'services/account'
import { Invitation } from 'types/account'

const AcceptInvite = () => {
  const [orgInvite, setOrgInvite] = useState<Invitation>()
  const navigate = useNavigate()
  const { refreshAuthState, changeOrganization } = useAuth()

  const getOrganizationInvite = async () => {
    const result = await getPendingInvitations()
    if (
      result.status === 'successful' &&
      result.data &&
      result.data.length > 0
    ) {
      const invitations = result.data as Invitation[]
      setOrgInvite(invitations[0])
    } else {
      navigate('/dashboard/home')
    }
  }
  useEffect(() => {
    getOrganizationInvite()
  }, [])

  const handleAccept = async () => {
    try {
      const result = await acceptInvitation(
        orgInvite?.organization.id as number
      )
      if (result.status === 'successful') {
        refreshAuthState()
        changeOrganization(orgInvite?.organization.id as number)
        navigate('/dashboard/home')
      }
    } catch (error) {
      console.error('Error accepting invitation:', error)
    }
  }

  const handleDecline = () => {}

  return (
    <OnboardingContainer
      title="Accept your Invitation"
      description={
        orgInvite?.InvitedByUser.firstName
          ? `${orgInvite?.InvitedByUser.firstName} has invited you to join ${orgInvite?.organization?.name}. Accept the invite to collaborate, manage orders, and stay updated effortlessly.`
          : `You have been invited to join ${orgInvite?.organization?.name}. Accept the invite to collaborate, manage orders, and stay updated effortlessly.`
      }
    >
      <Box
        display="flex"
        justifyContent="center"
        gap={1}
        alignItems="center"
        marginTop={4}
      >
        <Button
          size="small"
          sx={{ textTransform: 'none', color: '#DC143C', fontWeight: 'bold' }}
          onClick={handleDecline}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ textTransform: 'none', fontWeight: 'bold', paddingX: 2 }}
          onClick={handleAccept}
        >
          <CheckIcon className="mr-2" fontSize="small" /> Accept
        </Button>
      </Box>
    </OnboardingContainer>
  )
}

export default AcceptInvite
