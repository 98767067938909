import React, { useState } from 'react'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

interface MultiEmailInputProps {
  value: { label: string; value: string }[]
  onChange: (newEmails: { label: string; value: string }[]) => void
}

const MultiEmailField: React.FC<MultiEmailInputProps> = ({
  value,
  onChange,
}) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const handleEmailChange = (
    newValue: MultiValue<{ label: string; value: string }>
  ) => {
    onChange(newValue as { label: string; value: string }[])
  }

  const handleCreate = (inputValue: string) => {
    if (emailRegex.test(inputValue)) {
      onChange([...value, { label: inputValue, value: inputValue }])
    } else {
      setSnackbarMessage('Please Enter a valid email address!')
      setOpenSnackbar(true)
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <CreatableSelect
        isMulti
        value={value}
        onChange={handleEmailChange}
        onCreateOption={handleCreate}
        placeholder="Add more members to your store"
        formatCreateLabel={(inputValue: string) => `Invite "${inputValue}"`}
        styles={{
          control: (base) => ({
            ...base,
            padding: '2px',
            fontSize: '0.875rem',
            width: '100%',
            height: '2rem',
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: '#e0e0e0',
          }),
          multiValueLabel: (base) => ({
            ...base,
            fontSize: '0.875rem',
          }),
          multiValueRemove: (base) => ({
            ...base,
            cursor: 'pointer',
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: '0.85rem',
          }),
        }}
        classNamePrefix="react-select"
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default MultiEmailField
