import { useEffect, useState } from 'react'

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import {
  Box,
  Button,
  ButtonBase,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import CardMUI from '@mui/material/Card'
import { useNavigate, useParams } from 'react-router-dom'

import Card from 'components/card'
import Label from 'components/forms/Label'
import LoadingSpinner from 'components/spinner'
import { useAuth } from 'hooks/useAuth'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { getCheckoutSession } from 'services/checkout'
import { CheckoutSession } from 'types/checkout'
import { dateTimeFormat } from 'utils/dateFormat'
import { formatPrice } from 'utils/helper'

import srcImg from '../../../../../assests/img/sunrise-placeholder.png'
import { formatAddress } from '../../customers/components/CustomersList'

const CheckoutSessionDetailsPage = () => {
  const { id } = useParams()
  const { organization } = useAuth()
  const navigate = useNavigate()
  const [showNotesIndex, setShowNotesIndex] = useState<number | null>(null)
  const [session, setSession] = useState<CheckoutSession>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!id || !organization) return

    const fetchSession = async () => {
      setIsLoading(true)
      const res = await getCheckoutSession(organization.id, id)

      if (res?.status === 'successful' && res.data) {
        setSession(res.data)
      }
      setIsLoading(false)
    }

    fetchSession()
  }, [id, organization])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!session) {
    return (
      <p className=" text-gray-500 text-bold text-xl text-center">
        Session not found
      </p>
    )
  }

  const subTotal = session.items.reduce((acc: number, item) => {
    return acc + item.quantity * item.price
  }, 0)

  const { firstName = '', lastName = '' } = session.customer ?? {}

  let fullName = `${firstName} ${lastName ?? ''}`

  if (session.customerDetails && session.customerDetails.firstName) {
    const { firstName, lastName } = session.customerDetails
    fullName = `${firstName} ${lastName ?? ''}`
  }

  const contactDetails = {
    name: fullName,
    phoneNumber: session.customerDetails
      ? (session.customerDetails.phoneNumber ?? 'N/A')
      : (session.phoneNo ?? 'N/A'),
    email: session.customerDetails
      ? (session.customerDetails.email ?? 'N/A')
      : 'N/A',
  }

  return (
    <div>
      <div className=" mx-5 flex items-center">
        <IconButton sx={{ color: 'gray' }} onClick={() => navigate(-1)}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <p className="text-xl font-bold">{`Checkout Session`}</p>
      </div>
      <Box className="m-5 p-5 rounded" sx={{ border: '1px solid lightgray' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Label text="UID" color="secondary" />
            <Typography fontWeight={700}>{session.uid}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Total" color="secondary" />
            <Typography fontWeight={700}>
              {session.items.length} items, {formatPrice(session.totalPrice)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Created Date" color="secondary" />
            <Typography fontWeight={700}>
              {dateTimeFormat(session.createdAt)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Payment Id" color="secondary" />
            <Typography fontWeight={700}>
              {session.paymentId ?? 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Status" color="secondary" />
            <Typography fontWeight={700}>{session.status ?? 'N/A'}</Typography>
          </Grid>
        </Grid>
      </Box>
      <div className="mb-4">
        <div className="mx-5">
          <SubTabHeader title="Order Summary" />
        </div>
        <Card>
          {session.items.map((item, index) => (
            <CardMUI elevation={0} sx={{ padding: 0, mb: 2 }} key={index}>
              <CardContent>
                <Box
                  display={'flex'}
                  justifyContent={{ md: 'space-between' }}
                  alignItems={{ md: 'flex-start' }}
                  flexDirection={{ xs: 'column', md: 'row' }}
                  rowGap={3}
                >
                  <Box flex={1} display={'flex'} alignItems={'start'}>
                    <img
                      src={item.media ? item.media[0] : srcImg}
                      width={'70px'}
                      height={'70px'}
                      alt="product img"
                    />
                    <div className=" ml-5">
                      <ButtonBase
                        sx={{
                          fontWeight: '700',
                          textDecoration: 'underline',
                          mb: 1,
                        }}
                        onClick={() =>
                          navigate(`/dashboard/products/${item.productCode}`)
                        }
                      >
                        {item.name}
                      </ButtonBase>
                      {item.selectedOptions?.map((option) => (
                        <Typography
                          key={option.name}
                          variant="body2"
                          className="text-gray-700"
                          sx={{
                            fontWeight: 500,
                            fontSize: '0.75rem',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {option.name}:{' '}
                          {option.name.toLowerCase() === 'color' ? (
                            <span
                              className="inline-block h-4 w-4 rounded-full border border-gray-300 mx-1"
                              style={{ backgroundColor: option.value.value }}
                            ></span>
                          ) : (
                            option.value.value
                          )}
                        </Typography>
                      ))}
                      {item.notes && (
                        <Box
                          className="pr-4"
                          display={{ xs: 'block', md: 'none' }}
                        >
                          <Typography className="text-gray-600" fontSize="14px">
                            <span className="text-gray-800 text-sm">
                              Notes:{' '}
                            </span>
                            {showNotesIndex === index
                              ? item.notes
                              : `${item.notes.slice(0, 100)}...`}
                          </Typography>

                          {item.notes.length > 100 && (
                            <Button
                              size="small"
                              onClick={() =>
                                setShowNotesIndex((prevIndex) =>
                                  prevIndex === index ? null : index
                                )
                              }
                              sx={{
                                textTransform: 'none',
                                fontSize: '0.75rem',
                              }}
                            >
                              {showNotesIndex === index
                                ? 'See Less'
                                : 'See More'}
                            </Button>
                          )}
                        </Box>
                      )}
                    </div>
                  </Box>
                  <Box
                    flex={1}
                    display={'flex'}
                    justifyContent={{ xs: 'flex-end', md: 'space-between' }}
                    alignItems={'center'}
                    columnGap={{ xs: 10, md: 0 }}
                    marginTop={{ sm: 4 }}
                  >
                    <Typography fontWeight={700}>
                      {item.quantity} &times; {formatPrice(item.price)}
                    </Typography>
                    <Typography fontWeight={700}>
                      {formatPrice(item.quantity * item.price)}
                    </Typography>
                  </Box>
                </Box>
                {item.notes && (
                  <Box
                    className="pr-4 ml-20 w-1/2"
                    display={{ xs: 'none', md: 'flex' }}
                  >
                    <Typography className="text-gray-600 pl-1" fontSize="14px">
                      <span className="text-gray-800 text-sm">Notes: </span>
                      {showNotesIndex === index
                        ? item.notes
                        : `${item.notes.slice(0, 100)}...`}
                      {item.notes.length > 100 && (
                        <Button
                          size="small"
                          onClick={() =>
                            setShowNotesIndex((prevIndex) =>
                              prevIndex === index ? null : index
                            )
                          }
                          sx={{
                            textTransform: 'none',
                            fontSize: '0.75rem',
                            padding: 0,
                          }}
                        >
                          {showNotesIndex === index ? 'See Less' : 'See More'}
                        </Button>
                      )}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </CardMUI>
          ))}
          <div className=" flex justify-end items-center px-4 gap-12">
            <div>
              <Typography fontWeight={700}>Shipping Cost</Typography>

              <Typography fontWeight={700}>Total</Typography>
            </div>
            <div>
              <Typography fontWeight={700} textAlign={'right'}>
                {formatPrice(session.totalPrice - subTotal)}
              </Typography>
              <Typography fontWeight={700}>
                {formatPrice(session.totalPrice)}
              </Typography>
            </div>
          </div>
        </Card>
      </div>
      <div className="mb-4">
        <div className="mx-5">
          <ButtonBase
            sx={{
              fontWeight: 700,
              ':hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
            onClick={() =>
              navigate(`/dashboard/customers/${session.customer.orgSeqNo}`)
            }
          >
            {`Customer Details ( ${session.customer.orgSeqNo} )`}
          </ButtonBase>
        </div>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Label text="Name" color="secondary" />
              <Typography fontWeight={700}>{contactDetails.name}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Contact" color="secondary" />
              <Typography fontWeight={700}>
                {contactDetails.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Email" color="secondary" />
              <Typography fontWeight={700}>{contactDetails.email}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Shipping Address" color="secondary" />
              <Typography fontWeight={700}>
                {formatAddress(session.shippingAddress)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Label text="Billing Address" color="secondary" />
              <Typography fontWeight={700}>
                {formatAddress(session.billingAddress)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  )
}

export default CheckoutSessionDetailsPage
