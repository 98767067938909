import { JSX, useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import LoadingSpinner from 'components/spinner'
import { useAuth } from 'hooks/useAuth'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { getUserOrganizations } from 'services/organization'
import { UserOrganizations } from 'services/organization/index.types'
import { roleFormat } from 'utils/helper'

import OtherStoreDetail from './components/OtherStoreDetail'
import PrimaryStoreDetail from './components/PrimaryStoreDetail'

const StoreSettings = (): JSX.Element => {
  const { organizationId, changeOrganization, organization } = useAuth()
  const [stores, setStores] = useState<UserOrganizations[]>()
  const [storeUpdate, setStoreUpdate] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const otherStores = stores
    ?.filter((el) => el.organizationId !== organizationId)
    .map((el) => ({
      name: el.organization.name,
      role: roleFormat(el.role.toLowerCase()),
      storeId: el.organizationId,
    }))

  const primaryStore = stores?.find(
    (el) => el.organizationId === organizationId
  )

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result = await getUserOrganizations()
      if (result.status === 'successful') {
        setStores(result.data)
      }
      setIsLoading(false)
      setStoreUpdate(false)
    }
    if (organizationId || storeUpdate) get()
  }, [organizationId, storeUpdate])

  const handleDeleteStore = (): void => {
    if (otherStores && otherStores.length > 0) {
      changeOrganization(otherStores[0].storeId)
    } else if (!otherStores || (otherStores && otherStores.length === 0)) {
      changeOrganization(null)
      setStores([])
    }
  }

  // Update list
  const storeUpdateHandler = (): void => {
    setStoreUpdate(true)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!stores || (stores && stores.length === 0)) {
    return <SetupStorePrompt />
  }

  return (
    <>
      <Helmet>
        <title>
          Stores
          {organization && organization.name ? ` | ${organization.name}` : ''}
        </title>
      </Helmet>
      {primaryStore && (
        <PrimaryStoreDetail
          store={primaryStore}
          onDelete={handleDeleteStore}
          onUpdate={storeUpdateHandler}
        />
      )}
      {otherStores && otherStores.length > 0 && (
        <OtherStoreDetail stores={otherStores} />
      )}
    </>
  )
}

export default StoreSettings
