import { Navigate } from 'react-router-dom'

import OnboardingContainer from 'components/forms/OnboardingContainer'
import SignUpForm from 'components/forms/SignUpForm'
import { useAuth } from 'hooks/useAuth'

const SignupWithEmail = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Navigate to="/dashboard/home" replace={true} />

  return (
    <OnboardingContainer
      title="Get Started for Free"
      description="Start with our free plan, no strings attached!"
    >
      <SignUpForm />
    </OnboardingContainer>
  )
}

export default SignupWithEmail
