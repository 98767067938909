import React from 'react'

import { Box, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import AOS from 'aos'
import { Navigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

import Demo from './components/Demo'
import FAQSection from './components/FAQ'
import FeaturesSection from './components/Features'
import Footer from './components/Footer'
import HeroSection from './components/Hero'

const RootContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}))

const ContentContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}))

const LandingPageSection: React.FC = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Navigate to={'/dashboard/home'} />

  AOS.init({ duration: 1200 })

  return (
    <RootContainer>
      <ContentContainer>
        <HeroSection />
        <Demo />
      </ContentContainer>
      <Box
        sx={{
          background: `url(${process.env.REACT_APP_ASSETS_URL}/images/background/waves.svg) no-repeat`,
          backgroundSize: 'cover',
          width: '100%',
        }}
      >
        <Container>
          <FeaturesSection />
        </Container>
      </Box>
      <Box
        sx={{
          background:
            'linear-gradient(to bottom, #f4f6f7, rgba(0, 137, 123, 0.1))',
          backgroundSize: 'cover',
          width: '100%',
        }}
      >
        <Container>
          {/* <ContactUsForm /> */}
          <FAQSection />
        </Container>
      </Box>
      <Box
        sx={{
          background: 'rgba(0, 137, 123, 0.1)',
          width: '100%',
        }}
      >
        <Footer />
      </Box>
    </RootContainer>
  )
}

export default LandingPageSection
