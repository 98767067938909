import { JSX, useEffect, useRef, useState } from 'react'

import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid'
import { useSearchParams } from 'react-router-dom'

import { DataGridTable } from 'pages/dashboard/components/StyledDataGrid'
import { getOrders } from 'services/orders'
import { Order } from 'services/orders/index.type'
import { formatPrice, getOperator } from 'utils/helper'

import { columns } from './Columns'
import OrderStatusChips from './OrderStatusChips'
import { CustomToolbar } from './TableToolbar'
interface FilterModelType {
  filterField: string
  filterValue: string
  filterOperator: string
  filterLogicOperator: 'AND' | 'OR' | ''
}

const OrdersList = ({ orgId }: { orgId: number }): JSX.Element => {
  const [searchParams] = useSearchParams()
  const orderStatus = searchParams.get('orderStatus')

  const [list, setList] = useState<Order[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const cursor = useRef<{
    cursorPoint: number
    direction: 'forward' | 'backward' | ''
  }>({ cursorPoint: 0, direction: '' })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  //Sorting model
  const [sortingModel, setSortingModel] = useState<{
    sortField: string
    sortDirection: 'asc' | 'desc' | ''
  }>({
    sortField: '',
    sortDirection: '',
  })

  //Filteration Model
  const [filterModel, setFilterModel] = useState<FilterModelType>({
    filterField: '',
    filterValue: '',
    filterOperator: '',
    filterLogicOperator: '',
  })

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ): void => {
    if (paginationModel.page > newPaginationModel.page) {
      cursor.current.cursorPoint = list[0].id
      cursor.current.direction = 'backward'
      setPaginationModel(newPaginationModel)
    } else if (paginationModel.page < newPaginationModel.page) {
      cursor.current.cursorPoint = list[list.length - 1].id
      cursor.current.direction = ''
      setPaginationModel(newPaginationModel)
    } else if (paginationModel.page === newPaginationModel.page) {
      cursor.current.cursorPoint = 0
      cursor.current.direction = ''
      setPaginationModel({ page: 0, pageSize: newPaginationModel.pageSize })
    }
  }

  useEffect(() => {
    if (orderStatus && orderStatus !== 'all') {
      setFilterModel({
        filterField: 'status',
        filterValue: orderStatus,
        filterOperator: 'contains',
        filterLogicOperator: 'AND',
      })
      cursor.current.cursorPoint = 0
      cursor.current.direction = ''
    } else if (orderStatus === 'all') {
      setFilterModel({
        filterField: '',
        filterValue: '',
        filterOperator: '',
        filterLogicOperator: '',
      })
      cursor.current.cursorPoint = 0
      cursor.current.direction = ''
    }
  }, [orderStatus])

  useEffect(() => {
    async function initialFetch(): Promise<void> {
      const direction = cursor.current.direction
      const queryOptions = {
        orgId,
        cursor: cursor.current.cursorPoint || undefined,
        pageSize: paginationModel.pageSize || undefined,
        direction: direction || undefined,
        sortField: sortingModel.sortField,
        sortDirection: sortingModel.sortDirection,
        filterField: filterModel.filterField,
        filterValue: filterModel.filterValue,
        filterOperator: filterModel.filterOperator,
        filterLogicOperator: filterModel.filterLogicOperator,
      }

      const result = await getOrders(queryOptions)
      if (result) {
        setRowCount(result.total)
        setList(result.orders)
        if (result.orders.length > 0) {
          cursor.current.cursorPoint =
            result.orders[result.orders.length - 1].id
        }
      }
    }
    initialFetch()
  }, [orgId, paginationModel, sortingModel, filterModel])

  //Sorting
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel.length === 0) {
      setSortingModel({ sortField: '', sortDirection: '' })
    } else {
      setSortingModel({
        sortField: sortModel[0].field,
        sortDirection: sortModel[0].sort ?? '',
      })
    }
    changeDefaultPagination()
  }

  // Setting filterModel fields when user try to filter
  const onFilterChange = (newFilterModel: GridFilterModel): void => {
    if (newFilterModel.items.length === 0) {
      if (orderStatus && orderStatus !== 'all') {
        setFilterModel({
          filterField: 'status',
          filterValue: orderStatus,
          filterOperator: 'contains',
          filterLogicOperator: 'AND',
        })
      }
      changeDefaultPagination()
      return
    }

    if (
      newFilterModel.items.length > 0 &&
      (newFilterModel.items[0].value === undefined ||
        newFilterModel.items[0].value === 0)
    ) {
      if (orderStatus && orderStatus !== 'all') {
        setFilterModel({
          filterField: 'status',
          filterValue: orderStatus,
          filterOperator: 'contains',
          filterLogicOperator: 'AND',
        })
      } else {
        setFilterModel({
          filterField: '',
          filterValue: '',
          filterOperator: '',
          filterLogicOperator: '',
        })
      }
    } else {
      const operator = getOperator(newFilterModel.items[0].operator)
      if (newFilterModel.items[0].field === 'createdAt') {
        const inputDate = new Date(newFilterModel.items[0].value)
        newFilterModel.items[0].value = inputDate.toISOString()
      }
      setFilterModel({
        filterField: newFilterModel.items[0].field,
        filterValue: newFilterModel.items[0].value,
        filterOperator: operator,
        filterLogicOperator: (newFilterModel.logicOperator?.toUpperCase() ??
          '') as 'AND' | 'OR' | '',
      })
      if (orderStatus && orderStatus !== 'all') {
        setFilterModel({
          filterField: `status,${newFilterModel.items[0].field}`,
          filterValue: `${orderStatus},${newFilterModel.items[0].value}`,
          filterOperator: `contains,${operator}`,
          filterLogicOperator: 'AND',
        })
      }
    }
    changeDefaultPagination()
  }

  const changeDefaultPagination = () => {
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    })
    cursor.current.cursorPoint = 0
    cursor.current.direction = ''
  }

  const rows = list?.map((rowData: Order) => {
    const { pinCode } = rowData.shippingAddress
    const { firstName = '', lastName = '' } = rowData.customer ?? {}

    return {
      id: rowData.orgSeqNo,
      uid: rowData.uid,
      fullName: `${firstName} ${lastName ?? ''}`,
      shippingAddress: pinCode,
      createdAt: rowData.createdAt,
      phoneNo: rowData.phoneNo,
      status: rowData.status,
      totalPrice: formatPrice(rowData.totalPrice),
    }
  })

  return (
    <div className="mx-5">
      <OrderStatusChips />

      <DataGridTable
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: CustomToolbar,
        }}
        disableRowSelectionOnClick
        disableColumnMenu
        disableDensitySelector
        pagination
        paginationMode="server"
        rowCount={rowCount}
        onPaginationModelChange={handlePaginationModelChange}
        paginationModel={paginationModel}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        containerHeight={'calc(100vh - 200px)'}
      />
    </div>
  )
}

export default OrdersList
