import { JSX, useEffect, useState } from 'react'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import EditButton from 'pages/dashboard/components/EditButton'
import { changePassword, updateUser } from 'services/auth'
import theme from 'styles/muiTheme'

const FormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .min(8, 'Password must be 8+ characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$*_^&])[A-Za-z\d@#$*_^&]{8,}$/,
      'Password must be 8+ characters with at least one uppercase, one lowercase, one digit, and one special character (@, #, $, *, ^, &)'
    )
    .required('New password is required'),
  confirmNewPassword: Yup.string()
    .min(1, 'Password confirmation is required')
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords do not match')
    .required('Confirm password is required'),
})

const Settings = (): JSX.Element => {
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const { showSnackbar } = useSnackbar()

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
  })
  const [error, setError] = useState({
    firstName: '',
    lastName: '',
  })

  useEffect(() => {
    if (user) {
      setValues({
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
      })
    }
  }, [user])

  const handleFirstNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setValues((prev) => ({ ...prev, firstName: e.target.value }))
    setError((prev) => ({ ...prev, firstName: '' }))
  }

  const handleLastNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setValues((prev) => ({ ...prev, lastName: e.target.value }))
    setError((prev) => ({ ...prev, lastName: '' }))
  }

  const handleFirstNameBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      setError((prev) => ({ ...prev, firstName: '*First Name is required.' }))
    }
  }

  const handleSave = async (): Promise<void> => {
    if (values.firstName === '') {
      setError((prev) => ({ ...prev, firstName: '*First Name is required.' }))
      return
    }

    try {
      const response = await updateUser({
        firstName: values.firstName,
        lastName: values.lastName,
      })

      if (response?.status === 'successful') {
        setValues({
          firstName: values.firstName,
          lastName: values.lastName,
        })
        showSnackbar('Name updated successfully', 'success')
      } else {
        showSnackbar(response?.message || 'Failed to update name', 'error')
      }
    } catch (error) {
      console.error('Error updating name:', error)
      showSnackbar('An error occurred while updating the name', 'error')
    }

    setEdit(false)
  }

  const handleCancel = (): void => {
    setValues({
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
    })
    setError({
      firstName: '',
      lastName: '',
    })
    setEdit(false)
  }

  const handleClickOpen = (): void => {
    if (user?.id) {
      Amplitude.trackEvent('ACCOUNT_CHANGEPASSWORD_CLICKED', {
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email,
      })
    }
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      if (user?.id) {
        Amplitude.trackEvent('ACCOUNT_CHANGEPASSWORD_SAVE_CLICKED', {
          userId: user.id,
          userName: `${user.firstName} ${user.lastName}`,
          userEmail: user.email,
        })
      }
      const result = await changePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      if (result?.status === 'success')
        showSnackbar('Password changed successfully', 'success')

      handleClose()
    },
  })

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  const toggleCurrentPasswordVisibility = (): void => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const toggleConfirmPasswordVisibility = (): void => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleEdit = (): void => {
    setEdit((prev) => !prev)
  }

  return (
    <div className=" mb-5">
      <Box className=" flex gap-3 items-center justify-between mx-5">
        <h2 className="text-lg font-semibold">Account Settings</h2>
        {!edit && <EditButton buttonText={'Edit'} onClick={handleEdit} />}
      </Box>
      <Card>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={12} md={6}>
            <Label text="First Name" color="secondary" />
            {edit ? (
              <CustomTextField
                name="firstName"
                value={values.firstName}
                onChange={handleFirstNameChange}
                onBlur={handleFirstNameBlur}
                error={Boolean(error.firstName)}
                helperText={error.firstName}
              />
            ) : (
              <Typography fontWeight={700}>
                {values.firstName || 'N/A'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Last Name" color="secondary" />
            {edit ? (
              <CustomTextField
                name="lastName"
                value={values.lastName}
                onChange={handleLastNameChange}
                error={Boolean(error.lastName)}
                helperText={error.lastName}
              />
            ) : (
              <Typography fontWeight={700}>
                {values.lastName || 'N/A'}
              </Typography>
            )}
          </Grid>
          <Grid item md={6}>
            <Label text="Email" color="secondary" />
            <Typography fontWeight={700}>{user?.email}</Typography>
          </Grid>
        </Grid>
        <Button
          variant="text"
          size="small"
          sx={{
            color: 'text.primary',
            borderRadius: 0,
            p: 0,
            borderBottom: '1px solid',
            textTransform: 'none',
            fontWeight: 700,
            mt: 1.5,
          }}
          onClick={handleClickOpen}
        >
          Change Password
        </Button>
        {edit && (
          <div className="mt-5">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          PaperProps={{ component: 'form', onSubmit: formik.handleSubmit }}
        >
          <DialogTitle fontWeight={600}>Change Password</DialogTitle>
          <DialogContent>
            <div>
              <div className="flex items-center justify-between">
                <Label
                  text="Current Password"
                  htmlFor="currentPassword"
                  color="secondary"
                />
                <IconButton
                  sx={{ color: theme.palette.text.primary, padding: 0 }}
                  onClick={toggleCurrentPasswordVisibility}
                >
                  {showCurrentPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </div>
              <CustomTextField
                id="currentPassword"
                name="currentPassword"
                type={showCurrentPassword ? 'text' : 'password'}
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                }
                helperText={
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword
                }
              />
            </div>
            <div>
              <div className="flex items-center justify-between">
                <Label
                  text="New Password"
                  htmlFor="newPassword"
                  color="secondary"
                />
                <IconButton
                  sx={{ color: theme.palette.text.primary, padding: 0 }}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </div>
              <CustomTextField
                id="newPassword"
                name="newPassword"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />
            </div>
            <div>
              <div className="flex items-center justify-between">
                <Label
                  text="Confirm New Password"
                  htmlFor="confirmNewPassword"
                  color="secondary"
                />
                <IconButton
                  sx={{ color: theme.palette.text.primary, padding: 0 }}
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </div>
              <CustomTextField
                id="confirmNewPassword"
                name="confirmNewPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmNewPassword &&
                  Boolean(formik.errors.confirmNewPassword)
                }
                helperText={
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleClose}
              size="small"
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="small"
              sx={{ textTransform: 'none' }}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Submitting' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </div>
  )
}

export default Settings
