import { getToken } from 'utils/authCookies'

import { PricingPlan } from './index.type'

const pricingPlanBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/pricing-plans`

const getPricingPlan = async (
  planId: number
): Promise<{
  status: string
  data?: PricingPlan
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${pricingPlanBasePath}/${planId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    const { data, message } = await res.json()

    if (res.ok) {
      return { status: 'successful', data: data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

export { getPricingPlan }
