/* eslint-disable no-console */
import { useEffect, useState } from 'react'

import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import SizeChart from 'pages/dashboard/pages/products/components/SizeChart'
import { updateOrganization } from 'services/organization'
import { formatPrice } from 'utils/helper'

const ProductSettings = (): JSX.Element => {
  const { organization, updateOrganizationData } = useAuth()
  const [standardShipping, setStandardShipping] = useState<number>()
  const { showSnackbar } = useSnackbar()
  // const [expressShipping, setExpressShipping] = useState<number>()

  const [shippingCostEdit, setShippingCostEdit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (!organization) return
    if (organization) {
      setStandardShipping(organization.shippingCost / 100)
    }
  }, [organization])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!organization) return

    if (organization.id) {
      Amplitude.trackEvent('ORGANIZATION_SHIPPING_SAVE_CLICKED', {
        orgId: organization.id,
      })
    }

    if (organization.shippingCost === (standardShipping ?? 0) * 100) return

    setIsSubmitting(true)
    const result = await updateOrganization(organization.id, {
      shippingCost: (standardShipping ?? 0) * 100,
    })

    if (result && result.status === 'successful' && result.data) {
      showSnackbar('Shipping cost updated', 'success')
      updateOrganizationData({
        ...organization,
        shippingCost: result.data.shippingCost,
      })
      setShippingCostEdit(false)
    } else {
      showSnackbar('Please try again later.', 'error')
    }
    setIsSubmitting(false)
  }

  const handleCancel = () => {
    setShippingCostEdit(false)
    if (organization) {
      setStandardShipping(organization.shippingCost / 100)
    }
  }

  const handleSizeChartChange = async (url?: string): Promise<void> => {
    if (!organization) return

    if (organization.id) {
      Amplitude.trackEvent('ORGANIZATION_SIZE_CHART_CHANGE', {
        orgId: organization.id,
      })
    }

    const result = await updateOrganization(organization.id, {
      sizeChart: url ?? null,
    })

    if (result && result.status === 'successful' && result.data) {
      showSnackbar('Size chart updated', 'success')
      updateOrganizationData({
        ...organization,
        sizeChart: result.data.sizeChart,
      })
    } else {
      showSnackbar('Please try again later.', 'error')
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Product Settings
          {organization && organization.name ? ` | ${organization.name}` : ''}
        </title>
      </Helmet>
      <div className="mb-5">
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <h2 className="text-lg font-semibold ml-5">Product Settings</h2>
        </Box>
        <Box className="px-5 mt-5">
          {' '}
          <SubTabHeader
            title="Delivery"
            buttonText={shippingCostEdit ? undefined : 'Edit'}
            onClick={() => setShippingCostEdit(true)}
          />
        </Box>

        <Card>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="block">
                  <Label
                    text="Standard Shipping Cost"
                    htmlFor="standardShippingCost"
                    color="secondary"
                  />

                  {shippingCostEdit ? (
                    <TextField
                      name="standardShippingCost"
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      className="bg-white"
                      required
                      value={standardShipping}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setStandardShipping(Number(e.target.value))
                      }
                    />
                  ) : (
                    <p className="text-sm font-bold">
                      {formatPrice(organization?.shippingCost)}
                    </p>
                  )}
                </div>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <div className="block">
                  <Label
                    text="Express Shipping Cost"
                    htmlFor="expressShippingCost"
                    color="secondary"
                  />

                  {shippingCostEdit ? (
                    <TextField
                      name="expressShippingCost"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      className="bg-white"
                      required
                      value={expressShipping}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setExpressShipping(Number(e.target.value))
                      }
                    />
                  ) : (
                    <p className="text-sm font-bold">₹100</p>
                  )}
                </div>
              </Grid> */}
            </Grid>

            {shippingCostEdit && (
              <Box className="mt-4 flex" sx={{ gap: 2 }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleCancel}
                  disabled={isSubmitting}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disabled={isSubmitting}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
              </Box>
            )}
          </Box>
        </Card>
        <SizeChart
          sizeChartUrl={organization?.sizeChart ?? undefined}
          onSizeChartChange={handleSizeChartChange}
        />
      </div>
    </>
  )
}

export default ProductSettings
