import { Box, Button, Chip, Divider } from '@mui/material'
import { FaGoogle } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'

import OnboardingContainer from 'components/forms/OnboardingContainer'

const SignupStrategy = () => {
  const navigate = useNavigate()

  const onEmailSignup = () => {
    navigate('email')
  }

  const handleGoogleSignin = () => {
    const redirectPath = '/signin-callback'
    const currentDomain = window.location.origin
    const redirectUri = encodeURIComponent(`${currentDomain}${redirectPath}`)

    const googleAuthUrl = `https://${process.env.REACT_APP_COGNITO_APP_CLIENT_DOMAIN}/oauth2/authorize?response_type=code&identity_provider=Google&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&redirect_uri=${redirectUri}&scope=email+openid+profile`

    window.location.href = googleAuthUrl
  }

  return (
    <OnboardingContainer
      title="Create your Haulistic Account"
      description="Start with our free plan, no strings attached!"
    >
      <Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={2}
          my={5}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              textTransform: 'none',
              paddingY: 2,
              ':hover': {
                backgroundColor: 'black',
                color: 'white',
              },
            }}
            className="w-full md:w-3/4"
            onClick={onEmailSignup}
          >
            <MdMail size={18} className="mr-3" />
            Sign up with Email
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              textTransform: 'none',
              paddingY: 2,
              ':hover': {
                backgroundColor: 'black',
                color: 'white',
              },
            }}
            className="w-full md:w-3/4"
            onClick={handleGoogleSignin}
          >
            <FaGoogle size={18} className="mr-3" />
            Sign up with Google
          </Button>
        </Box>
        <Divider>
          <Chip label="or" size="small" />
        </Divider>
        <p className="text-sm my-4 text-center">
          Already have a Haulistic account?{' '}
          <Link to="/signin" className="underline cursor-pointer">
            Sign In{' '}
          </Link>
        </p>
        <p className="text-xs md:text-sm text-gray-400 mt-8">
          By proceeding, you agree to the{' '}
          <Link
            to="/terms-of-service"
            color="inherit"
            className="underline cursor-pointer"
          >
            Terms and Conditions
          </Link>{' '}
          and{' '}
          <Link
            to="/privacy-policy"
            color="inherit"
            className="underline cursor-pointer"
          >
            Privacy Policy
          </Link>
        </p>
      </Box>
    </OnboardingContainer>
  )
}

export default SignupStrategy
