import { FC, useState } from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Typography } from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { deleteProductOption } from 'services/products'
import { Product, ProductOption } from 'types/product'

import AddNewVariantForm from './AddNewVariantForm'
import VariantRow from './VariantRow'

interface VariantsTableProps {
  productCode: string
  optionData: ProductOption
  onUpdate: (data?: Product) => void
  product: Product
}

export interface RowValue {
  id?: number
  optionId: number
  value: string
  priceAdjustment?: string
  stock: string
  media: string[]
  caption: string
}

const VariantsTable: FC<VariantsTableProps> = ({
  optionData,
  productCode,
  product,
  onUpdate,
}) => {
  const [openOptionDialog, setOpenOptionDialog] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()
  const [isDeleting, setIsDeleting] = useState(false)
  const isPrimaryAvail = product.ProductOption.find(
    (op) => op.type === 'PRIMARY'
  )

  const handleDeleleOption = async () => {
    if (!organizationId) return
    setIsDeleting(true)
    const res = await deleteProductOption(
      organizationId,
      productCode,
      optionData.id
    )
    if (res.status === 'successful') {
      const updatedProduct = {
        ...product,
        ProductOption: product.ProductOption.filter(
          (op) => op.id !== optionData.id
        ),
      }
      onUpdate?.(updatedProduct)
      showSnackbar('Option Deleted successfully', 'success')
    }
    setIsDeleting(false)
  }

  const handleDeleleOptionValue = async (valueId: number) => {
    const updatedProduct = {
      ...product,
      ProductOption: product.ProductOption.map((op) => {
        if (op.id !== optionData.id) return op
        else
          return {
            ...op,
            ProductOptionValue: op.ProductOptionValue.filter(
              (vl) => vl.id !== valueId
            ),
          }
      }),
    }
    onUpdate?.(updatedProduct)
  }

  return (
    <>
      <div className="mb-5">
        <div className="border-b border-gray-300 flex items-center justify-between">
          <Typography fontWeight={700}>{optionData.name}</Typography>
          <div>
            <IconButton onClick={() => setOpenOptionDialog(true)}>
              <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
            </IconButton>
            <IconButton onClick={handleDeleleOption} disabled={isDeleting}>
              <DeleteOutlineOutlinedIcon
                fontSize="small"
                sx={{ color: 'text.primary' }}
              />
            </IconButton>
          </div>
        </div>
        <div>
          {optionData.ProductOptionValue.map((opValue, index) => (
            <div
              key={opValue.id}
              className={
                index < optionData.ProductOptionValue.length ? 'mt-2' : ''
              }
            >
              <VariantRow
                optionName={optionData.name}
                data={opValue}
                productCode={productCode}
                productMedia={product.media}
                onDelete={handleDeleleOptionValue}
                onUpdateProduct={onUpdate}
                product={product}
              />
            </div>
          ))}
        </div>
      </div>
      <AddNewVariantForm
        currentValues={optionData}
        isPrimaryPresent={isPrimaryAvail ? true : false}
        openDialog={openOptionDialog}
        onCloseDialog={() => setOpenOptionDialog(false)}
        product={product}
        onUpdate={onUpdate}
      />
    </>
  )
}

export default VariantsTable
