import { JSX, useEffect, useState } from 'react'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { getCustomer } from 'services/customers'
import { Customer } from 'services/customers/index.type'

import CustomerDetails from '../components/CustomerDetails'

const CustomersDetailsPage = (): JSX.Element => {
  const navigate = useNavigate()
  const { customerId } = useParams()
  const [data, setData] = useState<Customer>()
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const { organizationId } = useAuth()

  const handleUpdate = (): void => {
    setIsUpdate(true)
  }

  useEffect(() => {
    async function get(): Promise<void> {
      if (customerId || isUpdate) {
        const result = organizationId
          ? await getCustomer(Number(customerId), organizationId)
          : { status: 'failed', data: null }
        if (result?.status === 'successful' && result.data) {
          setData(result.data)
        }
        setIsUpdate(false)
      }
    }
    get()
  }, [customerId, isUpdate, organizationId])

  return (
    <>
      <Box display="flex" alignItems={'center'} gap={2}>
        <button onClick={() => navigate(-1)} className=" ml-5">
          <KeyboardBackspaceIcon />
        </button>
        <Typography
          paragraph
          sx={{
            fontWeight: '700',
            fontSize: '1.2rem',
            marginBottom: '1px',
          }}
        >
          Customer Details
        </Typography>
      </Box>
      {data && (
        <CustomerDetails
          data={data}
          setData={setData}
          onUpdate={handleUpdate}
        />
      )}
    </>
  )
}

export default CustomersDetailsPage
