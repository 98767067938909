import React, { useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Rating,
  ImageListItem,
  ImageList,
} from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import { useNavigate } from 'react-router-dom'

interface ReviewItemProps {
  id: number
  name: string
  productName: string
  productId: number
  rating: number
  createdAt: string
  reviewTitle?: string
  reviewText?: string
  reviewStatus: string
  email: string
  media: Array<string>
}

interface ProductsProps {
  id: number
  name: string
  media: Array<string>
  productCode: string
}

const getFileType = (url: string) => {
  const type = new URL(url).searchParams.get('type')
  return type || ''
}

const ReviewsList: React.FC<{
  products: ProductsProps[]
  reviews: ReviewItemProps[]
  activeTab: string
  orgId: number
  onStatusChange: (reviewId: number, status: string) => void
}> = ({ products, reviews, activeTab, onStatusChange }) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState<number | null>(null)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const handleRowClick = (id: number) => {
    setExpanded(expanded === id ? null : id)
  }

  const handleNavigateToProduct = (id: number) => {
    const product = products.find((item) => item.id === id)
    if (product) {
      navigate(`/dashboard/products/${product.productCode}`)
    }
  }

  return (
    <TableContainer component={Paper} className="rounded shadow-md">
      <Table>
        <TableHead sx={{ backgroundColor: 'accents.darkGrayAccent' }}>
          <TableRow>
            <TableCell sx={{ paddingRight: '8px' }}>
              <strong>Summary</strong>
            </TableCell>
            <TableCell sx={{ paddingX: '8px' }}>
              <strong>Rating</strong>
            </TableCell>
            <TableCell sx={{ paddingX: '8px' }}>
              <strong>Created</strong>
            </TableCell>
            <TableCell sx={{ paddingX: '8px' }}>
              <strong>Actions</strong>
            </TableCell>
            <TableCell sx={{ paddingX: '8px' }}>
              <strong>{''}</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reviews.map((review) => (
            <React.Fragment key={review.id}>
              <TableRow
                onClick={() => handleRowClick(review.id)}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'accents.grayAccent',
                }}
              >
                <TableCell sx={{ paddingRight: '8px' }}>
                  <div>
                    <Typography
                      sx={{
                        whiteSpace: { xs: 'nowrap', sm: 'normal' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <strong>{review.name}</strong> wrote a review about{' '}
                      <strong
                        className="underline cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleNavigateToProduct(review.productId)
                        }}
                      >
                        {review.productName}
                      </strong>
                    </Typography>
                  </div>
                  {review.email && (
                    <Typography className=" text-gray-500" fontSize={'0.8rem'}>
                      {review.email}
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ paddingX: '8px' }}>
                  <Rating
                    value={review.rating}
                    size="small"
                    readOnly
                    precision={0.5}
                  />
                </TableCell>
                <TableCell>
                  {' '}
                  {formatDistanceToNow(new Date(review.createdAt), {
                    addSuffix: true,
                  })}
                </TableCell>
                <TableCell sx={{ paddingX: '8px' }}>
                  {activeTab === 'pending' && (
                    <>
                      <Typography
                        component="span"
                        sx={{
                          color: '#00796b',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          onStatusChange(review.id, 'approved')
                        }}
                      >
                        Approve
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          color: '#d32f2f',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          marginLeft: 2,
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          onStatusChange(review.id, 'rejected')
                        }}
                      >
                        Reject
                      </Typography>
                    </>
                  )}
                  {activeTab === 'approved' && (
                    <Typography
                      component="span"
                      sx={{
                        color: '#00796b',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        '&:hover': { textDecoration: 'underline' },
                      }}
                      className="hover:underline"
                      onClick={(e) => {
                        e.stopPropagation()
                        onStatusChange(review.id, 'rejected')
                      }}
                    >
                      Reject
                    </Typography>
                  )}
                  {activeTab === 'rejected' && (
                    <>
                      <Typography
                        sx={{
                          color: '#00796b',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                        className="hover:underline"
                        onClick={(e) => {
                          e.stopPropagation()
                          onStatusChange(review.id, 'approved')
                        }}
                      >
                        Approve
                      </Typography>
                    </>
                  )}
                </TableCell>
                <TableCell sx={{ paddingX: '8px' }}>
                  <ExpandMoreIcon
                    sx={{
                      transform:
                        expanded === review.id
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                </TableCell>
              </TableRow>
              {expanded === review.id && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Accordion expanded={true}>
                      <AccordionDetails
                        sx={{
                          backgroundColor: '#F4F7F6',
                        }}
                      >
                        <Typography variant="h6" fontWeight="bold">
                          {review.reviewTitle}
                        </Typography>
                        <Typography className="mb-1">
                          {review.reviewText || 'No additional text provided.'}
                        </Typography>
                        {review.media && review.media.length > 0 && (
                          <ImageList
                            cols={4}
                            className="mt-4"
                            sx={{ width: { lg: 500 } }}
                          >
                            {review.media.map((fileUrl, imgIndex) => {
                              const fileType = getFileType(fileUrl)
                              const isVideo = fileType.startsWith('video/')
                              return (
                                <ImageListItem
                                  key={imgIndex}
                                  onClick={() => setActiveIndex(imgIndex)}
                                >
                                  {isVideo ? (
                                    <video
                                      src={fileUrl}
                                      autoPlay
                                      muted
                                      loop
                                      playsInline
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        minHeight: '80px',
                                        objectFit: 'cover',
                                      }}
                                    >
                                      <track
                                        kind="captions"
                                        label="English"
                                        srcLang="en"
                                        src=""
                                        default
                                      />
                                    </video>
                                  ) : (
                                    <img
                                      src={fileUrl}
                                      height={75}
                                      className={`cursor-pointer object-contain ${
                                        activeIndex === imgIndex
                                          ? 'opacity-100'
                                          : 'opacity-90'
                                      } hover:opacity-100`}
                                      alt={`Thumbnail ${imgIndex}`}
                                    />
                                  )}
                                </ImageListItem>
                              )
                            })}
                          </ImageList>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReviewsList
