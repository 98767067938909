import * as amplitude from '@amplitude/analytics-browser'

interface EventProperties {
  [key: string]: string | number | boolean
}

interface UserProperties {
  [key: string]: string | number | boolean
}

class Amplitude {
  private static initialized = false

  /**
   * Initializes the Amplitude SDK.
   * @param apiKey - The API key for your Amplitude project.
   * @param sessionSampleRate - Sample rate for session replays (0 to 1).
   */
  static init(apiKey: string, sessionSampleRate: number = 1): void {
    if (!this.initialized) {
      // Check if sessionReplay is available on the window
      if (
        window.sessionReplay &&
        typeof window.sessionReplay.plugin === 'function'
      ) {
        amplitude.add(
          window.sessionReplay.plugin({ sampleRate: sessionSampleRate })
        )
      } else {
        this.logError('Session Replay plugin is not available.')
      }

      // Initializing Amplitude with autocapture enabled
      amplitude.init(apiKey, {
        autocapture: {
          elementInteractions: false,
          formInteractions: false,
          pageViews: false,
        },
      })

      this.initialized = true
    }
  }

  /**
   * Tracks an event.
   * @param eventName - The name of the event to track.
   * @param properties - Additional properties to attach to the event.
   */
  static trackEvent(eventName: string, properties?: EventProperties): void {
    if (this.initialized) {
      amplitude.track(eventName, properties)
    } else {
      this.logError(
        'Amplitude has not been initialized. Call Amplitude.init() first.'
      )
    }
  }

  /**
   * Sets the user ID for Amplitude.
   * @param userId - The unique identifier for the user.
   */
  static setUserId(userId: string): void {
    if (this.initialized) {
      amplitude.setUserId(userId.toString())
    } else {
      this.logError(
        'Amplitude has not been initialized. Call Amplitude.init() first.'
      )
    }
  }

  /**
   * Sets user properties using the Identify API.
   * @param properties - An object containing user properties.
   */
  static setUserProperties(properties: UserProperties): void {
    if (this.initialized) {
      const identify = new amplitude.Identify()
      for (const key in properties) {
        if (Object.prototype.hasOwnProperty.call(properties, key)) {
          const value = properties[key]

          // Only set properties if the value is not null or undefined
          if (value !== null && value !== undefined) {
            identify.set(key, value)
          }
        }
      }
      amplitude.identify(identify)
    } else {
      this.logError(
        'Amplitude has not been initialized. Call Amplitude.init() first.'
      )
    }
  }

  /**
   * Logs a custom event with additional properties.
   * @param eventName - The name of the custom event to log.
   * @param properties - Additional properties for the custom event.
   */
  static logCustomEvent(eventName: string, properties: EventProperties): void {
    this.trackEvent(eventName, properties)
  }

  /**
   * Flushes any pending events to Amplitude.
   */
  static flushEvents(): void {
    if (this.initialized) {
      amplitude.flush()
    } else {
      this.logError(
        'Amplitude has not been initialized. Call Amplitude.init() first.'
      )
    }
  }

  /**
   * Logs an error message to the console.
   * @param message - The error message to log.
   */
  private static logError(message: string): void {
    console.error(`[Amplitude] ${message}`)
  }
}

export default Amplitude
