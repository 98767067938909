import { FC } from 'react'

import Pagination from '@mui/material/Pagination'
import { useLocation, useNavigate } from 'react-router-dom'

interface PaginationProps {
  totalProducts: number
  itemsPerPage?: number
}

const CustomPagination: FC<PaginationProps> = ({
  totalProducts,
  itemsPerPage = 16,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  const offsetParam = searchParams.get('offset') || '0'
  const limitParam = searchParams.get('limit') || itemsPerPage.toString()

  const totalPages = Math.ceil(totalProducts / itemsPerPage)
  const limit = parseInt(limitParam)
  const currentPage = Math.floor(parseInt(offsetParam) / limit) + 1

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    const params = new URLSearchParams(location.search)
    const newOffset = (page - 1) * limit
    params.set('offset', newOffset.toString())
    params.set('limit', limit.toString())

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  return (
    <Pagination
      count={totalPages}
      shape="rounded"
      page={currentPage}
      onChange={handlePageChange}
      sx={{
        '& .MuiPaginationItem-root': {
          borderRadius: 0,
        },
        '& .Mui-selected': {
          backgroundColor: 'none',
          borderBottom: '2px solid #666666',
        },
      }}
    />
  )
}

export default CustomPagination
