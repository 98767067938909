import { useState } from 'react'

import {
  Box,
  Button,
  Card,
  InputAdornment,
  styled,
  TextField,
} from '@mui/material'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import { Helmet } from 'react-helmet-async'
import { useForm, Controller } from 'react-hook-form'

import Amplitude from 'lib/amplitude'
import { joinWaitlist } from 'services/auth'

interface FormData {
  name: string
  email: string
  phoneNo: string
  brandName: string
}

const JoinWaitlistFormWithRecaptcha: React.FC = () => {
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

  if (!recaptchaKey) {
    throw new Error('Missing : REACT_APP_RECAPTCHA_SITE_KEY ')
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <JoinWaitlist />
    </GoogleReCaptchaProvider>
  )
}

const JoinWaitlist = () => {
  Amplitude.trackEvent('JOIN_WAITLIST_PAGE_LOADED', {
    referrer: document.referrer,
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: { name: '', email: '', phoneNo: '', brandName: '' },
  })

  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async (data: FormData) => {
    setLoading(true)
    setSuccessMessage('')
    setErrorMessage('')

    if (!executeRecaptcha) {
      setErrorMessage('reCAPTCHA could not be loaded. Please try again.')
      return
    }
    const token = await executeRecaptcha('submit')
    if (!token) {
      setErrorMessage('Failed to verify reCAPTCHA. Please try again.')
      return
    }

    Amplitude.trackEvent('JOIN_WAITLIST_CLICKED', {
      ...data,
    })

    try {
      const res = await joinWaitlist({
        ...data,
        recaptchaToken: token,
      })
      if (res && res.status === 'success') {
        setSuccessMessage(
          'You have successfully joined the waitlist. Our team will get back to you in less than 24 hours.'
        )
        reset()
      } else {
        setErrorMessage('Something went wrong. Please try again.')
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background:
      'radial-gradient(circle, rgba(0,137,123,1) 0%, rgba(0,0,0,1) 60%)',
  })

  return (
    <Container>
      <Helmet>
        <title>Join Waitlist | Haulistic</title>
      </Helmet>
      {/* <Box className="absolute top-4 left-4">
        <img
          src={process.env.REACT_APP_ASSETS_URL + '/images/logos/logo.png'}
          alt="Haulistic"
          className="w-8 h-8 object-contain"
        />
      </Box> */}

      <Card className="w-full mx-5 md:max-w-xl p-8 shadow-lg bg-white rounded-lg">
        <img
          src={
            process.env.REACT_APP_ASSETS_URL +
            '/images/logos/logoWithBrandName.png'
          }
          className="mb-5 w-24"
          alt="Haulistic"
        />
        <div>
          <p className="text-2xl font-bold mb-4">
            Limited early access – Join the waitlist now!
          </p>
          <p className="text-sm bg-gray-100 p-4 rounded">
            Submit your details below, and our team will reach out for more
            details.
            <br />
            We&apos;ll set up a{' '}
            <Box
              component={'span'}
              sx={{ fontWeight: 'bold', color: '#00897b' }}
            >
              free sample version of your website within 24 hours
            </Box>
            —so you can see Haulistic in action before you commit!
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-900">
                Name
              </label>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Name is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Your Name"
                    className="w-full"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-900">
                Email Address
              </label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Please enter a valid email address',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="yourname@xyz.com"
                    className="w-full"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-900">
                Phone Number
              </label>
              <Controller
                name="phoneNo"
                control={control}
                rules={{
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[6-9]\d{9}$/, // Ensures 10-digit Indian phone number format
                    message: 'Enter a valid 10-digit phone number',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="9876543210"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                      inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        maxLength: 10,
                      },
                    }}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, '') // Remove non-numeric characters
                      field.onChange(numericValue)
                    }}
                    className="w-full"
                    error={!!errors.phoneNo}
                    helperText={errors.phoneNo?.message}
                  />
                )}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-900">
                Brand Name 🏷️ (What’s your store called?)
              </label>
              <Controller
                name="brandName"
                control={control}
                rules={{ required: 'Brand name is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Loom & Label"
                    className="w-full"
                    error={!!errors.brandName}
                    helperText={errors.brandName?.message}
                  />
                )}
              />
            </div>

            <div className="flex justify-center mt-4">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="font-bold px-6 py-2"
                sx={{ textTransform: 'none' }}
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Join Waitlist'}
              </Button>
            </div>
            <Box component="p" sx={{ color: '#00897b', textAlign: 'center' }}>
              {successMessage}
            </Box>
            <Box component="p" sx={{ color: '#DC143C', textAlign: 'center' }}>
              {errorMessage}
            </Box>
          </form>
        </div>
      </Card>
    </Container>
  )
}

export default JoinWaitlistFormWithRecaptcha
