import React, { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate, Link } from 'react-router-dom'
import * as z from 'zod'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import theme from 'styles/muiTheme'

import ResendOtp from './ResendOtp'
import { forgotPassword } from '../../services/auth'

const FormSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Invalid email'),
  otp: z.string().min(6, 'OTP must be 6 digits').max(6, 'OTP must be 6 digits'),
  password: z
    .string()
    .min(8, 'Password must have at least 8 characters')
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$*_^&])[A-Za-z\d@#$*_^&]{8,}$/,
      'Password must be 8+ characters with at least one uppercase, one lowercase, one digit, and one special character (@, #, $, *, ^, &)'
    ),
})

const ForgotPasswordForm = () => {
  const {
    handleSubmit,
    register,
    watch,
    control,
    getFieldState,
    formState: { errors, isValid, isSubmitting },
  } = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      otp: '',
      password: '',
    },
  })

  // Register inputs
  const registerEmail = register('email')
  const registerOTP = register('otp')

  const emailState = getFieldState('email')
  const emailValue = watch('email')
  const [showOtpInput, setShowOtpInput] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const { showSnackbar } = useSnackbar()
  const { forgotPasswordConfirm } = useAuth()
  const navigate = useNavigate()

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  const handleGetOTP = async (): Promise<void> => {
    try {
      const res = await forgotPassword({ email: emailValue })

      if (res.status === 'successful') {
        setShowOtpInput(true)
      }
    } catch (error: unknown) {
      console.error((error as Error).message)
      setSnackbarMessage('User not found or invalid credentials')
      setSnackbarOpen(true)
    }
  }

  const onSubmitForm: SubmitHandler<{
    email: string
    otp: string
    password: string
  }> = async (data) => {
    const { email, otp, password } = data
    const res = await forgotPasswordConfirm(email, password, otp)
    if (res.status === 'successful') {
      navigate('/dashboard/home')
    } else if (res.status === 'failed' && res.message) {
      showSnackbar(res.message, 'error')
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitForm)} className="form-container">
        <div className="space-y-5 my-10">
          <div>
            <label
              htmlFor="email"
              className="text-base font-medium text-gray-900"
            >
              Email Address
            </label>
            <div className="mt-2">
              <input
                id="email"
                className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="email"
                placeholder="yourname@example.com"
                autoComplete="email"
                onChange={registerEmail.onChange}
                onBlur={registerEmail.onBlur}
                name={registerEmail.name}
                ref={registerEmail.ref}
              />
            </div>
          </div>
          {!showOtpInput && (
            <div>
              <Button
                type="button"
                color="primary"
                variant="contained"
                sx={{ width: '100%' }}
                disabled={!emailValue || emailState.invalid}
                onClick={handleGetOTP}
              >
                Get OTP{' '}
                <ArrowForwardIcon
                  className="ml-2"
                  component={ArrowForwardIcon}
                />
              </Button>
            </div>
          )}
          {showOtpInput && (
            <>
              <div>
                <label
                  htmlFor="otp"
                  className="text-base font-medium text-gray-900"
                >
                  OTP
                </label>
                <div className="mt-2">
                  <input
                    id="otp"
                    className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type="text"
                    placeholder="OTP"
                    autoComplete="off"
                    maxLength={6}
                    onChange={registerOTP.onChange}
                    onBlur={registerOTP.onBlur}
                    name={registerOTP.name}
                    ref={registerOTP.ref}
                  />
                  {errors.otp && (
                    <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                      *{errors.otp?.message}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="text-base font-medium text-gray-900"
                  >
                    New Password{' '}
                  </label>
                  <IconButton
                    sx={{ color: theme.palette.text.primary }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </div>
                <div className="mt-2">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={field.onChange}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        id="password"
                        autoComplete="new-password"
                      />
                    )}
                  />
                  {errors.password && (
                    <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                      *{errors.password?.message}
                    </p>
                  )}
                </div>
              </div>

              <ResendOtp email={emailValue} />
              <div>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ width: '100%' }}
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? 'Verifying' : 'Verify OTP'}
                  <ArrowForwardIcon
                    className="ml-2"
                    component={ArrowForwardIcon}
                  />
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
      <Link to="/signin" className="text-xs underline text-gray-500">
        Back to Sign In
      </Link>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default ForgotPasswordForm
