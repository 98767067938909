import { Box } from '@mui/material'
import {
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { format } from 'date-fns'

import CustomCell from 'components/table/CustomCell'
import { OrderStatusValue, statusColors } from 'services/orders/index.type'

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 100,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    resizable: false,
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value == '='
    ),
  },
  {
    field: 'createdAt',
    width: 210,
    headerName: 'Order Date',
    type: 'dateTime',
    valueFormatter: (value: string): string => {
      return format(new Date(value), 'MMM d, yyyy, h:mm a')
    },
    filterable: false,
  },
  {
    field: 'fullName',
    headerName: 'Name',
    hideable: false,
    width: 150,
    renderCell: (params) => <CustomCell {...params} route="orders" />,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value == 'contains'
    ),
  },
  {
    field: 'totalPrice',
    width: 100,
    type: 'number',
    headerName: 'Total',
    valueFormatter: (value: number): number => {
      return value ?? 0
    },
    filterable: false,
  },

  {
    field: 'shippingAddress',
    width: 150,
    type: 'number',
    headerName: 'Delivery Pin Code',
    sortable: false,
    filterable: false,
  },

  {
    field: 'phoneNo',
    width: 150,
    type: 'string',
    headerName: 'Phone Number',
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value == 'contains'
    ),
  },
  {
    field: 'uid',
    headerName: 'UID',
    width: 300,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value == 'contains'
    ),
  },
  {
    field: 'status',
    width: 150,
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const status = params.value as OrderStatusValue
      const color = statusColors[status] || '#9E9E9E'
      return (
        <Box
          component="p"
          sx={{
            backgroundColor: color,
          }}
          className="text-xs rounded-full text-center p-1 px-3 font-bold text-white my-3 inline-block"
        >
          {status === OrderStatusValue.CREATED
            ? 'NEW ORDER'
            : status.split('_').join(' ')}
        </Box>
      )
    },
  },
]
