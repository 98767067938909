import { FC, ChangeEvent, useState, useEffect } from 'react'

import { Box, Typography, Dialog } from '@mui/material'
import imageCompression from 'browser-image-compression'
import { useParams } from 'react-router-dom'

import Card from 'components/card'
import MultiImageCropper from 'components/files/FileCropDialog'
import Label from 'components/forms/Label'
import DisplayImage from 'pages/dashboard/components/DisplayImage'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { getPresignedUrl, uploadFile, deleteFile } from 'services/files'
import { updateExistingCategory } from 'services/products/categories'
import { validateCategoryImage } from 'utils/validation'

import DescriptionInput from './DescriptionInput'

interface DetailsSectionProps {
  isEditing: boolean
  description: string
  imageUrls: string[] | null
  setImageUrls: (urls: string[]) => void
  setDescription: (desc: string) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const CategoryDetailsSection: FC<DetailsSectionProps> = ({
  isEditing,
  description,
  imageUrls,
  setImageUrls,
  setDescription,
}) => {
  const [images, setImages] = useState<string[]>(imageUrls ?? [])
  const [error, setError] = useState<{ type: string; message: string } | null>(
    null
  )
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number
    fileName: string
  }>({
    progress: 0,
    fileName: '',
  })
  const [isUploading, setIsUploading] = useState(false)
  const { categoryId } = useParams<{ categoryId: string }>()
  const [cropFiles, setCropFiles] = useState<File[]>([])
  const [showCropper, setShowCropper] = useState(false)

  useEffect(() => {
    if (imageUrls) setImages(imageUrls)
  }, [imageUrls])

  const handleSave = async (files: File[]): Promise<void> => {
    if (!categoryId) {
      setError({
        type: 'image',
        message: 'Category ID is missing. Cannot update category.',
      })
      return
    }

    setIsUploading(true)

    try {
      const uploadedImages: string[] = [...images]

      for (const file of files) {
        const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
        setUploadProgress({ progress: 0, fileName: file.name })

        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 800,
          useWebWorker: true,
          fileType: 'image/webp',
        }

        const compressedFile = await imageCompression(file, options)
        const result = await uploadFile(
          compressedFile,
          signedUrl,
          (progress) => {
            setUploadProgress({ progress, fileName: file.name })
          }
        )

        if (result.status === 'successful') {
          const imageUrl = `${fileUrl}/${uuid}`
          uploadedImages.push(imageUrl)
        } else {
          setError({
            type: 'upload',
            message: 'File upload failed.',
          })
        }
      }

      // Ensure we only keep up to 2 images
      const finalImages = uploadedImages.slice(-2)
      setImages(finalImages)
      setImageUrls(finalImages)

      const updateData = { media: finalImages }
      await updateExistingCategory(categoryId, updateData)
    } catch (error) {
      setError({
        type: 'upload',
        message: 'An error occurred while uploading the files.',
      })
    } finally {
      setIsUploading(false)
    }
  }

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    const files = Array.from(e.target.files)
    const totalFiles = images.length + files.length

    if (totalFiles > 2) {
      return setError({
        type: 'image',
        message: 'You can only upload a maximum of 2 images.',
      })
    }

    for (const file of files) {
      const validate = await validateCategoryImage(file)

      if (!validate.valid) {
        return setError({
          type: 'image',
          message: validate.error || 'An error occurred.',
        })
      }
    }

    setCropFiles(files)
    setError(null)
    setShowCropper(true)
  }

  const handleCropComplete = (croppedFiles: File[]): void => {
    handleSave(croppedFiles)
    setCropFiles([])
    setShowCropper(false)
  }

  const handleCropCancel = (): void => {
    setShowCropper(false)
    setCropFiles([])
  }

  const handleDeleteImage = async (index: number): Promise<void> => {
    if (!categoryId || !images[index]) {
      setError({
        type: 'image',
        message: 'Cannot delete image. Category ID or image is missing.',
      })
      return
    }

    const fileName = images[index].split('/')[3]

    try {
      const result = await deleteFile(fileName)

      if (result.status === 'successful') {
        const updatedImages = images.filter((_, i) => i !== index)
        setImages(updatedImages)
        setImageUrls(updatedImages)

        await updateExistingCategory(categoryId, { media: updatedImages })
      } else {
        setError({
          type: 'delete',
          message: 'Image deletion failed.',
        })
      }
    } catch (error) {
      setError({
        type: 'delete',
        message: 'An error occurred while deleting the image.',
      })
    }
  }

  return (
    <>
      <Box
        className="mx-5 mb-4"
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography sx={{ fontWeight: '700' }}>Details</Typography>
      </Box>
      <Card>
        <Box className="mb-4">
          <Label text="Description" color="secondary" />
          {isEditing ? (
            <DescriptionInput
              value={description}
              onChange={(data) => setDescription(data)}
            />
          ) : (
            <Typography variant="body1">{description || 'N/A'}</Typography>
          )}
        </Box>

        <Typography
          component="label"
          color={`text.secondary`}
          sx={{
            display: 'block',
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          Images
        </Typography>
        <div className=" mb-2">
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            You can upload up to 2 images. The first image will be the primary
            display.
          </Typography>
        </div>

        <Box display="flex" gap={2} mb={2}>
          {images.map((img, index) => (
            <DisplayImage
              key={index}
              imageUrl={img}
              width={100}
              height={150}
              onDelete={isEditing ? () => handleDeleteImage(index) : undefined}
            />
          ))}
        </Box>

        {isEditing && images.length < 2 && (
          <InputFileUpload
            name="image"
            onChange={handleFileChange}
            buttonText={isUploading ? 'Uploading' : 'Upload File'}
            multiple
          />
        )}

        {uploadProgress.progress > 0 && uploadProgress.progress < 100 && (
          <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
            {uploadProgress.fileName} - {uploadProgress.progress}%
          </Typography>
        )}

        {error && error.type === 'image' && (
          <p className="text-xs text-red-500 block mt-1">{error.message}</p>
        )}
      </Card>

      {showCropper && cropFiles.length > 0 && (
        <Dialog open={showCropper} maxWidth="md" fullWidth>
          <MultiImageCropper
            files={cropFiles}
            onCropComplete={handleCropComplete}
            onClose={handleCropCancel}
          />
        </Dialog>
      )}
    </>
  )
}

export default CategoryDetailsSection
