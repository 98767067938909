import { forwardRef, useEffect } from 'react'

import TextField from '@mui/material/TextField'

interface AddressFormatType {
  address1: string
  city: string
  state: string
  country: string
  postalCode: string
}
export interface AddressType extends AddressFormatType {
  latitude?: number
  longitude?: number
  addressUrl?: string
}
interface AddressInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  name: string
  value: string
  inputRef: React.RefObject<HTMLInputElement>
  onAddressSelect?: (arg: AddressType | null) => void
  restrictTo?: string
  error?: boolean
  helperText?: string
}

const setupAutocomplete = (
  inputRef: React.RefObject<HTMLInputElement>,
  onAddressSelect?: (arg: AddressType | null) => void,
  restrictTo?: string
): void => {
  if (!inputRef.current) return
  const autocomplete = new window.google.maps.places.Autocomplete(
    inputRef.current,
    {
      types: ['address'],
      componentRestrictions: {
        country: restrictTo ? [restrictTo] : ['US', 'IN', 'UK'],
      }, // specify the country
      fields: ['address_components', 'geometry', 'url'],
    }
  )

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    if (!onAddressSelect) return

    const data = addressFormat(place.address_components)
    const latitude = place.geometry?.location?.lat()
    const longitude = place.geometry?.location?.lng()
    const url = place.url
    if (!data) return

    onAddressSelect({
      ...data,
      latitude,
      longitude,
      addressUrl: url,
    })
  })
}

const AddressInput = forwardRef<HTMLDivElement, AddressInputProps>(
  (
    {
      inputRef,
      onAddressSelect,
      restrictTo,
      error,
      onChange,
      onBlur,
      name,
      value,
      helperText,
    },
    ref
  ) => {
    useEffect(() => {
      setupAutocomplete(inputRef, onAddressSelect, restrictTo)
    }, [inputRef, onAddressSelect, restrictTo])

    return (
      <TextField
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
        ref={ref}
        fullWidth
        inputRef={inputRef}
        inputProps={{ style: { backgroundColor: 'white' } }}
        size="small"
        sx={{
          marginTop: 0,
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
          },
        }}
        error={error}
        helperText={helperText}
      />
    )
  }
)

function addressFormat(
  address_components: google.maps.GeocoderAddressComponent[] | undefined
): AddressFormatType | null {
  if (!address_components) return null

  let address1: string = ''
  let city: string = ''
  let state: string = ''
  let country: string = ''
  let postalCode: string = ''

  for (const component of address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'street_number': {
        address1 = `${component.long_name} `
        break
      }

      case 'route': {
        address1 += `${component.long_name} `
        break
      }

      case 'sublocality_level_2': {
        address1 += `${component.long_name} `
        break
      }

      case 'sublocality_level_1': {
        address1 += `${component.long_name} `
        break
      }

      case 'locality': {
        address1 += component.long_name
        city = component.long_name
        break
      }

      case 'administrative_area_level_3': {
        city = component.long_name
        break
      }

      case 'administrative_area_level_1': {
        state = component.long_name
        break
      }

      case 'country': {
        country = component.long_name
        break
      }
      case 'postal_code': {
        postalCode = `${component.long_name}`
        break
      }
    }
  }
  const data = { address1, city, state, country, postalCode }

  return data
}

AddressInput.displayName = 'AddressInput'

export default AddressInput
