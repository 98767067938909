import React, { FC } from 'react'

import { Box, styled } from '@mui/material'
import { DataGrid, DataGridProps } from '@mui/x-data-grid'

interface DataGridTableProps extends DataGridProps {
  containerHeight?: string | number
}

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle ': {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiDataGrid-row': {
    backgroundColor: theme.palette.accents?.grayAccent,
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.accents?.lightGrayAccent,
  },
  '& .MuiDataGrid-cell': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiDataGrid-container--top [role=row]': {
    backgroundColor: theme.palette.accents?.darkGrayAccent,
  },
}))

export const DataGridTable: FC<DataGridTableProps> = ({
  containerHeight,
  ...props
}) => {
  return (
    <Box
      sx={{
        minHeight: 400,
        width: '100%',
        overflowX: 'auto',
        height: containerHeight || 'calc(100vh - 140px)',
      }}
    >
      <StyledDataGrid
        {...props}
        disableRowSelectionOnClick
        disableColumnMenu
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            overflow: 'hidden',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'nowrap',
          },
        }}
      />
    </Box>
  )
}

export default DataGridTable
