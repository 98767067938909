import { ChangeEvent, FC, JSX, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Grid, IconButton, Link, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { deleteFile, getPresignedUrl, uploadFile } from 'services/files'
import { updateOrganization } from 'services/organization'
import { Organization } from 'services/organization/index.types'

const displayNameMap: { [key: string]: string } = {
  terms: 'Terms and Conditions',
  privacy: 'Privacy Policy',
  returnsAndRefunds: 'Returns & Refunds',
  shipping: 'Shipping Policy',
}

type Policies = { name: string; link: string; displayName: string }
interface PolicyDocumentsProps {
  websiteUrl: string
  policies?: string
  onUpdate?: (org?: Organization) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const PolicyDocuments: FC<PolicyDocumentsProps> = ({
  websiteUrl,
  policies,
  onUpdate,
}): JSX.Element => {
  const { organizationId, organization, updateOrganizationData } = useAuth()
  const [error, setError] = useState<{ type: string; message: string }>()
  const [uploadProgress, setUploadProgress] = useState({
    field: '',
    fileName: '',
    progress: 0,
  })

  const policiesArray: Policies[] | undefined = policies
    ? JSON.parse(policies)
    : undefined

  const termsDoc = policiesArray?.find((obj) => obj.name === 'terms')
  const privacyDoc = policiesArray?.find((obj) => obj.name === 'privacy')
  const returnsAndRefundsDoc = policiesArray?.find(
    (obj) => obj.name === 'returnsAndRefunds'
  )
  const shippingDoc = policiesArray?.find((obj) => obj.name === 'shipping')

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_POLICYDOCS_UPLOADFILE_CLICKED', {
        organizationId,
      })
    }

    const file = e.target.files[0]
    const size = 5 * 1024 * 1024
    if (file.type !== 'application/pdf') {
      setError({ type: e.target.name, message: 'Only PDF files are allowed.' })
      return
    }
    if (file.size > size) {
      setError({
        type: e.target.name,
        message: 'File size should be less than 5mb.',
      })
      return
    }

    const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
    setUploadProgress({
      field: e.target.name,
      fileName: file.name,
      progress: 0,
    })
    const result = await uploadFile(file, signedUrl, (progress) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: progress,
      }))
    })

    if (result.status === 'successful') {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: 100,
      }))

      const doc = {
        name: e.target.name,
        link: `${fileUrl}/${uuid}`,
        displayName: displayNameMap[e.target.name],
      }

      const data: Policies[] = []
      data.push(doc)

      if (policiesArray && policiesArray.length > 0) data.push(...policiesArray)

      const res = organizationId
        ? await updateOrganization(organizationId, {
            policies: data,
          })
        : { status: 'failed' }

      if (res?.status === 'successful' && res.data) {
        onUpdate?.(res.data)
        if (organization) {
          updateOrganizationData({
            ...organization,
            policies: res.data.policies,
          })
        }
      }
    }
    setUploadProgress({
      field: '',
      fileName: '',
      progress: 0,
    })
  }

  const onDelete = async (doc: {
    name: string
    link: string
  }): Promise<void> => {
    const fileName = doc.link.split('/')[3]
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_POLICYDOCS_DELETE_CLICKED', {
        organizationId,
      })
    }
    const result = await deleteFile(fileName)

    const policiesData = policiesArray?.filter((obj) => obj.name !== doc.name)

    if (result.status === 'successful') {
      const res = organizationId
        ? await updateOrganization(organizationId, { policies: policiesData })
        : { status: 'failed' }

      if (res && res.status === 'successful' && res.data) {
        onUpdate?.(res.data)
        if (organization) {
          updateOrganizationData({
            ...organization,
            policies: res.data.policies,
          })
        }
      }
    }
  }

  return (
    <Box id="policy-section">
      <h2 className="text-lg font-semibold ml-5">Policy Documents</h2>
      <Typography
        sx={{
          fontSize: '0.75rem',
          color: 'text.secondary',
          marginTop: '0.1rem',
          marginLeft: '20px',
        }}
      >
        *Only PDF files are supported for policy documents.
      </Typography>
      <Card>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Label text="Terms" />
            {termsDoc && (
              <>
                <Link
                  href={`${websiteUrl}/policies/${termsDoc.name}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {termsDoc.link.split('/')[3]}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(termsDoc)}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            )}
            {!termsDoc && (
              <>
                <InputFileUpload
                  name="terms"
                  buttonText="Upload File"
                  onChange={handleFileChange}
                />
                {uploadProgress.field === 'terms' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error?.type === 'terms' && (
                  <p className="text-xs text-red-500 block mt-1">
                    {error.message}
                  </p>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Label text="Privacy" />
            {privacyDoc && (
              <>
                <Link
                  href={`${websiteUrl}/policies/${privacyDoc.name}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {privacyDoc.link.split('/')[3]}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(privacyDoc)}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            )}
            {!privacyDoc && (
              <>
                <InputFileUpload
                  name="privacy"
                  buttonText="Upload File"
                  onChange={handleFileChange}
                />
                {uploadProgress.field === 'privacy' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error?.type === 'privacy' && (
                  <p className="text-xs text-red-500 block mt-1">
                    {error.message}
                  </p>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Label text="Returns & Refunds" />
            {returnsAndRefundsDoc && (
              <>
                <Link
                  href={`${websiteUrl}/policies/${returnsAndRefundsDoc.name}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {returnsAndRefundsDoc.link.split('/')[3]}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(returnsAndRefundsDoc)}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            )}
            {!returnsAndRefundsDoc && (
              <>
                <InputFileUpload
                  name="returnsAndRefunds"
                  buttonText="Upload File"
                  onChange={handleFileChange}
                />
                {uploadProgress.field === 'returnsAndRefunds' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error?.type === 'returnsAndRefunds' && (
                  <p className="text-xs text-red-500 block mt-1">
                    {error.message}
                  </p>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Label text="Shipping" />
            {shippingDoc && (
              <>
                <Link
                  href={`${websiteUrl}/policies/${shippingDoc.name}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {shippingDoc.link.split('/')[3]}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(shippingDoc)}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            )}
            {!shippingDoc && (
              <>
                <InputFileUpload
                  name="shipping"
                  buttonText="Upload File"
                  onChange={handleFileChange}
                />
                {uploadProgress.field === 'shipping' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error?.type === 'shipping' && (
                  <p className="text-xs text-red-500 block mt-1">
                    {error.message}
                  </p>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default PolicyDocuments
