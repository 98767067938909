import InfoIcon from '@mui/icons-material/Info'
import { Box, Tooltip } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import CustomCell from 'components/table/CustomCell'

export const columns: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => (
      <Box
        component="p"
        sx={{
          backgroundColor: params.value === 'DRAFT' ? '#FFE082' : '#AED581',
          color: params.value === 'DRAFT' ? 'black' : '#33691E',
        }}
        className="p-1 text-xs my-3 text-center rounded-full font-bold"
      >
        {params.value}
      </Box>
    ),
  },
  { field: 'productCode', headerName: 'Code', width: 100 },
  {
    field: 'name',
    headerName: 'Title',
    width: 400,
    renderCell: (params: GridRenderCellParams) => (
      <CustomCell {...params} row={params.row} route="products" />
    ),
  },
  { field: 'price', headerName: 'Starting Price', width: 150 },
  {
    field: 'isOnOffer',
    headerName: 'On Offer',
    width: 150,
    sortable: false,
  },
  {
    field: 'isListed',
    headerName: 'Is Listed',
    width: 150,
    renderHeader: (params) => (
      <div>
        <span className="font-bold">{params.colDef.headerName}</span>
        <Tooltip title="Shows if the product is listed or not">
          <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
        </Tooltip>
      </div>
    ),
  },
  { field: 'priority', headerName: 'Priority', width: 120 },

  // {
  //   field: 'createdBy',
  //   headerName: 'Created By',
  //   width: 150,
  // },
  // {
  //   field: 'createdAt',
  //   headerName: 'Created At',
  //   width: 200,
  // },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 200,
  },
]
