import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import ProIcon from './ProIcon'

const UpgradeButton = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/subscribe/select-plan')
  }
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
          backgroundColor: '#333333', // Slightly darker shade for hover
        },
      }}
      onClick={handleClick}
    >
      <ProIcon /> <span className="ml-2">Upgrade your Plan</span>
    </Button>
  )
}

export default UpgradeButton
