import { FC, ReactNode } from 'react'

import { Box } from '@mui/material'

import UpgradeButton from './UpgradeButton'

interface UpgradeCardProps {
  children: ReactNode
  flex?: boolean
}

const UpgradeCard: FC<UpgradeCardProps> = ({ children, flex }) => {
  return (
    <Box
      paddingY={1}
      paddingX={2}
      margin={3}
      sx={{
        display: {
          xs: 'block', // Always block on extra-small screens
          lg: flex ? 'flex' : 'block', // Use flex on large screens and up if prop is true
        },

        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        border: '1px solid lightgray',
      }}
      className="text-sm rounded"
    >
      <Box
        sx={{
          mb: {
            xs: 2, // Add bottom margin on small screens
            lg: flex ? 0 : 2, // Remove bottom margin when in flex layout
          },
        }}
      >
        {children}
      </Box>
      <UpgradeButton />
    </Box>
  )
}

export default UpgradeCard
