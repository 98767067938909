import { useEffect, useState } from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import useRazorpay from 'hooks/useRazorpay'
import { useSnackbar } from 'hooks/useSnackbar'
import { getPricingPlan } from 'services/pricingPlans'
import { PricingPlan } from 'services/pricingPlans/index.type'
import { createSubscription, getSubscription } from 'services/subscriptions'
import { pricingPlans } from 'utils/pricingPlan'

const CheckoutSubscription = () => {
  const queryParams = new URLSearchParams(location.search)
  const planId = Number(queryParams.get('planId'))
  const { organization } = useAuth()
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()

  const planFeatures =
    pricingPlans.find((plan) => plan.id === planId)?.features ?? []

  useRazorpay()

  const [planDetails, setPlanDetails] = useState<PricingPlan>()

  useEffect(() => {
    const fetchPlanDetails = async () => {
      if (!planId) return // Prevent API call if planId is invalid
      try {
        const { data } = await getPricingPlan(planId)
        setPlanDetails(data)
      } catch (error) {
        console.error('Failed to fetch plan details:', error)
      }
    }

    fetchPlanDetails()
  }, [planId])

  const subscriptionStartDate = () => {
    const today = new Date()
    const threeMonthsFromNow = new Date()
    threeMonthsFromNow.setDate(
      today.getDate() + (planDetails?.trialDuration ?? 0)
    )
    const day = threeMonthsFromNow.getDate()
    const month = threeMonthsFromNow.toLocaleString('en-US', { month: 'short' })
    const year = threeMonthsFromNow.getFullYear()

    const formattedDate = `${day} ${month} ${year}`

    return formattedDate
  }

  const onSubscribe = async () => {
    try {
      const { data } = await createSubscription(planId, organization!.id)
      openRazorpayDialog(data?.razorpaySubscriptionId ?? '')
    } catch (error) {
      console.error('Error creating subscription:', error)
    }
  }

  const openRazorpayDialog = (subscriptionId: string) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: subscriptionId,
      name: organization?.name,
      description: `Monthly ${planDetails?.name} Plan`,
      prefill: {
        email: organization?.email ?? null,
        contact: organization?.phoneNumber ?? null,
      },
      // image: '/your_logo.jpg',
      handler: async () => {
        const { data: subscription, status } = await getSubscription(
          organization!.id
        )
        if (
          status === 'successful' &&
          subscription?.razorpaySubscription?.status === 'authenticated'
        ) {
          showSnackbar('Payment Successful!', 'success')

          navigate('/dashboard/home')
        } else {
          showSnackbar('Something went wrong, please try again later!', 'error')
          navigate('/dashboard/home')
        }
      },
      theme: {
        color: '#00897b',
      },
    }

    const rzp = new window.Razorpay(options)
    rzp.open()
  }

  return (
    <Grid container sx={{ width: '100vw', height: { lg: '100vh' } }}>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          backgroundColor: '#F4F7F6',
          height: '100%',
          padding: { lg: 8, xs: 4 },
        }}
        order={{ xs: 2, lg: 1 }}
      >
        <Box
          sx={{
            color: '#00897b',
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
          className="lg:mt-20"
        >
          {planDetails?.name.toUpperCase()} PLAN
        </Box>

        {/* Pricing & Fee Details */}
        <Grid container spacing={2} sx={{ my: 3 }}>
          <Grid item xs={6}>
            <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
              <div className="text-3xl md:text-4xl font-bold">
                ₹{(planDetails?.price ?? 0) / 100}
                <span className="text-xs text-gray-500">/month</span>
              </div>
              <Box
                component="p"
                className="text-xs font-bold mt-4"
                sx={{ color: '#00897b' }}
              >
                ₹{(planDetails?.trialAmount ?? 0) / 100} for the first 3 months!
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
              <div className="text-3xl md:text-4xl font-bold">
                {planDetails?.commissionRate}%
              </div>
              <p className="text-xs font-bold text-gray-500 mt-4">
                fee on transactions via the website
              </p>
            </Paper>
          </Grid>
        </Grid>

        {/* Perks Section */}
        <p className="text-md font-bold text-gray-500 mt-8">PERKS</p>
        <List>
          {planFeatures.map((perk, index) => (
            <ListItem
              key={index}
              sx={{ backgroundColor: 'white', my: 1, borderRadius: 1, py: 2 }}
            >
              <p className="text-sm font-bold flex items-center">
                <CheckCircleIcon
                  fontSize="small"
                  sx={{ color: '#00897B' }}
                  className="mr-4"
                />
                {perk}
              </p>
            </ListItem>
          ))}
        </List>
        <Box className="mt-10">
          <Link
            to="/subscribe/select-plan"
            className="text-xs underline text-gray-500"
          >
            Not the right fit? Explore other plans
          </Link>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{ height: '100%', padding: { lg: 8, xs: 4 } }}
        order={{ xs: 1, lg: 2 }}
      >
        <p className="text-lg font-bold mt-20">Your Subscription</p>
        <Box component="div" className="flex justify-between my-8">
          <div>
            <Box className="text-lg font-bold">
              {planDetails?.name.toUpperCase()} PLAN
            </Box>
            <p className="text-xs text-gray-500 font-bold">Monthly</p>
          </div>
          <div>
            <p className="text-lg">₹{(planDetails?.price ?? 0) / 100}</p>
            <p className="text-xs text-gray-500 font-bold">
              ₹{(planDetails?.trialAmount ?? 0) / 100} for the first 3 months
            </p>
          </div>
        </Box>
        <Divider />
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            marginTop: 4,
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 12, px: 2 }}>
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <div>
                  <p className="text-sm font-bold"> Today</p>
                  <p className="text-xs text-gray-500">
                    ₹{(planDetails?.trialAmount ?? 0) / 100} for the first 3
                    months
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm font-bold">
                    ₹{((planDetails?.trialAmount ?? 0) / 100).toFixed(2)}
                  </p>
                  <p className="text-xs text-gray-500">First 3 months</p>
                </div>
              </Box>
            </TimelineContent>{' '}
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <div>
                  <p className="text-sm font-bold">
                    {subscriptionStartDate()}{' '}
                  </p>
                  <p className="text-xs text-gray-500">
                    {planDetails?.name} PLAN
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm font-bold">
                    {' '}
                    ₹{((planDetails?.price ?? 0) / 100).toFixed(2)}
                  </p>
                  <p className="text-xs text-gray-500">Every month</p>
                </div>
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
        <Box
          component="div"
          className="flex justify-between mb-8 px-8 py-4 rounded"
          sx={{ backgroundColor: '#F4F7F6' }}
        >
          <p className="text-sm font-bold">Amount Due</p>
          <p className="text-sm font-bold">
            ₹{((planDetails?.trialAmount ?? 0) / 100).toFixed(2)}
          </p>
        </Box>

        <Box component="div" display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="small"
            className="w-1/2"
            onClick={onSubscribe}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
          >
            Subscribe
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CheckoutSubscription
