import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Product } from 'services/products/index.types'

interface SearchSuggestionProps {
  products: Product[]
  onSuggestionClick: (product: Product) => void
  searchQuery: string
  onOverallSearch: () => void
}

const SearchSuggestions = ({
  products,
  onSuggestionClick,
  searchQuery,
  onOverallSearch,
}: SearchSuggestionProps) => {
  const navigate = useNavigate()

  const handleProductNameClick = (productCode: string) => {
    navigate(`/dashboard/products/${productCode}`)
  }

  return (
    <Box className="absolute left-0 right-0 mt-1 rounded-b-md shadow-lg bg-white mx-auto z-10">
      <List className="max-h-96 overflow-y-auto">
        <Typography variant="caption" sx={{ marginLeft: 2 }}>
          Products
        </Typography>
        <Divider className="mx-4" />
        {products.map((product) => (
          <ListItem
            key={product.id}
            onClick={() => onSuggestionClick(product)}
            className="hover:bg-gray-100 cursor-pointer"
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Box className="rounded overflow-hidden shadow-lg w-12 h-16 relative">
                <img
                  src={product.media[0] || '/images/sunrise-placeholder.png'}
                  className="block w-full h-full"
                  alt={product.name}
                />
              </Box>
              <div>
                <Typography
                  variant="body2"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleProductNameClick(product.productCode)
                  }}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {product.name}{' '}
                  <span className="ml-2">({product.productCode})</span>
                </Typography>
                {!product.isAvailable && (
                  <Typography
                    variant="caption"
                    className="text-gray-600 font-medium"
                  >
                    Out of Stock
                  </Typography>
                )}
              </div>
            </Box>
          </ListItem>
        ))}
      </List>
      <Box
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ cursor: 'pointer' }}
        className="border-t hover:bg-gray-50 rounded-b-md"
        onClick={onOverallSearch}
      >
        <Typography>Search for &quot;{searchQuery}&quot;</Typography>
        <EastOutlined fontSize="small" />
      </Box>
    </Box>
  )
}

export default SearchSuggestions
