import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Button, Grid, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import QuillEditor from 'components/quill'
import { useSnackbar } from 'hooks/useSnackbar'
import CategoryInput from 'pages/dashboard/components/CategoryInput'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import TextCollapse from 'pages/dashboard/components/TextCollapse'
import { getCategories, updateProduct } from 'services/products'
import { Category, Product } from 'types/product'

interface BasicDetailsProps {
  product: Product
  onUpdate?: (p: Product) => void
}

const BasicDetails: FC<BasicDetailsProps> = ({ product, onUpdate }) => {
  const [names, setNames] = useState<string | null>(null)
  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState({
    name: product.name,
    categoryIds: product.categoryIds,
    description: product.description.trim() ?? '',
    priority: product.priority.toString(),
  })

  const [errors, setErrors] = useState<{
    name: string | null
    priority: string | null
  }>({
    name: null,
    priority: null,
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    const category = product.categoryIds
    const get = async (orgId: number): Promise<void> => {
      const result = await getCategories(orgId)
      if (result?.status === 'successful' && category && category.length > 0) {
        const data = result.data.filter((el: Category) =>
          category.includes(el.id)
        )
        const categoryNames = data.map((el: Category) => el.name).join(', ')
        setNames(categoryNames)
      } else if (category?.length === 0) {
        setNames(null)
      }
    }
    get(product.organizationId)
  }, [product])

  const handleEdit = () => {
    setEdit(true)
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value

    setValues((prev) => ({ ...prev, name: value }))

    if (value.trim() !== '' && errors.name) {
      setErrors((prev) => ({ ...prev, name: null }))
    }
  }

  const handleCategoryChange = (ids: number[]): void => {
    setValues((prev) => ({ ...prev, categoryIds: ids }))
  }

  const handleDescirptionChange = (val: string): void => {
    setValues((prev) => ({ ...prev, description: val }))
  }

  const handlePriorityChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')
    setValues((prev) => ({ ...prev, priority: value }))

    if (value.trim() !== '' && errors.priority) {
      setErrors((prev) => ({ ...prev, priority: null }))
    }
  }

  const handleSave = async () => {
    if (values.name.trim() === '') {
      setErrors((prev) => ({ ...prev, name: '*Name is required' }))
      return
    }
    if (values.priority.trim() === '') {
      setErrors((prev) => ({ ...prev, priority: '*Priority is required.' }))
      return
    }
    setIsSubmitting(true)

    const result = await updateProduct({
      productId: product.id,
      dataUpdate: {
        name: values.name.trim(),
        categoryIds: values.categoryIds,
        description: values.description.trim(),
        priority: Number(values.priority),
      },
    })

    if (result.status === 'successful') {
      onUpdate?.(result.data)
      showSnackbar('Details updated successfully', 'success')
      setEdit(false)
    } else if (result.status === 'failed') {
      showSnackbar('Something went wrong. Try again', 'error')
    }
    setIsSubmitting(false)
  }

  const handleCancel = () => {
    setValues((prev) => ({
      ...prev,
      name: product.name,
      categoryIds: product.categoryIds,
      description: product.description.trim() ?? '',
      priority: product.priority.toString(),
    }))

    setErrors({ name: null, priority: null })
    setEdit(false)
  }

  return (
    <div>
      <div className="mx-5">
        <SubTabHeader
          title="Details"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
      </div>
      <Card>
        {!edit && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Label text="Product Name" color="secondary" />
              <Typography sx={{ fontWeight: '700' }}>{product.name}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Label text="Category" color="secondary" />
              <Typography sx={{ fontWeight: '700' }}>
                {names?.toUpperCase() ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Label text="Priority" color="secondary" />
              <Typography sx={{ fontWeight: '700' }}>
                {product.priority}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Label text="Description" color="secondary" />
              <TextCollapse text={product.description} />
            </Grid>
          </Grid>
        )}
        {edit && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Label text="ProductName" color="secondary" />
                <CustomTextField
                  id="name"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleNameChange}
                  error={errors.name !== null}
                  helperText={errors.name !== null && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label text="Category" color="secondary" />
                <CategoryInput
                  onChange={handleCategoryChange}
                  defaultValues={values.categoryIds}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Label text="Priority" color="secondary" />
                <CustomTextField
                  id="priority"
                  name="priority"
                  type="number"
                  value={values.priority}
                  onChange={handlePriorityChange}
                  error={errors.priority !== null}
                  helperText={errors.priority !== null && errors.priority}
                />
              </Grid>
              <Grid item xs={12}>
                <Label text="Description" color="secondary" />
                <QuillEditor
                  value={values.description}
                  onChange={handleDescirptionChange}
                  limit={1500}
                />
              </Grid>
            </Grid>
            <div className="mt-4">
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: 'none' }}
                onClick={handleSave}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
            </div>
          </div>
        )}
      </Card>
    </div>
  )
}

export default BasicDetails
