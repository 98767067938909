import { ChangeEvent, FC, useEffect, useState } from 'react'

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import {
  Button,
  Chip,
  Grid,
  InputAdornment,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { updateProductOptionValue } from 'services/products'
import { ProductOption } from 'services/products/index.types'
import { Product } from 'types/product'

import OptionValueFileUpload from './OptionFileUpload'

interface OptionPriceAndStockProps {
  productId?: number
  variantOptions: ProductOption[]
  handleNextStep: () => void
  productCode: string
  onPrevStep: (step?: number) => void
  product: Product | null
  onUpdateOptions: (ops: ProductOption[]) => void
  onProductUpdate: (p: Product) => void
}

interface OptionValue {
  optionId: number
  valueId: number
  name: string
  value: string
  type: string
  price: string
  stock: string
  priceAdjustment: string
  media: string[] | null
}

type GroupedProductOption = {
  name: string
  options: OptionValue[]
}

const OptionPriceAndStock: FC<OptionPriceAndStockProps> = ({
  product,
  productCode,
  variantOptions,
  handleNextStep,
  onPrevStep,
  onUpdateOptions,
  onProductUpdate,
}) => {
  const [productSecondaryOptions, setProductSecondaryOptions] = useState<
    GroupedProductOption[]
  >([])
  const [primaryOptions, setPrimaryOptions] = useState<OptionValue[]>([])
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()
  const [openDialog, setOpenDialog] = useState(false)
  const [currentOptionIds, setCurrentOptionIds] = useState<{
    optionId: number | null
    valueId: number | null
    media: string[] | null
  }>({
    optionId: null,
    valueId: null,
    media: null,
  })
  const [errors, setErrors] = useState<Record<number, string>>({})

  const handlePriceChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    let value = e.target.value
    value = value.replace(/[^0-9.]/g, '')

    const decimalCount = (value.match(/\./g) || []).length

    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf('.'))
    }

    if (value.includes('.')) {
      const [integer, decimal] = value.split('.')
      value = integer + '.' + (decimal ? decimal.slice(0, 2) : '')
    }

    const updatedOptions = [...primaryOptions]
    updatedOptions[index].price = value
    setPrimaryOptions(updatedOptions)

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      if (value.trim() !== '') {
        delete newErrors[index]
      }
      return newErrors
    })
  }

  const handlePrimaryStockChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    let value = e.target.value

    value = value.replace(/[^0-9]/g, '')

    const updatedOptions = [...primaryOptions]
    updatedOptions[index].stock = value
    setPrimaryOptions(updatedOptions)
  }

  const handlePriceAdjustmentChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    optionIndex: number,
    valueIndex: number
  ) => {
    const name = e.target.name

    let value = e.target.value
    value = value.replace(/[^0-9.]/g, '')

    const decimalCount = (value.match(/\./g) || []).length

    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf('.'))
    }

    if (value.includes('.')) {
      const [integer, decimal] = value.split('.')
      value = integer + '.' + (decimal ? decimal.slice(0, 2) : '')
    }

    setProductSecondaryOptions((prevOptions) =>
      prevOptions.map((option, i) =>
        i === optionIndex
          ? {
              ...option,
              options: option.options.map((val, j) =>
                j === valueIndex ? { ...val, [name]: value } : val
              ),
            }
          : option
      )
    )
  }

  const handleSecondaryStockChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    optionIndex: number,
    valueIndex: number
  ) => {
    const name = e.target.name
    let value = e.target.value

    value = value.replace(/[^0-9]/g, '')

    setProductSecondaryOptions((prevOptions) =>
      prevOptions.map((option, i) =>
        i === optionIndex
          ? {
              ...option,
              options: option.options.map((val, j) =>
                j === valueIndex ? { ...val, [name]: value } : val
              ),
            }
          : option
      )
    )
  }

  useEffect(() => {
    setPrimaryOptions([])
    setProductSecondaryOptions([])

    variantOptions.forEach((option) => {
      if (option.type === 'PRIMARY') {
        const newPrimaryOptions = option.ProductOptionValue.sort(
          (a, b) => a.id - b.id
        ).map((value) => ({
          optionId: option.id,
          valueId: value.id,
          name: option.name,
          value: value.value,
          type: 'PRIMARY',
          price: value.price ? (value.price / 100).toString() : '',
          stock: value.stock ? value.stock.toString() : '',
          priceAdjustment: '',
          media: value.media ?? null,
        }))
        setPrimaryOptions(newPrimaryOptions)
      } else {
        const newSecondaryOptions = option.ProductOptionValue.sort(
          (a, b) => a.id - b.id
        ).map((value) => ({
          optionId: option.id,
          valueId: value.id,
          name: option.name,
          value: value.value,
          type: 'SECONDARY',
          price: '',
          stock: value.stock ? value.stock.toString() : '',
          priceAdjustment: value.priceAdjustment
            ? (value.priceAdjustment / 100).toString()
            : '',
          media: value.media ?? null,
        }))
        setProductSecondaryOptions((prev) => {
          const groupedOptions: Record<string, OptionValue[]> = prev.reduce(
            (acc, item) => {
              acc[item.name] = item.options
              return acc
            },
            {} as Record<string, OptionValue[]>
          )

          newSecondaryOptions.forEach((option) => {
            if (!groupedOptions[option.name]) {
              groupedOptions[option.name] = []
            }
            groupedOptions[option.name].push(option)
          })

          return Object.entries(groupedOptions).map(([name, options]) => ({
            name,
            options,
          }))
        })
      }
    })
  }, [variantOptions])

  const handleOpenDialog = (
    optionId: number,
    valueId: number,
    media: string[] | null
  ): void => {
    setCurrentOptionIds({ optionId, valueId: valueId, media })
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
    setCurrentOptionIds({
      optionId: null,
      valueId: null,
      media: null,
    })
  }
  const handleSubmit = async (): Promise<void> => {
    if (primaryOptions.length > 0) {
      const newErrors: Record<number, string> = {}

      primaryOptions.forEach((option, index) => {
        if (!option.price || option.price.toString().trim() === '') {
          newErrors[index] = 'Selling Price is required'
        }
      })

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors)
        return
      }

      setErrors({})
    }
    setIsSubmitting(true)

    const data = primaryOptions.map((option) => ({
      optionId: option.optionId,
      valueId: option.valueId,
      price: Number(option.price) * 100,
      stock: option.stock !== '' ? Number(option.stock) : undefined,
    }))

    const secondaryData = productSecondaryOptions.flatMap((optionGroup) =>
      optionGroup.options.map((option) => ({
        optionId: option.optionId,
        valueId: option.valueId,
        priceAdjustment: Number(option.priceAdjustment) * 100,
        stock: option.stock !== '' ? Number(option.stock) : undefined,
      }))
    )

    const allData = [...data, ...secondaryData]

    const res = organizationId
      ? await updateProductOptionValue(organizationId, productCode, {
          optionValues: allData,
        })
      : { status: 'failed', message: 'Organization ID is required' }

    const updatedVariantOptions = variantOptions.map((op) => {
      return {
        ...op,
        ProductOptionValue: op.ProductOptionValue.map((vl) => {
          const updatedData = allData.find((opVal) => opVal.valueId === vl.id)

          if (updatedData) {
            if (op.type === 'PRIMARY' && 'price' in updatedData) {
              return {
                ...vl,
                price: updatedData.price,
                stock: updatedData.stock ?? null,
              }
            } else if ('priceAdjustment' in updatedData) {
              return {
                ...vl,
                priceAdjustment: updatedData.priceAdjustment,
                stock: updatedData.stock ?? null,
              }
            }
          }
          return vl
        }),
      }
    })

    if (res.status === 'successful') {
      handleNextStep()
      onUpdateOptions(updatedVariantOptions)
    } else {
      showSnackbar(
        res.message || 'Failed to update options. Please try again.',
        'error'
      )
    }

    setIsSubmitting(false)
  }

  const handleValueImageAdd = (uploadUrl?: string): void => {
    if (
      currentOptionIds.optionId !== null &&
      currentOptionIds.valueId !== null
    ) {
      setCurrentOptionIds((prev) => ({
        ...prev,
        media: uploadUrl ? [uploadUrl] : [],
      }))

      const updatedProduct = product
        ? ({
            ...product,
            id: product.id,
            media: Array.from(
              new Set([
                ...(product.media || []),
                ...(uploadUrl ? [uploadUrl] : []),
              ])
            ),
          } as Product)
        : null

      if (updatedProduct) {
        onProductUpdate(updatedProduct)
      }

      const updatedOptions = variantOptions.map((option) => {
        if (option.id === currentOptionIds.optionId) {
          return {
            ...option,
            ProductOptionValue: option.ProductOptionValue.map((value) => {
              if (value.id === currentOptionIds.valueId) {
                return {
                  ...value,
                  media: uploadUrl ? [uploadUrl] : [],
                }
              }
              return value
            }),
          }
        }
        return option
      })

      if (updatedOptions) {
        onUpdateOptions(updatedOptions)
      }
    }
  }

  return (
    <div>
      <div>
        {primaryOptions.length > 0 && (
          <div className="flex items-center justify-between ml-5 mr-5">
            <h2 className="text-lg font-semibold">{primaryOptions[0].name}</h2>
            <Chip
              label="Primary Variant Option"
              sx={{
                color: '#15803d',
                backgroundColor: '#bbf7d0',
                fontSize: '12px',
              }}
            />
          </div>
        )}
        {primaryOptions.length > 0 && (
          <Card>
            {primaryOptions.map((option, index) => (
              <Grid
                container
                spacing={2}
                mb={primaryOptions.length - 1 === index ? 0 : 2}
                key={index}
              >
                <Grid item xs={12} md={2}>
                  <Label text={'Image'} color="secondary" />
                  {option.media && option.media.length > 0 ? (
                    <img
                      src={option.media[0]}
                      alt="Option Media"
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleOpenDialog(
                          option.optionId,
                          option.valueId,
                          option.media
                        )
                      }
                    />
                  ) : (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="button"
                      onClick={() =>
                        handleOpenDialog(
                          option.optionId,
                          option.valueId,
                          option.media
                        )
                      }
                    >
                      <AddAPhotoIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <Label text={'Option'} color="secondary" />
                  <Typography variant="body1" fontWeight={600}>
                    {primaryOptions[0].name.toLowerCase() === 'color' ? (
                      <Box
                        sx={{
                          display: 'inlineblock',
                          backgroundColor: option.value,
                          height: '20px',
                          width: '20px',
                          borderRadius: 1,
                        }}
                      ></Box>
                    ) : (
                      <Typography variant="body1" fontWeight={600}>
                        {option.value}
                      </Typography>
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Label text={'Selling Price*'} color="secondary" />
                  <CustomTextField
                    type="string"
                    name="price"
                    value={option.price}
                    onChange={(e) => handlePriceChange(e, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    error={Boolean(errors[index])}
                    helperText={errors[index]}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Label text={'Quantity in Stock'} color="secondary" />
                  <CustomTextField
                    type="string"
                    name="stock"
                    value={option.stock}
                    onChange={(e) => handlePrimaryStockChange(e, index)}
                  />
                </Grid>
              </Grid>
            ))}
          </Card>
        )}
      </div>
      {productSecondaryOptions.length > 0 && (
        <div>
          {productSecondaryOptions.map((optionValue, optionIndex) => (
            <div key={optionIndex}>
              <div className="flex items-center justify-between ml-5">
                <h2 className="text-lg font-semibold">{optionValue.name}</h2>
              </div>
              <Card>
                {optionValue.options.map((value, valueIndex) => (
                  <Grid
                    container
                    spacing={2}
                    mb={optionValue.options.length - 1 === valueIndex ? 0 : 2}
                    key={valueIndex}
                  >
                    <Grid item xs={12} md={2}>
                      <Label text={'Image'} color="secondary" />
                      {value.media && value.media.length > 0 ? (
                        <img
                          src={value.media[0]}
                          alt="Option Media"
                          style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'cover',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            handleOpenDialog(
                              value.optionId,
                              value.valueId,
                              value.media
                            )
                          }
                        />
                      ) : (
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="button"
                          onClick={() =>
                            handleOpenDialog(
                              value.optionId,
                              value.valueId,
                              value.media
                            )
                          }
                        >
                          <AddAPhotoIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Label text={'Variant'} color="secondary" />
                      {optionValue.name.toLowerCase() === 'color' ? (
                        <Box
                          sx={{
                            display: 'inlineblock',
                            backgroundColor: value.value,
                            height: '20px',
                            width: '20px',
                            borderRadius: 1,
                          }}
                        ></Box>
                      ) : (
                        <Typography variant="body1" fontWeight={600}>
                          {value.value}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Label text={'Additional Price'} color="secondary" />
                      <CustomTextField
                        type="text"
                        name="priceAdjustment"
                        value={value.priceAdjustment}
                        onChange={(e) =>
                          handlePriceAdjustmentChange(
                            e,
                            optionIndex,
                            valueIndex
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Label text={'Quantity in Stock'} color="secondary" />
                      <CustomTextField
                        type="string"
                        name="stock"
                        value={value.stock}
                        onChange={(e) =>
                          handleSecondaryStockChange(e, optionIndex, valueIndex)
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Card>
            </div>
          ))}
        </div>
      )}
      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <OptionValueFileUpload
              productOptionId={currentOptionIds}
              productCode={productCode}
              onCloseDialog={handleCloseDialog}
              onFilesAdd={handleValueImageAdd}
              productMedia={product?.media ?? []}
            />
          </DialogContent>
        </Dialog>
      )}
      <div className="mx-5">
        <div className="flex items-center w-full">
          <div className="flex-1 basis-1/2">
            <Button
              size="small"
              variant="outlined"
              sx={{
                textTransform: 'none',
              }}
              onClick={() => onPrevStep(undefined)}
            >
              Prev: Product Variants
            </Button>
          </div>
          <div className="flex-1 basis-3/5">
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Next: Add Notes & Tags'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptionPriceAndStock
