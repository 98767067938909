import React, { useEffect, useRef, useState } from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface EditorProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  limit?: number
}

const QuillEditor: React.FC<EditorProps> = ({
  value,
  onChange,
  placeholder,
  limit = 1000,
}) => {
  const quillRef = useRef<ReactQuill | null>(null)
  const [error, setError] = useState('')

  const handleChange = (
    content: string,
    delta: unknown,
    source: unknown,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    const plainText = editor.getText().trim()
    if (source === 'user') {
      if (plainText.length > limit) {
        setError(`Maximum limit: ${limit} characters.`)
        const currentEditor = quillRef.current?.getEditor()
        if (currentEditor) {
          const currentContent = currentEditor.getContents()
          const truncatedContent = currentContent.slice(0, limit)

          currentEditor.setContents(truncatedContent, 'silent')
          onChange(currentEditor.root.innerHTML)
        }
      } else {
        setError('')
        const html = editor.getHTML()
        onChange(html === '<p><br></p>' ? '' : html)
      }
    }
  }

  const handlePaste = (e: ClipboardEvent) => {
    const clipboardText = e.clipboardData?.getData('text/plain') || ''
    const currentEditor = quillRef.current?.getEditor()

    const plainText = (value + clipboardText).slice(0, limit)

    if (currentEditor && plainText.length > limit) {
      setError(`Maximum limit: ${limit} characters.`)
      e.preventDefault()
      const delta = currentEditor.clipboard.convert(plainText)
      currentEditor.setContents(delta, 'silent')
      onChange(currentEditor.root.innerHTML)
    } else {
      setError('')
    }
  }

  useEffect(() => {
    const editor = quillRef.current?.getEditor()
    editor?.root.addEventListener('paste', handlePaste)

    return () => {
      editor?.root.removeEventListener('paste', handlePaste)
    }
  }, [value])

  const plainTextLength = value.replace(/<[^>]*>/g, '').slice(0, limit).length

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  }

  return (
    <div className="space-y-1">
      <ReactQuill
        className="bg-white"
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        ref={quillRef}
        modules={modules}
      />
      <div className="flex justify-between items-center text-sm">
        {error && <div className="text-red-500">{error}</div>}
        {limit && (
          <div className="text-gray-500 text-sm ml-auto">
            {plainTextLength}/{limit} characters
          </div>
        )}
      </div>
    </div>
  )
}

export default QuillEditor
