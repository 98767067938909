import {
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
} from '@mui/x-data-grid'
import { format } from 'date-fns'

import CustomCell from 'components/table/CustomCell'

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 70,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    filterable: true,
    resizable: false,
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value == '='
    ),
  },
  {
    field: 'fullName',
    headerName: 'Name',
    hideable: false,
    width: 200,
    filterable: true,
    renderCell: (params) => <CustomCell {...params} route="customers" />,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value == 'contains'
    ),
  },
  {
    field: 'email',
    width: 250,
    headerName: 'Email',
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value == 'contains'
    ),
  },
  {
    field: 'address',
    width: 400,
    headerName: 'Address',
    sortable: false,
    filterable: false,
  },
  {
    field: 'phoneNumber',
    width: 150,
    type: 'string',
    headerName: 'Phone Number',
    sortable: false,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains'
    ),
  },
  {
    field: 'amountSpent',
    width: 120,
    type: 'string',
    headerName: 'Amount Spent',
    sortable: false,
    filterable: false,
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value == '>' ||
        operator.value === '<' ||
        operator.value == '=' ||
        operator.value == '>=' ||
        operator.value == '<='
    ),
  },
  {
    field: 'createdAt',
    width: 180,
    headerName: 'Created At',
    type: 'dateTime',
    valueFormatter: (value): string => {
      return format(new Date(value), 'MMM d, yyyy, h:mm a')
    },
    filterable: false,
  },
]
