import { FC, useEffect, useState } from 'react'

import { Button } from '@mui/material'

import Card from 'components/card'
import { ProductOption } from 'services/products/index.types'

import OptionInput from './VariantOptionInput'

interface VariantOptionsProps {
  handleVariantOptions: (option: ProductOption) => void
  handleNoVariantsStep: () => void
  productCode: string
  onNextStep: () => void
  onRemoveVariantOption: (optionId: number) => void
  variantOptions: ProductOption[]
  onPrevStep: (step?: number) => void
}

interface Option {
  name: string
  values: string[]
  isPrimary: boolean
  optionId: string | number
}

const VariantOptions: FC<VariantOptionsProps> = ({
  handleVariantOptions,
  handleNoVariantsStep,
  productCode,
  onNextStep,
  onRemoveVariantOption,
  variantOptions,
  onPrevStep,
}) => {
  const [options, setOptions] = useState<Option[]>([])
  const [error, setError] = useState<string | null>(null)
  const [primaryOptionAdded, setPrimaryOptionAdded] = useState(false)

  useEffect(() => {
    if (variantOptions.length > 0) {
      const ops = variantOptions.map((op) => ({
        name: op.name,
        values: op.ProductOptionValue.map((vl) => vl.value),
        isPrimary: op.type === 'PRIMARY',
        optionId: op.id,
      }))
      const primaryop = ops.find((obj) => obj.isPrimary)
      if (primaryop) setPrimaryOptionAdded(true)
      setOptions(ops)
    } else {
      setOptions([
        {
          name: '',
          values: [],
          isPrimary: false,
          optionId: '',
        },
      ])
    }
  }, [variantOptions])

  const handleOptionAdd = (option: Option, index: number): void => {
    const updatedOptions = [...options]
    updatedOptions[index] = option
    const primaryop = updatedOptions.find((obj) => obj.isPrimary === true)
    if (primaryop) setPrimaryOptionAdded(true)
    setOptions(updatedOptions)
  }

  const handleAddNewOption = (): void => {
    setOptions((prevOptions) => [
      ...prevOptions,
      { name: '', values: [], isPrimary: false, optionId: '' },
    ])
  }

  const handleDeleteOption = (index: number): void => {
    const primaryop = options[index]
    if (primaryop.isPrimary) setPrimaryOptionAdded(false)
    setOptions((prevOptions) => prevOptions.filter((_, idx) => idx !== index))
  }

  const handleNextStep = (): void => {
    if (options.length === 0) {
      handleNoVariantsStep()
      return
    }
    if (variantOptions.length === 0) {
      setError('*Please provide values to all options or delete them.')
      return
    }
    onNextStep()
  }

  return (
    <div>
      <h2 className="text-lg font-semibold ml-5">Product Variants</h2>
      <Card>
        {error && <p className="text-sm text-red-500 my-2">{error}</p>}
        {options.map((option, index) => (
          <OptionInput
            key={index}
            option={option}
            onAddOption={handleVariantOptions}
            index={index}
            onDelete={handleDeleteOption}
            productCode={productCode}
            onRemoveVariantOption={onRemoveVariantOption}
            onRowOptionAdd={handleOptionAdd}
            primaryOptionAdded={primaryOptionAdded}
          />
        ))}
        <Button
          variant="contained"
          size="small"
          disableElevation
          sx={{
            textTransform: 'none',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #E0E0E0',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#E0E0E0',
              color: 'black',
            },
            p: '2px 14px',
            fontSize: '12px',
          }}
          onClick={handleAddNewOption}
        >
          + Add Another Variant
        </Button>
      </Card>
      <div className=" flex justify-center"></div>
      <div className="mx-5 my-4">
        <div className="flex items-center w-full">
          <div className="flex-1 basis-1/2">
            <Button
              size="small"
              variant="outlined"
              sx={{
                textTransform: 'none',
              }}
              onClick={() => onPrevStep(undefined)}
              disabled={variantOptions.length !== 0}
            >
              Prev: Add Variants
            </Button>
          </div>
          <div className="flex-1 basis-3/5">
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleNextStep}
            >
              Next: Assign Variant Price & Stock
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantOptions
