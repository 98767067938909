import React, { ReactNode, useEffect } from 'react'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import SignUpConfirmationForm from 'components/forms/SignUpConfirmationForm'
import { Navbar } from 'components/navbar'
import { AuthProvider } from 'contexts/authContext'
import { SnackbarProvider } from 'contexts/snackbarContext'
import { TemplateContentProvider } from 'contexts/template/templateContentContext'
import { TemplateProvider } from 'contexts/template/templateContext'
import { AboutUs } from 'pages/about-us'
import AcceptInvite from 'pages/auth/accept-invite'
import ForgotPassword from 'pages/auth/forgot-password'
import JoinWaitlist from 'pages/auth/JoinWaitlist'
import NewPassword from 'pages/auth/new-password'
import SignInWithEmail from 'pages/auth/signin'
import SigninStrategy from 'pages/auth/signin/SigninStrategy'
import SigninCallback from 'pages/auth/signin-callback'
import SignupWithEmail from 'pages/auth/signup'
import CreateStore from 'pages/auth/signup/CreateStore'
import SignupStrategy from 'pages/auth/signup/SignupStrategy'
import ContactForm from 'pages/contact-us'
import Dashboard from 'pages/dashboard'
import Analytics from 'pages/dashboard/pages/analytics'
import AnalyticsPage from 'pages/dashboard/pages/analytics/pages/AnalyticsPage'
import Customers from 'pages/dashboard/pages/customers'
import AddCustomerPage from 'pages/dashboard/pages/customers/pages/AddCustomerPage'
import CustomersDetailsPage from 'pages/dashboard/pages/customers/pages/CustomersDetailsPage'
import CustomersPage from 'pages/dashboard/pages/customers/pages/CustomersPage'
import DashboardHome from 'pages/dashboard/pages/home'
import Orders from 'pages/dashboard/pages/orders'
import CheckoutSessionDetailsPage from 'pages/dashboard/pages/orders/pages/CheckoutSessionDetailsPage'
import CheckoutSessionsPage from 'pages/dashboard/pages/orders/pages/CheckoutSessionsPage'
import OrderDetailsPage from 'pages/dashboard/pages/orders/pages/OrderDetailsPage'
import OrdersPage from 'pages/dashboard/pages/orders/pages/OrdersPage'
import Products from 'pages/dashboard/pages/products'
import CategoryList from 'pages/dashboard/pages/products/components/categories'
import AddCategoryPage from 'pages/dashboard/pages/products/components/categories/components/AddNewCategory'
import CategoryDetailsPage from 'pages/dashboard/pages/products/components/categories/components/CategoryDetailsPage'
import Reviews from 'pages/dashboard/pages/products/components/reviews'
import AddProductPage from 'pages/dashboard/pages/products/pages/AddProductPage'
import ProductDetailsPage from 'pages/dashboard/pages/products/pages/ProductDetailsPage'
import ProductsPage from 'pages/dashboard/pages/products/pages/ProductsPage'
import AccountSettings from 'pages/dashboard/pages/settings/pages/account'
import PaymentSettings from 'pages/dashboard/pages/settings/pages/payment'
import PhonePeSetup from 'pages/dashboard/pages/settings/pages/payment/components/phonepe/PhonePeSetup'
import ProductSettings from 'pages/dashboard/pages/settings/pages/product'
import StoreSettings from 'pages/dashboard/pages/settings/pages/stores'
import StockPage from 'pages/dashboard/pages/stock/pages/StockPage'
import WebsiteBuilder from 'pages/dashboard/pages/website'
import { DataDeletionPolicy } from 'pages/data-deletion-policy'
import Home from 'pages/home'
import Pricing from 'pages/pricing'
import { PrivacyPolicy } from 'pages/privacy-policy'
import { RefundAndCancellation } from 'pages/refund-and-cancellation'
import { ShippingPolicy } from 'pages/shipping-and-delivery'
import CheckoutSubscription from 'pages/subscribe/checkout'
import SelectPlan from 'pages/subscribe/select-plan'
import { TermsOfService } from 'pages/terms-of-service'
import ProtectedRoute from 'routes/ProtectedRoute'

interface AppProps {
  children: ReactNode
}

export const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App: React.FC<AppProps> = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <TemplateProvider>
          <TemplateContentProvider>
            <SnackbarProvider>
              <HelmetProvider>
                <Helmet>
                  <title>Haulistic</title>
                  <link
                    rel="icon"
                    type="image/png"
                    href={
                      process.env.REACT_APP_ASSETS_URL +
                      '/images/logos/logo.png'
                    }
                  />
                </Helmet>
                <Navbar />
                <ScrollToTop />
                <Routes>
                  <Route path="" element={<Home />} />
                  <Route path="join-waitlist" element={<JoinWaitlist />} />

                  <Route path="signin" element={<SigninStrategy />} />
                  <Route path="signin/email" element={<SignInWithEmail />} />

                  <Route path="signin-callback" element={<SigninCallback />} />

                  <Route path="new-password" element={<NewPassword />} />
                  <Route path="signup" element={<SignupStrategy />} />
                  <Route path="signup/email" element={<SignupWithEmail />} />
                  <Route path="invite/accept" element={<AcceptInvite />} />
                  <Route
                    path="create-your-store"
                    element={
                      <ProtectedRoute>
                        <CreateStore />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="verify-email"
                    element={<SignUpConfirmationForm />}
                  />
                  <Route path="forgot-password" element={<ForgotPassword />} />

                  <Route path="subscribe">
                    <Route
                      path="checkout"
                      element={
                        <ProtectedRoute>
                          <CheckoutSubscription />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="select-plan"
                      element={
                        <ProtectedRoute>
                          <SelectPlan />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route
                    path="dashboard"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  >
                    <Route path="home" element={<DashboardHome />} />
                    <Route path="customers" element={<Customers />}>
                      <Route index element={<CustomersPage />} />
                      <Route
                        path=":customerId"
                        element={<CustomersDetailsPage />}
                      />
                      <Route path="new" element={<AddCustomerPage />} />
                    </Route>
                    <Route path="orders" element={<Orders />}>
                      <Route path=":orderId" element={<OrderDetailsPage />} />
                      <Route path="all" element={<OrdersPage />} />
                      <Route
                        path="checkout-sessions"
                        element={<CheckoutSessionsPage />}
                      />
                      <Route
                        path="checkout-sessions/:id"
                        element={<CheckoutSessionDetailsPage />}
                      />
                    </Route>
                    <Route path="products" element={<Products />}>
                      <Route path="all" element={<ProductsPage />} />
                      <Route path="stock" element={<StockPage />} />
                      <Route path="new" element={<AddProductPage />} />
                      <Route path="categories" element={<CategoryList />} />
                      <Route path="reviews" element={<Reviews />} />
                      <Route
                        path="categories/:categoryId"
                        element={<CategoryDetailsPage />}
                      />
                      <Route
                        path="categories/new"
                        element={<AddCategoryPage />}
                      />
                      <Route
                        path=":productCode"
                        element={<ProductDetailsPage />}
                      />
                      <Route
                        path="draft/:productCode"
                        element={<AddProductPage />}
                      />
                    </Route>
                    <Route path="analytics" element={<Analytics />}>
                      <Route index element={<AnalyticsPage />} />
                    </Route>
                    <Route
                      path="website-builder"
                      element={<WebsiteBuilder />}
                    />
                    <Route path="settings">
                      <Route path="account" element={<AccountSettings />} />
                      <Route path="stores" element={<StoreSettings />} />
                      <Route path="payments" element={<PaymentSettings />} />
                      <Route path="product" element={<ProductSettings />} />

                      <Route
                        path="payments/phonepe"
                        element={<PhonePeSetup />}
                      />
                    </Route>
                    {/* <Route path="marketing">
                      <Route path="email" element={<Email />} />
                    </Route>
                    <Route path="editor" element={<EditorPage />} /> */}
                  </Route>
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="terms-of-service" element={<TermsOfService />} />
                  <Route path="contact-us" element={<ContactForm />} />
                  <Route
                    path="refund-and-cancellation"
                    element={<RefundAndCancellation />}
                  />
                  <Route
                    path="shipping-and-delivery"
                    element={<ShippingPolicy />}
                  />
                  <Route
                    path="data-deletion-policy"
                    element={<DataDeletionPolicy />}
                  />
                  <Route path="pricing" element={<Pricing />} />
                  <Route path="about-us" element={<AboutUs />} />
                </Routes>
              </HelmetProvider>
            </SnackbarProvider>
          </TemplateContentProvider>
        </TemplateProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
