import { useState, useEffect, useRef, JSX } from 'react'

import {
  acceptInvitation,
  declineInvitation,
  getPendingInvitations,
} from 'services/account'
import { Invitation } from 'types/account'

import PendingInvitations from './components/PendingInvitations'
import Settings from './components/Settings'

const AccountSettings = (): JSX.Element => {
  const [pendingInvitationList, setPendingInvitationList] = useState<
    Invitation[]
  >([])
  const fetchInvitationsCalled = useRef(false)

  async function fetchPendingInvites(): Promise<void> {
    const result = await getPendingInvitations()

    if (result.status === 'successful' && result.data) {
      setPendingInvitationList(result.data)
    }
  }

  const handleAcceptInvitation = async (inviteId: number) => {
    try {
      await acceptInvitation(inviteId)
      setPendingInvitationList((prev) =>
        prev.filter((invite) => invite.organization.id !== inviteId)
      )
    } catch (error) {
      console.error('Error accepting invitation:', error)
    }
  }

  const handleDeclineInvitation = async (inviteId: number) => {
    try {
      await declineInvitation(inviteId)
      setPendingInvitationList((prev) =>
        prev.filter((invite) => invite.organization.id !== inviteId)
      )
    } catch (error) {
      console.error('Error declining invitation:', error)
    }
  }

  useEffect(() => {
    if (!fetchInvitationsCalled.current) {
      fetchPendingInvites()
      fetchInvitationsCalled.current = true
    }
  }, [])
  return (
    <div>
      <Settings />
      {pendingInvitationList.length > 0 && (
        <PendingInvitations
          inviteList={pendingInvitationList}
          onAcceptInvitation={handleAcceptInvitation}
          onDeclineInvitation={handleDeclineInvitation}
        />
      )}
    </div>
  )
}

export default AccountSettings
