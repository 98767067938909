import { JSX, useEffect, useState } from 'react'

import LoadingSpinner from 'components/spinner'
import { useAuth } from 'hooks/useAuth'
import EmptyTabContent from 'pages/dashboard/components/EmptyTabContent'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { TabHeader } from 'pages/dashboard/components/TabHeader'
import { getCustomers } from 'services/customers'

import CustomersList from '../components/CustomersList'

// This Page will show customers List
const CustomersPage = (): JSX.Element => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { organizationId, organization } = useAuth()

  useEffect(() => {
    async function fetchCustomers(): Promise<void> {
      if (organizationId) {
        setIsLoading(true)
        const result = await getCustomers({ orgId: organizationId })
        if (result && result.total > 0) {
          setTableActive(true)
        } else {
          setTableActive(false)
        }
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }
    fetchCustomers()
  }, [organizationId])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (organization == null) {
    return <SetupStorePrompt />
  }

  return (
    <div>
      <div className="mb-5">
        {!tableActive && (
          <EmptyTabContent
            title="Customers"
            description="Manage your customers, see their order history and group
                customers into segments."
            addButtonText="Add Customer"
            //importButtonText="Import Customers"
          />
        )}

        {tableActive && organizationId && (
          <>
            <TabHeader title="Customers" />
            <CustomersList orgId={organizationId} />
          </>
        )}
      </div>
    </div>
  )
}

export default CustomersPage
