import { FC } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { Product } from 'types/product'
import { dateTimeFormat } from 'utils/dateFormat'
interface ProductSummaryProps {
  product: Product
}

const ProductSummary: FC<ProductSummaryProps> = ({ product }) => {
  const { organization } = useAuth()

  let productLink: string | null = null
  if (organization?.customDomain) {
    productLink = `${organization.customDomain}/products/${product.route}`
  } else if (organization?.website) {
    productLink = `${organization.website}/products/${product.route}`
  }

  const createdBy =
    typeof product.createdByUser.firstName === 'string'
      ? `${product.createdByUser.firstName} ${product.createdByUser.lastName ?? ''}`
      : null

  const variants =
    product.ProductOption.length > 0
      ? product.ProductOption.map(
          (op) => `${op.ProductOptionValue.length} ${op.name}`
        ).join(', ')
      : 'None'

  return (
    <Box
      className=" m-5 p-5 rounded"
      sx={{ border: '1px solid', borderColor: 'accents.lightGrayAccent' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Label text="Product Code" color="secondary" />
          <Typography fontWeight={700}>{product.productCode}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Label text="Public Link" color="secondary" />
          <div className="text-wrap break-words">
            {product.isListed ? (
              <Typography
                component={'a'}
                fontWeight={700}
                href={
                  productLink && productLink.startsWith('http')
                    ? productLink
                    : productLink
                      ? `https://${productLink}`
                      : '#'
                }
                target="_blank"
                rel="noopener noreferrer"
                className="flex-grow underline"
              >
                {productLink}
              </Typography>
            ) : (
              <Typography fontWeight={700} className="flex-grow">
                None
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Label text="Variants" color="secondary" />
          <Typography fontWeight={700}>{variants}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <Label text="Created" color="secondary" />
          <Typography fontWeight={700}>
            {createdBy && `${createdBy}, `}
            {dateTimeFormat(product.createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Label text="Last Updated" color="secondary" />
          <Typography fontWeight={700}>
            {dateTimeFormat(product.updatedAt)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductSummary
