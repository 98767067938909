import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Label from 'components/forms/Label'

interface AccordionHeaderProps {
  productCode: string
  totalVariants: string
  productName: string
  expanded: boolean
}

const StockAccordionHeader: React.FC<AccordionHeaderProps> = ({
  productCode,
  productName,
  totalVariants,
  expanded,
}) => {
  const navigate = useNavigate()

  const handleNavigateToProduct = () => {
    navigate(`/dashboard/products/${productCode}`)
  }

  return (
    <>
      <ExpandMoreIcon
        sx={{
          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease-in-out',
          marginRight: 2,
        }}
      />

      <Box
        className="flex-1 sm:mr-40"
        sx={{
          display: { xs: 'flex', sm: 'flex-1' },
          justifyContent: { xs: 'space-between' },
          alignItems: { xs: 'center' },
          gap: 2,
        }}
      >
        <Box>
          <Label text="Product" color="secondary" />
          <Typography
            className="inline-block hover:underline font-bold"
            onClick={handleNavigateToProduct}
          >
            {productName}
          </Typography>
        </Box>
        <Box
          sx={{
            marginLeft: { xs: 2, sm: 0 },
          }}
        >
          <Label text="Variants" color="secondary" />
          <Typography className="inline-block hover:underline font-bold">
            {totalVariants}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default StockAccordionHeader
