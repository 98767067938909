import { JSX } from 'react'

import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import CustomerEdit from '../components/CustomerEdit'

const AddCustomerPage = (): JSX.Element => {
  const navigate = useNavigate()
  const handleBackNavigation = (): void => {
    navigate(-1)
  }
  return (
    <>
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent={'space-between'}
        className="mx-5 mb-2"
      >
        <p className="text-xl font-bold">Add New Customer</p>
        <Button
          variant="outlined"
          size="small"
          sx={{
            textTransform: 'none',
            color: 'black',
            borderColor: '#d1d5db',
            '&:hover': {
              borderColor: '#6b7280',
            },
          }}
          onClick={handleBackNavigation}
        >
          Cancel
        </Button>
      </Box>
      <CustomerEdit />
    </>
  )
}

export default AddCustomerPage
