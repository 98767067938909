import { Typography, Box, Container, Divider } from '@mui/material'

import Footer from 'pages/home/components/Footer'
/* eslint-disable react/no-unescaped-entities */

export const RefundAndCancellation: React.FC = () => {
  return (
    <Box className="mt-20">
      {/* Header Section */}
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" gutterBottom>
            Refund and Cancellation Policy
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Effective Date: 6 August 2024
          </Typography>
          <Divider />

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              Our Commitment to You
            </Typography>
            <Typography paragraph>
              At Haulistic, we value your business and are dedicated to
              providing a smooth and enjoyable experience. This policy is
              designed to be clear and transparent, so you always know what to
              expect. If you have any questions or concerns, we’re here to help
              at every step. Thank you for choosing Haulistic!
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              1. Introduction
            </Typography>
            <Typography paragraph>
              This Refund and Cancellation Policy outlines the terms under which
              refunds and cancellations will be processed for Haulistic’s
              monthly subscription services. By subscribing to Haulistic, you
              agree to the terms outlined in this policy.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              2. Subscription Cancellation
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>2.1. Monthly Subscriptions</strong>
            </Typography>
            <Typography paragraph>
              - You may cancel your monthly subscription at any time by
              contacting Haulistic’s support team at support@haulistic.io.
              <br />- Cancellations will take effect at the end of your current
              billing cycle. You will retain access to Haulistic's services
              until the end of the month in which you cancel.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              3. Refund Policy
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>3.1. Refund Eligibility</strong>
            </Typography>
            <Typography paragraph>
              - Cancellations will be accepted within 10 days after the payment
              has been done.
              <br />- Refunds are available for monthly subscriptions canceled
              on or before the 20th of the current month.
              <br />- If you cancel after the 20th of the month, no refund will
              be provided for the current month, but your subscription will not
              renew in the next billing cycle.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>3.2. Refund Process</strong>
            </Typography>
            <Typography paragraph>
              - To request a refund, please contact Haulistic support at
              support@haulistic.io.
              <br />- Refunds for eligible cancellations will be processed and
              credited to the original payment method within 7-10 business days.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              4. Service Downtime or Disruption
            </Typography>
            <Typography paragraph>
              Haulistic is committed to providing reliable and high-quality
              service to our users. We understand the importance of
              uninterrupted access and will strive to maintain high
              availability.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong> 4.1. Minor Service Disruptions</strong>
            </Typography>
            <Typography paragraph>
              - In the event of minor service disruptions, we will offer refunds
              on a prorated basis to compensate for the affected period. Please
              reach out to our support team at support@haulistic.io if you
              experience a disruption that you feel qualifies.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong> 4.2. Severe Disruptions</strong>
            </Typography>
            <Typography paragraph>
              - If there is a severe disruption lasting more than three
              consecutive days, we will proactively refund the entire month’s
              subscription fee for impacted users, as we recognize the
              inconvenience this may cause.
            </Typography>

            <Typography paragraph>
              We value your trust and are here to ensure you receive the best
              experience possible. Haulistic is not responsible for disruptions
              caused by third-party service providers, though we will work
              closely with them to resolve any issues swiftly.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              5. Modifications to Services or Pricing
            </Typography>
            <Typography paragraph>
              At Haulistic, we’re always working to improve our services and
              features to better serve you. Occasionally, this may mean
              adjusting our offerings or pricing to keep up with new
              developments and ensure the highest quality of service.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong> 5.1. Service and Feature Updates</strong>
            </Typography>
            <Typography paragraph>
              - We may introduce new features, make updates, or modify existing
              services. These changes will be aimed at enhancing your experience
              and helping your business grow.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong> 5.2. Pricing Adjustments</strong>
            </Typography>
            <Typography paragraph>
              - Should there be any changes to our pricing, we will provide you
              with at least two months' notice before the new rates take effect.
              This gives you ample time to review and adjust your subscription
              if needed.
            </Typography>

            <Typography paragraph>
              We’re committed to transparency and keeping you informed, so you
              always know what to expect from Haulistic.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              6. Contact Information
            </Typography>
            <Typography paragraph>
              For questions, cancellations, or refund requests, please contact
              us at support@haulistic.io.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              7. Frequently Asked Questions (FAQ)
            </Typography>
            <Typography paragraph>
              <strong>Q1: How do I cancel my subscription?</strong>
              <br />
              A: Simply contact our support team at support@haulistic.io, and
              we’ll guide you through the cancellation process. You’ll continue
              to have access to your subscription until the end of the current
              billing cycle.
            </Typography>
            <Typography paragraph>
              <strong>
                Q2: Will I get a refund if I cancel my subscription in the
                middle of the month?
              </strong>
              <br />
              A: Yes, if you cancel on or before the 20th of the month, you’re
              eligible for a refund for the current month. Cancellations after
              the 20th will take effect the following month.
            </Typography>
            <Typography paragraph>
              <strong>
                Q3: What if there’s a minor disruption in service?
              </strong>
              <br />
              A: If you experience minor service disruptions, please reach out
              to us at support@haulistic.io. We’ll assess the impact and offer
              prorated refunds to cover the affected period.
            </Typography>
            <Typography paragraph>
              <strong>
                Q4: What happens if there’s a severe service outage?
              </strong>
              <br />
              A: If there’s a severe outage lasting more than three days, we’ll
              automatically refund your subscription for the entire month.
            </Typography>
            <Typography paragraph>
              <strong>
                Q5: How will I know if there are changes in pricing or services?
              </strong>
              <br />
              A: We’ll notify you at least two months in advance of any pricing
              adjustments, so you’ll have plenty of time to review and make any
              necessary changes to your subscription.
            </Typography>
            <Typography paragraph>
              <strong>Q6: Who do I contact if I have more questions?</strong>
              <br />
              A: For any additional questions or support, feel free to reach out
              to us at support@haulistic.io. We’re here to help!
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  )
}
