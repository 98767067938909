import { ChangeEvent, FC, useEffect, useState } from 'react'

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

const sortingList = [
  { label: 'Alphabetically, A-Z', value: 'name_asc' },
  { label: 'Alphabetically, Z-A', value: 'name_desc' },
  { label: 'Price, low to high', value: 'price_asc' },
  { label: 'Price, high to low', value: 'price_desc' },
  { label: 'Date, old to new', value: 'createdAt_asc' },
  { label: 'Date, new to old', value: 'createdAt_desc' },
  { label: 'Featured', value: 'priority_desc' },
]

interface ProductToolbarProps {
  totalProducts: number
  highestPrice?: number
}

const ProductToolbar: FC<ProductToolbarProps> = ({
  totalProducts,
  highestPrice,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [priceAnchorEl, setPriceAnchorEl] = useState<null | HTMLElement>(null)
  const [sortValue, setSortValue] = useState<string>('priority_desc')
  const [priceRange, setPriceRange] = useState({ from: '', to: '' })
  const [selectedAvailability, setSelectedAvailability] = useState<
    'inStock' | 'outOfStock' | null
  >(null)

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search)

  useEffect(() => {
    const sort = searchParams.get('sort') || 'priority'
    const order = searchParams.get('order') || 'desc'
    setSortValue(`${sort}_${order}`)

    const minPrice = searchParams.get('min_price') || ''
    const maxPrice = searchParams.get('max_price') || ''
    setPriceRange({ from: minPrice, to: maxPrice })

    const availability = searchParams.get('availability')
    setSelectedAvailability(
      availability === '1'
        ? 'inStock'
        : availability === '0'
          ? 'outOfStock'
          : null
    )
  }, [location.search])

  const open = Boolean(anchorEl)
  const openPriceMenu = Boolean(priceAnchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePriceMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPriceAnchorEl(event.currentTarget)
  }

  const handlePriceMenuClose = () => {
    setPriceAnchorEl(null)
    updateUrlParams()
  }

  const updateUrlParams = () => {
    const params = new URLSearchParams(location.search)

    if (priceRange.from !== '') {
      params.set('min_price', priceRange.from)
    } else {
      params.delete('min_price')
    }

    if (priceRange.to !== '') {
      params.set('max_price', priceRange.to)
    } else {
      params.delete('max_price')
    }

    navigate(`${location.pathname}?${params.toString()}`)
  }

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value
    setSortValue(value)

    const params = new URLSearchParams(location.search)
    const sortParams = value.split('_')

    if (value) {
      params.set('sort', sortParams[0])
      params.set('order', sortParams[1])
      params.set('offset', '0')
      params.set('limit', '16')
    } else {
      params.delete('sort')
      params.delete('order')
    }

    navigate(`${location.pathname}?${params.toString()}`)
  }

  const handlePriceRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const isValidValue = /^\d*$/.test(value)
    if (!isValidValue) return
    setPriceRange((prev) => ({ ...prev, [name]: value }))
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      updateUrlParams()
    }
  }

  const handleToggle = (item: 'inStock' | 'outOfStock') => {
    const newSelectedAvailability = selectedAvailability === item ? null : item
    setSelectedAvailability(newSelectedAvailability)

    const params = new URLSearchParams(location.search)
    if (newSelectedAvailability === 'inStock') {
      params.set('availability', '1')
    } else if (newSelectedAvailability === 'outOfStock') {
      params.set('availability', '0')
    } else {
      params.delete('availability')
    }
    navigate(`${location.pathname}?${params.toString()}`)
  }

  const handleAvailablityFilterReset = () => {
    if (selectedAvailability === null) return

    setSelectedAvailability(null)
    const params = new URLSearchParams(location.search)
    params.delete('availability')
    navigate(`${location.pathname}?${params.toString()}`)
  }

  return (
    <Box>
      <div className="mb-4 flex items-center justify-between">
        <Box className="flex items-center">
          <Typography variant="body2" className="mr-3 text-gray-700">
            Filter:
          </Typography>
          <Button
            id="availablity-button"
            endIcon={<KeyboardArrowDownRoundedIcon />}
            className="mr-5"
            sx={{
              textTransform: 'none',
              color: 'black',
              paddingY: 0,
            }}
            onClick={handleClick}
          >
            Availability
          </Button>
          <Menu
            id="availablity-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'availablity-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                width: '300px',
              },
            }}
          >
            <MenuList dense className="flex items-center justify-between">
              <MenuItem>
                <Typography fontSize={'small'}>
                  {selectedAvailability ? '1 selected' : '0 selected'}
                </Typography>
              </MenuItem>
              <MenuItem>
                <ButtonBase onClick={handleAvailablityFilterReset}>
                  Reset All
                </ButtonBase>
              </MenuItem>
            </MenuList>
            <Divider />
            <MenuList dense>
              <MenuItem
                onClick={() => handleToggle('inStock')}
                sx={{ padding: 0 }}
              >
                <Checkbox
                  checked={selectedAvailability === 'inStock'}
                  size="small"
                />
                In stock
              </MenuItem>
              <MenuItem
                onClick={() => handleToggle('outOfStock')}
                sx={{ padding: 0 }}
              >
                <Checkbox
                  checked={selectedAvailability === 'outOfStock'}
                  size="small"
                />
                Out of stock
              </MenuItem>
            </MenuList>
          </Menu>
          <Button
            id="price-menu"
            endIcon={<KeyboardArrowDownRoundedIcon />}
            sx={{
              textTransform: 'none',
              color: 'black',
              paddingY: 0,
            }}
            onClick={handlePriceMenuClick}
          >
            Price
          </Button>
          <Menu
            id="price-menu"
            anchorEl={priceAnchorEl}
            open={openPriceMenu}
            onClose={handlePriceMenuClose}
            MenuListProps={{
              'aria-labelledby': 'price-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                width: '300px',
              },
            }}
          >
            {highestPrice && (
              <MenuList dense disablePadding>
                <MenuItem>
                  The highest price is Rs. {(highestPrice / 100).toFixed(2)}
                </MenuItem>
              </MenuList>
            )}
            <Divider />
            <MenuList dense className="flex">
              <MenuItem>
                <label htmlFor="from" className=" text-lg mr-1">
                  ₹
                </label>
                <TextField
                  id="from"
                  label="From"
                  name="from"
                  size="small"
                  value={priceRange.from}
                  onChange={handlePriceRangeChange}
                  onKeyDown={handleKeyPress}
                />
              </MenuItem>
              <MenuItem>
                <label htmlFor="to" className="text-lg mr-1">
                  ₹
                </label>
                <TextField
                  id="to"
                  name="to"
                  label="To"
                  size="small"
                  value={priceRange.to}
                  onChange={handlePriceRangeChange}
                  onKeyDown={handleKeyPress}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box>
          <Typography
            variant="body2"
            gutterBottom
            display={'inline-block'}
            className="mr-3 text-gray-700"
          >
            Sort by:
          </Typography>
          <Select
            value={sortValue}
            onChange={handleSortChange}
            size="small"
            IconComponent={KeyboardArrowDownRoundedIcon}
            sx={{
              color: 'black',
              fontSize: '0.875rem',
              background: 'none',
              border: 'none',
              padding: 0,
              mr: 2,
              '& .MuiSelect-select': {
                padding: 0,
                fontSize: 'inherit',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
            }}
          >
            {sortingList.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="body2" display={'inline-block'}>
            {totalProducts === 0
              ? '0 Product'
              : totalProducts === 1
                ? '1 Product'
                : `${totalProducts} Products`}
          </Typography>
        </Box>
      </div>
    </Box>
  )
}

export default ProductToolbar
