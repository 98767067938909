import React from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import Footer from 'pages/home/components/Footer'
import theme from 'styles/muiTheme'
import { pricingPlans } from 'utils/pricingPlan'

const SelectPlan: React.FC = () => {
  const navigate = useNavigate()
  const { organization } = useAuth()

  const PlanCard = styled(Card)(({ theme }) => ({
    borderRadius: '8px',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      boxShadow: `0px 4px 50px ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  }))

  const handleSelectPlan = async (planId: number) => {
    navigate(`/subscribe/checkout?planId=${planId}`)
  }

  return (
    <div className="mt-32">
      <div className="text-3xl md:text-4xl text-center font-bold mb-5">
        <p className="mb-1">Start with the Best Fit</p>
        <p>Change Plans as Your Business Grows!</p>
      </div>
      <Container maxWidth="lg" className="py-10">
        <Box className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center">
          {pricingPlans.map((plan, index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
              {plan.title === 'LITE' && (
                <Box
                  sx={{
                    background: '#F4F7F6',
                    px: 2,
                    py: 0.5,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    border: '1px solid #00897b',
                    borderBottomWidth: 0,
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    // zIndex: 2,
                    width: '100%',
                  }}
                >
                  <CheckCircleIcon
                    fontSize="small"
                    sx={{ color: '#00897B', mr: 1 }}
                  />
                  Most Popular
                </Box>
              )}
              <PlanCard
                className={`${plan.id !== 1 ? 'hover:shadow-lg' : ''}`}
                sx={{
                  borderTopLeftRadius: plan.title === 'LITE' ? 0 : '8px',
                  borderTopRightRadius: plan.title === 'LITE' ? 0 : '8px',
                  border: plan.title === 'LITE' ? '1px solid #00897b' : '',
                  borderTopWidth: 0,
                }}
              >
                <CardContent className="text-center">
                  <Box
                    component="div"
                    className="text-lg font-bold"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {plan.title}
                  </Box>
                  <p className="text-xs text-slate-400 font-bold">
                    {plan.description}
                  </p>
                  <div className="text-4xl my-8 font-bold">
                    {plan.price}
                    <span className="text-xs text-slate-400">/month</span>
                    <div className="text-sm px-5 mt-4">
                      + {plan.commission}% fee <br />
                      <span className="text-slate-400">
                        on total transactions via the website.
                      </span>
                    </div>
                  </div>
                  {organization?.currentPricingPlanId === plan.id ? (
                    <Box
                      sx={{
                        backgroundColor: '#F4F7F6',
                        color: '#00897B',
                        paddingY: 1,
                        borderRadius: 5,
                        fontSize: 'small',
                        fontWeight: 'bold',
                      }}
                    >
                      CURRENT PLAN
                    </Box>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth
                      sx={{ borderRadius: 5 }}
                      onClick={() => handleSelectPlan(plan.id)}
                    >
                      Select {plan.title}
                    </Button>
                  )}
                  <Box className="my-8 border-t-2 border-gray-200 w-3/4 mx-auto"></Box>
                  <p className="text-xs font-bold text-slate-400 mb-4">
                    PLAN PERKS
                  </p>
                  <ul className="list-none text-center px-4">
                    {plan.features.map((feature, i) => (
                      <li key={i} className="mb-2 text-sm">
                        {feature}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </PlanCard>
            </Box>
          ))}
        </Box>
      </Container>
      <Container maxWidth="md" className="my-10">
        <p className="mb-4 text-lg font-bold text-center">
          Looking for a highly tailored solution? Contact us for an{' '}
          <Box component="span" sx={{ color: '#00897b' }}>
            Enterprise Plan
          </Box>
          .
        </p>
        <div className="flex justify-evenly space-x-4">
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            onClick={() => {
              navigate('/contact-us')
            }}
          >
            Contact Us
          </Button>{' '}
        </div>
      </Container>
      <Box
        sx={{
          background: 'rgba(0, 137, 123, 0.1)',
          width: '100%',
        }}
      >
        <Footer />
      </Box>
    </div>
  )
}

export default SelectPlan
