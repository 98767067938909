import React, { useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'

import Amplitude from 'lib/amplitude'
import Footer from 'pages/home/components/Footer'
import { earlyRequestAccess } from 'services/auth'
import theme from 'styles/muiTheme'
import { pricingPlans } from 'utils/pricingPlan'

const Pricing: React.FC = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Email validation function
  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  // Handle email input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setError('')
    setSuccessMessage('')
  }

  const handleSubmit = async (
    executeRecaptcha: ((action?: string) => Promise<string>) | undefined
  ) => {
    Amplitude.trackEvent('REQUEST_INVITE_CLICKED', {
      email: email,
    })

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.')
      return
    }

    if (!executeRecaptcha) {
      setError('reCAPTCHA could not be loaded. Please try again later.')
      return
    }

    try {
      const token = await executeRecaptcha('request_invite')
      if (!token) {
        setError('Failed to verify reCAPTCHA. Please try again.')
        return
      }

      setIsSubmitting(true)

      const res = await earlyRequestAccess({
        email,
        recaptchaToken: token,
      })

      if (res && res.status === 'success') {
        setSuccessMessage(
          'Your request has been received. Our team will get back to you in less than 24 hours.'
        )
        setEmail('')
      } else {
        setError('Something went wrong. Please try again.')
      }
    } catch (error) {
      setError('Something went wrong. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const PlanCard = styled(Card)(({ theme }) => ({
    borderRadius: '8px',
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    '&:hover': {
      boxShadow: `0px 4px 50px ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  }))

  const RecaptchaWrappedButton = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    return (
      <Button
        variant="contained"
        size="small"
        sx={{ textTransform: 'none', fontWeight: 'bold' }}
        onClick={() => handleSubmit(executeRecaptcha)}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Sending...' : 'Learn More'}
      </Button>
    )
  }

  return (
    <div className="mt-32">
      <div className="text-3xl md:text-4xl text-center font-bold mb-5">
        <p className="mb-1">Start with the Best Fit</p>
        <p>Change Plans as Your Business Grows!</p>
      </div>
      <Container maxWidth="lg" className="py-10">
        <Box className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {pricingPlans.map((plan, index) => (
            <PlanCard key={index} className="hover:shadow-lg">
              <CardContent className="text-center">
                <Box
                  component="p"
                  className="text-lg font-bold"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {plan.title}
                </Box>
                <p className="text-xs text-slate-400 font-bold">
                  {plan.description}
                </p>
                <div className="text-4xl my-8 font-bold">
                  {plan.price}
                  <span className="text-xs text-slate-400">/month</span>
                  <div className="text-sm px-5 mt-4">
                    + {plan.commission}% fee <br />
                    <span className="text-slate-400">
                      on total transactions via the website.
                    </span>
                  </div>
                </div>
                <Box className="my-8 border-t-2 border-gray-200 w-3/4 mx-auto"></Box>
                <p className="text-xs font-bold text-slate-400 mb-4">
                  PLAN PERKS
                </p>
                <ul className="list-none text-center px-4">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="mb-2 text-sm">
                      {feature}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </PlanCard>
          ))}
        </Box>
      </Container>
      <Container maxWidth="md" className="my-10">
        <p className="mb-4 text-lg font-bold text-center">
          Looking for a highly tailored solution? Contact us for an{' '}
          <Box component="span" sx={{ color: '#00897b' }}>
            Enterprise Plan
          </Box>
          .
        </p>
        <div className="flex justify-evenly space-x-4">
          <TextField
            className="flex-1"
            id="request-invite-email-input"
            label="Enter your email address"
            variant="outlined"
            size="small"
            value={email}
            onChange={handleChange}
            error={!!error}
            helperText={error}
          />
          <RecaptchaWrappedButton />
        </div>
        {successMessage && (
          <Typography variant="body2" color="green" className="mt-4">
            {successMessage}
          </Typography>
        )}
      </Container>
      <Box
        sx={{
          background: 'rgba(0, 137, 123, 0.1)',
          width: '100%',
        }}
      >
        <Footer />
      </Box>
    </div>
  )
}

const PricingWithRecaptcha: React.FC = () => {
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

  if (!recaptchaKey) {
    throw new Error('Missing REACT_APP_RECAPTCHA_SITE_KEY')
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <Pricing />
    </GoogleReCaptchaProvider>
  )
}

export default PricingWithRecaptcha
