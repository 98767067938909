import * as React from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { useNavigate, useLocation } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'

import DashboardSidebar from './Sidebar'
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const drawerWidth = 300

export const Navbar = (props: Props) => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthenticated, signOut } = useAuth()

  const signInPath = '/signin'
  const isSignInRoute = location.pathname === signInPath
  const isHome = location.pathname === '/'

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const handleSignIn = () => {
    Amplitude.trackEvent('LANDINGPAGE_SIGN_IN_CLICKED', {})
    navigate('/signin')
  }
  const navigateToPricing = () => {
    Amplitude.trackEvent('LANDINGPAGE_PRICING_CLICKED', {})
    navigate('/pricing')
  }
  const navigateToHomePage = () => {
    navigate('/')
  }
  const handleTabClick = () => {
    setMobileOpen(false)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined

  const hideNavbarRoutes = [
    '/signup',
    '/signup/email',
    '/account-onboarding',
    '/join-waitlist',
    '/setup-store',
    '/verify-email',
    '/signin',
    '/signin/email',
    '/forgot-password',
    '/create-your-store',
    '/invite/accept',
    '/new-password',
  ]

  const shouldShowNavbar = !hideNavbarRoutes.includes(location.pathname)

  if (!shouldShowNavbar) {
    return (
      <Box position="absolute" top={10} left={10}>
        <Button color="primary" onClick={navigateToHomePage}>
          <img
            src={process.env.REACT_APP_ASSETS_URL + '/images/logos/logo.png'}
            alt="Haulistic"
            width="30"
          />
        </Button>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: 'white', zIndex: 1300 }}>
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' }, color: 'text.primary' }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }}>
            <Button color="primary" onClick={navigateToHomePage}>
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  '/images/logos/logoWithBrandName.png'
                }
                alt="Haulistic"
                width="150"
              />
            </Button>
          </Box>
          {isHome && !isAuthenticated && (
            <Box>
              <Button color="primary" onClick={navigateToPricing}>
                Pricing
              </Button>
            </Box>
          )}
          {!isSignInRoute && !isAuthenticated && (
            <Box>
              <Button color="primary" onClick={handleSignIn}>
                Sign In
              </Button>
            </Box>
          )}
          {isAuthenticated && (
            <Box>
              <Button color="primary" variant="text" onClick={signOut}>
                Sign Out
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              backgroundColor: '#F4F6F7',
              paddingRight: 2,
              boxSizing: 'border-box',
              maxWidth: drawerWidth,
              zIndex: (theme) => theme.zIndex.appBar - 1,
            },
          }}
        >
          <DashboardSidebar drawer onClick={handleTabClick} />
        </Drawer>
      )}
    </Box>
  )
}
