import { getToken } from 'utils/authCookies'

import {
  CreateOrganizationParams,
  Organization,
  UpdateOrganizationParams,
  UserOrganizations,
} from './index.types'

const organizationBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/organization`

const getOrganization = async (
  id: number
): Promise<{
  status: string
  data?: Organization
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    const { data, message } = await res.json()

    if (res.ok) {
      return { status: 'successful', data: data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

// Get the user's organization list.
const getUserOrganizations = async (): Promise<{
  status: string
  data?: UserOrganizations[]
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    const { data, error } = await res.json()

    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const deactivateOrganization = async (
  id: number
): Promise<{
  status: string
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/${id}/deactivate`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
    })

    const { error } = await res.json()

    if (res.ok) {
      return { status: 'successful' }
    }
    return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const activateOrganization = async (
  id: number
): Promise<{
  status: string
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/${id}/activate`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
    })

    const { error } = await res.json()

    if (res.ok) {
      return { status: 'successful' }
    }
    return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const deleteOrganization = async (
  id: number
): Promise<{
  status: string
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'DELETE',
    })

    const { error } = await res.json()

    if (res.ok) {
      return { status: 'successful' }
    }
    return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const createOrganization = async (
  newOrganization: CreateOrganizationParams
): Promise<
  { status: string; message?: string; data?: Organization } | undefined
> => {
  const token = await getToken()
  const { phoneNumber, countryCode, ...rest } = newOrganization

  const body = { ...rest, phoneNumber: `${countryCode}${phoneNumber}` }

  try {
    const res = await fetch(organizationBasePath, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    })

    const { data, error } = await res.json()
    if (data) return { status: 'successful', data }
    if (error) return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
  }
}

const updateOrganization = async (
  orgId: number,
  updateOrganizationParams: UpdateOrganizationParams
): Promise<
  { status: string; message?: string; data?: Organization } | undefined
> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/${orgId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify(updateOrganizationParams),
    })

    const { data, error } = await res.json()

    if (data) return { status: 'successful', data }
    if (error) return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
  }
}

const deletePendingInvitation = async (
  organizationId: number,
  userId: number
) => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${organizationBasePath}/${organizationId}/user/${userId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (res.ok) {
      return { status: 'successful' }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error deleting pending invitation:', error)
    throw error
  }
}

const attachCustomDomain = async (
  domain: string,
  orgId: number
): Promise<
  { status: string; message?: string; data?: unknown } | undefined
> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/domains`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ domain, orgId }),
    })

    const { data, error } = await res.json()
    if (data) return { status: 'successful', data }
    if (error) return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
  }
}

const checkIfscCode = async (
  code: string
): Promise<{ status: boolean; data?: unknown }> => {
  try {
    const res = await fetch(`https://ifsc.razorpay.com/${code}`)

    if (res.ok) {
      const data = await res.json()
      return { status: true, data }
    }
    return { status: false }
  } catch (err) {
    console.error(err)
    return { status: false }
  }
}

const createBrand = async (
  name: string,
  orgCategory: string
): Promise<
  { status: string; message?: string; data?: Organization } | undefined
> => {
  const token = await getToken()

  try {
    const res = await fetch(organizationBasePath, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ name, orgCategory }),
    })

    const { data, error } = await res.json()
    if (data) return { status: 'successful', data }
    if (error) return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
  }
}

export {
  getOrganization,
  deactivateOrganization,
  activateOrganization,
  getUserOrganizations,
  deleteOrganization,
  createOrganization,
  updateOrganization,
  deletePendingInvitation,
  attachCustomDomain,
  checkIfscCode,
  createBrand,
}
