import { JSX } from 'react'

import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'

export function CustomToolbar(): JSX.Element {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  )
}
