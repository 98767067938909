import React, { ChangeEvent, useState } from 'react'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import OnboardingContainer from 'components/forms/OnboardingContainer'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import theme from 'styles/muiTheme'

const passwordSchema = z
  .string()
  .min(8, 'Password must have at least 8 characters')
  .regex(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$*_^&])[A-Za-z\d@#$*_^&]{8,}$/,
    'Password must be 8+ characters with at least one uppercase, one lowercase, one digit, and one special character (@, #, $, *, ^, &)'
  )

function validatePassword(password: string): {
  valid: boolean
  message: string
} {
  const result = passwordSchema.safeParse(password)

  if (result.success) {
    return { valid: true, message: 'Password is valid!' }
  } else {
    return {
      valid: false,
      message: result.error.errors[0]?.message || 'Invalid password',
    }
  }
}

const NewPassword = () => {
  const location = useLocation()
  const { email, session } = location.state || {}

  const [showPassword, setShowPassword] = useState(false)

  const [password, setPassword] = useState('')
  const [error, setError] = useState({
    field: '',
    message: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { newPasswordChallenge, refreshAuthState } = useAuth()
  const navigate = useNavigate()

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setPassword(value)

    if (value.trim() !== '' && error.field === 'password') {
      setError((prev) => ({ ...prev, field: '', message: '' }))
    }
  }

  const handleSubmit = async () => {
    const validatePass = validatePassword(password)

    if (!validatePass.valid) {
      setError({
        field: 'password',
        message: validatePass.message,
      })
      return
    }

    setIsSubmitting(true)
    const res = await newPasswordChallenge(email, password, session)
    if (res.status === 'successful') {
      refreshAuthState()
      navigate('/invite/accept')
    } else if (res.status === 'failed' && res.message) {
      showSnackbar(res.message, 'error')
    }
    setIsSubmitting(false)
  }

  if (!email && !session) {
    return (
      <p className=" text-xl w-full text-center text-bold mt-28">
        Something went wrong
      </p>
    )
  }
  return (
    <OnboardingContainer
      title="Create New Password"
      description="You must change your temporary password to continue."
    >
      <div className="space-y-5 my-5">
        <div>
          <label
            htmlFor="firstName"
            className="text-base font-medium text-gray-900"
          >
            Email Address
          </label>
          <div className="mt-1">
            <input
              id="email"
              className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-gray-400 px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
              type="email"
              value={email}
              name="email"
              readOnly
            />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label
              htmlFor="firstName"
              className="text-base font-medium text-gray-900"
            >
              New Password
            </label>
            <IconButton
              sx={{ color: theme.palette.text.primary }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <VisibilityOffIcon fontSize="small" />
              ) : (
                <VisibilityIcon fontSize="small" />
              )}
            </IconButton>
          </div>
          <div>
            <input
              value={password}
              onChange={handlePasswordChange}
              className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
              type={showPassword ? 'text' : 'password'}
              placeholder="New Password"
              id="password"
              autoComplete="new-password"
            />
            {error.field === 'password' && (
              <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                *{error.message}
              </p>
            )}
          </div>
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            sx={{ width: '100%', textTransform: 'none' }}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </OnboardingContainer>
  )
}

export default NewPassword
