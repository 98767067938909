import { useEffect, useState, useRef } from 'react'

import { ViewList, ViewModule } from '@mui/icons-material'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import {
  Box,
  ButtonGroup,
  Button,
  Divider,
  IconButton,
  TextField,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import LoadingSpinner from 'components/spinner'
import UpgradeCard from 'components/upgrade-plan/UpgradeCard'
import { useAuth } from 'hooks/useAuth'
import AddButton from 'pages/dashboard/components/AddButton'
import EmptyTabContent from 'pages/dashboard/components/EmptyTabContent'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { getPricingPlan } from 'services/pricingPlans'
import { PricingPlan } from 'services/pricingPlans/index.type'
import { getAllProducts } from 'services/products'
import { Product } from 'services/products/index.types'

import ProductCardView from '../components/CardContainer/ProductCardView'
import SearchSuggestions from '../components/CardContainer/SearchSuggestions'
import ProductList from '../components/List'

interface ProductQueryParams {
  orgId: number
  offset?: string
  limit?: string
  q?: string
  min_price?: string
  max_price?: string
  sort?: string
  order?: 'asc' | 'desc'
  availability?: string
}

const ProductsPage = () => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { organizationId, organization } = useAuth()
  const [productCount, setProductCount] = useState<number>(0)
  const [productHighestPrice, setProductHighestPrice] = useState<number>(0)
  const [products, setProducts] = useState<Product[]>([])
  const [currentPlanDetails, setCurrentPlanDetails] =
    useState<PricingPlan | null>(null)
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)
  const [viewMode, setViewMode] = useState<'list' | 'card'>('list')
  const [searchQuery, setSearchQuery] = useState('')
  const [searchSuggestions, setSearchSuggestions] = useState<Product[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const searchRef = useRef<HTMLDivElement>(null)

  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const offset = searchParams.get('offset') || '0'
  const limit = searchParams.get('limit') || '16'
  const sort = searchParams.get('sort') || 'priority'
  const order = searchParams.get('order') || 'desc'
  const min_price = searchParams.get('min_price')
  const max_price = searchParams.get('max_price')
  const availability = searchParams.get('availability')
  const q = searchParams.get('q')

  useEffect(() => {
    if (q) {
      setSearchQuery(q)
    }
  }, [q])

  const buildQueryParams = (): ProductQueryParams => {
    const params: ProductQueryParams = {
      orgId: organizationId || 0,
      offset,
      limit,
      sort,
      order: order as 'asc' | 'desc',
    }

    if (viewMode === 'card') {
      if (min_price) params.min_price = (parseFloat(min_price) * 100).toString()
      if (max_price) params.max_price = (parseFloat(max_price) * 100).toString()
      if (availability) params.availability = availability
      if (q) params.q = q
    }

    return params
  }

  const updateUrlParams = (newParams: Partial<ProductQueryParams>) => {
    const params = new URLSearchParams(location.search)

    if (newParams.offset !== undefined) params.set('offset', newParams.offset)
    if (newParams.limit !== undefined) params.set('limit', newParams.limit)
    if (newParams.sort !== undefined) params.set('sort', newParams.sort)
    if (newParams.order !== undefined) params.set('order', newParams.order)
    if (newParams.min_price !== undefined) {
      newParams.min_price
        ? params.set('min_price', newParams.min_price)
        : params.delete('min_price')
    }
    if (newParams.max_price !== undefined) {
      newParams.max_price
        ? params.set('max_price', newParams.max_price)
        : params.delete('max_price')
    }
    if (newParams.availability !== undefined) {
      newParams.availability
        ? params.set('availability', newParams.availability)
        : params.delete('availability')
    }
    if (newParams.q !== undefined) {
      newParams.q ? params.set('q', newParams.q) : params.delete('q')
    }

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  useEffect(() => {
    async function fetchProducts(): Promise<void> {
      if (organizationId) {
        if (isInitialLoad) {
          setIsLoading(true)
        }
        try {
          const queryParams = buildQueryParams()
          const result = await getAllProducts(queryParams)

          if (result && result.status === 'successful') {
            setProducts(result.data)
            setProductCount(result.total || result.data.length)
            setTableActive(result.data.length > 0)
            setProductHighestPrice(result.highestPrice)
          } else {
            setTableActive(false)
          }
        } catch (error) {
          console.error('Error fetching products:', error)
          setTableActive(false)
        } finally {
          setIsLoading(false)
          setIsInitialLoad(false)
        }
      } else {
        setIsLoading(false)
      }
    }

    fetchProducts()
  }, [location.search, organizationId, viewMode])

  useEffect(() => {
    async function fetchPlanDetails(): Promise<void> {
      if (organization && organization.currentPricingPlanId) {
        try {
          const planResult = await getPricingPlan(
            organization.currentPricingPlanId
          )
          if (planResult && planResult.data) {
            setCurrentPlanDetails(planResult.data)
          }
        } catch (error) {
          console.error('Error fetching plan details', error)
        }
      }
    }

    fetchPlanDetails()
  }, [organization])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setSearchQuery(query)

    if (query.length > 2) {
      getAllProducts({
        orgId: organizationId || 0,
        q: query,
        limit: '5',
      }).then((result) => {
        if (result?.status === 'successful') {
          setSearchSuggestions(result.data)
          setShowSuggestions(true)
        }
      })
    } else {
      setShowSuggestions(false)
    }
  }

  const clearSearch = () => {
    setSearchQuery('')
    setSearchSuggestions([])
    setShowSuggestions(false)
    updateUrlParams({ q: undefined })
  }

  const handleSuggestionClick = (product: Product) => {
    navigate(`/products/${product.id}`)
    clearSearch()
  }

  const handleOverallSearch = () => {
    updateUrlParams({ q: searchQuery, offset: '0' })
    setShowSuggestions(false)
  }

  const handleSearchKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleOverallSearch()
    }
  }

  const hasFiltersApplied = () => {
    return viewMode === 'card' && (q || min_price || max_price || availability)
  }

  const handleViewModeChange = (newMode: 'list' | 'card') => {
    if (newMode !== viewMode) {
      if (newMode === 'list') {
        navigate({
          pathname: location.pathname,
          search: '',
        })
      }
      setViewMode(newMode)
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (organization == null) {
    return <SetupStorePrompt />
  }

  return (
    <div>
      <div className="mb-5">
        {!tableActive && !hasFiltersApplied() && (
          <EmptyTabContent
            title="Products"
            description="This is where you can add new products, update existing listings, manage inventory, and adjust pricing. It's designed to help you effectively organize and optimize your product offerings for increased sales."
            addButtonText="Add Product"
            // importButtonText="Import Products"
          />
        )}

        {(tableActive || hasFiltersApplied()) && organizationId && (
          <>
            <Box
              className="mx-5 mb-4"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <p className="text-xl font-bold">Products</p>

              <Box display="flex" alignItems="center" gap={2}>
                <ButtonGroup aria-label="view mode" variant="text" size="small">
                  <Button
                    onClick={() => handleViewModeChange('list')}
                    sx={{ color: viewMode === 'list' ? '#00897b' : 'gray' }}
                  >
                    <ViewList fontSize="small" />
                  </Button>
                  <Button
                    onClick={() => handleViewModeChange('card')}
                    sx={{ color: viewMode === 'card' ? '#00897b' : 'gray' }}
                  >
                    <ViewModule fontSize="small" />
                  </Button>
                </ButtonGroup>

                {currentPlanDetails &&
                productCount >= currentPlanDetails.maxProducts ? null : (
                  <AddButton buttonText=" + Add New" />
                )}
              </Box>
            </Box>
            {currentPlanDetails &&
              productCount >= currentPlanDetails.maxProducts && (
                <UpgradeCard>
                  <p>
                    You&apos;ve reached the limit of{' '}
                    {currentPlanDetails.maxProducts} products for your current
                    plan. To add more products, please upgrade your plan or
                    remove some existing products.
                  </p>
                </UpgradeCard>
              )}

            {viewMode === 'list' ? (
              <ProductList products={products} rowCount={productCount} />
            ) : (
              <>
                <Box ref={searchRef} className="mx-5 mb-4 mt-6 relative">
                  <TextField
                    size="small"
                    fullWidth
                    label="Search products"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyPress={handleSearchKeyPress}
                    InputProps={{
                      endAdornment: (
                        <Box display="flex" alignItems="center">
                          {searchQuery && (
                            <>
                              <IconButton size="small" onClick={clearSearch}>
                                <HighlightOffOutlinedIcon fontSize="small" />
                              </IconButton>
                              <Divider
                                orientation="vertical"
                                flexItem
                                className="mx-6"
                              />
                            </>
                          )}
                          <IconButton
                            size="small"
                            onClick={handleOverallSearch}
                            disabled={!searchQuery}
                          >
                            <SearchOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      ),
                    }}
                  />
                  {showSuggestions && searchSuggestions.length > 0 && (
                    <SearchSuggestions
                      products={searchSuggestions}
                      onSuggestionClick={handleSuggestionClick}
                      searchQuery={searchQuery}
                      onOverallSearch={handleOverallSearch}
                    />
                  )}
                </Box>
                <ProductCardView
                  products={products}
                  totalProducts={productCount}
                  highestPrice={productHighestPrice}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ProductsPage
