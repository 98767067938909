import { ChangeEvent, FC, useState } from 'react'

import { Button, Grid, InputAdornment, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'

interface ShippingConfigFormProps {
  product: Product
  onUpdate: (data?: Product) => void
}
const ShippingConfigForm: FC<ShippingConfigFormProps> = ({
  product,
  onUpdate,
}) => {
  const { organizationId } = useAuth()
  const [edit, setEdit] = useState(false)
  const [dispatchDays, setDispatchDays] = useState(
    product.dispatchDays ? product.dispatchDays.toString() : '0'
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_SHIPPING_EDIT_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleDispatchDaysChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')
    setDispatchDays(value)
  }

  const handleSave = async () => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_SHIPPING_SAVE_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }
    // Check if no changes have been made
    if (+dispatchDays === product.dispatchDays) {
      setEdit(false)
      return
    }
    setIsSubmitting(true)
    const res = await updateProduct({
      productId: product.id,
      dataUpdate: {
        dispatchDays: dispatchDays === '' ? 0 : Number(dispatchDays),
      },
    })

    if (res.status === 'successful') {
      showSnackbar('Product updated.', 'success')
      onUpdate(res.data)
      setEdit(false)
    } else {
      showSnackbar('Please try again later.', 'error')
    }
    setIsSubmitting(false)
  }
  const handleCancel = () => {
    setEdit(false)
    setDispatchDays(product.dispatchDays ? product.dispatchDays.toString() : '')
  }

  return (
    <div className="mb-5">
      <div className="mx-5">
        <SubTabHeader
          title="Shipping"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
      </div>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Label text="Dispatch Days" htmlFor="price" color="secondary" />
            {edit ? (
              <CustomTextField
                type="text"
                name="dispatchDays"
                value={dispatchDays}
                onChange={handleDispatchDaysChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">days</InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {product.dispatchDays}
              </Typography>
            )}
          </Grid>
        </Grid>
        {edit && (
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default ShippingConfigForm
