import { Box, Container } from '@mui/material'
import { styled } from '@mui/material/styles'

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
}))
const Demo = () => {
  return (
    <Container className="mt-4" sx={{ width: { sm: '90%', md: '60%' } }}>
      <ImageContainer>
        <video
          src={
            process.env.REACT_APP_ASSETS_URL +
            '/images/background/coverVideo.mp4'
          }
          className="h-auto rounded-lg"
          style={{ width: '100%' }}
          autoPlay
          loop
          muted
          playsInline
        />
      </ImageContainer>
    </Container>
  )
}

export default Demo
