import { FC, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import Cropper, { Area } from 'react-easy-crop'

import getCroppedImg from 'utils/cropImage'

interface MultiImageCropperProps {
  files: File[]
  onCropComplete: (cropfiles: File[]) => void
  onClose?: () => void
  aspect?: number
}

const MultiImageCropper: FC<MultiImageCropperProps> = ({
  files,
  onCropComplete,
  onClose,
  aspect,
}) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [croppedFiles, setCroppedFiles] = useState<File[]>([])
  const [blobUrl, setBlobUrl] = useState<string>()
  const [isCropping, setIsCropping] = useState(false)

  useEffect(() => {
    if (files[currentFileIndex]) {
      const newBlobUrl = URL.createObjectURL(files[currentFileIndex])
      setBlobUrl(newBlobUrl)
      return (): void => {
        if (newBlobUrl) {
          URL.revokeObjectURL(newBlobUrl)
        }
      }
    }
  }, [currentFileIndex, files])

  const onCropCompleteHandler = (
    croppedArea: Area,
    croppedAreaPixels: Area
  ): void => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const handleCrop = async (): Promise<void> => {
    if (croppedAreaPixels && files[currentFileIndex] && blobUrl) {
      setIsCropping(true)

      try {
        const croppedImageBlob = await getCroppedImg(blobUrl, croppedAreaPixels)

        const croppedFile = new File(
          [croppedImageBlob],
          files[currentFileIndex].name,
          { type: files[currentFileIndex].type }
        )

        const updatedCroppedFiles = [...croppedFiles, croppedFile]

        if (currentFileIndex < files.length - 1) {
          setCroppedFiles(updatedCroppedFiles)
          setCurrentFileIndex((prevIndex) => prevIndex + 1)
        } else {
          onCropComplete(updatedCroppedFiles)
        }
      } catch (error) {
        console.error('Error cropping image:', error)
      } finally {
        setIsCropping(false)
      }
    }
  }

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogContent>
        <Typography variant="h6">
          Crop Image {currentFileIndex + 1} of {files.length}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 400,
            marginTop: 2,
          }}
        >
          <Cropper
            image={blobUrl}
            crop={crop}
            aspect={aspect ?? 3 / 4}
            onCropChange={setCrop}
            onCropComplete={onCropCompleteHandler}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {onClose && <Button onClick={onClose}>Cancel</Button>}
        <Button
          onClick={handleCrop}
          variant="contained"
          color="primary"
          disabled={isCropping}
        >
          Crop and Next
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default MultiImageCropper
