import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from 'react'

import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'

import Amplitude from 'lib/amplitude'
import {
  confirmForgotPassword,
  confirmRegistration,
  getUser,
  login,
  logout,
  resetPasswordChallenge,
} from 'services/auth'
import { GetAuthTokensFromGoogleResponse } from 'services/auth/index.types'
import { getOrganization } from 'services/organization'
import {
  AuthContextType,
  AuthStatus,
  ChallengeResponse,
  User,
} from 'types/auth'
import { Organization } from 'types/organization'
import {
  clearAuthCookies,
  getAuthCookie,
  getToken,
  setAuthCookies,
} from 'utils/authCookies'

/**
 * 1) Provide default context values.
 *    Notice `forgotPasswordConfirm` and other methods return the correct types.
 */
const defaultContext: AuthContextType = {
  isAuthenticated: false,
  accessToken: null,
  signIn: async () => ({ status: 'pending' }),
  signOut: async () => Promise.resolve(),
  confirmSignUp: async () => ({ status: 'pending' }),
  forgotPasswordConfirm: async () => ({ status: 'pending' }),
  user: null,
  organization: null,
  organizationId: null,
  changeOrganization: () => {},
  updateOrganizationData: () => {},
  refreshAuthState: () => {},
  newPasswordChallenge: async () => ({ status: 'pending' }),
  signInWithGoogle: async () => Promise.resolve(),
}

export const AuthContext = createContext<AuthContextType>(defaultContext)

/**
 * 2) Shape of the state inside AuthProvider
 */
interface AuthState {
  user: User | null
  organizationId: number | null
  organization: Organization | null
  authStatus: AuthStatus
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    organizationId: null,
    organization: null,
    authStatus: {
      isAuthenticated: getAuthCookie('AccessToken') != null,
      accessToken: getAuthCookie('AccessToken'),
    },
  })

  const hasFetchedUser = useRef(false)
  const navigate = useNavigate()

  useEffect(() => {
    const initializeAuth = async (): Promise<void> => {
      try {
        // If user data is already fetched, skip
        if (hasFetchedUser.current) return

        // Check for existing token (if any)
        const token = await getToken('AccessToken')
        if (token) {
          // Update state to reflect authenticated status
          setAuthState((prevState) => ({
            ...prevState,
            authStatus: { isAuthenticated: true, accessToken: token },
          }))

          // Fetch user data
          const userData = await getUser()
          if (userData) {
            setAuthState((prevState) => ({
              ...prevState,
              user: userData,
            }))

            // If the user has organizations, set default
            if (userData.organizations?.length > 0) {
              const defaultOrg = localStorage.getItem('defaultOrg')
              const orgId =
                defaultOrg && defaultOrg !== ''
                  ? Number(defaultOrg)
                  : userData.organizations[0].organizationId

              setAuthState((prevState) => ({
                ...prevState,
                organizationId: orgId,
              }))

              localStorage.setItem('defaultOrg', orgId.toString())
            }
          }
          hasFetchedUser.current = true
        }
      } catch (error) {
        console.error('Failed to initialize auth:', error)
      }
    }

    initializeAuth()
  }, [authState.authStatus.accessToken])

  /**
   * 3) Whenever organizationId changes, fetch the corresponding organization
   */
  useEffect(() => {
    const getOrg = async (organizationId: number): Promise<void> => {
      try {
        const result = await getOrganization(organizationId)
        // If we got data back, set it; otherwise store null
        if (result.status === 'successful') {
          setAuthState((prevState) => ({
            ...prevState,
            organization: result.data ?? null, // <= ensure no undefined
          }))
        }
      } catch (error) {
        console.error('Failed to get organization:', error)
      }
    }

    if (authState.organizationId) {
      getOrg(authState.organizationId)
    }
  }, [authState.organizationId])

  /**
   * 4) signIn
   */
  const signIn = async (
    email: string,
    password: string
  ): Promise<{
    status: string
    message?: string
    data?: ChallengeResponse
  }> => {
    try {
      const result = await login({ email, password })
      if (result.status === 'successful' && result.data.AccessToken) {
        const authData = result.data
        setAuthCookies(authData)
        setAuthState((prevState) => ({
          ...prevState,
          authStatus: {
            isAuthenticated: true,
            accessToken: authData.AccessToken,
          },
        }))
        Amplitude.setUserId('user_' + result.data.userId.toString())
        return { status: 'successful' }
      } else if (
        result.status === 'successful' &&
        result.data.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        return { status: 'successful', data: result.data }
      } else {
        // Optionally handle sign-in failure scenario
        console.error('Sign in failed: ', result)
        return { status: 'failed' }
      }
    } catch (error) {
      console.error('Failed to sign in:', error)
      throw error // re-throw if you want to handle in the component
    }
  }

  /**
   * 5) confirmSignUp
   */
  const confirmSignUp = async (
    email: string,
    password: string,
    confirmationCode: string
  ): Promise<{ status: string; message?: string }> => {
    try {
      const result = await confirmRegistration({
        email,
        password,
        confirmationCode,
      })
      if (result.status === 'successful') {
        const authData = result.data
        setAuthCookies(authData)
        setAuthState((prevState) => ({
          ...prevState,
          authStatus: {
            isAuthenticated: true,
            accessToken: authData.AccessToken,
          },
        }))
        Amplitude.setUserId('user_' + result.data.userId.toString())
        return { status: 'successful' }
      }
      return { status: 'failed', message: result.message }
    } catch (error) {
      console.error('Failed to confirm sign-up:', error)
      return { status: 'failed', message: 'Unexpected error occurred' }
    }
  }

  /**
   * 6) forgotPasswordConfirm
   */
  const forgotPasswordConfirm = async (
    email: string,
    password: string,
    confirmationCode: string
  ): Promise<{ status: string; message?: string }> => {
    try {
      const result = await confirmForgotPassword({
        email,
        password,
        confirmationCode,
      })
      if (result.status === 'successful') {
        const authData = result.data
        setAuthCookies(authData)
        setAuthState((prevState) => ({
          ...prevState,
          authStatus: {
            isAuthenticated: true,
            accessToken: authData.AccessToken,
          },
        }))
        Amplitude.setUserId('user_' + result.data.userId.toString())
        return { status: 'successful' }
      }
      return { status: 'failed', message: result.message }
    } catch (error) {
      console.error('Failed to confirm forgot password:', error)
      return { status: 'failed', message: 'Unexpected error occurred' }
    }
  }

  /**
   * 7) signOut
   */
  const signOut = async (): Promise<void> => {
    const token = authState.authStatus.accessToken ?? ''
    const isGoogleUser = isGoogleSignIn(token)
    clearAuthCookies()
    localStorage.removeItem('defaultOrg')
    setAuthState({
      user: null,
      organizationId: null,
      organization: null,
      authStatus: { isAuthenticated: false, accessToken: null },
    })
    try {
      if (isGoogleUser) {
        const logoutUrl = `https://${process.env.REACT_APP_COGNITO_APP_CLIENT_DOMAIN}/logout?client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&logout_uri=${process.env.REACT_APP_LOGOUT_URI}&response_type=code&redirect_uri=${process.env.REACT_APP_LOGOUT_URI}`
        window.location.href = logoutUrl
      } else {
        const result = await logout(token)
        if (result.status === 'successful') {
          navigate('/')
        } else {
          console.error('Sign out failed: ', result)
          navigate('/')
        }
      }
    } catch (error) {
      console.error('Failed to sign out:', error)
    }
  }

  /**
   * 8) changeOrganization
   */
  const changeOrganization = (id: number | null): void => {
    setAuthState((prevState) => ({
      ...prevState,
      organizationId: id,
      // We clear out the old organization when changing
      organization: null,
    }))
    if (id) {
      localStorage.setItem('defaultOrg', id.toString())
    } else {
      localStorage.removeItem('defaultOrg')
    }
  }

  /**
   * 9) updateOrganizationData
   */
  const updateOrganizationData = (org: Organization): void => {
    setAuthState((prevState) => ({
      ...prevState,
      organization: org,
    }))
  }

  /**
   * 10) refreshAuthState
   * - Lets you re-fetch user data if needed (e.g., after signIn).
   */
  const refreshAuthState = (): void => {
    hasFetchedUser.current = false
    localStorage.removeItem('defaultOrg')
    setAuthState((prevState) => ({
      ...prevState,
      user: null,
      organizationId: null,
      organization: null,
    }))
  }

  const newPasswordChallenge = async (
    email: string,
    password: string,
    session: string
  ): Promise<{ status: string; message?: string }> => {
    try {
      const result = await resetPasswordChallenge(email, password, session)
      if (result.status === 'successful') {
        const authData = result.data
        setAuthCookies(authData)
        setAuthState((prevState) => ({
          ...prevState,
          authStatus: {
            isAuthenticated: true,
            accessToken: authData.AccessToken,
          },
        }))
        Amplitude.setUserId('user_' + result.data.userId.toString())
        return { status: 'successful' }
      }
      return { status: 'failed', message: result.message }
    } catch (error) {
      console.error('Failed to confirm sign-up:', error)
      return { status: 'failed', message: 'Unexpected error occurred' }
    }
  }

  const signInWithGoogle = async (
    googleData: GetAuthTokensFromGoogleResponse
  ): Promise<void> => {
    // Store tokens in localStorage (or use your existing cookie utils)
    localStorage.setItem('id_token', googleData.id_token)
    localStorage.setItem('access_token', googleData.access_token)
    localStorage.setItem('refresh_token', googleData.refresh_token)
    localStorage.setItem('expires_in', googleData.expires_in.toString())
    localStorage.setItem('token_type', googleData.token_type)

    // Optionally, use your authCookies util to set tokens as cookies
    setAuthCookies({
      AccessToken: googleData.access_token,
      RefreshToken: googleData.refresh_token,
      IdToken: googleData.id_token,
      ExpiresIn: googleData.expires_in,
      TokenType: googleData.token_type,
    })

    // Update the auth state to mark the user as authenticated
    setAuthState((prevState) => ({
      ...prevState,
      authStatus: {
        isAuthenticated: true,
        accessToken: googleData.access_token,
      },
    }))
  }

  const isGoogleSignIn = (token: string): boolean => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decoded = jwtDecode<any>(token)
      if (decoded?.username?.startsWith('google_')) {
        return true
      }
      return false
    } catch (error) {
      // If decoding fails, assume it's not Google
      return false
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authState.authStatus.isAuthenticated,
        accessToken: authState.authStatus.accessToken,
        signIn,
        signOut,
        confirmSignUp,
        forgotPasswordConfirm,
        user: authState.user,
        organization: authState.organization,
        organizationId: authState.organizationId,
        changeOrganization,
        updateOrganizationData,
        refreshAuthState,
        newPasswordChallenge,
        signInWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
