import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  ReactNode,
} from 'react'

import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from '@mui/material'

interface CustomTextFieldProps {
  id?: string
  value?: string
  name?: string
  placeholder?: string
  type?: HTMLInputTypeAttribute | undefined
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  error?: boolean
  helperText?: ReactNode
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined
  min?: number
  max?: number
  disabled?: boolean
  step?: number
}

const CustomTextField: FC<CustomTextFieldProps> = ({
  id,
  value,
  name,
  placeholder,
  type,
  onChange,
  onBlur,
  error,
  helperText,
  InputProps,
  min,
  max,
  disabled,
  step,
}) => {
  return (
    <TextField
      size="small"
      fullWidth
      inputProps={{
        style: { backgroundColor: 'white' },
        min: min,
        max: max,
        step: step,
      }}
      sx={{
        marginTop: 0,
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
        },
      }}
      variant="outlined"
      margin="normal"
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={InputProps}
      disabled={disabled}
      onWheel={(e) => (e.target as HTMLInputElement).blur()}
    />
  )
}

export default CustomTextField
