import { FC, useState } from 'react'

import { Typography, FormControlLabel, Switch } from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'

interface ManageStatusDetailProps {
  product: Product
  onUpdate: (product: Product) => void
}

const ManageStatusDetail: FC<ManageStatusDetailProps> = ({
  product,
  onUpdate,
}) => {
  const [isListed, setIsListed] = useState(product.isListed)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const handleSave = async (): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_MANAGE_STATUS_CHANGE_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }

    setIsSaving(true)

    const result = await updateProduct({
      productId: product.id,
      dataUpdate: { isListed: !isListed },
    })
    if (result.status === 'successful' && result.data) {
      setIsListed(!isListed)
      onUpdate?.(result.data)
      showSnackbar('Product status updated.', 'success')
    } else {
      showSnackbar('Failed to update product status.', 'error')
    }
    setIsSaving(false)
  }

  return (
    <div className="mb-5">
      <div className="mx-5">
        <SubTabHeader title="Manage Status" />
        <Typography variant="caption" color="textSecondary" gutterBottom>
          Turn this on to make the product visible and searchable on the website
          upon creation.
        </Typography>
      </div>
      <Card>
        <FormControlLabel
          control={
            <Switch
              checked={isListed}
              onChange={handleSave}
              disabled={isSaving}
            />
          }
          label="List Product on Website"
        />
      </Card>
    </div>
  )
}

export default ManageStatusDetail
