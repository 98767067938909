import { User } from 'types/auth'
import { getToken } from 'utils/authCookies'

import {
  LoginProps,
  ConfirmRegistrationProps,
  RegisterProps,
  ResendCodeProps,
  ForgotPasswordProps,
  ConfirmForgotPasswordProps,
  RequestAccessParams,
  ChangePasswordParams,
  contactUsFormAccessParams,
  JoinWaitlistParams,
  GetAuthTokensFromGoogleResponse,
} from './index.types'

const userBasePath = `${process.env.REACT_APP_API_BASE_PATH}/user`
const adminUserBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/user`

const login = async ({ email, password }: LoginProps) => {
  try {
    const response = await fetch(`${userBasePath}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })

    const { data, message } = await response.json()

    if (response.ok && data.AccessToken) {
      return { status: 'successful', data }
    }

    if (response.ok && data.session) {
      return { status: 'successful', data }
    }

    throw new Error(message || 'Login failed')
  } catch (error) {
    console.error('Error logging in:', error)
    throw error
  }
}

const register = async ({
  firstName,
  lastName,
  email,
  password,
}: RegisterProps) => {
  try {
    const response = await fetch(`${userBasePath}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ firstName, lastName, email, password }),
    })

    const { message, field } = await response.json()
    if (response.ok) return { status: 'successful' }
    return { status: 'failed', error: message, field }
  } catch (error) {
    console.error('Error registering: ', error)
    throw error
  }
}

const confirmRegistration = async ({
  email,
  password,
  confirmationCode,
}: ConfirmRegistrationProps) => {
  try {
    const response = await fetch(`${userBasePath}/confirm-registration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, confirmationCode }),
    })

    const { data, message } = await response.json()
    if (response.ok) return { status: 'successful', data }
    else return { status: 'failed', message }

    throw new Error(message || 'Sign up failed')
  } catch (error) {
    console.error('Error confirming registration:', error)
    throw error
  }
}

const resendCode = async ({ email }: ResendCodeProps) => {
  try {
    const response = await fetch(`${userBasePath}/resend-confirmation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    const { data } = await response.json()
    return data
  } catch (error) {
    console.error('Error resending code:', error)
    throw error
  }
}

const forgotPassword = async ({ email }: ForgotPasswordProps) => {
  try {
    const response = await fetch(`${userBasePath}/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    if (response.ok) {
      return { status: 'successful' }
    } else {
      const errorData = await response.json()
      throw new Error(
        errorData.message || 'Failed to send forgot password request'
      )
    }
  } catch (error) {
    console.error('Error sending forgot password request:', error)
    throw error
  }
}

const confirmForgotPassword = async ({
  email,
  confirmationCode,
  password,
}: ConfirmForgotPasswordProps) => {
  try {
    const response = await fetch(`${userBasePath}/confirm-forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, confirmationCode, password }),
    })

    const { data } = await response.json()
    if (response.ok) {
      return { status: 'successful', data: data.data }
    } else {
      const errorData = await response.json()
      return { status: 'failed', message: errorData.message }
    }
  } catch (error) {
    console.error('Error confirming forgot password request:', error)
    throw error
  }
}

const logout = async (token: string) => {
  try {
    const response = await fetch(`${userBasePath}/sign-out`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    })
    if (response.ok) {
      return { status: 'successful' }
    } else {
      const errorData = await response.json()
      throw new Error(errorData.message || 'Failed to sign out')
    }
  } catch (error) {
    console.error('Error signing out', error)
    throw error
  }
}

const sendRefreshToken = async (token: string) => {
  try {
    const response = await fetch(`${userBasePath}/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
    if (response.ok) {
      const { data } = await response.json()
      return data
    }
  } catch (error) {
    console.error('Error refresh token', error)
    throw error
  }
}

const getUser = async (): Promise<User | undefined> => {
  const token = await getToken()

  try {
    const res = await fetch(`${adminUserBasePath}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    const { data } = await res.json()
    if (data) return data
  } catch (error) {
    console.error(error)
  }
}

const earlyRequestAccess = async (values: RequestAccessParams) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_PATH}/enquiries/request-access`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }
    )

    if (res.ok) return { status: 'success' }
  } catch (error) {
    console.error(error)
  }
}

const contactUsFormAccess = async (values: contactUsFormAccessParams) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_PATH}/contacts/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }
    )

    if (res.ok) return { status: 'success' }
  } catch (error) {
    console.error(error)
  }
}

const changePassword = async (values: ChangePasswordParams) => {
  const token = await getToken()
  const accessToken = await getToken('AccessToken')

  try {
    const response = await fetch(`${adminUserBasePath}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify({ ...values, accessToken }),
    })
    if (response.ok) {
      return { status: 'success' }
    }
  } catch (error) {
    console.error(error)
  }
}

const joinWaitlist = async (values: JoinWaitlistParams) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_PATH}/enquiries/join-waitlist`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }
    )

    if (res.ok) return { status: 'success' }
  } catch (error) {
    console.error(error)
  }
}

const resetPasswordChallenge = async (
  email: string,
  password: string,
  session: string
) => {
  try {
    const response = await fetch(`${userBasePath}/reset-password-challenge`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, session }),
    })

    const { data, message } = await response.json()

    if (response.ok && data.AccessToken) {
      return { status: 'successful', data }
    } else {
      return { status: 'failed', message: message ?? 'Please try again later' }
    }
  } catch (error) {
    console.error('Error logging in:', error)
    throw error
  }
}

const updateUser = async ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName?: string
}) => {
  const token = await getToken()

  try {
    const response = await fetch(`${adminUserBasePath}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ firstName, lastName }),
    })

    const { message, data } = await response.json()

    if (response.ok) {
      return { status: 'successful', data }
    } else {
      return { status: 'failed', message: message || 'Failed to update name' }
    }
  } catch (error) {
    console.error('Error updating user name:', error)
    throw error
  }
}

const getAuthTokensFromGoogle = async (
  code: string
): Promise<
  { status: string; data: GetAuthTokensFromGoogleResponse } | undefined
> => {
  try {
    const res = await fetch(`${userBasePath}/google/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: code }),
    })

    const { data } = await res.json()

    if (res.ok) return { status: 'successful', data }
  } catch (error) {
    console.error(error)
  }
}

export {
  register,
  login,
  confirmRegistration,
  forgotPassword,
  resendCode,
  confirmForgotPassword,
  logout,
  sendRefreshToken,
  getUser,
  earlyRequestAccess,
  changePassword,
  contactUsFormAccess,
  joinWaitlist,
  resetPasswordChallenge,
  updateUser,
  getAuthTokensFromGoogle,
}
