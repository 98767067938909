import { useState, useEffect, FC, JSX } from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { scaleLinear } from 'd3-scale'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'

import { countryCodeToName } from '../data/countryCode'
import geoData from '../data/world.json'

type HeatmapData = {
  [key: string]: number
}

interface MapChartProps {
  data: HeatmapData
}

const MapChart: FC<MapChartProps> = ({ data }): JSX.Element => {
  const [zoom, setZoom] = useState(1)
  const [translate, setTranslate] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(
    null
  )
  const [countryData, setCountryData] = useState<HeatmapData>({})
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null)
  const [hoveredValue, setHoveredValue] = useState<number | null>(null)
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })

  useEffect(() => {
    const convertedData = Object.keys(data).reduce((acc, code) => {
      const countryName = countryCodeToName[code]
      if (countryName) {
        acc[countryName] = data[code]
      }
      return acc
    }, {} as HeatmapData)
    setCountryData(convertedData)
  }, [data])

  useEffect(() => {
    const centerX = 0
    const centerY = 0
    setTranslate({ x: centerX, y: centerY })
  }, [])

  const handleZoomIn = (): void => {
    setZoom((prevZoom) => Math.min(prevZoom * 1.5, 10))
  }

  const handleZoomOut = (): void => {
    setZoom((prevZoom) => Math.max(prevZoom / 1.5, 1))
  }

  const handleMouseDown = (event: React.MouseEvent): void => {
    if (zoom !== 1) {
      setIsDragging(true)
      setDragStart({ x: event.clientX, y: event.clientY })
    }
  }

  const handleMouseMove = (event: React.MouseEvent): void => {
    if (isDragging && dragStart) {
      const dx = event.clientX - dragStart.x
      const dy = event.clientY - dragStart.y
      setTranslate((prev) => ({ x: prev.x + dx, y: prev.y + dy }))
      setDragStart({ x: event.clientX, y: event.clientY })
    }
  }

  const handleMouseUp = (): void => {
    setIsDragging(false)
    setDragStart(null)
  }

  const getColorScale = scaleLinear<string>()
    .domain([0, 1, Math.max(...Object.values(countryData))])
    .range(['#D6D6DA', '#81C784', '#00897B'])

  const getColor = (value?: number): string => {
    return value ? getColorScale(value) : '#D6D6DA'
  }

  const handleMouseMoveForTooltip = (event: React.MouseEvent): void => {
    setMousePosition({ x: event.clientX, y: event.clientY })
  }

  return (
    <Box
      position="relative"
      sx={{ overflow: 'hidden' }}
      onMouseMove={handleMouseMoveForTooltip}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 10 }}>
        <IconButton onClick={handleZoomIn} color="primary">
          <AddIcon />
        </IconButton>
        <IconButton onClick={handleZoomOut} color="primary">
          <RemoveIcon />
        </IconButton>
      </Box>
      <Box
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        sx={{
          transform: `scale(${zoom}) translate(${translate.x}px, ${translate.y}px)`,
          transformOrigin: 'center',
          transition: isDragging ? 'none' : 'transform 0.3s ease-in-out',
          cursor: zoom !== 1 ? (isDragging ? 'grabbing' : 'grab') : 'default',
        }}
      >
        <ComposableMap
          projectionConfig={{
            scale: 140,
          }}
          width={800}
          height={350}
          style={{ width: '100%', height: 'auto' }}
        >
          <Geographies geography={geoData}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const countryName = geo.properties.name
                const value = countryData[countryName]

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={getColor(value)}
                    stroke="#FFFFFF"
                    style={{
                      hover: {
                        cursor: 'pointer',
                      },
                    }}
                    onMouseEnter={() => {
                      setHoveredCountry(countryName)
                      setHoveredValue(value || 0)
                    }}
                    onMouseLeave={() => {
                      setHoveredCountry(null)
                      setHoveredValue(null)
                    }}
                  />
                )
              })
            }
          </Geographies>
        </ComposableMap>
      </Box>

      {hoveredCountry && hoveredValue !== null && (
        <Box
          sx={{
            position: 'fixed',
            left: mousePosition.x + 10,
            top: mousePosition.y + 10,
            backgroundColor: '#fff',
            padding: '5px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 1000,
          }}
        >
          <Typography variant="caption">
            {hoveredCountry}: {hoveredValue}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default MapChart
