import { FC, ReactNode } from 'react'

import { Box, Card, styled } from '@mui/material'

interface OnboardingContainerProps {
  title: string
  description: string
  children: ReactNode
}

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background:
    'radial-gradient(circle, rgba(0,137,123,1) 0%, rgba(0,0,0,1) 60%)',
})

const OnboardingContainer: FC<OnboardingContainerProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <Container>
      <Card className="mx-4 p-4 px-6 lg:mx-0 lg:p-8 md:w-1/2 lg:w-1/3">
        <img
          src={
            process.env.REACT_APP_ASSETS_URL +
            '/images/logos/logoWithBrandName.png'
          }
          className="mb-5"
          alt="Haulistic"
          width="100"
        />
        <Box>
          <p className="text-xl md:text-2xl font-bold mb-2">{title}</p>
          <p className="text-xs md:text-sm text-gray-500">{description}</p>
          {children}
        </Box>
      </Card>
    </Container>
  )
}

export default OnboardingContainer
