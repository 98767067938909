import { Template } from 'types/template'
import { getToken } from 'utils/authCookies'

const templateBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/email`

interface SaveTemplateParams {
  content: string
  templateName: string
  orgId: number
}

export const getAllTemplates = async (
  orgId: number
): Promise<Template[] | undefined> => {
  const token = await getToken()

  try {
    const res = await fetch(`${templateBasePath}?orgId=${orgId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    if (res.ok) {
      const { data } = await res.json()
      return data
    }
  } catch (err) {
    console.error(err)
  }
}

export const getTemplateContent = async (template: Template) => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${templateBasePath}/${template.id}?isDefault=${template.isDefault}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      }
    )
    if (res.ok) {
      const { data } = await res.json()
      return data
    } else {
      throw new Error('Failed to fetch template content')
    }
  } catch (err) {
    throw new Error('Failed to fetch template content')
  }
}

export const saveTemplateContent = async ({
  content,
  templateName,
  orgId,
}: SaveTemplateParams) => {
  const token = await getToken()
  try {
    const res = await fetch(`${templateBasePath}/?orgId=${orgId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ templateContent: content, templateName }),
    })
    if (res.ok) {
      const { data } = await res.json()
      return data
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteTemplateById = async (templateId: number) => {
  const token = await getToken()
  try {
    const res = await fetch(`${templateBasePath}/${templateId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    if (res.ok) {
      await res.json()
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateTemplate = async (
  id: string,
  templateContent: string,
  templateName: string
) => {
  const token = await getToken()
  try {
    const res = await fetch(`${templateBasePath}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ templateContent, templateName }),
    })
    if (res.ok) {
      const { data } = await res.json()
      return data
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
