import { FC } from 'react'

import { Link } from '@mui/material'

interface WhatsAppButtonProps {
  phoneNumber: string
  message: string
  buttonText: string
}
const WhatsAppButton: FC<WhatsAppButtonProps> = ({
  phoneNumber,
  message,
  buttonText,
}) => {
  const openWhatsApp = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Link
      onClick={openWhatsApp}
      sx={{ textTransform: 'none', cursor: 'pointer' }}
    >
      {buttonText}
    </Link>
  )
}

export default WhatsAppButton
