import { FC } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

interface ConfirmationDialogProps {
  title: string
  content: string
  open: boolean
  onClose: () => void
  onConfirm: () => void
  confirmButtonText?: string
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  open,
  content,
  onClose,
  onConfirm,
  confirmButtonText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <p>{content}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ textTransform: 'none' }} size="small">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
          disableElevation
          sx={{ textTransform: 'none' }}
          variant="contained"
          size="small"
        >
          {confirmButtonText ?? 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
