/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material'
import { SiRazorpay, SiPhonepe } from 'react-icons/si'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

import PhonePeSetup from './components/phonepe/PhonePeSetup'

const PaymentSettings = () => {
  const { organization } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const isConnected = searchParams.get('status') === 'true'

  const handleSetupPhonePe = () => {
    navigate('phonepe')
  }

  // return (
  //   <div className="flex">
  //     {/* {organization?.defaultPaymentMethod === 'PHONEPE' && (
  //       <PhonePeSetup paymentSetupStatus={organization?.paymentSetupStatus} />
  //     )} */}

  //   </div>
  // )
  return (
    <Box>
      <div className="mb-4 mx-5">
        <p className="text-lg font-bold">Payments</p>
      </div>
      <Card variant="outlined" sx={{ m: 2, px: 2 }}>
        <CardContent>
          <Box display="flex" alignItems="center">
            <SiPhonepe size={30} />
            <div className="mx-4 grow">
              <p className="text-md">Phonepe</p>
              <Box className="text-xs font-bold">
                {organization?.paymentSetupStatus === 'COMPLETED' ||
                isConnected ? (
                  <Box component="span" sx={{ color: '#00897b' }}>
                    Connected Successfully
                  </Box>
                ) : (
                  <Box className="text-xs font-bold" sx={{ color: 'gray' }}>
                    Not connected
                  </Box>
                )}
              </Box>
            </div>
            {organization?.paymentSetupStatus !== 'COMPLETED' &&
              !isConnected && (
                <Box component="div" display="flex" gap={1} alignItems="center">
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ textTransform: 'none', fontWeight: 'bold' }}
                    onClick={handleSetupPhonePe}
                  >
                    Setup PhonePe
                  </Button>
                </Box>
              )}
            {/* <Box component="div" display="flex" gap={1} alignItems="center">
              <Checkbox
                size="small"
                // checked={checked}
                // onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p className="text-sm">Mark as default payment gateway</p>
            </Box> */}
          </Box>
        </CardContent>
      </Card>

      {/* <Card variant="outlined" sx={{ m: 2, px: 2 }}>
        <CardContent>
          <Box display="flex" alignItems="center">
            <SiRazorpay size={30} />
            <div className="mx-4 grow">
              <p className="text-md">Razorpay</p>
              <Box className="text-xs font-bold" sx={{ color: 'gray' }}>
                Not connected
              </Box>
            </div>
            <Box component="div" display="flex" gap={1} alignItems="center">
              <Button
                size="small"
                variant="outlined"
                sx={{ textTransform: 'none', fontWeight: 'bold' }}
              >
                Setup Razorpay
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card> */}
    </Box>
  )
}

export default PaymentSettings
