import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as z from 'zod'

import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import theme from 'styles/muiTheme'

import { register } from '../../services/auth'

const FormSchema = z.object({
  firstName: z.string().min(1, 'First name is required').max(100),
  lastName: z.string().max(100).optional(),
  email: z.string().min(1, 'Email is required').email('Invalid email'),
  password: z
    .string()
    .min(8, 'Password must be 8+ characters')
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$*_^&])[A-Za-z\d@#$*_^&]{8,}$/,
      'Password must be 8+ characters with at least one uppercase, one lowercase, one digit, and one special character (@, #, $, *, ^, &)'
    ),
})

interface FormValues {
  firstName: string
  lastName: string
  email: string
  password: string
}

const SignUpForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const userEmail = queryParams.get('userEmail')

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: userEmail ?? '',
      password: '',
    },
  })

  const [showPassword, setShowPassword] = useState(false)
  const { showSnackbar } = useSnackbar()

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const result = await register(values)
    if (values) {
      Amplitude.trackEvent('CREATE_ACCOUNT_REGISTER_CLICKED', {
        userEmail: values.email,
      })
    }

    if (result.status === 'successful') {
      showSnackbar('Please verify your email.', 'info')
      navigate('/verify-email', {
        state: { email: values.email, password: values.password },
      })
    } else if (result.status === 'failed' && result.field && result.error) {
      result.field === 'UsernameExistsException'
        ? showSnackbar(result.error, 'error')
        : showSnackbar('Please try again later.', 'error')
    }
  }

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <div className="space-y-4 mt-10">
          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="text-base font-medium text-gray-900"
            >
              Email Address
            </label>
            <div className="mt-1">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    value={field.value}
                    onChange={field.onChange}
                    className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type="email"
                    placeholder="email@example.com"
                    id="email"
                    autoComplete="email"
                  />
                )}
              />
            </div>
            {errors.email && (
              <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                *{errors.email?.message}
              </p>
            )}
          </div>
          {/* Password */}
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="text-base font-medium text-gray-900"
              >
                Password
              </label>
              <IconButton
                sx={{ color: theme.palette.text.primary, padding: 0 }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
            <div className=" mt-1">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <input
                    value={field.value}
                    onChange={field.onChange}
                    className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    id="password"
                    autoComplete="new-password"
                  />
                )}
              />
              {errors.password && (
                <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                  *{errors.password?.message}
                </p>
              )}
            </div>
          </div>

          {/* Full Name */}
          <div>
            <label
              htmlFor="firstName"
              className="text-base font-medium text-gray-900"
            >
              Your Name
            </label>
            <div className="mt-1">
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <input
                    value={field.value}
                    onChange={field.onChange}
                    className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type="text"
                    placeholder="Your Name"
                    id="firstName"
                    autoComplete="firstName"
                  />
                )}
              />
              {errors.firstName && (
                <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                  *{errors.firstName?.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              sx={{ mt: 5, textTransform: 'none' }}
            >
              Create Haulistic account
            </Button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default SignUpForm
