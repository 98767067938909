import { JSX, useState } from 'react'

import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
import DOMPurify from 'dompurify'
import { useParams } from 'react-router-dom'

import Card, { SubCard } from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import EditButton from 'pages/dashboard/components/EditButton'
// import TagInput from 'pages/dashboard/components/TagInput'
import { updateCustomer } from 'services/customers'
import {
  Customer,
  UpdateCustomerDetailsParams,
} from 'services/customers/index.type'

import AddressDialog from './AddressDialog'
import CheckoutList from './CheckoutList'
import ContactInformation from './ContactInformation'
import OrdersList from './OrdersDetail'

interface CustomerDetailsProps {
  data: Customer
  onUpdate?: () => void
  setData: (data: Customer) => void
}

const CustomerDetails = ({
  data,
  setData,
  onUpdate,
}: CustomerDetailsProps): JSX.Element => {
  const { customerId } = useParams()
  const { showSnackbar } = useSnackbar()
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  // const [isEditingTags, setIsEditingTags] = useState(false)
  // const [tags, setTags] = useState<number[]>(data.tagIds)
  // const [tagNames, setTagNames] = useState<string[]>([])
  const [notes, setNotes] = useState<string | undefined>(data.notes)
  const [isNotesLimitExceeded, setIsNotesLimitExceeded] = useState(false)
  const { organizationId } = useAuth()
  const [isDialogOpen, setIsDialogOpen] = useState({
    openEmpty: false,
    openFill: false,
  })
  const memberSince = format(new Date(data.createdAt), 'dd MMM yyyy')

  const handleOpen = (dialog: string): void => {
    if (organizationId && customerId) {
      Amplitude.trackEvent('CUSTOMER_ADDADDRESS_CLICKED', {
        orgId: organizationId,
        customerId: customerId,
      })
    }
    setIsDialogOpen((prevState) => ({ ...prevState, [dialog]: true }))
  }

  const handleClose = (dialog: string): void => {
    if (organizationId && customerId) {
      Amplitude.trackEvent('CUSTOMER_ADDADDRESS_CANCEL_CLICKED', {
        orgId: organizationId,
        customerId: customerId,
      })
    }
    setIsDialogOpen((prevState) => ({ ...prevState, [dialog]: false }))
  }

  // useEffect(() => {
  //   const get = async (orgId: number, tagIds: number[]): Promise<void> => {
  //     const result = await getTags('customer', orgId)
  //     if (result && result.status === 'successful' && result.data) {
  //       const data = result.data.filter((el: Tag) => tagIds.includes(el.id))
  //       const namesList = data.map((el: Tag) => el.name)
  //       // setTagNames(namesList)
  //     }
  //   }
  //   if (organizationId && Array.isArray(data.tagIds) && data.tagIds.length > 0)
  //     get(organizationId, data.tagIds)
  // }, [organizationId, data.tagIds])

  const handleSave = async (title: string): Promise<void> => {
    // Ensure customerId is defined and convert it to a number
    if (customerId) {
      const updatedData: UpdateCustomerDetailsParams = {
        notes: notes ? notes.trim() : undefined,
      }
      const result = organizationId
        ? await updateCustomer(Number(customerId), organizationId, updatedData)
        : { status: 'failed', message: 'Organization not found' }
      if (result?.status === 'successful' && result.data) {
        setData(result.data)
        showSnackbar(`${title} updated successfully.`, 'success')
      } else if (result?.status === 'failed') {
        // Handle the error appropriately
        result.message
          ? showSnackbar(result.message, 'error')
          : showSnackbar('Please try again later.', 'error')
      }
    }
  }

  // const handleTagsChange = (newTags: number[]): void => {
  //   setTags(newTags)
  // }

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    if (input.length > 1000) {
      setNotes(input.substring(0, 1000))
      setIsNotesLimitExceeded(true)
    } else {
      setNotes(input)
      setIsNotesLimitExceeded(false)
    }
  }

  const handleNotesEdit = (): void => {
    if (organizationId && customerId) {
      Amplitude.trackEvent('CUSTOMER_NOTES_EDIT_CLICKED', {
        orgId: organizationId,
        customerId: customerId,
      })
    }
    setIsEditingNotes(true)
  }

  const handleSaveNotes = async (): Promise<void> => {
    if (organizationId && customerId) {
      Amplitude.trackEvent('CUSTOMER_NOTES_SAVE_CLICKED', {
        orgId: organizationId,
        customerId: customerId,
      })
    }
    await handleSave('Notes')
    setIsEditingNotes(false)
  }

  const handleNotesCancel = (): void => {
    if (organizationId && customerId) {
      Amplitude.trackEvent('CUSTOMER_NOTES_CANCEL_CLICKED', {
        orgId: organizationId,
        customerId: customerId,
      })
    }
    setNotes(data.notes)
    setIsEditingNotes(false)
    setIsNotesLimitExceeded(false)
  }

  // const handleTagsEdit = (): void => {
  //   if (organizationId && customerId) {
  //     Amplitude.trackEvent('CUSTOMER_TAGS_EDIT_CLICKED', {
  //       orgId: organizationId,
  //       customerId: customerId,
  //     })
  //   }
  //   setIsEditingTags(true)
  // }

  // const handleTagsSave = async (): Promise<void> => {
  //   if (organizationId && customerId) {
  //     Amplitude.trackEvent('CUSTOMER_TAGS_SAVE_CLICKED', {
  //       orgId: organizationId,
  //       customerId: customerId,
  //     })
  //   }
  //   await handleSave('Tags')
  //   setIsEditingTags(false)
  // }

  // const handleTagsCancel = (): void => {
  //   if (organizationId && customerId) {
  //     Amplitude.trackEvent('CUSTOMER_TAGS_CANCEL_CLICKED', {
  //       orgId: organizationId,
  //       customerId: customerId,
  //     })
  //   }
  //   setIsEditingTags(false)
  // }

  return (
    <div className="my-5">
      <Card>
        <div className="flex flex-col">
          <Box className=" mb-5">
            <Typography sx={{ fontWeight: '700' }}>
              {data.firstName} {data.lastName}{' '}
              <span className="ml-1">({data.orgSeqNo})</span>
            </Typography>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Member since {memberSince}
            </Typography>
          </Box>
          <Box
            className="flex-1 overflow-y-auto"
            sx={{ scrollbarWidth: 'none' }}
          >
            <Box className=" mb-4">
              <Typography sx={{ fontWeight: '700' }}>Orders</Typography>

              {data.order && data.order.length === 0 && (
                <SubCard>
                  <Box textAlign={'center'}>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        color: 'text.secondary',
                        fontWeight: '600',
                      }}
                    >
                      This customer hasn&apos;t placed any order yet
                    </Typography>
                  </Box>
                </SubCard>
              )}
              {data.order && data.order.length > 0 && (
                <div className=" my-2">
                  <OrdersList list={data.order} />
                </div>
              )}
            </Box>
            {data.CheckoutSessions && data.CheckoutSessions.length > 0 && (
              <Box className=" mb-4">
                <Typography sx={{ fontWeight: '700' }}>
                  Checkout Sessions
                </Typography>
                <div className=" my-2">
                  <CheckoutList list={data.CheckoutSessions} />
                </div>
              </Box>
            )}
            <ContactInformation
              setData={setData}
              customerId={data.orgSeqNo}
              firstName={data.firstName}
              lastName={data.lastName}
              email={data.email}
              phoneNumber={data.phoneNumber}
              instagramId={data.instagramId}
            />
            <Box className="mb-4">
              <Box className=" flex gap-3 items-center justify-between">
                {' '}
                <Typography sx={{ fontWeight: '700' }}>Address</Typography>
                {data.billingAddress && (
                  <EditButton
                    buttonText={'Edit'}
                    onClick={() => handleOpen('openFill')}
                  />
                )}
              </Box>

              {!data.billingAddress && (
                <Paper
                  className=" my-2 text-center p-5"
                  style={{
                    boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Button
                    onClick={() => handleOpen('openEmpty')}
                    variant="contained"
                    size="small"
                    sx={{ textTransform: 'none' }}
                  >
                    Add Address
                  </Button>
                  {isDialogOpen.openEmpty && (
                    <AddressDialog
                      onClose={() => handleClose('openEmpty')}
                      customerId={data.orgSeqNo}
                      onUpdate={onUpdate}
                    />
                  )}
                </Paper>
              )}
              {data.billingAddress && (
                <Paper
                  className="my-2 py-3 px-5 relative h-40"
                  style={{
                    boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {isDialogOpen.openFill && (
                    <AddressDialog
                      onClose={() => handleClose('openFill')}
                      address={data.billingAddress}
                      customerId={data.orgSeqNo}
                      onUpdate={onUpdate}
                    />
                  )}

                  <Typography sx={{ fontSize: '0.75rem', fontWeight: '600' }}>
                    {data.billingAddress.addressLine2}
                    <br />
                    {data.billingAddress.addressLine1}
                    <br />
                    {data.billingAddress.city !== data.billingAddress.state &&
                      `${data.billingAddress.city},`}{' '}
                    {data.billingAddress.state}
                    <br />
                    {data.billingAddress.country}
                    <br />
                    {data.billingAddress.pinCode}
                    <br />
                    {data.billingAddress.phoneNo}
                  </Typography>
                </Paper>
              )}
            </Box>
            <Box className=" mb-4">
              <Box className=" flex gap-3 items-center justify-between">
                <div>
                  <Typography sx={{ fontWeight: '700' }}>Notes</Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    Notes are private and won&apos;t be shared with the customer
                  </Typography>
                </div>

                {!isEditingNotes && (
                  <EditButton buttonText={'Edit'} onClick={handleNotesEdit} />
                )}
              </Box>
              <SubCard>
                {!isEditingNotes && (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data.notes || 'None'),
                    }}
                  />
                )}
                {isEditingNotes && (
                  <div>
                    <div>
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={6}
                        variant="outlined"
                        value={notes}
                        onChange={handleNotesChange}
                        InputProps={{
                          style: {
                            fontSize: '14px',
                          },
                        }}
                        sx={{
                          marginTop: 0,
                          '& .MuiFormHelperText-root': {
                            marginLeft: 0,
                          },
                        }}
                        error={isNotesLimitExceeded}
                        helperText={
                          isNotesLimitExceeded
                            ? 'Maximum limit: 1000 characters.'
                            : `${notes?.length || 0}/1000 characters`
                        }
                      />
                    </div>
                    <div className="mt-4">
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ textTransform: 'none', mr: 2 }}
                        onClick={handleNotesCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ textTransform: 'none' }}
                        onClick={handleSaveNotes}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </SubCard>
            </Box>
            {/* Customer Tags  */}
            {/* <Box className=" mb-4">
              <Box className=" flex gap-3 items-center justify-between ">
                <Typography sx={{ fontWeight: '700' }}>Tags</Typography>
                {!isEditingTags && (
                  <EditButton buttonText={'Edit'} onClick={handleTagsEdit} />
                )}
              </Box>
              <SubCard>
                {isEditingTags ? (
                  <div>
                    <TagInput
                      entity="customer"
                      onChange={handleTagsChange}
                      defaultValues={tags}
                    />
                    <div className="mt-4">
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ textTransform: 'none', mr: 2 }}
                        onClick={handleTagsCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ textTransform: 'none' }}
                        onClick={handleTagsSave}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Box>
                    {tagNames.length > 0 ? (
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {tagNames.map((tag, index) => (
                          <Chip key={index} label={tag} variant="outlined" />
                        ))}
                      </Stack>
                    ) : (
                      <Typography>N/A</Typography>
                    )}
                  </Box>
                )}
              </SubCard>
            </Box> */}
          </Box>
        </div>
      </Card>
    </div>
  )
}

export default CustomerDetails
