import { FormEvent, JSX, useEffect, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button } from '@mui/material'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { createCustomer } from 'services/customers'
import { showCountries } from 'utils/countries'

import Address from './Address'
import CustomerForm from './CustomerForm'
import { AddressType } from '../../../components/AddressInput'
import {
  AddressFormSchema,
  CustomerFormSchema,
} from '../schema/CustomerEditFormSchema'

const CustomerEdit = (): JSX.Element => {
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<AddressType>()
  const methods = useForm({
    resolver: zodResolver(CustomerFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      countryCode: '',
      phoneNo: '',
      instagramIdLink: '',
    },
  })

  const addressFormMethods = useForm({
    resolver: zodResolver(AddressFormSchema),
    defaultValues: {
      country: '',
      firstName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      pinCode: '',
      countryCode: '',
      phoneNo: '',
      lastName: '',
    },
  })

  const countryCode = methods.watch('countryCode', '')

  const phoneNo = useWatch({
    control: methods.control,
    name: 'phoneNo',
    defaultValue: '',
  })

  const email = useWatch({
    control: methods.control,
    name: 'email',
  })

  useEffect(() => {
    methods.trigger('email')
  }, [email, methods])

  const onAddressSelect = (data: AddressType): void => {
    setSelectedAddress(data)
  }

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (organizationId) {
      Amplitude.trackEvent('CUSTOMER__ADD_CUSTOMER_CLICKED', {
        orgId: organizationId,
      })
    }
    const basicInfoValid = await methods.trigger()
    const addressValid = await addressFormMethods.trigger()

    if (!basicInfoValid) return
    if (!addressValid) return

    const basicValues = methods.getValues()
    const addressValues = addressFormMethods.getValues()

    if (selectedAddress && selectedAddress.country !== addressValues.country) {
      addressFormMethods.setError('addressLine1', {
        type: 'custom',
        message: '*Not valid for this country: ' + addressValues.country,
      })
      setIsSubmitting(false)
      return
    }

    if (selectedAddress && selectedAddress.state !== addressValues.state) {
      addressFormMethods.setError('addressLine1', {
        type: 'custom',
        message: '*Not valid for this state: ' + addressValues.state,
      })
      setIsSubmitting(false)
      return
    }

    if (
      selectedAddress &&
      selectedAddress?.city &&
      selectedAddress.city !== addressValues.city
    ) {
      addressFormMethods.setError('addressLine1', {
        type: 'custom',
        message: '*Not valid for this city: ' + addressValues.city,
      })
      setIsSubmitting(false)
      return
    }

    if (
      selectedAddress &&
      selectedAddress.postalCode &&
      selectedAddress.postalCode !== addressValues.pinCode
    ) {
      addressFormMethods.setError('pinCode', {
        type: 'custom',
        message: '*Not valid for this city',
      })
      setIsSubmitting(false)
      return
    }
    const addressPhoneNo = addressValues.countryCode + addressValues.phoneNo

    const data = {
      firstName: basicValues.firstName,
      lastName: basicValues.lastName,
      email: basicValues.email === '' ? undefined : basicValues.email,
      phoneNo: basicValues.countryCode + basicValues.phoneNo,
      instagramId: basicValues.instagramIdLink
        ? 'https://www.instagram.com/' + basicValues.instagramIdLink
        : undefined,
      address: {
        firstName: addressValues.firstName,
        lastName: addressValues.lastName,
        addressLine1: addressValues.addressLine1,
        addressLine2: addressValues.addressLine2,
        city: addressValues.city,
        state: addressValues.state,
        country: addressValues.country,
        pinCode: addressValues.pinCode,
        phoneNo: addressPhoneNo,
        latitude: selectedAddress?.latitude,
        longitude: selectedAddress?.longitude,
        addressUrl: selectedAddress?.addressUrl,
      },
    }

    const result = organizationId
      ? await createCustomer(data, organizationId)
      : { status: 'failed' }
    setIsSubmitting(false)
    if (result?.status === 'successful') {
      showSnackbar('Customer created successful.', 'success')
      navigate('/dashboard/customers', { replace: true })
    } else if (result?.status === 'failed') {
      result.message
        ? showSnackbar(result.message, 'error')
        : showSnackbar('Please try again later.', 'error')
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <CustomerForm restrictToCountries={showCountries} />
        </form>
      </FormProvider>
      <FormProvider {...addressFormMethods}>
        <form>
          <Box>
            <h2 className="text-lg font-semibold ml-4">Address</h2>
            <p className="text-sm text-gray-500 mb-2 ml-4">
              The primary address of this customer (optional)
            </p>
            <Card>
              <Address
                onAddressSelect={onAddressSelect}
                countryCodeValue={countryCode}
                phoneNoValue={phoneNo}
              />
            </Card>
          </Box>
        </form>
      </FormProvider>
      <Box textAlign={'center'} marginBottom={2}>
        <Button
          variant="contained"
          type="submit"
          onClick={handleFormSubmit}
          disabled={isSubmitting}
          sx={{ textTransform: 'none' }}
        >
          {isSubmitting ? 'Adding' : 'Add Customer'}
        </Button>
      </Box>
    </>
  )
}

export default CustomerEdit
