import { FC, useState } from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Chip, Paper, ButtonBase } from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { deleteProductOption, updateProductOption } from 'services/products'
import { Product, ProductOption } from 'types/product'

import AddNewVariantForm from './AddNewVariantForm'
import PrimaryVariantRow from './PrimaryVariantRow'

interface PrimaryTableProps {
  option: ProductOption
  productCode: string
  onUpdate: (data?: Product) => void
  product: Product
}

export interface RowValue {
  id?: number
  optionId: number
  value: string
  price: number | null
  stock: number | null
  media: string[]
}

const PrimaryTable: FC<PrimaryTableProps> = ({
  option,
  onUpdate,
  productCode,
  product,
}) => {
  const [openOptionDialog, setOpenOptionDialog] = useState(false)
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const { showSnackbar } = useSnackbar()

  const isPrimaryAvail = product.ProductOption.find(
    (op) => op.type === 'PRIMARY'
  )

  const handleMarkAsSecondaryChange = async () => {
    if (!organizationId) return

    setIsSubmitting(true)
    const result = await updateProductOption(
      organizationId,
      productCode,
      option.id,
      {
        type: 'SECONDARY',
        values: option.ProductOptionValue.map((opVal) => ({
          valueId: opVal.id,
          value: opVal.value,
        })),
      }
    )

    if (result.status === 'successful' && result.data) {
      const updatedProduct = {
        ...product,
        ProductOption: product.ProductOption.map((op) => ({
          ...op,
          type: 'SECONDARY',
        })),
      }
      onUpdate(updatedProduct)
    } else if (result.status === 'failed') {
      showSnackbar(result.message ?? 'Something went wrong', 'error')
    }
    setIsSubmitting(false)
  }

  const handleDeleleOption = async () => {
    if (!organizationId) return
    setIsDeleting(true)
    const res = await deleteProductOption(
      organizationId,
      productCode,
      option.id
    )
    if (res.status === 'successful') {
      const updatedProduct = {
        ...product,
        ProductOption: product.ProductOption.filter(
          (op) => op.id !== option.id
        ),
      }
      onUpdate?.(updatedProduct)
      showSnackbar('Option Deleted successfully', 'success')
    }
    setIsDeleting(false)
  }

  const handleDeleleOptionValue = async (valueId: number) => {
    const updatedProduct = {
      ...product,
      ProductOption: product.ProductOption.map((op) => {
        if (op.id !== option.id) return op
        else
          return {
            ...op,
            ProductOptionValue: op.ProductOptionValue.filter(
              (vl) => vl.id !== valueId
            ),
          }
      }),
    }
    onUpdate?.(updatedProduct)
  }

  return (
    <>
      <Paper className="border border-gray-300 rounded m-5" elevation={0}>
        <div className=" border-b border-gray-300 flex items-center justify-between px-4 py-2">
          <div className="flex items-center">
            <SubTabHeader title={option.name} />
            <Chip
              label="Primary Variant Option"
              sx={{
                color: '#15803d',
                backgroundColor: '#bbf7d0',
                fontSize: '12px',
                ml: 2,
              }}
              size="small"
            />
          </div>
          <div>
            <ButtonBase
              className="text-sm font-bold"
              sx={{ textDecoration: 'underline', marginRight: 3 }}
              onClick={handleMarkAsSecondaryChange}
              disabled={isSubmitting}
            >
              Mark as Secondary
            </ButtonBase>
            <IconButton onClick={() => setOpenOptionDialog(true)}>
              <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
            </IconButton>
            <IconButton onClick={handleDeleleOption} disabled={isDeleting}>
              <DeleteOutlineOutlinedIcon
                fontSize="small"
                sx={{ color: 'text.primary' }}
              />
            </IconButton>
          </div>
        </div>
        <div className=" py-2 px-4">
          <div>
            {option.ProductOptionValue.map((opValue, index) => (
              <div
                key={opValue.id}
                className={
                  index < option.ProductOptionValue.length ? 'mt-2' : ''
                }
              >
                <PrimaryVariantRow
                  optionName={option.name}
                  data={opValue}
                  productCode={productCode}
                  productMedia={product.media}
                  onDelete={handleDeleleOptionValue}
                  onUpdateProduct={onUpdate}
                  product={product}
                />
              </div>
            ))}
          </div>
        </div>
      </Paper>
      <AddNewVariantForm
        currentValues={option}
        isPrimaryPresent={isPrimaryAvail ? true : false}
        openDialog={openOptionDialog}
        onCloseDialog={() => setOpenOptionDialog(false)}
        product={product}
        onUpdate={onUpdate}
      />
    </>
  )
}

export default PrimaryTable
