import { getToken } from 'utils/authCookies'

const reviewsBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/reviews`

export const getReviews = async (orgId: number, reviewStatus?: string) => {
  const token = await getToken()

  try {
    const queryParams = new URLSearchParams()
    if (reviewStatus) {
      queryParams.append('reviewStatus', reviewStatus)
    }
    queryParams.append('sortBy', 'createdAt')
    queryParams.append('sortOrder', 'desc')

    const res = await fetch(
      `${reviewsBasePath}/all/${orgId}?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { data } = await res.json()

    if (res.ok) {
      return { status: 'successful', data: data }
    } else {
      return { status: 'failed', message: 'Failed to fetch reviews' }
    }
  } catch (error) {
    console.error('Error fetching reviews:', error)
    return { status: 'failed', message: 'An error occurred' }
  }
}

export const updateExistingReview = async (
  reviewId: number,
  orgId: number,
  status: string
) => {
  const token = await getToken()

  try {
    const res = await fetch(
      `${reviewsBasePath}/${reviewId}/status?orgId=${orgId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status }),
      }
    )

    const { data } = await res.json()

    if (res.ok) {
      return { status: 'successful', data }
    } else {
      return {
        status: 'failed',
        message: 'Failed to update review status',
      }
    }
  } catch (error) {
    console.error('Error updating review:', error)
    return {
      status: 'failed',
      message: 'An error occurred',
    }
  }
}
