import React, { useState } from 'react'

import { TextField, Button, Box, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import OnboardingContainer from 'components/forms/OnboardingContainer'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import { createBrand } from 'services/organization'
import { OrgCategory } from 'types/organization'

const orgCategories = [
  { label: 'Clothing', value: 'CLOTHING' },
  { label: 'Jewellery', value: 'JEWELLERY' },
  { label: 'Home Decor', value: 'HOME_DECOR' },
  { label: 'Beauty & Skincare', value: 'BEAUTY_SKINCARE' },
  { label: 'Footwear', value: 'FOOTWEAR' },
  { label: 'Accessories', value: 'ACCESSORIES' },
  { label: 'Art & Crafts', value: 'ART_CRAFTS' },
  { label: 'Food & Beverages', value: 'FOOD_BEVERAGES' },
  { label: 'Health & Wellness', value: 'HEALTH_WELLNESS' },
  { label: 'Stationery & Gifts', value: 'STATIONERY_GIFTS' },
  { label: 'Baby & Kids', value: 'BABY_KIDS' },
  { label: 'Pet Supplies', value: 'PET_SUPPLIES' },
  { label: 'Books & Educational Products', value: 'BOOKS_EDUCATIONAL' },
]

const CreateStore: React.FC = () => {
  const { changeOrganization } = useAuth()
  const navigate = useNavigate()

  const [brandName, setBrandName] = useState('')
  const [orgCategory, setOrgCategory] = useState<OrgCategory | ''>('')

  const handleStoreSetup = async () => {
    if (!brandName || !orgCategory || brandName.trim().length === 0) {
      alert('Please fill out both fields.')
      return
    }

    Amplitude.trackEvent(`CREATE_MY_STORE_BUTTON_CLICKED`, {
      buttonName: 'CREATE MY STORE',
    })

    const result = await createBrand(brandName, orgCategory)

    if (result?.status === 'successful' && result.data) {
      changeOrganization(result.data.id)
      navigate('/dashboard/home')
    }
  }

  return (
    <OnboardingContainer
      title="Let's Set Up Your Store!"
      description="Give your brand a home on Haulistic! Just a few quick details and you're good to go."
    >
      <Box my={4}>
        <label
          htmlFor="brandName"
          className="text-base font-medium text-gray-900"
        >
          Brand Name 🏷️ (What’s your store called?)
        </label>

        <TextField
          id="brandName"
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Loom & Label"
          value={brandName}
          onChange={(e) => setBrandName(e.target.value)}
        />
      </Box>

      <Box my={3}>
        <label
          htmlFor="category"
          className="text-base font-medium text-gray-900"
        >
          Category 🛍️ (What do you sell?)
        </label>

        <TextField
          id="category"
          select
          fullWidth
          variant="outlined"
          size="small"
          value={orgCategory}
          onChange={(e) => setOrgCategory(e.target.value as OrgCategory)}
        >
          {orgCategories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="contained"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
          onClick={handleStoreSetup}
          disabled={!orgCategory || !brandName || brandName.trim().length === 0}
        >
          Create my Store
        </Button>
      </Box>
    </OnboardingContainer>
  )
}

export default CreateStore
