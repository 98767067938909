import { JSX, useState } from 'react'

import {
  GridEventListener,
  GridRenderCellParams,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'

interface CustomCellProps extends GridRenderCellParams {
  route: string
}

const CustomCell: React.FC<CustomCellProps> = ({
  id,
  row,
  value,
}): JSX.Element => {
  const apiRef = useGridApiContext()
  const navigate = useNavigate()

  const [rowHover, setRowHover] = useState(false)
  const handleRowMouseEnterEvent: GridEventListener<'rowMouseEnter'> = (
    params
  ) => {
    if (params.id === id) setRowHover(true)
  }
  const handleRowMouseLeaveEvent: GridEventListener<'rowMouseLeave'> = (
    params
  ) => {
    if (params.id === id) setRowHover(false)
  }

  const handleNavigate = (): void => {
    if (location.pathname == '/dashboard/products/all') {
      const productCode = row?.productCode
      const status = row.status
      if (status === 'DRAFT') {
        navigate(`/dashboard/products/draft/${productCode}`)
      } else {
        navigate(`/dashboard/products/${productCode}`)
      }
    } else if (location.pathname === '/dashboard/orders/all') {
      navigate(`/dashboard/orders/${id}`)
    } else {
      navigate(`${id}`)
    }
  }

  useGridApiEventHandler(apiRef, 'rowMouseEnter', handleRowMouseEnterEvent)
  useGridApiEventHandler(apiRef, 'rowMouseLeave', handleRowMouseLeaveEvent)

  return (
    <div className="relative">
      <span
        className={`${rowHover ? ' underline' : ''} cursor-pointer`}
        onClick={handleNavigate}
      >
        {value}
      </span>
    </div>
  )
}

export default CustomCell
