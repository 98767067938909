import React, { useState } from 'react'

import { Box, Button, TableCell, TableRow, TextField } from '@mui/material' // Import Button for Cancel

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import EditButton from 'pages/dashboard/components/EditButton'
import { updateProductOptionValue } from 'services/products'

import { Variant, VariantValues } from './StockDetailsTable'

interface StockTableRowProps {
  productCode: string
  variant: VariantValues
  setVariants: React.Dispatch<React.SetStateAction<Variant[]>>
  optionId: number
  optionName: string
}

const StockTableRow: React.FC<StockTableRowProps> = ({
  variant,
  productCode,
  optionId,
  optionName,
}) => {
  const [editing, setEditing] = useState(false)
  const { organizationId } = useAuth()

  const [editedVariant, setEditedVariant] = useState({
    stock: variant.stock !== null ? variant.stock.toString() : '',
  })

  const [originalVariant, setOriginalVariant] = useState<
    Partial<VariantValues>
  >({
    stock: variant.stock,
  })

  const handleEditClick = () => {
    if (organizationId) {
      Amplitude.trackEvent('STOCK_ITEM_EDIT_CLICKED', {
        stockId: variant.valueId,
        orgId: organizationId,
      })
    }
    setEditing(true)
  }

  const handleSaveClick = async () => {
    if (variant.valueId && organizationId) {
      Amplitude.trackEvent('STOCK_ITEM_SAVED_CLICKED', {
        optionValueId: variant.valueId,
        orgId: organizationId,
      })

      const result = await updateProductOptionValue(
        organizationId,
        productCode,
        {
          optionValues: [
            {
              optionId: optionId,
              valueId: variant.valueId,
              stock:
                editedVariant.stock !== '' ? Number(editedVariant.stock) : null,
            },
          ],
        }
      )

      if (result.status === 'successful') {
        setOriginalVariant((prev) => ({
          ...prev,
          stock:
            editedVariant.stock !== '' ? Number(editedVariant.stock) : null,
        }))
      }

      setEditing(false)
    } else {
      throw new Error('Error || undefined.')
    }
  }

  const handleCancelClick = () => {
    setEditedVariant({
      stock: originalVariant.stock?.toString() ?? '',
    })
    setEditing(false)
  }

  const handleChange = (field: keyof VariantValues, value: string) => {
    const stockValue = value.replace(/[^0-9]/g, '')

    setEditedVariant((prev) => ({
      ...prev,
      [field]: stockValue,
    }))
  }

  return (
    <TableRow>
      <TableCell>
        {optionName.toLowerCase() === 'color' ? (
          <Box
            sx={{
              display: 'inlineblock',
              backgroundColor: variant.value,
              height: '20px',
              width: '20px',
              borderRadius: 1,
            }}
          ></Box>
        ) : (
          variant.value
        )}
      </TableCell>

      <TableCell>
        {editing ? (
          <TextField
            type="text"
            value={editedVariant.stock}
            onChange={(e) => handleChange('stock', e.target.value)}
            variant="outlined"
            size="small"
          />
        ) : (
          (originalVariant.stock ?? 'Not Set')
        )}
      </TableCell>
      <TableCell> 0</TableCell>
      <TableCell align="right">
        {editing ? (
          <>
            <div className="flex flex-row justify-end">
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleCancelClick}
                  disableElevation
                  sx={{
                    p: '1px',
                    fontSize: '0.8rem',
                    fontWeight: 700,
                    textTransform: 'none',
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="ml-2">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleSaveClick}
                  disableElevation
                  sx={{
                    p: '1px',
                    fontSize: '0.8rem',
                    fontWeight: 700,
                    textTransform: 'none',
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : (
          <EditButton buttonText="Edit" onClick={handleEditClick} />
        )}
      </TableCell>
    </TableRow>
  )
}

export default StockTableRow
