import { FC, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from '@mui/material'

interface DisplayImageProps {
  imageUrl: string
  width?: number
  height?: number
  cols?: number
  onDelete?: () => void
  isDeleting?: boolean
  isSelectedImage?: boolean
  onSelectImage?: () => void
}

const DisplayImage: FC<DisplayImageProps> = ({
  imageUrl,
  width,
  height,
  onDelete,
  isDeleting,
  isSelectedImage,
  onSelectImage,
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false)

  const handleOpenDialog = () => setDialogOpen(true)
  const handleCloseDialog = () => setDialogOpen(false)

  const handleDelete = () => {
    if (onDelete) onDelete()
    handleCloseDialog()
  }
  return (
    <>
      <Box
        key={imageUrl}
        sx={{
          height,
          width,
          opacity: isDeleting ? 0.7 : 1,
          position: 'relative',
          cursor: 'pointer',
          '&:hover .delete-icon': {
            display: 'flex',
          },
        }}
        onClick={() => window.open(imageUrl, '_blank')}
      >
        <img
          src={imageUrl}
          alt={`img-${imageUrl}`}
          loading="lazy"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '4px',
          }}
        />
        {onDelete && (
          <Box
            className="delete-icon"
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              display: 'none',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                handleOpenDialog()
              }}
              disabled={isDeleting}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
        {onSelectImage && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 10,
            }}
          >
            <Checkbox
              size="small"
              checked={isSelectedImage}
              onClick={(e) => {
                e.stopPropagation()
                onSelectImage && onSelectImage()
              }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'primary.main',
                },
              }}
            />
          </Box>
        )}
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        sx={{
          '& .MuiDialog-paper': {
            padding: '1px',
            maxWidth: '480px',
            width: '100%',
          },
        }}
      >
        <DialogTitle>Are you sure you want to delete this image?</DialogTitle>
        <DialogContent>
          <img
            src={imageUrl}
            alt="Selected"
            style={{
              height: 100,
              borderRadius: '4px',
              marginBottom: '16px',
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button
            variant="outlined"
            sx={{
              px: 2.5,
              py: 0.3,
              textTransform: 'none',
              color: 'black',
              borderColor: 'accents.darkGrayAccent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderColor: 'accents.darkGrayAccent',
              },
            }}
            onClick={handleCloseDialog}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{ px: 3, py: 0.3, textTransform: 'none' }}
            onClick={handleDelete}
            startIcon={<CheckIcon />}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DisplayImage
