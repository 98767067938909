import React, { forwardRef } from 'react'

import { Grid, MenuItem, TextField } from '@mui/material'

import { CountriesListParams } from 'utils/countries'

interface PhoneNumberInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  countryCodeName: string
  phoneNumberName: string
  value: { countryCode: string; phoneNumber: string }
  error: { countryCode?: string; phoneNumber?: string }
  helperText?: { countryCode?: string; phoneNumber?: string }
  countriesList: CountriesListParams[]
}

const PhoneNumberInput = forwardRef<HTMLDivElement, PhoneNumberInputProps>(
  (
    {
      onChange,
      onBlur,
      countryCodeName,
      phoneNumberName,
      value,
      error,
      helperText,
      countriesList,
    },
    ref
  ) => {
    return (
      <Grid container columnSpacing={2} ref={ref}>
        <Grid item xs={5} md={4}>
          <TextField
            select
            SelectProps={{
              style: { backgroundColor: 'white' },
              renderValue: (selected) => {
                const selectedCountry = countriesList.find(
                  (country) => country.phoneCode === selected
                )
                return selectedCountry ? selectedCountry.phoneCode : ''
              },
            }}
            sx={{
              marginTop: 0,
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
              },
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name={countryCodeName}
            value={value.countryCode}
            onChange={onChange}
            onBlur={onBlur}
            error={Boolean(error.countryCode)}
            helperText={helperText?.countryCode}
          >
            {countriesList.map((country) => (
              <MenuItem
                key={country.name}
                value={country.phoneCode}
                sx={{
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }}
              >
                {`${country.phoneCode}  ${country.name}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={7} md={8}>
          <TextField
            className="inline-block"
            sx={{
              marginTop: 0,
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
              },
            }}
            inputProps={{ style: { backgroundColor: 'white' } }}
            fullWidth
            type="tel"
            size="small"
            variant="outlined"
            margin="normal"
            name={phoneNumberName}
            value={value.phoneNumber}
            onChange={onChange}
            onBlur={onBlur}
            error={Boolean(error.phoneNumber)}
            helperText={Boolean(error.phoneNumber) && helperText?.phoneNumber}
          />
        </Grid>
      </Grid>
    )
  }
)

PhoneNumberInput.displayName = 'PhoneNumberInput'

export default PhoneNumberInput
