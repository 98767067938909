import { JSX } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import { useFormik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import * as Yup from 'yup'

import Card from 'components/card'
import Label from 'components/forms/Label'
import WhatsAppButton from 'components/whatsapp-button'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { createPhonePeAccount } from 'services/payments/phonepe'

const PhonePeSetup = (): JSX.Element => {
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const validationSchema = Yup.object({
    merchantId: Yup.string(),
    clientId: Yup.string().required('Client ID is required'),
    clientSecret: Yup.string().required('Client Secret is required'),
    clientVersion: Yup.number()
      .required('Client Version is required')
      .typeError('Client Version must be a number'),
  })

  const formik = useFormik({
    initialValues: {
      merchantId: '',
      clientId: '',
      clientSecret: '',
      clientVersion: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (organizationId === null) {
          console.error('Organization ID is null')
          showSnackbar(
            'Failed to set up PhonePe account: Invalid organization ID',
            'error'
          )
          return
        }

        const parsedValues = {
          ...values,
          clientVersion: Number(values.clientVersion),
        }

        const result = await createPhonePeAccount(organizationId, parsedValues)

        if (result.status === 'successful') {
          resetForm()
          showSnackbar('PhonePe account connected successfully', 'success')
          navigate('/dashboard/settings/payments?status=true')
        } else {
          showSnackbar(
            `Failed to set up PhonePe account: ${result.message}`,
            'error'
          )
        }
      } catch (error) {
        console.error('Error setting up PhonePe account:', error)
        showSnackbar('An unexpected error occurred. Please try again.', 'error')
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleCancel = () => {
    navigate('/dashboard/settings/payments')
  }

  return (
    <Box>
      <Box component="p" className="text-lg font-bold" sx={{ marginX: 2 }}>
        Connect your PhonePe Account
      </Box>
      {/* Instructions Section */}
      <Box className="m-4">
        {' '}
        <Accordion>
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#fff8e1' }}
          >
            <p className="flex items-center text-sm ">
              <InfoIcon className="mr-2" />
              How to Get Your PhonePe Merchant Credentials
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="p-5">
              {/* Step 1 */}
              <div className="mb-4 text-sm">
                <p className="font-bold mb-1">
                  1. Sign Up for a PhonePe Business Account
                </p>
                <ul className="list-disc ml-5">
                  <li>
                    Go to{' '}
                    <Link
                      to="https://business.phonepe.com/register?referral-code=RF2501151109154185414874"
                      target="_blank"
                      className="underline cursor-pointer font-bold"
                    >
                      PhonePe for Business
                    </Link>{' '}
                    and register as a merchant.
                  </li>
                  <li>Complete the necessary KYC and verification steps.</li>
                </ul>
              </div>

              {/* Step 2 */}
              <div className="my-4 text-sm">
                <p className="font-bold mb-1">
                  2. Access Your Merchant Dashboard
                </p>
                <ul className="list-disc ml-5">
                  <li>
                    After approval, log in to your{' '}
                    <Link
                      to="https://business.phonepe.com/login"
                      target="_blank"
                      className="underline cursor-pointer font-bold"
                    >
                      PhonePe Business Dashboard
                    </Link>
                    .
                  </li>
                  <li>
                    Look for <em>Developer</em>, <em>Integration</em>, or{' '}
                    <em>API Settings</em> in the menu.
                  </li>
                </ul>
              </div>

              {/* Step 3 */}
              <div className="my-4 text-sm">
                <p className="font-bold mb-1">3. Locate Your Credentials</p>
                <ul className="list-disc ml-5">
                  <li>
                    Copy your <em>Merchant ID</em>, <em>Client ID</em>,{' '}
                    <em>Client Secret</em>, and <em>Client Version</em>.
                  </li>
                  <li>
                    If you can’t find them,{' '}
                    <WhatsAppButton
                      buttonText="text us"
                      phoneNumber="+919790469245"
                      message="Hello! I need help setting up my PhonePe account"
                    />{' '}
                    and we will help you out.
                  </li>
                </ul>
              </div>

              {/* Step 4 */}
              <div className="my-4 text-sm">
                <p className="font-bold mb-1">4. Enter Your Details Below</p>
                <ul className="list-disc ml-5">
                  <li>
                    Paste your credentials in the form below and click{' '}
                    <em>Save</em> to connect.
                  </li>
                </ul>
              </div>

              {/* Additional Note */}
              <Typography variant="body2" color="text.secondary">
                <em>
                  Keep your credentials confidential.{' '}
                  <WhatsAppButton
                    buttonText="Reach out to us"
                    phoneNumber="+919790469245"
                    message="Hello! I need help setting up my PhonePe account"
                  />{' '}
                  if you need any assistance.{' '}
                </em>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Card>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 1 }}>
            <Label text="Client ID" htmlFor="clientId" color="secondary" />
            <CustomTextField
              id="clientId"
              name="clientId"
              value={formik.values.clientId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.clientId && Boolean(formik.errors.clientId)}
              helperText={formik.touched.clientId && formik.errors.clientId}
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <Label
              text="Client Secret"
              htmlFor="clientSecret"
              color="secondary"
            />
            <CustomTextField
              id="clientSecret"
              name="clientSecret"
              value={formik.values.clientSecret}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.clientSecret &&
                Boolean(formik.errors.clientSecret)
              }
              helperText={
                formik.touched.clientSecret && formik.errors.clientSecret
              }
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <Label
              text="Client Version"
              htmlFor="clientVersion"
              color="secondary"
            />
            <CustomTextField
              id="clientVersion"
              name="clientVersion"
              value={formik.values.clientVersion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
              error={
                formik.touched.clientVersion &&
                Boolean(formik.errors.clientVersion)
              }
              helperText={
                formik.touched.clientVersion && formik.errors.clientVersion
              }
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <Label text="Merchant ID" htmlFor="merchantId" color="secondary" />
            <CustomTextField
              id="merchantId"
              name="merchantId"
              value={formik.values.merchantId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.merchantId && Boolean(formik.errors.merchantId)
              }
              helperText={formik.touched.merchantId && formik.errors.merchantId}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              mt: 1.5,
            }}
          >
            <Button variant="outlined" size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="small"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </Box>
        </form>
      </Card>
    </Box>
  )
}

export default PhonePeSetup
