import React, { useEffect, useState } from 'react'

import { Button, Grid } from '@mui/material'

import Card from 'components/card'
import FileUpload from 'components/files/FileUpload'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import DisplayImage from 'pages/dashboard/components/DisplayImage'
import { deleteFile } from 'services/files'
import {
  getProduct,
  updateProduct,
  updateProductOptionValue,
} from 'services/products'
import { Product } from 'types/product'

interface ProductFilesProps {
  productId?: number
  productCode?: string
  productOptionId?: {
    optionId: number | null
    valueId: number | null
  }
  onFilesAdd?: (files: string[]) => void
  onNextStep?: () => void
  setImageUploaded?: (
    record:
      | Record<string, string[]>
      | ((prev: Record<string, string[]>) => Record<string, string[]>)
  ) => void

  onCloseDialog?: () => void
  onPrevStep?: (step?: number) => void
  onUpdateProduct?: (p: Product) => void
  onUploading?: () => void
  uploadingImage?: boolean
}

const fileUrl = `${process.env.REACT_APP_FILE_URL}`

const ProductFiles: React.FC<ProductFilesProps> = ({
  productId,
  productCode,
  productOptionId,
  onFilesAdd,
  onNextStep,
  setImageUploaded,
  onCloseDialog,
  onPrevStep,
  onUpdateProduct,
  onUploading,
  uploadingImage,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
  const [productData, setProductData] = useState<Product>()
  const [selectedImages, setSelectedImages] = useState<string[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()

  let mediaFiles1: string[] = []
  let mediaFiles2: string[] = []

  if (productData?.media) {
    mediaFiles1 = productData.media.map((m) => m.split('/')[3])
  }

  if (productData?.ProductOption && productOptionId) {
    const productOption = productData.ProductOption.find(
      (option) => option.id === productOptionId.optionId
    )

    if (productOption) {
      const productOptionValue = productOption.ProductOptionValue.find(
        (value) =>
          value.optionId === productOptionId.optionId &&
          value.id === productOptionId.valueId
      )

      if (productOptionValue?.media) {
        mediaFiles2 = productOptionValue.media.map((m) => m.split('/')[3])
      }
    }
  }

  const allMediaFiles = [
    ...new Set([...mediaFiles1, ...mediaFiles2, ...uploadedFiles]),
  ]

  useEffect(() => {
    if (uploadedFiles.length === 0) return
    if (productId && organizationId) {
      updateProductFiles(productId, uploadedFiles)
      Amplitude.trackEvent('PRODUCT_IMAGES_ADDED', {
        productId: productId,
        orgId: organizationId,
      })
    }
  }, [uploadedFiles, productId, organizationId])

  const toggleImageSelection = (fileName: string) => {
    if (productOptionId) {
      if (selectedImages.includes(fileName)) {
        setSelectedImages([])
      } else if (selectedImages.length < 1) {
        setSelectedImages([fileName])
      } else {
        showSnackbar('You can only select one image per variant.', 'error')
      }
    } else {
      setSelectedImages((prev) =>
        prev.includes(fileName)
          ? prev.filter((img) => img !== fileName)
          : [...prev, fileName]
      )
    }
  }

  useEffect(() => {
    if (onFilesAdd) onFilesAdd(uploadedFiles)
  }, [uploadedFiles, onFilesAdd])

  const generateKey = (optionId: number, valueId: number) =>
    `${optionId}-${valueId}`

  const updateProductFiles = async (
    productId: number,
    uploadedFiles: string[]
  ): Promise<void> => {
    const mergeFiles = uploadedFiles.map((file) => `${fileUrl}/${file}`)
    if (productData?.media) {
      productData.media.forEach((file) => {
        if (!mergeFiles.includes(file)) {
          mergeFiles.push(file)
        }
      })
    }
    if (productOptionId?.optionId && productOptionId?.valueId) {
      const productOption = productData?.ProductOption.find(
        (option) => option.id === productOptionId.optionId
      )

      if (productOption) {
        const productOptionValue = productOption.ProductOptionValue.find(
          (value) =>
            value.optionId === productOptionId.optionId &&
            value.id === productOptionId.valueId
        )

        if (productOptionValue?.media) {
          productOptionValue.media.forEach((file) => {
            if (!mergeFiles.includes(file)) {
              mergeFiles.push(file)
            }
          })
        }
      }
    }

    let res
    setIsUploading(true)
    if (organizationId && productOptionId && productCode) {
      if (
        productOptionId.optionId !== null &&
        productOptionId.valueId !== null
      ) {
        try {
          const uploadOptionImage = selectedImages

          if (uploadOptionImage.length === 0) {
            return
          }
          res = await updateProductOptionValue(organizationId, productCode, {
            optionValues: [
              {
                optionId: productOptionId.optionId,
                valueId: productOptionId.valueId,
                media: uploadOptionImage.map((file) => `${fileUrl}/${file}`),
              },
            ],
          })

          if (setImageUploaded) {
            const key = generateKey(
              productOptionId.optionId,
              productOptionId.valueId
            )

            setImageUploaded((prev) => ({
              ...prev,
              [key]:
                uploadOptionImage.length > 0
                  ? uploadOptionImage.map((file) => `${fileUrl}/${file}`)
                  : [],
            }))
          }
          showSnackbar('Image updated successfully.', 'success')
        } catch (error) {
          console.error('Error updating product variant value:', error)
          return
        }
      } else {
        console.error(
          'OptionId or ValueId is null, cannot update product option.'
        )
        return
      }
    } else {
      try {
        res = await updateProduct({
          productId,
          dataUpdate: {
            media: mergeFiles,
          },
        })
      } catch (error) {
        console.error('Error updating product:', error)
        return
      }
    }

    if (res.status === 'successful' && res.data) {
      setProductData(res.data)
      onUpdateProduct?.(res.data)
      setUploadedFiles([])
    }
    setIsUploading(false)

    if (productId && organizationId) {
      Amplitude.trackEvent('PRODUCT_IMAGES_SUBMIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
  }

  const getProductDetails = async (
    productCode: string,
    organizationId: number
  ): Promise<void> => {
    const res = await getProduct({ productCode, organizationId })
    if (res.status === 'successful') {
      setProductData(res.data)
    }
  }

  useEffect(() => {
    if (productCode && organizationId) {
      getProductDetails(productCode, organizationId)
    }
  }, [productCode])

  const onDelete = async (fileName: string): Promise<void> => {
    const res = await deleteFile(fileName)
    if (res.status === 'successful') {
      if (productId) {
        const filterFiles = allMediaFiles.filter((f) => f !== fileName)
        const result = await updateProduct({
          productId,
          dataUpdate: {
            media: filterFiles.map((file) => `${fileUrl}/${file}`),
          },
        })
        if (result.status === 'successful' && result.data) {
          setProductData(result.data)
          onUpdateProduct?.(result.data)
        }
      }

      if (productId && organizationId) {
        Amplitude.trackEvent('PRODUCT_IMAGE_DELETE_CLICKED', {
          productId: productId,
          orgId: organizationId,
        })
      }

      const currentUploadedFiles = uploadedFiles.filter((f) => f !== fileName)
      setUploadedFiles(currentUploadedFiles)
    }
  }

  const handleNextStep = () => {
    if (allMediaFiles.length === 0) {
      showSnackbar('At least one image is required.', 'info')
    } else if (onNextStep) {
      onNextStep()
    }
  }

  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-5">Product Images</h2>
      <p className="text-sm text-gray-500 mb-2 ml-5">
        Select images of the product from your device, or simply drag and drop
        them here.
      </p>
      <Card>
        {allMediaFiles && allMediaFiles.length > 0 && (
          <Grid container spacing={1} marginBottom={2}>
            {allMediaFiles.map((fileName, index) => (
              <Grid item key={index} xs={3} sm={2} md={onNextStep ? 1 : 1.5}>
                <DisplayImage
                  imageUrl={`${process.env.REACT_APP_FILE_URL}/${fileName}`}
                  {...(!productOptionId && {
                    onDelete: () => onDelete(fileName),
                  })}
                  height={120}
                  {...(productOptionId && {
                    isSelectedImage: selectedImages.includes(fileName),
                    onSelectImage: () => toggleImageSelection(fileName),
                  })}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <FileUpload
          onClose={() => {}}
          onSuccess={(file: string) => setUploadedFiles((f) => [...f, file])}
          uploadedCount={allMediaFiles.length}
          onUploading={onUploading}
        />
      </Card>
      {onNextStep && (
        <div className="mx-5">
          <div className="flex items-center w-full">
            <div className="flex-1 basis-1/2">
              <Button
                size="small"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => onPrevStep?.(undefined)}
              >
                Prev: Add Basic Details
              </Button>
            </div>
            <div className="flex-1 basis-3/5">
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  textTransform: 'none',
                }}
                onClick={handleNextStep}
                disabled={isUploading || uploadingImage}
              >
                Next : Add Variants
              </Button>
            </div>
          </div>
          {/* <div className="flex items-center justify-center">
            <Button
              variant="text"
              size="small"
              onClick={onNextStep}
              sx={{
                textTransform: 'none',
                textDecoration: 'underline',
                display:
                  allMediaFiles && allMediaFiles.length > 0 ? 'none' : 'block',
                mt: 1,
              }}
            >
              This product has no images, skip to next step {'>>'}
            </Button>
          </div> */}
        </div>
      )}
      {productOptionId && (
        <div className="flex justify-end mt-4">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ textTransform: 'none', marginRight: '8px' }}
            onClick={() => {
              setSelectedImages([])
              setUploadedFiles([])
              if (onCloseDialog) onCloseDialog()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={() => {
              if (productId) {
                updateProductFiles(productId, uploadedFiles)
                if (onCloseDialog) onCloseDialog()
              }
            }}
            disabled={isUploading || uploadingImage}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProductFiles
