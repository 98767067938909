import { JSX, useEffect, useState } from 'react'

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import LoadingSpinner from 'components/spinner'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import ConfirmationDialog from 'pages/dashboard/components/ConfirmationDialog'
import { getProduct, updateProduct } from 'services/products'
import { Product } from 'types/product'

import FilesDetails from '../components/FilesDetails'
import ManageStatusDetail from '../components/ManageStatusDetail'
import NotesDetails from '../components/NotesDetails'
import OffersDetails from '../components/OffersDetails'
import PricingDetails from '../components/PricingDetails'
import BasicDetails from '../components/ProductDetails'
// import SeoDetails from '../components/SeoDetails'
import ShippingConfigForm from '../components/ShippingConfigForm'
import SizeChart from '../components/SizeChart'
import ProductSummary from '../components/Summary'
import TagsDetails from '../components/TagsDetails'
import VariantsDetails from '../components/VariantsDetails'

const ProductDetailsPage = (): JSX.Element => {
  const [product, setProduct] = useState<Product>()
  const { productCode } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const naviagte = useNavigate()
  const [dataUpdate, setDataUpdate] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openAction = Boolean(anchorEl)
  const { organization, organizationId } = useAuth()

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result =
        productCode && organizationId
          ? await getProduct({ productCode, organizationId })
          : { status: 'failed' }
      if (result.status === 'successful') setProduct(result.data)
      setIsLoading(false)
    }
    get()
  }, [productCode, organizationId])

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result =
        productCode && organizationId
          ? await getProduct({ productCode, organizationId })
          : { status: 'failed' }
      if (result.status === 'successful') {
        setProduct(result.data)
      }
      setIsLoading(false)
      setDataUpdate(false)
    }
    if (dataUpdate) get()
  }, [dataUpdate, productCode, organizationId])

  const handleUpdate = (data?: Product): void => {
    if (data) {
      setProduct({ ...data })
    } else {
      setDataUpdate(true)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleActionClose = (): void => {
    setAnchorEl(null)
  }

  const handleDeleteDialog = (): void => {
    setDeleteDialog(true)
  }

  const handleDeleteProduct = async (): Promise<void> => {
    if (product) {
      const result = await updateProduct({
        productId: product.id,
        dataUpdate: { isDeleted: true },
      })
      if (result.status === 'successful') {
        showSnackbar('Product Deleted.', 'success')
        naviagte(-1)
      } else if (result.status === 'failed') {
        showSnackbar('Please try again later.', 'error')
      }
    }
    setDeleteDialog(false)
  }

  const handleSizeChartChange = async (url?: string): Promise<void> => {
    if (!product) return
    const result = await updateProduct({
      productId: product.id,
      dataUpdate: { sizeChart: url ?? null },
    })

    if (result.status === 'successful') {
      setProduct(result.data)
      showSnackbar('Size chart updated', 'success')
    } else if (result.status === 'failed') {
      showSnackbar('Please try again later.', 'error')
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!product) return <h1>No Product found.</h1>

  const handlePreviewButtonClick = (): void => {
    if (organization && organization.customDomain && product) {
      window.open(
        `https://${organization.customDomain}/products/preview/${product.route}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else if (organization && product) {
      const productRoute = `${organization.website}/products/preview/${product.route}`

      window.open(productRoute, '_blank', 'noopener,noreferrer')
    }
  }

  const isPrimaryVariantAvail = product.ProductOption.find(
    (op) => op.type.toLowerCase() === 'primary'
  )

  return (
    <Box className="mb-5">
      <div className=" flex items-center justify-between mr-5">
        <div className="flex items-center ml-2 w-full">
          <IconButton sx={{ color: 'gray' }} onClick={() => naviagte(-1)}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <p className="text-xl w-full font-bold">
            {`${product.name} (${product.productCode})`}
          </p>
        </div>
        <div className="flex">
          <Button
            variant="contained"
            size="small"
            sx={{
              paddingInline: '1rem',
              textTransform: 'none',
            }}
            id="basic-button"
            aria-controls={openAction ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openAction ? 'true' : undefined}
            onClick={handleClick}
          >
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleActionClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiMenuItem-root': {
                '&:hover': {
                  backgroundColor: 'lightgray',
                },
              },
            }}
          >
            {!product.isListed && (
              <MenuItem onClick={handlePreviewButtonClick}>
                Show Product Preview
              </MenuItem>
            )}
            <MenuItem onClick={handleDeleteDialog}>Delete Product</MenuItem>
          </Menu>
        </div>
      </div>
      <ProductSummary product={product} />
      <BasicDetails product={product} onUpdate={handleUpdate} />
      {!isPrimaryVariantAvail && (
        <PricingDetails product={product} onUpdate={handleUpdate} />
      )}
      <ShippingConfigForm product={product} onUpdate={handleUpdate} />
      <FilesDetails
        media={product.media}
        productId={product.id}
        productCode={product.productCode}
        product={product}
        onUpdate={handleUpdate}
      />

      {!isPrimaryVariantAvail && (
        <OffersDetails
          price={product.price}
          discountedPrice={product.discountedPrice}
          discountPercentage={product.discountPercentage}
          startOfferDate={product.startOfferDate}
          endOfferDate={product.endOfferDate}
          productId={product.id}
          onUpdate={handleUpdate}
        />
      )}
      <VariantsDetails
        variants={product.ProductOption}
        productCode={product.productCode}
        onUpdate={handleUpdate}
        product={product}
      />
      <SizeChart
        sizeChartUrl={product.sizeChart}
        onSizeChartChange={handleSizeChartChange}
      />

      {/* <SeoDetails
        seoValues={{
          seoTitle: product.seoTitle,
          seoDescription: product.seoDescription,
          seoLink: product.seoLink,
        }}
        productId={product.id}
        onUpdate={handleUpdate}
      /> */}
      <NotesDetails
        productId={product.id}
        notesData={product.notes}
        onUpdate={handleUpdate}
      />
      <TagsDetails
        productId={product.id}
        tagIds={product.tagIds}
        onUpdate={handleUpdate}
      />
      <ManageStatusDetail product={product} onUpdate={handleUpdate} />
      <div>
        <ConfirmationDialog
          open={deleteDialog}
          title="Delete Product"
          content="This action will permanently delete the product. Are you sure that you want to proceed?"
          onClose={() => setDeleteDialog(false)}
          onConfirm={handleDeleteProduct}
          confirmButtonText="Confirm & Delete"
        />
      </div>
    </Box>
  )
}

export default ProductDetailsPage
