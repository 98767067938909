import { FC, useEffect, useState } from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { updateOrganization } from 'services/organization'
import { Organization } from 'services/organization/index.types'

interface UIThemeProps {
  mainColor?: string
  secondaryColor?: string
  onUpdate: (org?: Organization) => void
}

const UITheme: FC<UIThemeProps> = ({
  mainColor,
  secondaryColor,
  onUpdate,
}): JSX.Element => {
  const [primaryColor, setPrimaryColor] = useState<string>('#00897B')
  const [accentColor, setAccentColor] = useState<string>('#FF6B6B')
  const [error, setError] = useState<string>()
  const [colors, setColors] = useState<{
    primaryColor: string
    accentColor: string
  }>({
    primaryColor: '#00897B',
    accentColor: '#FF6B6B',
  })
  const [edit, setEdit] = useState(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleEdit = () => {
    setEdit(true)
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_UITHEME_EDIT_CLICKED', {
        organizationId,
      })
    }
  }

  useEffect(() => {
    if (mainColor && secondaryColor) {
      setPrimaryColor(mainColor)
      setAccentColor(secondaryColor)
      setColors({ primaryColor: mainColor, accentColor: secondaryColor })
    }
  }, [mainColor, secondaryColor, edit])

  const handlePrimaryColorChange = (
    value: string,
    colors: MuiColorInputColors
  ): void => {
    setPrimaryColor(value)
    setColors((prev) => ({ ...prev, primaryColor: colors.hex }))
    setError(undefined)
  }

  const handleAccentColorChange = (
    value: string,
    colors: MuiColorInputColors
  ): void => {
    setAccentColor(value)
    setColors((prev) => ({ ...prev, accentColor: colors.hex }))
    setError(undefined)
  }

  const handleSubmit = async (): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_UITHEME_SAVE_CLICKED', {
        organizationId,
      })
    }

    if (!colors.primaryColor || !colors.accentColor) {
      return setError('Select both colors.')
    }

    setIsSubmitting(true)
    const res = organizationId
      ? await updateOrganization(organizationId, {
          primaryColor: colors.primaryColor,
          secondaryColor: colors.accentColor,
        })
      : { status: 'failed' }

    if (res && res.status === 'successful') {
      showSnackbar('UI colors set.', 'success')
      onUpdate(res.data)
    }

    if (res && res.status === 'failed') {
      res.message
        ? showSnackbar(res.message, 'error')
        : showSnackbar('Something went wrong. Try again later', 'error')
    }
    setIsSubmitting(false)
    setEdit(false)
  }

  const handleCancel = (): void => {
    setEdit(false)
  }

  return (
    <Box flex={1}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <h2 className="text-lg font-semibold ml-5 mr-2">UI Theme</h2>
        {!edit && (
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleEdit}
            sx={{
              backgroundColor: '#e0e0e0',
              color: 'text.primary',
              textTransform: 'none',
              padding: '0.1rem 0.75rem',
              ':hover': { backgroundColor: '#bdbdbd' },
              marginRight: '20px',
            }}
          >
            Edit
          </Button>
        )}
      </Box>
      <Card>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <div className="flex items-center">
              <Label htmlFor="primaryColor" text="Primary Color" />
              <Tooltip
                title="This color defines the main theme of your UI, affecting  buttons, and key elements."
                arrow
                placement="top"
              >
                <IconButton
                  sx={{
                    fontSize: '0.875rem',
                    padding: '0.25rem',
                    mb: '0.25rem',
                  }}
                >
                  <InfoOutlinedIcon fontSize="inherit" color="info" />
                </IconButton>
              </Tooltip>
            </div>
            {!edit && (
              <Box display={'flex'} alignItems={'center'} mt={1.5}>
                <Box
                  sx={{
                    display: 'inlineblock',
                    backgroundColor: mainColor ?? '#00897B',
                    height: '30px',
                    width: '30px',
                    borderRadius: 1,
                    mr: 1.5,
                  }}
                ></Box>
                {mainColor ?? '#00897B'}
              </Box>
            )}
            {edit && (
              <MuiColorInput
                value={
                  primaryColor.startsWith('#')
                    ? primaryColor
                    : '#' + primaryColor
                }
                name="primaryColor"
                format="hex"
                onChange={handlePrimaryColorChange}
                inputProps={{
                  style: { backgroundColor: 'white' },
                  maxLength: 7,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="flex items-center">
              <Label htmlFor="accentColor" text="Accent color" />
              <Tooltip
                title="This color highlights important elements like links, and active states."
                arrow
                placement="top"
              >
                <IconButton
                  sx={{
                    fontSize: '0.875rem',
                    padding: '0.25rem',
                    mb: '0.25rem',
                  }}
                >
                  <InfoOutlinedIcon fontSize="inherit" color="info" />
                </IconButton>
              </Tooltip>
            </div>
            {!edit && (
              <Box display={'flex'} alignItems={'center'} mt={1.5}>
                <Box
                  sx={{
                    display: 'inlineblock',
                    backgroundColor: secondaryColor ?? '#FF6B6B',
                    height: '30px',
                    width: '30px',
                    borderRadius: 1,
                    mr: 1.5,
                  }}
                ></Box>
                {secondaryColor ?? '#FF6B6B'}
              </Box>
            )}
            {edit && (
              <MuiColorInput
                value={
                  accentColor.startsWith('#') ? accentColor : '#' + accentColor
                }
                name="accentColor"
                format="hex"
                onChange={handleAccentColorChange}
                inputProps={{
                  style: { backgroundColor: 'white' },
                  maxLength: 7,
                }}
              />
            )}
          </Grid>
        </Grid>
        {error && <p className="text-xs text-red-500 block mt-1">{error}</p>}
        {edit && (
          <Box className=" mt-4 flex items-center gap-4">
            <Button variant="outlined" size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </Box>
        )}
      </Card>
    </Box>
  )
}

export default UITheme
