import { ReactNode, useEffect, useState } from 'react'

import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined'
import CategoryIcon from '@mui/icons-material/CategoryOutlined'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GroupIcon from '@mui/icons-material/GroupOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import WebOutlinedIcon from '@mui/icons-material/WebOutlined'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import theme from 'styles/muiTheme'

interface DashboardItem {
  label: string
  icon: ReactNode
  path?: string
  children?: {
    label: string
    path: string
  }[]
}

interface DashboardSidebarProps {
  drawer?: boolean
  onClick?: () => void
}

const items: DashboardItem[] = [
  {
    label: 'Home',
    icon: <DashboardIcon />,
    path: 'home',
  },
  {
    label: 'Customers',
    icon: <GroupIcon />,
    path: 'customers',
  },
  {
    label: 'Orders',
    icon: <ShoppingCartIcon />,
    path: 'orders',
    children: [
      {
        label: 'All Orders',
        path: 'orders/all',
      },
      {
        label: 'Checkout Sessions',
        path: 'orders/checkout-sessions',
      },
    ],
  },
  {
    label: 'Products',
    icon: <CategoryIcon />,
    path: 'products',
    children: [
      {
        label: 'All Products',
        path: 'products/all',
      },
      {
        label: 'Stock',
        path: 'products/stock',
      },
      {
        label: 'Categories',
        path: 'products/categories',
      },
      {
        label: 'Manage Reviews',
        path: 'products/reviews',
      },
    ],
  },
  {
    label: 'Analytics',
    icon: <AnalyticsIcon />,
    path: 'analytics',
  },
  {
    label: 'Website Builder',
    icon: <WebOutlinedIcon />,
    path: 'website-builder',
  },
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    path: 'settings',
    children: [
      {
        label: 'Account',
        path: 'settings/account',
      },
      {
        label: 'Stores',
        path: 'settings/stores',
      },
      {
        label: 'Product',
        path: 'settings/product',
      },
      {
        label: 'Payments',
        path: 'settings/payments',
      },
    ],
  },
  // {
  //   label: 'Marketing',
  //   icon: <WebAssetOutlinedIcon />,
  //   path: 'marketing',
  //   children: [
  //     {
  //       label: 'Email Builder',
  //       path: 'marketing/email',
  //     },
  //   ],
  // },
]

const DashboardSidebar: React.FC<DashboardSidebarProps> = ({
  drawer,
  onClick,
}) => {
  const navigate = useNavigate()
  const { organization } = useAuth()

  const [activeItem, setActiveItem] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    const pathName = location.pathname.replace('/dashboard/', '')
    let activeTab: string = ''

    //To keep tab active on refresh and sync between drawer
    for (const item of items) {
      if (item.children) {
        for (const child of item.children) {
          if (child.path && pathName.startsWith(child.path)) {
            activeTab = child.label
            break
          }
        }
      }
      if (activeTab) break
      if (item.path && pathName.startsWith(item.path)) {
        activeTab = item.label
      }
    }

    if (activeTab) setActiveItem(activeTab)
  }, [location.pathname])

  const handleDrawerItemClick = (item: { label: string; path?: string }) => {
    Amplitude.trackEvent(
      `DASHBOARD_${item.label.toUpperCase()}_BUTTON_CLICKED`,
      {
        buttonName: item.label,
      }
    )
    if (drawer && item.path) {
      navigate('dashboard/' + item.path)
    } else if (item.path) {
      navigate(item.path)
    }
    setActiveItem(item.label)
    if (onClick) onClick()
  }

  const handleOrganizationClick = () => {
    if (organization?.website)
      window.open(organization.website, '_blank', 'noopener,noreferrer')
  }

  const renderListItem = (item: DashboardItem) => {
    return (
      <ListItem key={item.label} disablePadding>
        <ListItemButton
          onClick={() => handleDrawerItemClick(item)}
          sx={{
            borderRadius: 10,
            height: 48,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            ...(activeItem === item.label && {
              fontWeight: 'bold',
              backgroundColor: 'inherit',
              color: theme.palette.primary.main,
            }),
          }}
        >
          <ListItemIcon
            sx={{
              color: 'inherit',
              minWidth: 35,
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
          />
        </ListItemButton>
      </ListItem>
    )
  }

  const renderAccordionItem = (item: DashboardItem) => {
    return (
      <Accordion
        key={item.label}
        disableGutters
        elevation={0}
        sx={{ '&:before': { display: 'none' }, backgroundColor: '#F4F6F7' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${item.label}-content`}
          id={`${item.label}-header`}
          sx={{
            py: 0,
            my: 0,
            height: 48,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ListItemIcon
            sx={{ minWidth: 35, paddingTop: 0.5, color: 'inherit' }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ py: 0 }}>
          <List component="div" disablePadding>
            {item.children?.map((child) => (
              <ListItemButton
                key={child.label}
                onClick={() => handleDrawerItemClick(child)}
                sx={{
                  fontSize: '0.5rem',
                  borderRadius: 10,
                  pl: 5,
                  py: 0.5,
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                  ...(activeItem === child.label && {
                    fontWeight: 'bold',
                    backgroundColor: 'inherit',
                    color: theme.palette.primary.main,
                  }),
                }}
              >
                <ListItemText
                  primary={child.label}
                  primaryTypographyProps={{
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    )
  }
  return (
    <Box sx={{ pt: 12, px: 2 }}>
      {organization && organization.name ? (
        <Button
          sx={{ ml: 1, mb: 1, backgroundColor: theme.palette.secondary.light }}
          onClick={handleOrganizationClick}
        >
          <span className="text-lg">{organization.name}</span>
          <OpenInNewIcon className="ml-4" fontSize="small" />
        </Button>
      ) : null}
      <List>
        {items.map((item) =>
          item.children ? renderAccordionItem(item) : renderListItem(item)
        )}
      </List>
    </Box>
  )
}

export default DashboardSidebar
