import { getToken } from 'utils/authCookies'

const accountBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/user`

const getPendingInvitations = async () => {
  const token = await getToken()
  try {
    const res = await fetch(`${accountBasePath}/invites`, {
      method: 'GET',
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    })

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error
  }
}

const acceptInvitation = async (organizationId: number) => {
  const token = await getToken()
  try {
    const res = await fetch(`${accountBasePath}/invite/accept`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ organizationId }),
    })

    if (res.ok) {
      return { status: 'successful' }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error accepting invitation:', error)
    throw error
  }
}

const declineInvitation = async (organizationId: number) => {
  const token = await getToken()
  try {
    const res = await fetch(`${accountBasePath}/invite/decline`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ organizationId }),
    })

    if (res.ok) {
      return { status: 'successful' }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error declining invitation:', error)
    throw error
  }
}

export { getPendingInvitations, acceptInvitation, declineInvitation }
