import React, { useState } from 'react'

import { Typography, Button, Box, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

// import Amplitude from 'lib/amplitude'

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '2.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.875rem',
    textAlign: 'center',
  },
}))

const Tagline = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontSize: '1.25rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    textAlign: 'center',
  },
}))

const HeroSection: React.FC = () => {
  const navigate = useNavigate()

  // const handleRequestEarlyAccessClick = () => {
  //   const element = document.getElementById('request-early-access')
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: 'smooth',
  //     })
  //   } else {
  //     console.error(`Element with id: request-early-access not found.`)
  //   }
  // }

  const handleGetStartedClick = () => {
    if (!email) {
      navigate(`/signup`)
    } else {
      navigate(`/signup/email?userEmail=${encodeURIComponent(email)}`)
    }
  }

  const [email, setEmail] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState('')

  return (
    <Box margin={'auto'} textAlign={'center'} pt={10}>
      <Title variant="h2">Build your Online Business with Haulistic</Title>
      <Tagline variant="subtitle1">
        Set up your online store in minutes for free!
      </Tagline>

      <Box
        display={{ sm: 'block', md: 'flex' }}
        alignItems="center"
        justifyContent={'center'}
        gap={2}
      >
        <TextField
          label="Enter your email"
          variant="outlined"
          className="w-full md:w-1/2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
          helperText={error}
          sx={{ my: 3 }}
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleGetStartedClick}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  )
}

export default HeroSection
