import React, { JSX } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  usePDF,
  PDFViewer,
  Font,
} from '@react-pdf/renderer'

import { SelectedOptionMapping } from 'services/orders/index.type'

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
})

// Define types for the invoice data
interface InvoiceData {
  billFrom: {
    name: string
    address: string
    city: string
    state: string
    country: string
    pinCode: string
    email?: string | null
  } | null
  billTo: {
    name: string
    address: string
    city: string
    state: string
    country: string
    pinCode: string
    email: string
  }
  invoiceNumber: string
  invoiceDate: string
  amountDue: number
  items: {
    name: string
    quantity: number
    unitPrice: number
    selectedOptions?: SelectedOptionMapping[]
  }[]
  taxRate: number
  totalPrice: number
  shippingCost: number
}

interface InvoicePDFProps {
  invoiceData: InvoiceData
  open: boolean
  onClose: () => void
}

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: 'Open Sans',
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: 10,
    marginTop: 10,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  text: {
    marginBottom: 5,
  },
  table: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    padding: 5,
    borderBottom: '1px solid #EEE',
    textAlign: 'right',
    flex: 1,
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
})

// PDF Document Component
const InvoiceDocument = ({
  invoiceData,
}: {
  invoiceData: InvoiceData
}): JSX.Element => {
  const taxAmount = invoiceData.totalPrice * (invoiceData.taxRate / 100)
  const total = invoiceData.totalPrice + taxAmount

  const billFrom = invoiceData.billFrom
  const billTo = invoiceData.billTo

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>INVOICE</Text>

        {/* Bill From Section */}
        <Text style={[styles.sectionTitle, { fontWeight: 'bold' }]}>
          BILL FROM:
        </Text>
        {billFrom && (
          <>
            <Text style={styles.text}>{billFrom.name}</Text>
            <Text style={styles.text}>{billFrom.address}</Text>
            <Text style={styles.text}>
              {billFrom.city}, {billFrom.state}, {billFrom.pinCode}
            </Text>
            <Text style={styles.text}>{billFrom.country}</Text>
            <Text style={styles.text}>{billFrom.email}</Text>
          </>
        )}

        {/* Bill To Section */}
        <View style={{ borderBottom: '1px solid #000', marginVertical: 10 }} />

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* Bill To Section */}
          <View style={{ width: '50%' }}>
            <Text style={styles.sectionTitle}>BILL TO:</Text>
            <Text style={styles.text}>{invoiceData.billTo.name}</Text>
            <Text style={styles.text}>{invoiceData.billTo.address}</Text>
            <Text style={styles.text}>
              {billTo.city}, {billTo.state}, {billTo.pinCode}
            </Text>
            <Text style={styles.text}>{invoiceData.billTo.country}</Text>
            <Text style={styles.text}>{invoiceData.billTo.email}</Text>
          </View>

          {/* Invoice Details Section */}
          <View style={{ width: '40%' }}>
            <View style={styles.totalRow}>
              <Text>INVOICE #</Text>
              <Text>{invoiceData.invoiceNumber}</Text>
            </View>
            <View style={styles.totalRow}>
              <Text>INVOICE DATE:</Text>
              <Text>{invoiceData.invoiceDate}</Text>
            </View>
            <View style={[styles.totalRow, { backgroundColor: '#eceff1' }]}>
              <Text>AMOUNT DUE:</Text>
              <Text>INR {invoiceData.amountDue.toFixed(2)}</Text>
            </View>
          </View>
        </View>

        {/* Item Table */}
        <View style={styles.table}>
          <View
            style={[
              styles.tableRow,
              { fontWeight: 'bold', backgroundColor: '#eceff1' },
            ]}
          >
            <Text
              style={[styles.tableCell, { width: '40%', textAlign: 'left' }]}
            >
              Item Description
            </Text>
            <Text style={[styles.tableCell]}>Quantity</Text>
            <Text style={[styles.tableCell]}>Unit Cost</Text>
            <Text style={[styles.tableCell]}>Line Total</Text>
          </View>
          {invoiceData.items.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text
                style={[styles.tableCell, { width: '40%', textAlign: 'left' }]}
              >
                {item.name}
                {item.selectedOptions && item.selectedOptions.length > 0 && (
                  <Text style={[styles.tableCell, { textAlign: 'left' }]}>
                    {`\n${item.selectedOptions
                      .map((option) => option.value.value)
                      .join(' | ')}`}
                  </Text>
                )}
              </Text>
              <Text style={[styles.tableCell]}>{item.quantity}</Text>
              <Text style={[styles.tableCell]}>
                INR {item.unitPrice.toFixed(2)}
              </Text>
              <Text style={[styles.tableCell]}>
                INR {(item.quantity * item.unitPrice).toFixed(2)}
              </Text>
            </View>
          ))}
        </View>

        {/* Subtotal, Tax, and Total */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: 20,
          }}
        >
          <View style={{ width: 160 }}>
            <View style={styles.totalRow}>
              <Text>SUBTOTAL:</Text>
              <Text>INR {invoiceData.totalPrice.toFixed(2)}</Text>
            </View>
            <View style={styles.totalRow}>
              <Text>SHIPPING COST:</Text>
              <Text>INR {invoiceData.shippingCost.toFixed(2)}</Text>
            </View>
            <View style={styles.totalRow}>
              <Text>TAX ({invoiceData.taxRate}%):</Text>
              <Text>INR {taxAmount.toFixed(2)}</Text>
            </View>
            <View
              style={[
                styles.totalRow,
                {
                  backgroundColor: '#eceff1',
                },
              ]}
            >
              <Text style={{ fontWeight: 'bold' }}>TOTAL:</Text>
              <Text>INR {total.toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export const GenerateInvoice: React.FC<InvoicePDFProps> = ({
  invoiceData,
  open,
  onClose,
}) => {
  const [instance] = usePDF({
    document: <InvoiceDocument invoiceData={invoiceData} />,
  })

  // Function to handle PDF download
  const handleDownload = (): void => {
    if (instance.url) {
      const link = document.createElement('a')
      link.href = instance.url
      link.download = `invoice-${invoiceData.invoiceNumber}.pdf`
      link.click()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Invoice PDF</DialogTitle>

      <DialogContent dividers sx={{ height: '600px' }}>
        <PDFViewer width="100%" height={'600px'}>
          <InvoiceDocument invoiceData={invoiceData} />
        </PDFViewer>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose} size="small">
          Close
        </Button>
        <Button
          variant="contained"
          onClick={handleDownload}
          disabled={instance.loading || !!instance.error}
          size="small"
        >
          {instance.loading ? 'Generating...' : 'Download PDF'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateInvoice
