import { ChangeEvent, FC, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { MuiColorInput } from 'mui-color-input'
import { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { createProductOption, deleteProductOption } from 'services/products'
import { ProductOption } from 'services/products/index.types'
import { isValidHex } from 'utils/helper'

type OptionType = {
  label: string
  value: string
  id: number
}

interface Option {
  name: string
  values: string[]
  isPrimary: boolean
  optionId: string | number
}

interface OptionInputProps {
  option: Option
  onAddOption: (option: ProductOption) => void
  index: number
  onDelete: (index: number) => void
  productCode: string
  onRemoveVariantOption: (optionId: number) => void
  onRowOptionAdd: (option: Option, index: number) => void
  primaryOptionAdded: boolean
}

interface ColorValue {
  code: string
  name: string
  id: number
  isNew: boolean
}

const OptionInput: FC<OptionInputProps> = ({
  option,
  onAddOption,
  index,
  onDelete,
  productCode,
  onRemoveVariantOption,
  onRowOptionAdd,
  primaryOptionAdded,
}) => {
  const theme = useTheme()
  const [tagOptions, setTagOptions] = useState<MultiValue<OptionType>>([])
  const [value, setValue] = useState<MultiValue<OptionType>>()
  const [optionName, setOptionName] = useState<string>('')
  const [isPrimary, setIsPrimary] = useState<boolean>(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [colorValues, setColorValues] = useState<ColorValue[]>([])
  const [colorFormValue, setColorFormValue] = useState({
    code: '',
    name: '',
  })
  const [addNewColorOption, setAddNewColorOption] = useState(false)
  const [colorFormErrors, setColorFormErrors] = useState<string>()

  const handleChange = (newValue: MultiValue<OptionType>): void => {
    setValue(newValue)
  }

  const handleGenerateStandardSizeOptions = (): void => {
    const sizeOptions = [
      { label: 'XXS', value: 'XXS', id: 1 },
      { label: 'XS', value: 'XS', id: 2 },
      { label: 'S', value: 'S', id: 3 },
      { label: 'M', value: 'M', id: 4 },
      { label: 'L', value: 'L', id: 5 },
      { label: 'XL', value: 'XL', id: 6 },
      { label: 'XXL', value: 'XXL', id: 7 },
      { label: '3XL', value: '3XL', id: 8 },
      { label: '4XL', value: '4XL', id: 9 },
    ]
    setTagOptions(sizeOptions)
    setValue(sizeOptions)
  }

  const handlePrimaryOptionChange = (): void => {
    setIsPrimary(!isPrimary)
  }

  let disableDoneBtn = optionName.trim() === '' || (value?.length ?? 0) < 2

  if (optionName.toLowerCase().trim() === 'color')
    disableDoneBtn = colorValues.length <= 1

  const handleOptionDone = async (): Promise<void> => {
    if (optionName.trim() === '') {
      setError('*Please provide a variant name')
      return
    }
    if (
      optionName.toLowerCase().trim() !== 'color' &&
      (!value || value?.length <= 1)
    ) {
      setError('*Please provide atleast two variant values')
      return
    }

    if (optionName.toLowerCase().trim() === 'color' && colorValues.length < 2) {
      setError('*Provide atleast two color options')
      return
    }

    const formattedOptionName =
      optionName.charAt(0).toUpperCase() + optionName.slice(1).toLowerCase()

    let data
    if (formattedOptionName.toLowerCase().trim() === 'color') {
      data = {
        name: formattedOptionName,
        values: colorValues.map((vl) => ({
          value: vl.code,
          caption: vl.name,
        })),
        type: isPrimary ? 'PRIMARY' : 'SECONDARY',
      }
    } else {
      data = {
        name: formattedOptionName,
        values: value
          ? value.map((vl) => ({
              value: vl.label,
            }))
          : [],
        type: isPrimary ? 'PRIMARY' : 'SECONDARY',
      }
    }

    const res = organizationId
      ? await createProductOption(productCode, organizationId, data)
      : { status: 'failed', message: 'Please try again later.' }

    if (res.status === 'successful' && res.data) {
      onAddOption(res.data)
      setError(null)
      setColorValues([])
      setColorFormErrors(undefined)
      onRowOptionAdd(
        {
          name: formattedOptionName,
          values: value ? value.map((vl) => vl.label) : [],
          isPrimary: isPrimary,
          optionId: res.data.id.toString(),
        },
        index
      )
    } else if (res.status === 'failed') {
      showSnackbar(res.message || 'Please try again later.', 'error')
    }
  }

  const handleRemoveOption = (): void => {
    onDelete(index)
  }

  const handleOptionDelete = async (
    optionId: number | undefined
  ): Promise<void> => {
    if (!optionId) return
    setIsDeleting(true)
    const res = organizationId
      ? await deleteProductOption(organizationId, productCode, optionId)
      : { status: 'failed', message: 'Please try again later.' }

    if (res.status === 'successful') {
      showSnackbar(
        res.message || 'Product option deleted successfully',
        'success'
      )
      onDelete(index)
      onRemoveVariantOption(optionId)
    } else if (res.status === 'failed') {
      showSnackbar(res.message || 'Please try again later.', 'error')
    }
    setIsDeleting(false)
  }

  const handleColorChange = (value: string): void => {
    setColorFormValue((prev) => ({ ...prev, code: value }))
    setColorFormErrors(undefined)
  }

  const handleColorNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setColorFormValue((prev) => ({ ...prev, name: value }))
    setColorFormErrors(undefined)
  }

  const handleAddColor = (): void => {
    if (colorFormValue.code.trim() === '' || !isValidHex(colorFormValue.code)) {
      setColorFormErrors('*Invalid color value')
      return
    }
    if (colorFormValue.name.trim() === '') {
      setColorFormErrors('*Please provide color name')
      return
    }

    setColorValues((prev) => [
      ...prev,
      {
        code: colorFormValue.code,
        name: colorFormValue.name,
        isNew: true,
        id: Math.random(),
      },
    ])

    setColorFormValue({
      code: '',
      name: '',
    })

    setAddNewColorOption(false)
    setColorFormErrors(undefined)
  }

  const handleAddColorCancel = (): void => {
    setColorFormValue({
      code: '',
      name: '',
    })

    setAddNewColorOption(false)
    setColorFormErrors(undefined)
  }

  const handleRemoveColorOption = (index: number): void => {
    const updatedColorsValues = colorValues.filter((vl, idx) => idx !== index)

    setColorValues(updatedColorsValues)
  }

  return (
    <div>
      {error && <p className="text-sm text-red-500 my-2">{error}</p>}
      {option.optionId !== '' && (
        <div>
          <div className="mb-2">
            <Label text="Variant Name" color="secondary" />
            <Typography variant="body1" fontSize={16} fontWeight={600}>
              {option.name}
            </Typography>
          </div>
          <div className="mb-2">
            <Label text="Variant Values" color="secondary" />
            {option.name.toLowerCase() !== 'color' ? (
              <Typography variant="body1" fontSize={16} fontWeight={600}>
                {option.values.join(', ')}
              </Typography>
            ) : (
              <div className="flex items-center gap-2">
                {option.values.map((color, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'inlineblock',
                      backgroundColor: color,
                      height: '20px',
                      width: '20px',
                      borderRadius: 1,
                    }}
                  ></Box>
                ))}
              </div>
            )}
          </div>
          <div className="mb-2">
            <Label text="Primary" color="secondary" />
            <Typography variant="body1" fontSize={16} fontWeight={600}>
              {option.isPrimary ? 'Yes' : 'No'}
            </Typography>
          </div>
          <div className="my-4 flex justify-end">
            <Button
              variant="text"
              size="small"
              sx={{
                textTransform: 'none',
                color: 'black',
                backgroundColor: 'white',
                border: '1px solid #e0e0e0',
                mr: 2,
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                  color: 'black',
                },
                padding: '1px 16px',
                fontSize: '12px',
              }}
              disabled={isDeleting}
              onClick={() => handleOptionDelete(Number(option.optionId))}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </div>
        </div>
      )}
      {option.optionId === '' && (
        <>
          <div>
            <Label text="Variant Name" htmlFor="option.name" />
            <CreatableSelect
              name="option.name"
              value={
                optionName ? { label: optionName, value: optionName } : null
              }
              onChange={(newValue) => setOptionName(newValue?.value || '')}
              options={[
                { label: 'Size', value: 'Size' },
                { label: 'Color', value: 'Color' },
              ]}
              isClearable
              placeholder="Can be Size, Color or any custom variant option."
              styles={{
                control: (base, state) => ({
                  ...base,
                  minHeight: '40px',
                  backgroundColor: theme.palette.background.paper,
                  borderWidth: state.isFocused ? '2px' : '1px',
                  borderColor: state.isFocused
                    ? theme.palette.primary.main
                    : 'rgba(0, 0, 0, 0.23)',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: state.isFocused
                      ? theme.palette.primary.main
                      : '#333333',
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  fontSize: '0.85rem',
                }),
              }}
            />
          </div>
          <div className="mt-6">
            <Label text="Variant Values" htmlFor="option.values" />
            {optionName.toLowerCase() !== 'color' && (
              <CreatableSelect
                name="option.values"
                isMulti
                isClearable
                options={tagOptions}
                value={value}
                onChange={handleChange}
                placeholder="Sizes could be XS, S, M, etc., and colors could be Red, Green, Blue, etc."
                onCreateOption={(inputValue) => {
                  const newOption = {
                    label: inputValue.toUpperCase(),
                    value: inputValue.toUpperCase(),
                    id: Math.random(),
                  }
                  setTagOptions((prev) => [...prev, newOption])
                  setValue((prev) => [...(prev || []), newOption])
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    minHeight: '40px',
                    backgroundColor: theme.palette.background.paper,
                    borderWidth: state.isFocused ? '2px' : '1px',
                    borderColor: state.isFocused
                      ? theme.palette.primary.main
                      : 'rgba(0, 0, 0, 0.23)',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: state.isFocused
                        ? theme.palette.primary.main
                        : '#333333',
                    },
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 2,
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? theme.palette.action.selected
                      : state.isFocused
                        ? theme.palette.grey[400]
                        : theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    '&:hover': {
                      backgroundColor: theme.palette.grey[400],
                    },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    fontSize: '0.85rem',
                  }),
                }}
              />
            )}
            {optionName.toLowerCase() === 'color' && colorValues.length > 0 && (
              <div className="flex items-center mt-2 gap-2 flex-wrap">
                {colorValues.map((color, index) => (
                  <div
                    key={index}
                    className="flex items-center border border-solid rounded gap-2 p-1 bg-white"
                  >
                    <Box
                      sx={{
                        display: 'inlineblock',
                        backgroundColor: color.code,
                        height: '20px',
                        width: '20px',
                        borderRadius: 1,
                      }}
                    ></Box>
                    <Typography variant="body1">{color.name}</Typography>
                    <IconButton
                      sx={{
                        padding: 0,
                        color: 'text.primary',
                        ':hover': {
                          color: 'error.main',
                        },
                      }}
                      onClick={() => handleRemoveColorOption(index)}
                    >
                      <CloseRoundedIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
                {!addNewColorOption && (
                  <IconButton
                    sx={{ padding: 0, mx: 1.5 }}
                    onClick={() => setAddNewColorOption(true)}
                  >
                    <AddIcon sx={{ color: 'black' }} fontSize="small" />
                  </IconButton>
                )}
              </div>
            )}
            {optionName.toLowerCase() === 'color' &&
              (addNewColorOption || colorValues.length === 0) && (
                <div className="my-4">
                  <div className="flex items-center mt-4 gap-2">
                    <MuiColorInput
                      value={colorFormValue.code}
                      name="displayColor"
                      format="hex"
                      onChange={(value) => handleColorChange(value)}
                      size="small"
                      inputProps={{
                        style: {
                          backgroundColor: 'white',
                        },
                      }}
                      sx={{
                        maxWidth: '140px',
                        backgroundColor: 'white',
                      }}
                      label="Color"
                    />
                    <TextField
                      size="small"
                      variant="outlined"
                      onChange={handleColorNameChange}
                      value={colorFormValue.name}
                      placeholder="Enter color name"
                      inputProps={{
                        style: {
                          backgroundColor: 'white',
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={handleAddColor}
                      disableElevation
                      sx={{
                        textTransform: 'none',
                      }}
                    >
                      Add
                    </Button>
                    {colorValues.length > 0 && (
                      <Button
                        onClick={handleAddColorCancel}
                        disableElevation
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                  {colorFormErrors && (
                    <p className="text-red-500 text-xs mt-1 pl-2">
                      {colorFormErrors}
                    </p>
                  )}
                </div>
              )}
          </div>
          {optionName.toLowerCase() === 'size' && (
            <ButtonBase
              sx={{
                textTransform: 'none',
                textDecoration: 'underline',
                color: 'text.primary',
                fontSize: '14px',
                marginTop: '10px',
              }}
              onClick={handleGenerateStandardSizeOptions}
            >
              Generate Standard Size Options
            </ButtonBase>
          )}
          {!primaryOptionAdded && (
            <div className="mt-6">
              <Label text="Is this the Primary Variant?" />
              <FormControlLabel
                control={<Checkbox checked={isPrimary} />}
                label="Mark as primary variant"
                onChange={handlePrimaryOptionChange}
              />
              {isPrimary && (
                <Accordion sx={{ borderRadius: '8px' }} defaultExpanded>
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      backgroundColor: '#fff8e1',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      border: '1px solid #FFB300',
                    }}
                  >
                    <p className="flex items-center text-sm">
                      <InfoIcon className="mr-2" fontSize="small" />
                      What is a Primary Variant?
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="text-sm mt-2">
                      Choosing a &quot;primary variant&quot; means you set one
                      main price. Other variants will just change this price
                      slightly by adding or subtracting from it. This simplifies
                      the process, so you don&apos;t have to enter a price for
                      every possible combination.
                    </p>

                    <p className="font-bold mt-4 text-sm">Example:</p>

                    <p className="text-sm">
                      Suppose your primary variant is &quot;Full Set&quot;
                      priced at <span className="font-bold">₹1000</span>. For
                      another variant like &quot;Size&quot;, you might add{' '}
                      <span className="font-bold">₹300</span> for bigger sizes
                      (like 3XL) or subtract{' '}
                      <span className="font-bold">₹100</span> for smaller sizes
                      (like XS). Instead of entering a new price each time,
                      you&apos;re simply adjusting the main price (
                      <span className="font-bold">₹1000</span>).
                    </p>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          )}

          <div className="mt-8 flex justify-start">
            <Button
              variant="contained"
              size="small"
              disableElevation
              sx={{
                textTransform: 'none',
                marginRight: 2,
              }}
              disabled={disableDoneBtn}
              onClick={handleOptionDone}
            >
              Add Variant
            </Button>
            <Button
              variant="text"
              size="small"
              sx={{
                textTransform: 'none',
              }}
              onClick={handleRemoveOption}
            >
              Remove Variant
            </Button>
          </div>
        </>
      )}
      <hr className="my-4" />
    </div>
  )
}

export default OptionInput
