import { JSX, useEffect, useState } from 'react'

import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'

import { useAuth } from 'hooks/useAuth'
import { DataGridTable } from 'pages/dashboard/components/StyledDataGrid'
import { getAllProducts } from 'services/products'
import { Product } from 'services/products/index.types'
import { dateTimeFormat } from 'utils/dateFormat'
import { formatPrice } from 'utils/helper'

import { columns } from './Columns'

interface ProductListProps {
  products: Product[]
  rowCount: number
}

export const isOfferValid = (
  startOfferDate?: Date | null,
  endOfferDate?: Date | null
): boolean => {
  const currentDate = new Date()

  if (startOfferDate && endOfferDate) {
    return (
      new Date(startOfferDate) <= currentDate &&
      currentDate <= new Date(endOfferDate)
    )
  }

  if (startOfferDate) {
    return new Date(startOfferDate) <= currentDate
  }

  if (endOfferDate) {
    return currentDate <= new Date(endOfferDate)
  }

  return false
}

const ProductList: React.FC<ProductListProps> = ({
  products,
  rowCount,
}): JSX.Element => {
  const { organizationId } = useAuth()
  const [list, setList] = useState<Product[]>(products)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [totalRows, setTotalRows] = useState(rowCount)
  const [isLoading, setIsLoading] = useState(false)

  const [sortingModel, setSortingModel] = useState<{
    sortField: string
    sortDirection: 'asc' | 'desc' | ''
  }>({
    sortField: '',
    sortDirection: '',
  })

  useEffect(() => {
    async function fetchProducts(): Promise<void> {
      if (organizationId) {
        setIsLoading(true)
        try {
          const queryParams = {
            orgId: organizationId,
            offset: (
              paginationModel.page * paginationModel.pageSize
            ).toString(),
            limit: paginationModel.pageSize.toString(),
            ...(sortingModel.sortField !== '' &&
              sortingModel.sortDirection !== '' && {
                sort: sortingModel.sortField,
                order: sortingModel.sortDirection,
              }),
          }
          const result = await getAllProducts(queryParams)

          if (result && result.status === 'successful') {
            setList(result.data)
            setTotalRows(result.total || result.data.length)
          }
        } catch (error) {
          console.error('Error fetching products:', error)
        } finally {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    }

    fetchProducts()
  }, [organizationId, paginationModel, sortingModel])

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ): void => {
    setPaginationModel(newPaginationModel)
  }

  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel.length === 0) {
      setSortingModel({ sortField: '', sortDirection: '' })
    } else {
      setSortingModel({
        sortField: sortModel[0].field,
        sortDirection: sortModel[0].sort ?? '',
      })
    }
    setPaginationModel({ page: 0, pageSize: 25 })
  }

  const rows = list.map((product) => {
    const isOnOffer = isOfferValid(product.startOfferDate, product.endOfferDate)
    return {
      productCode: product.productCode,
      id: product.id,
      name: product.name,
      price: formatPrice(product.lowestPrice),
      isOnOffer: isOnOffer ? 'Yes' : 'No',
      isListed: `${product.isListed ? 'Yes' : 'No'}`,
      createdBy: product.createdByUser
        ? `${product.createdByUser.firstName} ${product.createdByUser.lastName}`
        : 'N/A',
      createdAt: dateTimeFormat(product.createdAt),
      updatedAt: dateTimeFormat(product.updatedAt),
      status: product.status,
      priority: product.priority,
    }
  })

  return (
    <div className="mx-5">
      <DataGridTable
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        rowCount={totalRows}
        disableRowSelectionOnClick
        disableColumnMenu
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        loading={isLoading}
      />
    </div>
  )
}

export default ProductList
