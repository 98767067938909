import { Customer } from 'services/customers/index.type'

export enum OrderStatusValue {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  RETURN_INITIATED = 'RETURN_INITIATED',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
}

export const statusColors: { [key in OrderStatusValue]: string } = {
  [OrderStatusValue.CREATED]: '#c0ca33', // Warm Amber
  [OrderStatusValue.PROCESSING]: '#4FC3F7', // Bright Sky Blue
  [OrderStatusValue.SHIPPED]: '#1565C0', // Navy Blue
  [OrderStatusValue.DELIVERED]: '#388E3C', // Forest Green
  [OrderStatusValue.RETURN_INITIATED]: '#FBC02D', // Bright Yellow
  [OrderStatusValue.REFUNDED]: '#ef6c00', // Crimson Red
  [OrderStatusValue.CANCELLED]: '#C62828', // Deep Red
}

export interface SelectedOptionMapping {
  id: number
  name: string
  value: {
    id: number
    value: string
  }
}
export interface ShippingAddress {
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  country: string
  pinCode: string
}

export interface Order {
  id: number
  firstName: string
  lastName: string
  totalPrice: number
  createdAt: string
  updatedAt: string
  status: string
  customerId: number
  paymentId: number
  shippingAddress: ShippingAddress
  billingAddress: ShippingAddress
  phoneNo: string
  extraAddressInfo: Record<string, unknown>
  note: string
  organizationId: number
  items: {
    productId: number
    name: string
    media?: string[]
    quantity: number
    price: number
    description?: string
    notes?: string
    selectedOptions?: SelectedOptionMapping[]
    productCode: string
  }[]
  customer: Customer
  orderStatuses: OrderStatus[]
  orgSeqNo: number
  uid: string
  shippingDate: Date | null
  expectedDeliveryDate: Date | null
  shippingProvider: string
  trackingId: string
  trackingLink: string
}

export interface OrderQueryParams {
  orgId: number
  cursor?: number
  pageSize?: number
  direction?: 'backward' | 'forward' | ''
  sortField?: string
  sortDirection?: 'asc' | 'desc' | ''
  filterField?: string
  filterValue?: string
  filterOperator?: string
  filterLogicOperator?: 'AND' | 'OR' | ''
}

export interface OrderStatus {
  id: number
  status: string
  description: string
  createdAt: string
}

export interface UpdateOrderParams {
  note?: string
  customerId?: number
  organizationId: number
  shippingDate?: Date | null
  expectedDeliveryDate?: Date | null
  shippingProvider?: string | null
  trackingId?: string | null
  trackingLink?: string | null
}
