import React, { useEffect, useState } from 'react'

import { Grid } from '@mui/material'

import LoadingSpinner from 'components/spinner'
import { useAuth } from 'hooks/useAuth'
import { TabHeader } from 'pages/dashboard/components/TabHeader'
import { getCategories } from 'services/products'

import CategoryCard, { Category } from './components/CategoryCard'

const CategoryList: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { organizationId } = useAuth()

  useEffect(() => {
    const fetchCategories = async (orgId: number) => {
      try {
        const result = await getCategories(orgId)
        if (result?.status === 'successful') {
          setCategories(result.data)
        }
      } catch (error) {
        console.error('Error fetching categories:', error)
      } finally {
        setLoading(false)
      }
    }

    if (organizationId) fetchCategories(organizationId)
  }, [organizationId])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="mb-5">
      <TabHeader title="Categories" />
      <Grid
        container
        paddingX={{ xs: 3, lg: 3 }}
        rowSpacing={{ xs: 5, sm: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        justifyContent={'flex-start'}
      >
        {categories.map((category) => (
          <Grid item key={category.id} xs={6} sm={4} md={3} xl={2}>
            <CategoryCard item={category} />
          </Grid>
        ))}
      </Grid>
      {categories.length === 0 && (
        <div className="mt-10 text-center text-sm text-gray-500">
          No categories found
        </div>
      )}
    </div>
  )
}

export default CategoryList
