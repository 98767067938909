import React from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Typography,
  IconButton,
  Paper,
  List,
  ListItem,
} from '@mui/material'

import theme from 'styles/muiTheme'

interface Invitation {
  id: number
  name: string
  organization: string
  slug: string
}

interface PendingInvitationsProps {
  inviteList: Array<{
    organization: {
      id: number
      name: string
      slug: string
    }
    InvitedByUser: {
      id: number
      firstName: string
      lastName: string
    } | null
  }>
  onAcceptInvitation: (inviteId: number) => Promise<void>
  onDeclineInvitation: (inviteId: number) => Promise<void>
}

const PendingInvitations: React.FC<PendingInvitationsProps> = ({
  inviteList,
  onAcceptInvitation,
  onDeclineInvitation,
}) => {
  const invitations: Invitation[] = inviteList.map((item) => ({
    id: item.organization.id,
    organization: item.organization.name,
    slug: item.organization.slug,
    name:
      item.InvitedByUser && item.InvitedByUser.firstName
        ? `${item.InvitedByUser.firstName}`
        : 'Unknown User',
  }))

  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-4">Pending Invitations</h2>

      <Paper
        className="m-5"
        sx={{
          backgroundColor: 'secondary.main',
          boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
        }}
      >
        <List component="div" disablePadding className="border-b">
          {invitations.map((invite) => (
            <div key={invite.id}>
              <ListItem
                className="px-5 py-2 border-b "
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',

                  rowGap: 1,
                }}
                disablePadding
              >
                <p className="text-sm">
                  {invite.name
                    ? `${invite.name} has invited you to join`
                    : 'You have been invited to join'}
                  {'  '}
                  <Typography
                    component="span"
                    sx={{
                      minWidth: '150px',
                      fontWeight: '700',
                      textDecoration: 'underline',
                      '&:hover': { cursor: 'pointer' },
                    }}
                  >
                    <a
                      href={`http://${invite.slug}.haulistic.io/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none',
                        color: theme.palette.primary.main,
                      }}
                    >
                      {invite.organization}
                    </a>
                  </Typography>
                </p>

                <div>
                  <div className="hidden md:flex">
                    <Button
                      variant="outlined"
                      size="small"
                      className="w-28"
                      sx={{
                        marginRight: 2,
                        fontWeight: 'bold',
                        backgroundColor: 'white',
                      }}
                      onClick={() => onAcceptInvitation(invite.id)}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className="w-28"
                      sx={{
                        backgroundColor: 'white',
                        fontWeight: 'bold',
                        border: '0.5px solid gray',
                        color: theme.palette.text.primary,
                        '&:hover': {
                          backgroundColor: theme.palette.grey[200],
                        },
                      }}
                      onClick={() => onDeclineInvitation(invite.id)}
                    >
                      Decline
                    </Button>
                  </div>
                  <div className=" flex md:hidden">
                    <IconButton
                      sx={{
                        color: theme.palette.success.main,
                        marginRight: 1,
                      }}
                      onClick={() => onAcceptInvitation(invite.id)}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        color: theme.palette.error.main,
                      }}
                      onClick={() => onDeclineInvitation(invite.id)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </ListItem>
            </div>
          ))}
        </List>
      </Paper>
    </div>
  )
}

export default PendingInvitations
