import { FC, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Label from 'components/forms/Label'
import QuillEditor from 'components/quill'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import PhoneNumberInput from 'pages/dashboard/components/PhoneNumberInput'
import { updateOrganization } from 'services/organization'
import {
  AddressJsonType,
  UserOrganizations,
} from 'services/organization/index.types'
import { getRestrictedCountriesList, getStatesOfCountry } from 'utils/countries'
import { getCountryCodeAndPhoneNumber } from 'utils/phoneNumber'

interface StoreCreateFormValues {
  name: string
  website: string
  email: string
  phoneNumber: string
  countryCode: string
  address: {
    addressLine1: string
    addressLine2?: string
    city: string
    state: string
    country: string
    pinCode: string
  }
  description: string
}

const phoneRegExp = /^((\+)?(\d{1,3}))?(\d{10})$/

const storeCreateFormSchema = Yup.object().shape({
  name: Yup.string().required('Store name is required'),
  website: Yup.string()
    .url('Enter a valid website URL')
    .required('Website is required'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Enter a valid phone number')
    .required('Phone number is required'),
  address: Yup.object()
    .shape({
      addressLine1: Yup.string().required(
        '*Apartment, Suite, etc. is required'
      ),
      addressLine2: Yup.string().required('*Address is required'),
      city: Yup.string().required('*City is required'),
      state: Yup.string().required('*State is required'),
      country: Yup.string().required('*Country is required'),
      pinCode: Yup.string().required('*Pin Code is required'),
    })
    .optional(),
  description: Yup.string().nullable().optional(),
})

interface EditStoreProps {
  store: UserOrganizations
  onUpdate: () => void
  onEditClose: () => void
}

const EditStore: FC<EditStoreProps> = ({ store, onUpdate, onEditClose }) => {
  const { organizationId, updateOrganizationData } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [selectedCountry, setSelectedCountry] = useState<string>(
    store.organization.address
      ? JSON.parse(store.organization.address).country
      : ''
  )

  const countriesList = getRestrictedCountriesList()

  const restrictTo = countriesList.find(
    (country) => country.name === selectedCountry
  )?.isoCode
  const statesList = getStatesOfCountry(restrictTo)

  const phoneNoData =
    store.organization.phoneNumber &&
    /^(\+\d{1,3})?\d{10}$/.test(store.organization.phoneNumber)
      ? getCountryCodeAndPhoneNumber(store.organization.phoneNumber)
      : undefined

  const address: AddressJsonType = store.organization.address
    ? JSON.parse(store.organization.address)
    : {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pinCode: '',
      }

  const initialValues = {
    name: store.organization.name,
    website: store.organization.website ?? '',
    email: store.organization.email ?? '',
    phoneNumber:
      phoneNoData && phoneNoData.phoneNumber
        ? phoneNoData.phoneNumber.toString()
        : '',
    countryCode:
      phoneNoData && phoneNoData.countryCode
        ? '+' + phoneNoData.countryCode.toString()
        : '',
    address: {
      addressLine1: address.addressLine1 ?? '',
      addressLine2: address.addressLine2 ?? '',
      city: address.city,
      state: address.state,
      country: address.country,
      pinCode: address.pinCode,
    },
    description: store.organization.description ?? '',
  }

  const handleOnChange =
    (setFieldValue: FormikHelpers<StoreCreateFormValues>['setFieldValue']) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      const { name, value } = event.target
      setFieldValue(name, value)
    }

  const handleStoreSetup = async (
    values: StoreCreateFormValues
  ): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('STORE_DETAILS_SAVE_CLICKED', {
        orgId: organizationId,
      })
    }
    const { name, email, phoneNumber, countryCode, description, address } =
      values

    const result = organizationId
      ? await updateOrganization(organizationId, {
          name,
          email,
          phoneNumber: countryCode + phoneNumber,
          address,
          description,
        })
      : { status: 'failed' }

    if (result?.status === 'successful' && result.data) {
      showSnackbar('Store updated successfully.', 'success')
      updateOrganizationData(result.data)
      onUpdate()
    } else if (result?.status === 'failed' && result.message) {
      showSnackbar(result.message, 'error')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={storeCreateFormSchema}
      onSubmit={handleStoreSetup}
    >
      {({
        setFieldValue,
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
      }) => (
        <Form className="w-full">
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <Label text="Name" htmlFor="name" color="secondary" />
              <CustomTextField
                name="name"
                value={values.name}
                onChange={handleOnChange(setFieldValue)}
                placeholder="Your store name"
                error={touched.name && Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                  marginBottom: '0.5rem',
                }}
              >
                Website
              </Typography>
              {store.organization.website ? (
                <a
                  href={store.organization.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontWeight: '700',
                  }}
                >
                  {store.organization.website}
                </a>
              ) : (
                <Typography sx={{ fontWeight: '700' }}>N/A</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Email" htmlFor="email" color="secondary" />
              <CustomTextField
                name="email"
                value={values.email}
                placeholder="support@yourstorename.haulistic.io"
                onChange={handleOnChange(setFieldValue)}
                error={touched.email && Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label
                text="Phone Number"
                htmlFor="phoneNumber"
                color="secondary"
              />
              <PhoneNumberInput
                countriesList={countriesList}
                countryCodeName="countryCode"
                phoneNumberName="phoneNumber"
                value={{
                  countryCode: values.countryCode,
                  phoneNumber: values.phoneNumber,
                }}
                error={{
                  countryCode:
                    touched.countryCode && errors.countryCode
                      ? errors.countryCode
                      : '',
                  phoneNumber:
                    touched.phoneNumber && errors.phoneNumber
                      ? errors.phoneNumber
                      : '',
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={{
                  phoneNumber: errors.phoneNumber,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Label
                text="Country"
                htmlFor="address.country"
                color="secondary"
              />
              <TextField
                SelectProps={{ style: { backgroundColor: 'white' } }}
                onChange={(e) => {
                  handleChange(e)
                  setSelectedCountry(e.target.value)
                }}
                onBlur={handleBlur}
                name={'address.country'}
                value={values.address.country}
                sx={{ marginTop: 0 }}
                fullWidth
                id="country"
                variant="outlined"
                margin="normal"
                size="small"
                error={
                  touched.address?.country && Boolean(errors.address?.country)
                }
                helperText={touched.address?.country && errors.address?.country}
                select
              >
                {countriesList.map((val) => (
                  <MenuItem
                    key={val.name}
                    value={val.name}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'lightgray',
                      },
                    }}
                  >
                    {`${val.name}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <Label
                text="Apartment, Suite, etc."
                htmlFor="address.addressLine1"
                color="secondary"
              />
              <CustomTextField
                name="address.addressLine1"
                value={values.address.addressLine1}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.address?.addressLine1 &&
                  Boolean(errors.address?.addressLine1)
                }
                helperText={<ErrorMessage name="address.addressLine1" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Label
                text="Address"
                htmlFor="address.addressLine2"
                color="secondary"
              />
              <CustomTextField
                name="address.addressLine2"
                value={values.address.addressLine2}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.address?.addressLine2 &&
                  Boolean(errors.address?.addressLine2)
                }
                helperText={<ErrorMessage name="address.addressLine2" />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Label text="City" htmlFor="address.city" color="secondary" />
              <CustomTextField
                name="address.city"
                value={values.address.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.address?.city && Boolean(errors.address?.city)}
                helperText={<ErrorMessage name="address.city" />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Label text="State" htmlFor="address.state" color="secondary" />
              <TextField
                SelectProps={{ style: { backgroundColor: 'white' } }}
                onChange={handleChange}
                onBlur={handleBlur}
                name={'address.state'}
                value={values.address.state}
                sx={{ marginTop: 0 }}
                fullWidth
                id="state"
                variant="outlined"
                margin="normal"
                size="small"
                error={touched.address?.state && Boolean(errors.address?.state)}
                helperText={touched.address?.state && errors.address?.state}
                select
              >
                {statesList.length === 0 && (
                  <MenuItem disabled>Choose country to select state</MenuItem>
                )}
                {statesList.map((val) => (
                  <MenuItem
                    key={val.name}
                    value={val.name}
                    sx={{
                      fontSize: '0.75rem',
                      padding: '2px 8px',
                      '&:hover': {
                        backgroundColor: 'lightgray',
                      },
                    }}
                  >
                    {val.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <Label
                text="Pin Code"
                htmlFor="address.pinCode"
                color="secondary"
              />
              <CustomTextField
                name="address.pinCode"
                value={values.address.pinCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.address?.pinCode && Boolean(errors.address?.pinCode)
                }
                helperText={<ErrorMessage name="address.pinCode" />}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Label
                text="Description"
                htmlFor="description"
                color="secondary"
              />
              <QuillEditor
                value={values.description}
                onChange={(val) => setFieldValue('description', val)}
                limit={1000}
              />
            </Grid>
          </Grid>
          <Box display={'flex'} alignItems={'center'} mt={4} columnGap={2}>
            <Button onClick={onEditClose} variant="outlined" size="small">
              Cancel
            </Button>
            <Button type="submit" variant="contained" size="small">
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default EditStore
