import { getToken } from 'utils/authCookies'

import { CheckServiceParams, CourierData } from './index.types'

const deliveryBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/delivery`

export const checkCourierServiceability = async (
  values: CheckServiceParams
): Promise<{ status: string; data?: CourierData; message?: string }> => {
  const token = await getToken()

  try {
    const res = await fetch(`${deliveryBasePath}/service`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(values),
    })

    const { data, message } = await res.json()

    if (res.ok) {
      return { status: 'successful', data: data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}
