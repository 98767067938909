import React, { useEffect, useState } from 'react'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Select, { SingleValue } from 'react-select'

import GenericChip from 'components/generic-chip'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { getAllProducts } from 'services/products'
import { getReviews, updateExistingReview } from 'services/products/reviews'

import ReviewsList from './components/ReviewsList'
import placeholderImage from '../../../../../../assests/img/sunrise-placeholder.png'

export interface Reviews {
  id: number
  productId: number
  productName: string
  name: string
  email: string
  rating: number
  reviewTitle?: string
  reviewText?: string
  reviewStatus: string
  media: Array<string>
  organizationId: number
  createdAt: string
  updatedAt: string
}

export interface Product {
  id: number
  name: string
  media: Array<string>
  productCode: string
}

interface ProductOption {
  value: number
  label: string
  customLabel: React.ReactNode
}

const Reviews: React.FC = () => {
  const navigate = useNavigate()
  const [reviews, setReviews] = useState<Reviews[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<
    'pending' | 'approved' | 'rejected'
  >('pending')
  const { organizationId } = useAuth()
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  )
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    const fetchReviews = async (orgId: number, status: string) => {
      setLoading(true)
      try {
        const result = await getReviews(orgId, status)
        if (result?.status === 'successful') {
          setReviews(result.data)
        } else {
          setReviews([])
        }
      } catch (error) {
        console.error('Error fetching Reviews:', error)
        setReviews([])
      } finally {
        setLoading(false)
      }
    }

    const fetchProducts = async (orgId: number) => {
      try {
        const result = await getAllProducts({ orgId })
        if (result?.status === 'successful') {
          setProducts(result.data)
        } else {
          setProducts([])
        }
      } catch (error) {
        console.error('Error fetching Products:', error)
        setProducts([])
      }
    }

    if (organizationId) {
      fetchReviews(organizationId, activeTab)
      fetchProducts(organizationId)
    }
  }, [organizationId, activeTab])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    params.set('type', activeTab)

    const newUrl = `${window.location.pathname}?${params.toString()}`
    window.history.replaceState({}, '', newUrl)
  }, [activeTab])

  const tabs: {
    label: string
    value: 'pending' | 'approved' | 'rejected'
    color: string
  }[] = [
    { label: 'PENDING REVIEW', value: 'pending', color: '#F0BD4E' },
    { label: 'APPROVED', value: 'approved', color: '#5CBB5C' },
    { label: 'REJECTED', value: 'rejected', color: '#FF6C6C' },
  ]

  const productOptions = products.map((product) => ({
    value: product.id,
    label: product.name,
    customLabel: (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <img
          src={product.media?.[0] || placeholderImage}
          alt={product.name}
          style={{
            width: '40px',
            height: '40px',
            objectFit: 'cover',
            borderRadius: '4px',
          }}
        />
        <span
          className="text-sm"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'full',
          }}
          title={product.name}
        >
          {product.name}
        </span>
      </div>
    ),
  }))

  const handleProductChange = (
    selectedOption: SingleValue<ProductOption | null>
  ) => {
    setSelectedProductId(selectedOption ? selectedOption.value : null)
  }

  const handleStatusChange = async (reviewId: number, status: string) => {
    if (organizationId === null) {
      showSnackbar(
        'Organization ID is missing. Please try again later.',
        'error'
      )
      return
    }

    try {
      const { status: updateStatus } = await updateExistingReview(
        reviewId,
        organizationId,
        status
      )

      if (updateStatus !== 'successful') {
        showSnackbar(
          'Failed to update review status. Please try again later.',
          'error'
        )
        return
      }

      showSnackbar('Review Status Changed.', 'success')
      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.id === reviewId ? { ...review, reviewStatus: status } : review
        )
      )
      const result = await getReviews(organizationId, activeTab)
      if (result?.status !== 'successful') {
        showSnackbar(
          'Failed to fetch updated reviews. Please try again later.',
          'error'
        )
        return
      }

      setReviews(result.data)
    } catch (error) {
      showSnackbar(
        'Error updating review status. Please try again later.',
        'error'
      )
    }
  }

  const displayedReviews =
    selectedProductId !== null
      ? reviews.filter((review) => review.productId === selectedProductId)
      : reviews

  const handleTabClick = (value: 'pending' | 'approved' | 'rejected') => {
    setActiveTab(value)
  }

  return (
    <div>
      <Box display="flex" alignItems="flex-start" gap={2} marginRight={1}>
        <button onClick={() => navigate(-1)} className="ml-5">
          <KeyboardBackspaceIcon />
        </button>
        <Typography
          sx={{ fontWeight: '700', fontSize: '1.2rem', marginBottom: '1px' }}
        >
          Manage Reviews
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mt-2 mb-4 px-5"
      >
        <Box marginY={2} display="flex">
          {tabs.map((tab) => (
            <GenericChip
              key={tab.value}
              label={tab.label}
              value={tab.value}
              color={tab.color}
              isSelected={activeTab === tab.value}
              onClick={handleTabClick}
            />
          ))}
        </Box>

        <Box
          width="300px"
          sx={{ marginTop: { xs: 1, sm: 0 } }}
          className="hidden md:visible"
        >
          <Select
            options={productOptions}
            onChange={handleProductChange}
            placeholder="Search Products"
            isClearable
            isSearchable
            formatOptionLabel={(option) => option.customLabel}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: theme.colors.neutral20,
              },
            })}
          />
        </Box>
      </Box>

      <Grid container sx={{ paddingX: '20px' }}>
        {loading && organizationId ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <ReviewsList
            products={products}
            reviews={displayedReviews}
            activeTab={activeTab}
            orgId={organizationId!}
            onStatusChange={handleStatusChange}
          />
        )}
      </Grid>
      {displayedReviews.length === 0 && (
        <div className="mt-10 text-center text-sm text-gray-500">
          No reviews found
        </div>
      )}
    </div>
  )
}

export default Reviews
