import { useState, useEffect, useRef } from 'react'

import { Box, useTheme } from '@mui/material'

interface DescriptionInputProps {
  value?: string | null
  onChange: (note: string) => void
  limit?: number
  error?: boolean
}

const DescriptionInput = ({
  value = '',
  onChange,
  limit = 1000,
  error,
}: DescriptionInputProps) => {
  value = value || ''
  const [charCount, setCharCount] = useState(value.length)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const theme = useTheme()

  useEffect(() => {
    adjustHeight()
  }, [value])

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newText = e.target.value
    if (newText.length > limit) {
      newText = newText.substring(0, limit)
    }
    onChange(newText)
    setCharCount(newText.length)
    adjustHeight()
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')
    const allowedText = pastedText.substring(0, limit - charCount)
    const newText = value + allowedText
    onChange(newText)
    setCharCount(newText.length)
    adjustHeight()
  }

  return (
    <Box mt={2} className="editor-container">
      <textarea
        ref={textareaRef}
        name="description"
        className="w-full border border-gray-300 rounded py-2 px-3 overflow-hidden resize-none"
        rows={3}
        value={value}
        onChange={handleNotesChange}
        onPaste={handlePaste}
        style={{
          minHeight: '3rem',
          maxHeight: '300px',
          outline: 'none',
          border: error ? '1px solid red' : '1px solid #ccc',
        }}
        onFocus={(e) =>
          (e.target.style.outline = `1px solid ${theme.palette.primary.main}`)
        }
        onBlur={(e) => (e.target.style.outline = 'none')}
      />
      <div className="text-right text-sm text-gray-500">
        {charCount}/{limit} characters
      </div>
    </Box>
  )
}

export default DescriptionInput
