import { ChangeEvent, FC, useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { MuiColorInput } from 'mui-color-input'
import { MultiValue, SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { createProductOption, updateProductOption } from 'services/products'
import { Product, ProductOption } from 'types/product'
import { isValidHex } from 'utils/helper'
type OptionType = {
  label: string
  value: string
  id: number
  isNew: boolean
}

interface ColorValue {
  code: string
  name: string
  id: number
  isNew: boolean
}

interface AddNewVariantFormProps {
  currentValues?: ProductOption
  isPrimaryPresent?: boolean
  openDialog: boolean
  onCloseDialog: () => void
  product: Product
  onUpdate?: (p: Product) => void
}

const AddNewVariantForm: FC<AddNewVariantFormProps> = ({
  currentValues,
  isPrimaryPresent,
  openDialog,
  onCloseDialog,
  product,
  onUpdate,
}) => {
  const theme = useTheme()
  const [optionName, setOptionName] = useState<string>('')
  const [tagOptions, setTagOptions] = useState<MultiValue<OptionType>>([])
  const [values, setValues] = useState<MultiValue<OptionType>>()
  const [isPrimary, setIsPrimary] = useState<boolean>(false)
  const [errors, setErrors] = useState<{
    name: string | null
    values: string | null
  }>({ name: null, values: null })
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [colorValues, setColorValues] = useState<ColorValue[]>([])
  const [colorFormValue, setColorFormValue] = useState({
    code: '',
    name: '',
  })
  const [addNewColorOption, setAddNewColorOption] = useState(false)
  const [colorFormErrors, setColorFormErrors] = useState<string>()

  useEffect(() => {
    if (currentValues) {
      setOptionName(currentValues.name)
      if (currentValues.name.toLowerCase() !== 'color') {
        const values = currentValues.ProductOptionValue.map((vl) => ({
          label: vl.value,
          value: vl.value,
          id: vl.id,
          isNew: false,
        }))
        setValues(values)
      } else {
        const values = currentValues.ProductOptionValue.map((vl) => ({
          code: vl.value,
          name: vl.caption,
          id: vl.id,
          isNew: false,
        }))
        setColorValues(values)
      }
      setIsPrimary(currentValues.type === 'PRIMARY')
    }
  }, [currentValues])

  const handleOptionNameChange = (newValue: SingleValue<{ value: string }>) => {
    setOptionName(newValue?.value || '')

    if (errors?.name && newValue?.value.trim() !== '') {
      setErrors((prev) => ({ ...prev, name: null }))
    }
  }

  const handleChange = (newValue: MultiValue<OptionType>): void => {
    setValues(newValue)

    if (errors.values || newValue.length > 1) {
      setErrors((prev) => ({ ...prev, values: null }))
    }
  }

  const handlePrimaryOptionChange = (): void => {
    setIsPrimary(!isPrimary)
  }

  const handleGenerateStandardSizeOptions = (): void => {
    const sizeOptions = [
      { label: 'XXS', value: 'XXS', id: 1, isNew: true },
      { label: 'XS', value: 'XS', id: 2, isNew: true },
      { label: 'S', value: 'S', id: 3, isNew: true },
      { label: 'M', value: 'M', id: 4, isNew: true },
      { label: 'L', value: 'L', id: 5, isNew: true },
      { label: 'XL', value: 'XL', id: 6, isNew: true },
      { label: 'XXL', value: 'XXL', id: 7, isNew: true },
      { label: '3XL', value: '3XL', id: 8, isNew: true },
      { label: '4XL', value: '4XL', id: 9, isNew: true },
    ]
    setTagOptions(sizeOptions)
    setValues(sizeOptions)
  }

  let disableAddBtn = optionName.trim() === '' || (values ?? []).length <= 1

  if (optionName.toLowerCase().trim() === 'color')
    disableAddBtn = colorValues.length <= 1

  const handleAdd = async () => {
    if (optionName.trim() === '') {
      setErrors((prev) => ({ ...prev, name: '*Variant name is required' }))
      return
    }

    if (
      optionName.toLowerCase().trim() !== 'color' &&
      (!values || values?.length <= 1)
    ) {
      setErrors((prev) => ({ ...prev, values: '*Provide atleast two value' }))
      return
    }

    if (optionName.toLowerCase().trim() === 'color' && colorValues.length < 2) {
      setErrors((prev) => ({
        ...prev,
        values: '*Provide atleast two color options',
      }))
      return
    }

    setIsSubmitting(true)

    const formattedOptionName =
      optionName.charAt(0).toUpperCase() + optionName.slice(1).toLowerCase()

    if (currentValues) {
      let data
      if (formattedOptionName.toLowerCase().trim() === 'color') {
        const existingValues = colorValues.filter((vl) => vl.id && !vl.isNew)
        const newValues = colorValues.filter((vl) => vl.isNew)
        data = {
          name: formattedOptionName,
          values: [
            ...existingValues.map((vl) => ({
              value: vl.code,
              caption: vl.name,
              valueId: vl.id,
            })),
            ...newValues.map((vl) => ({ value: vl.code, caption: vl.name })),
          ],
          type: isPrimary ? 'PRIMARY' : 'SECONDARY',
        }
      } else {
        const existingValues = (values ?? []).filter((vl) => vl.id && !vl.isNew)
        const newValues = (values ?? []).filter((vl) => vl.isNew)
        data = {
          name: formattedOptionName,
          values: [
            ...existingValues.map((vl) => ({
              value: vl.label,
              valueId: vl.id,
            })),
            ...newValues.map((vl) => ({ value: vl.label })),
          ],
          type: isPrimary ? 'PRIMARY' : 'SECONDARY',
        }
      }

      const res = organizationId
        ? await updateProductOption(
            organizationId,
            product.productCode,
            currentValues.id,
            data
          )
        : { status: 'failed', message: 'Please try again later.' }

      if (res.status === 'successful' && res.data) {
        const updatedProduct = {
          ...product,
          ProductOption: (product.ProductOption || [])
            .map((opt) => (opt?.id === currentValues.id ? res.data : opt))
            .filter((opt): opt is ProductOption => opt !== undefined),
        }

        onUpdate?.(updatedProduct)
      } else if (res.status === 'failed') {
        showSnackbar(res.message || 'Please try again later.', 'error')
      }
    } else {
      let data
      if (formattedOptionName.toLowerCase().trim() === 'color') {
        data = {
          name: formattedOptionName,
          values: colorValues.map((vl) => ({
            value: vl.code,
            caption: vl.name,
          })),
          type: isPrimary ? 'PRIMARY' : 'SECONDARY',
        }
      } else {
        data = {
          name: formattedOptionName,
          values: values
            ? values.map((vl) => ({
                value: vl.label,
              }))
            : [],
          type: isPrimary ? 'PRIMARY' : 'SECONDARY',
        }
      }
      const res = organizationId
        ? await createProductOption(product.productCode, organizationId, data)
        : { status: 'failed', message: 'Please try again later.' }

      if (res.status === 'successful' && res.data) {
        const updatedProduct = {
          ...product,
          ProductOption: [...(product.ProductOption || []), res.data],
        }
        onUpdate?.(updatedProduct)
      } else if (res.status === 'failed') {
        showSnackbar(res.message || 'Please try again later.', 'error')
      }
    }

    setIsSubmitting(false)
    onCloseDialog()
    setOptionName('')
    setTagOptions([])
    setValues(undefined)
    setIsPrimary(false)
    setColorValues([])
    setColorFormErrors(undefined)
  }

  const handleClose = () => {
    if (currentValues) {
      setOptionName(currentValues.name)
      if (currentValues.name.toLowerCase() !== 'color') {
        const values = currentValues.ProductOptionValue.map((vl) => ({
          label: vl.value,
          value: vl.value,
          id: vl.id,
          isNew: false,
        }))
        setValues(values)
      } else {
        const values = currentValues.ProductOptionValue.map((vl) => ({
          code: vl.value,
          name: vl.caption,
          id: vl.id,
          isNew: false,
        }))
        setColorValues(values)
      }

      setIsPrimary(currentValues.type === 'PRIMARY')
    } else {
      setOptionName('')
      setValues(undefined)
      setTagOptions([])
      setIsPrimary(false)
      setColorValues([])
      setColorFormErrors(undefined)
    }
    onCloseDialog?.()
  }

  const handleColorChange = (value: string): void => {
    setColorFormValue((prev) => ({ ...prev, code: value }))
    setColorFormErrors(undefined)
  }

  const handleColorNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setColorFormValue((prev) => ({ ...prev, name: value }))
    setColorFormErrors(undefined)
  }

  const handleAddColor = (): void => {
    if (colorFormValue.code.trim() === '' || !isValidHex(colorFormValue.code)) {
      setColorFormErrors('*Invalid color value')
      return
    }
    if (colorFormValue.name.trim() === '') {
      setColorFormErrors('*Please provide color name')
      return
    }

    setColorValues((prev) => [
      ...prev,
      {
        code: colorFormValue.code,
        name: colorFormValue.name,
        isNew: true,
        id: Math.random(),
      },
    ])

    setColorFormValue({
      code: '',
      name: '',
    })

    setAddNewColorOption(false)
    if (errors.values) {
      setErrors((prev) => ({ ...prev, values: null }))
    }
    setColorFormErrors(undefined)
  }

  const handleAddColorCancel = (): void => {
    setColorFormValue({
      code: '',
      name: '',
    })

    setAddNewColorOption(false)
    setColorFormErrors(undefined)
  }

  const handleRemoveColorOption = (index: number): void => {
    const updatedColorsValues = colorValues.filter((vl, idx) => idx !== index)

    setColorValues(updatedColorsValues)
  }

  return (
    <>
      <Dialog fullWidth open={openDialog} onClose={onCloseDialog}>
        <DialogTitle>
          <div className="flex items-center justify-between">
            <div>
              {currentValues ? 'Edit Variant Option' : 'Add New Variant Option'}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="mb-4">
            <Label text="Variant Name" color="secondary" />
            <CreatableSelect
              name="name"
              value={
                optionName ? { label: optionName, value: optionName } : null
              }
              onChange={handleOptionNameChange}
              options={[
                { label: 'Size', value: 'Size' },
                { label: 'Color', value: 'Color' },
              ]}
              isClearable
              placeholder="Can be Size, Color or any custom variant option."
              styles={{
                control: (base, state) => ({
                  ...base,
                  minHeight: '40px',
                  backgroundColor: theme.palette.background.paper,
                  borderWidth: state.isFocused ? '2px' : '1px',
                  borderColor: state.isFocused
                    ? theme.palette.primary.main
                    : 'rgba(0, 0, 0, 0.23)',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: state.isFocused
                      ? theme.palette.primary.main
                      : '#333333',
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  fontSize: '0.85rem',
                }),
              }}
            />
            {errors.name && (
              <p className="text-red-500 text-xs mt-1 pl-2">{errors.name}</p>
            )}
          </div>
          <div className=" mt-2">
            <Label text="Variant Values" color="secondary" />
            {optionName.toLowerCase() !== 'color' && (
              <CreatableSelect
                name="values"
                isMulti
                isClearable
                options={tagOptions}
                value={values}
                onChange={handleChange}
                placeholder="Sizes could be XS, S, M, etc., and colors could be Red, Green, Blue, etc."
                onCreateOption={(inputValue) => {
                  const newOption = {
                    label: inputValue.toUpperCase(),
                    value: inputValue.toUpperCase(),
                    id: Math.random(),
                    isNew: true,
                  }
                  setTagOptions((prev) => [...prev, newOption])
                  setValues((prev) => [...(prev || []), newOption])
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    minHeight: '40px',
                    backgroundColor: theme.palette.background.paper,
                    borderWidth: state.isFocused ? '2px' : '1px',
                    borderColor: state.isFocused
                      ? theme.palette.primary.main
                      : 'rgba(0, 0, 0, 0.23)',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: state.isFocused
                        ? theme.palette.primary.main
                        : '#333333',
                    },
                    marginY: 4,
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 2,
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? theme.palette.action.selected
                      : state.isFocused
                        ? theme.palette.grey[400]
                        : theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    '&:hover': {
                      backgroundColor: theme.palette.grey[400],
                    },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    fontSize: '0.85rem',
                  }),
                }}
              />
            )}
            {optionName.toLowerCase() === 'color' && colorValues.length > 0 && (
              <div className="flex items-center mt-2 gap-2 flex-wrap">
                {colorValues.map((color, index) => (
                  <div
                    key={index}
                    className="flex items-center border border-solid rounded gap-2 p-1"
                  >
                    <Box
                      sx={{
                        display: 'inlineblock',
                        backgroundColor: color.code,
                        height: '20px',
                        width: '20px',
                        borderRadius: 1,
                      }}
                    ></Box>
                    <Typography variant="body1">{color.name}</Typography>
                    <IconButton
                      sx={{
                        padding: 0,
                        color: 'text.primary',
                        ':hover': {
                          color: 'error.main',
                        },
                      }}
                      onClick={() => handleRemoveColorOption(index)}
                    >
                      <CloseRoundedIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
                {!addNewColorOption && (
                  <IconButton
                    sx={{ padding: 0, mx: 1.5 }}
                    onClick={() => setAddNewColorOption(true)}
                  >
                    <AddIcon sx={{ color: 'black' }} fontSize="small" />
                  </IconButton>
                )}
              </div>
            )}
            {optionName.toLowerCase() === 'color' &&
              (addNewColorOption || colorValues.length === 0) && (
                <div className="my-4">
                  <div className="flex items-center my-4 gap-2">
                    <MuiColorInput
                      value={colorFormValue.code}
                      name="displayColor"
                      format="hex"
                      onChange={(value) => handleColorChange(value)}
                      size="small"
                      inputProps={{
                        style: {
                          backgroundColor: 'white',
                        },
                      }}
                      sx={{
                        maxWidth: '140px',
                      }}
                      label="Color"
                    />
                    <TextField
                      size="small"
                      variant="outlined"
                      onChange={handleColorNameChange}
                      value={colorFormValue.name}
                      placeholder="Enter color name"
                    />

                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleAddColor}
                      disableElevation
                      sx={{
                        textTransform: 'none',
                      }}
                    >
                      Add
                    </Button>
                    {colorValues.length > 0 && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleAddColorCancel}
                        disableElevation
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                  {colorFormErrors && (
                    <p className="text-red-500 text-xs mt-1 pl-2">
                      {colorFormErrors}
                    </p>
                  )}
                </div>
              )}
            {errors.values && (
              <p className="text-red-500 text-xs mt-1 pl-2">{errors.values}</p>
            )}
          </div>
          {optionName.toLowerCase() === 'size' && (
            <ButtonBase
              sx={{
                textTransform: 'none',
                textDecoration: 'underline',
                color: 'text.primary',
                fontSize: '14px',
                marginTop: '10px',
              }}
              onClick={handleGenerateStandardSizeOptions}
            >
              Generate Standard Size Options
            </ButtonBase>
          )}

          {!isPrimaryPresent && (
            <div className="my-2">
              <FormControlLabel
                control={<Checkbox checked={isPrimary} />}
                label="Mark as primary variant"
                onChange={handlePrimaryOptionChange}
              />
              <Accordion sx={{ borderRadius: '8px' }} defaultExpanded>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    backgroundColor: '#fff8e1',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    border: '1px solid #FFB300',
                  }}
                >
                  <p className="flex items-center text-sm">
                    <InfoIcon className="mr-2" fontSize="small" />
                    What is a Primary Variant2?
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text-sm mt-2">
                    Choosing a &quot;primary variant&quot; means you set one
                    main price. Other variants will just change this price
                    slightly by adding or subtracting from it. This simplifies
                    the process, so you don&apos;t have to enter a price for
                    every possible combination.
                  </p>

                  <p className="font-bold mt-4 text-sm">Example:</p>

                  <p className="text-sm">
                    Suppose your primary variant is &quot;Full Set&quot; priced
                    at <span className="font-bold">₹1000</span>. For another
                    variant like &quot;Size&quot;, you might add{' '}
                    <span className="font-bold">₹300</span> for bigger sizes
                    (like 3XL) or subtract{' '}
                    <span className="font-bold">₹100</span> for smaller sizes
                    (like XS). Instead of entering a new price each time,
                    you&apos;re simply adjusting the main price (
                    <span className="font-bold">₹1000</span>).
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <div className="flex">
            <Button
              disableElevation
              sx={{ textTransform: 'none', marginRight: 2 }}
              size="small"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{ textTransform: 'none' }}
              size="small"
              onClick={handleAdd}
              disabled={isSubmitting || disableAddBtn}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddNewVariantForm
