import { JSX, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import GenericChip from 'components/generic-chip'
import CustomCell from 'components/table/CustomCell'
import { useAuth } from 'hooks/useAuth'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { DataGridTable } from 'pages/dashboard/components/StyledDataGrid'
import { getCheckoutSessions } from 'services/checkout'
import theme from 'styles/muiTheme'
import { CheckoutSession } from 'types/checkout'
import { formatPrice } from 'utils/helper'

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 150,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'fullName',
    headerName: 'Name',
    hideable: false,
    width: 150,
    renderCell: (params) => (
      <CustomCell {...params} route="orders/checkout-sessions" />
    ),
  },
  {
    field: 'createdAt',
    width: 210,
    headerName: 'Created',
    type: 'dateTime',
    filterable: false,
    valueFormatter: (value: string): string => {
      return format(new Date(value), 'MMM d, yyyy, h:mm a')
    },
  },
  {
    field: 'totalPrice',
    width: 100,
    type: 'number',
    headerName: 'Total',
    valueFormatter: (value: number): number => {
      return value ?? 0
    },
    filterable: false,
  },
  {
    field: 'paymentId',
    headerName: 'Payemnt Id',
    width: 150,
    type: 'string',
  },
  {
    field: 'shippingAddress',
    width: 150,
    type: 'number',
    headerName: 'Delivery Pin Code',
    sortable: false,
    filterable: false,
  },
  {
    field: 'phoneNo',
    width: 150,
    type: 'string',
    headerName: 'Phone Number',
    sortable: false,
  },
  {
    field: 'status',
    width: 150,
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
  },
]

const statusChips = [
  {
    label: 'ALL',
    value: 'ALL',
    color: theme.palette.primary.main,
  },
  {
    label: 'CREATED',
    value: 'CREATED',
    color: '#388E3C',
  },
  {
    label: 'COMPLETED',
    value: 'COMPLETED',
    color: '#4FC3F7',
  },
]

const CheckoutSessionsPage = (): JSX.Element => {
  const { organizationId, organization } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedStatus = searchParams.get('status') || 'ALL'
  const [list, setList] = useState<CheckoutSession[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rowCount, setRowCount] = useState<number>(0)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  const handleChipClick = (status: 'CREATED' | 'COMPLETED') => {
    setSearchParams({ status: status })
    setPaginationModel({ page: 0, pageSize: 25 })
  }

  useEffect(() => {
    async function initialFetch(): Promise<void> {
      if (organizationId) {
        setIsLoading(true)
        const result = await getCheckoutSessions({
          orgId: organizationId as number,
          offset: paginationModel.page * paginationModel.pageSize,
          limit: paginationModel.pageSize,
          status: selectedStatus === 'ALL' ? undefined : selectedStatus,
        })
        if (result?.total && result.total > 0) {
          result?.checkoutSessions
            ? setList(result.checkoutSessions)
            : setList([])
          setRowCount(result.total || result.checkoutSessions.length)
        }
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }
    initialFetch()
  }, [organizationId, paginationModel, selectedStatus])

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ): void => {
    setPaginationModel(newPaginationModel)
  }

  if (organization == null) {
    return <SetupStorePrompt />
  }

  const rows = list?.map((rowData: CheckoutSession) => {
    const { pinCode } = rowData.shippingAddress
    const { firstName = '', lastName = '' } = rowData.customer ?? {}

    let fullName = `${firstName} ${lastName ?? ''}`

    if (rowData.customerDetails && rowData.customerDetails.firstName) {
      const { firstName, lastName } = rowData.customerDetails
      fullName = `${firstName} ${lastName ?? ''}`
    }

    return {
      id: rowData.uid,
      fullName: fullName,
      paymentId: rowData.paymentId ?? 'N/A',
      shippingAddress: pinCode,
      createdAt: rowData.createdAt,
      phoneNo: rowData.phoneNo,
      status: rowData.status,
      totalPrice: formatPrice(rowData.totalPrice),
    }
  })

  return (
    <div>
      <div className="mb-5">
        <div className=" mx-5">
          <p className="text-xl font-bold mb-4">Checkout Sessions</p>
          <Box marginBottom={2} className="flex flex-wrap gap-2">
            {statusChips.map((chip) => (
              <GenericChip
                key={chip.value}
                label={chip.label}
                value={chip.value as 'CREATED' | 'COMPLETED'}
                color={chip.color}
                isSelected={selectedStatus === chip.value}
                onClick={handleChipClick}
              />
            ))}
          </Box>
          <DataGridTable
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            disableRowSelectionOnClick
            disableColumnMenu
            disableDensitySelector
            pagination
            paginationMode="server"
            rowCount={rowCount}
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            containerHeight={'calc(100vh - 200px)'}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
export default CheckoutSessionsPage
