import { FC } from 'react'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, IconButton } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { format } from 'date-fns'

import { StyledDataGrid } from 'pages/dashboard/components/StyledDataGrid'
import { CheckoutSession } from 'types/checkout'
import { formatPrice } from 'utils/helper'

import { formatAddress } from './CustomersList'

interface CheckoutListProps {
  list: CheckoutSession[]
}

const CheckoutList: FC<CheckoutListProps> = ({ list }) => {
  const handleRowClick = (uid: string): void => {
    // Navigate to the detail page using only the row ID
    window.open(`/dashboard/orders/checkout-sessions/${uid}`, '_blank')
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: 'paymentId',
      headerName: 'Payemnt Id',
      type: 'string',
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: 'createdAt',
      width: 150,
      headerName: 'Created',
      type: 'dateTime',
      filterable: false,
      valueFormatter: (value: string): string => {
        return format(new Date(value), 'MMMM d, yyyy')
      },
    },
    {
      field: 'shippingAddress',
      headerName: 'Shipping Address',
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: 'status',
      width: 150,
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
    },
    {
      field: 'totalPrice',
      type: 'number',
      headerName: 'Total Amount',
      valueFormatter: (value: number): number => {
        return value ?? 0
      },
      flex: 0.5,
      minWidth: 120,
    },
    {
      field: 'navigate',
      headerName: '',
      align: 'center',
      flex: 0.7,
      renderCell: (params) => (
        <IconButton onClick={() => handleRowClick(params.id as string)}>
          <OpenInNewIcon fontSize="small" color="primary" />
        </IconButton>
      ),
    },
  ]

  const rows = list?.map((rowData: CheckoutSession) => {
    const computeAddress = formatAddress(rowData?.shippingAddress) || 'N/A'

    return {
      id: rowData.uid,
      paymentId: rowData.paymentId,
      shippingAddress: computeAddress,
      createdAt: rowData.createdAt,
      status: rowData.status,
      totalPrice: formatPrice(rowData.totalPrice),
    }
  })

  return (
    <Box
      className="bg-white"
      sx={{ width: { sm: 'calc(100vw - 340px)', md: 'calc(100vw - 370px)' } }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 4 } },
        }}
        pageSizeOptions={[4]}
        disableColumnMenu
        pagination
      />
    </Box>
  )
}

export default CheckoutList
