import { JSX } from 'react'

import { Typography } from '@mui/material'

interface LabelProps {
  text: string
  htmlFor?: string
  color?: 'secondary'
}

const Label: React.FC<LabelProps> = ({
  text,
  htmlFor,
  color = 'primary',
}): JSX.Element => {
  return (
    <Typography
      component="label"
      htmlFor={htmlFor}
      color={`text.${color}`}
      sx={{
        display: 'block',
        fontSize: '0.875rem',
        fontWeight: 600,
        marginBottom: '0.25rem',
      }}
    >
      {text}
    </Typography>
  )
}

export default Label
