import { JSX } from 'react'

import { Box, Grid, InputAdornment, MenuItem, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { CountriesListParams, getCountriesList } from 'utils/countries'

const countriesList = getCountriesList()

type FormInputTypes = {
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phoneNo: string
  instagramIdLink?: string
}
interface CustomerFormProps {
  restrictToCountries?: string[]
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  restrictToCountries,
}): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInputTypes>()
  let mainCountriesList: CountriesListParams[]

  if (restrictToCountries) {
    mainCountriesList = countriesList.filter((obj) =>
      restrictToCountries.includes(obj.isoCode)
    )
  } else {
    mainCountriesList = countriesList
  }

  return (
    <Box>
      <h2 className="text-lg font-semibold ml-4">Details</h2>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Label htmlFor="firstName" text="First Name" />
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  fullWidth
                  id="firstName"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  error={errors.firstName && true}
                  helperText={errors.firstName && errors.firstName.message}
                  sx={{
                    marginTop: 0,
                    '& .MuiFormHelperText-root': {
                      marginLeft: 0,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label htmlFor="lastName" text="Last Name" />
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{
                    marginTop: 0,
                    '& .MuiFormHelperText-root': {
                      marginLeft: 0,
                    },
                  }}
                  fullWidth
                  id="lastName"
                  variant="outlined"
                  size="small"
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label htmlFor="email" text="Email" />
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{
                    marginTop: 0,
                    '& .MuiFormHelperText-root': {
                      marginLeft: 0,
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                  error={errors.email && true}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label htmlFor="phoneNo" text="Phone Number" />
            <Grid container columnSpacing={2}>
              <Grid item xs={4} md={2}>
                <Controller
                  name="countryCode"
                  control={control}
                  render={({ field: { onChange, onBlur, name, value } }) => (
                    <TextField
                      SelectProps={{ style: { backgroundColor: 'white' } }}
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      sx={{
                        marginTop: 0,
                        '& .MuiFormHelperText-root': {
                          marginLeft: 0,
                        },
                      }}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      // error={errors.countryCode && true}
                      error={!!errors.countryCode}
                      helperText={errors.countryCode?.message}
                      select
                    >
                      {mainCountriesList.map((val) => (
                        <MenuItem
                          key={val.name}
                          value={val.phoneCode}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'lightgray',
                            },
                          }}
                        >
                          {`${val.phoneCode}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={8} md={10}>
                <Controller
                  name="phoneNo"
                  control={control}
                  render={({ field: { onChange, onBlur, name, value } }) => (
                    <TextField
                      className="inline-block"
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      sx={{
                        marginTop: 0,
                        '& .MuiFormHelperText-root': {
                          marginLeft: 0,
                        },
                      }}
                      inputProps={{ style: { backgroundColor: 'white' } }}
                      type="tel"
                      fullWidth
                      id="phoneNo"
                      variant="outlined"
                      size="small"
                      margin="normal"
                      error={!!errors.phoneNo}
                      helperText={errors.phoneNo && errors.phoneNo.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Label htmlFor="instagramIdLink" text="Instagram Page" />
            <p className="text-xs text-gray-500 mb-1">Optional</p>
            <Controller
              name="instagramIdLink"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, '')
                    onChange(newValue)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        https://www.instagram.com/
                      </InputAdornment>
                    ),
                  }}
                  placeholder="   instagram_id"
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{
                    marginTop: 0,
                    '& .MuiFormHelperText-root': {
                      marginLeft: 0,
                    },
                  }}
                  fullWidth
                  id="instagramIdLink"
                  variant="outlined"
                  margin="normal"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default CustomerForm
