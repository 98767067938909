import { Box } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import GenericChip from 'components/generic-chip'
import { OrderStatusValue, statusColors } from 'services/orders/index.type'
import theme from 'styles/muiTheme'

const OrderStatusChips = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedStatus = searchParams.get('orderStatus') || 'all'

  const handleChipClick = (status: OrderStatusValue | 'all') => {
    setSearchParams({ orderStatus: status })
  }

  const statusChips = [
    {
      label: 'ALL ORDERS',
      value: 'all' as 'all' | OrderStatusValue,
      color: theme.palette.primary.main,
    },
    ...Object.entries(statusColors).map(([key, color]) => ({
      label:
        key === OrderStatusValue.CREATED
          ? 'NEW ORDERS'
          : key.split('_').join(' '),
      value: key as OrderStatusValue,
      color,
    })),
  ]

  return (
    <Box marginBottom={2} className="flex flex-wrap gap-2">
      {statusChips.map((chip) => (
        <GenericChip
          key={chip.value}
          label={chip.label}
          value={chip.value}
          color={chip.color}
          isSelected={selectedStatus === chip.value}
          onClick={handleChipClick}
        />
      ))}
    </Box>
  )
}
export default OrderStatusChips
