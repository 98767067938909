import { Divider, Chip } from '@mui/material'
import { Link, Navigate } from 'react-router-dom'

import LoginForm from 'components/forms/LoginForm'
import OnboardingContainer from 'components/forms/OnboardingContainer'
import { useAuth } from 'hooks/useAuth'

const SignInWithEmail = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Navigate to="/dashboard/home" replace={true} />

  return (
    <OnboardingContainer
      title="Sign in to your account"
      description="Access your account to manage your store and stay updated."
    >
      <LoginForm />

      <p className="mt-5"></p>
      <Divider>
        <Chip label="or" size="small" />
      </Divider>
      <p className="text-sm my-4 text-center">
        Don&apos;t have a Haulistic account yet?{' '}
        <Link to="/signup" className="underline cursor-pointer">
          Sign Up
        </Link>
      </p>
      <p className="text-xs md:text-sm text-gray-400 mt-8">
        By proceeding, you agree to the{' '}
        <Link
          to="/terms-of-service"
          color="inherit"
          className="underline cursor-pointer"
        >
          Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link
          to="/privacy-policy"
          color="inherit"
          className="underline cursor-pointer"
        >
          Privacy Policy
        </Link>
      </p>
    </OnboardingContainer>
  )
}

export default SignInWithEmail
