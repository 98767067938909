import { getToken } from 'utils/authCookies'
import { buildCustomerUrl } from 'utils/helper'

import {
  Customer,
  CustomerQueryParams,
  CustomerDetailsParams,
  UpdateCustomerDetailsParams,
} from './index.type'

const customerBasePath = `${process.env.REACT_APP_API_BASE_PATH}/admin/customers`

const getCustomers = async (
  query: CustomerQueryParams
): Promise<{ total: number; customers: Customer[] } | undefined> => {
  const token = await getToken()
  const url = buildCustomerUrl(customerBasePath, query)

  try {
    const res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    if (res.ok) {
      const { data } = await res.json()
      return data
    }
  } catch (err) {
    console.error(err)
  }
}

const createCustomer = async (
  newCustomerData: CustomerDetailsParams,
  organizationId: number
): Promise<
  { status: string; message?: string; data?: Customer } | undefined
> => {
  const token = await getToken()
  const { firstName, lastName, email, phoneNo, instagramId, address } =
    newCustomerData

  const addressData = {
    ...address,
    firstName: address?.firstName === '' ? firstName : address?.firstName,
    lastName:
      address?.lastName === '' && address?.firstName === ''
        ? lastName
        : address?.lastName,
    phoneNo: address?.phoneNo === '' ? phoneNo : address?.phoneNo,
  }

  const bodyData = {
    firstName,
    lastName,
    email,
    phoneNumber: phoneNo,
    instagramId,
    organizationId,
    billingAddress: address?.country === '' ? undefined : addressData,
  }
  try {
    const res = await fetch(customerBasePath, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(bodyData),
    })

    const { data, error, message } = await res.json()
    if (data) return { status: 'successful', data }
    if (error) return { status: 'failed', message: error }
    if (message) return { status: 'failed', message: message }
  } catch (err) {
    console.error('Error:', err)
  }
}

const getCustomer = async (
  id: number,
  orgId: number
): Promise<{ status: string; data: Customer } | undefined> => {
  const token = await getToken()

  try {
    const res = await fetch(`${customerBasePath}/${id}?orgId=${orgId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    if (res.ok) {
      const { data } = await res.json()
      return { status: 'successful', data }
    }
  } catch (err) {
    console.error(err)
  }
}

const updateCustomer = async (
  customerId: number,
  organizationId: number,
  updatedCustomerData: UpdateCustomerDetailsParams
): Promise<
  { status: string; message?: string; data?: Customer } | undefined
> => {
  const token = await getToken()

  try {
    const res = await fetch(
      `${customerBasePath}/${customerId}?orgId=${organizationId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: JSON.stringify(updatedCustomerData),
      }
    )

    const { data, error } = await res.json()
    if (data) return { status: 'successful', data }
    if (error) return { status: 'failed', message: error }
  } catch (err) {
    console.error(err)
  }
}

export { getCustomers, createCustomer, getCustomer, updateCustomer }
