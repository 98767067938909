import { JSX, useEffect, useState } from 'react'

import {
  Box,
  Button,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'

const steps = [
  {
    id: 'contact-info',
    label: 'Add contact Information',
    description:
      'Ensure that your contact information is accurate and accessible. Provide phone numbers, email addresses, physical addresses, and any other preferred communication channels so customers can easily reach out for support or inquiries.',
    path: 'settings/stores',
    buttonText: 'Add Contact Info',
  },
  {
    id: 'customize-website',
    label: 'Customize your website',
    description:
      'Tailor your online store to reflect your brand’s identity. Upload your logo, select your brand colors, and customize design elements to create a cohesive and professional look that builds trust with your audience.',
    path: 'website-builder',
    buttonText: 'Customize Website',
  },
  {
    id: 'policy-pages',
    label: 'Add Policy Pages',
    description:
      'Create essential policy pages such as Return Policy, Shipping Policy, Privacy Policy, and Terms & Conditions. These pages set clear expectations, manage customer inquiries, and enhance your store’s credibility.',
    path: 'website-builder',
    buttonText: 'Add Policy Pages',
  },
  {
    id: 'add-product',
    label: 'Add your first product',
    description:
      'List your initial product by adding high-quality images, detailed descriptions, competitive pricing, and accurate inventory settings. This step is crucial for launching your store and attracting your first customers.',
    path: 'products/all',
    buttonText: 'Add Product',
  },
  {
    id: 'connect-domain',
    label: 'Add Your Own Domain (Only supported in Growth and Lite) - Optional',
    description:
      'Connect a custom domain to boost your store’s professional image and brand consistency. This option, available on Growth and Lite plans, can also improve your search engine visibility.',
    path: 'website-builder',
    buttonText: 'Connect Domain',
  },
  {
    id: 'setup-payments',
    label: 'Set up Payments',
    description:
      'Configure your payment methods to securely handle transactions. Set up your preferred payment gateways, choose your currencies, and test the integration to ensure a seamless checkout experience for your customers.',
    path: 'settings/payments',
    buttonText: 'Setup Payments',
  },
  {
    id: 'test-order',
    label: 'Place a Test Order',
    description:
      'Please make atleast one test order to ensure that the payment gateway is working properly. This step is crucial to verify that your store’s checkout process is smooth and that payments are processed correctly.',
    path: 'products/all',
    buttonText: 'Choose a Product to Test',
  },
  {
    id: 'invite-staff',
    label: 'Invite Staff',
    description:
      'Add team members to your store’s admin dashboard and assign them appropriate roles and permissions. This facilitates efficient management and ensures that everyone involved has the access they need.',
    path: 'settings/stores',
    buttonText: 'Invite Staff',
  },
  {
    id: 'share-website',
    label: 'Share Your Website with Customers',
    description:
      'Promote your store by sharing your website link across social media, email, and other marketing channels. This is key to driving traffic and starting to generate sales.',
    buttonText: 'Share Website',
  },
  {
    id: 'completed',
    label: 'COMPLETED',
    description:
      'All steps are complete! Your online store is fully set up and ready to start selling. Continue to monitor and update your store as your business grows.',
    buttonText: 'All Done',
  },
]

type Policies = { name: string; link: string; displayName: string }

const CheckList = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<{ [key: string]: boolean }>({})
  const navigate = useNavigate()
  const { organization } = useAuth()
  const [totalSteps, setTotalSteps] = useState(steps.length - 2)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!organization) {
      return
    }

    if (organization) {
      if (organization.currentPricingPlanId === 1) {
        setTotalSteps(steps.length - 3)
      }
      if (
        organization.address &&
        organization.address.trim() !== '' &&
        organization.email &&
        organization.phoneNumber
      ) {
        setCompleted((prev) => ({ ...prev, 'contact-info': true }))
        setActiveStep(1)
      }

      if (
        organization.logo &&
        organization.logoWithBrandName &&
        (organization.coverImages ?? []).length > 0 &&
        (organization.coverImagesMobile ?? []).length > 0
      ) {
        setCompleted((prev) => ({ ...prev, 'customize-website': true }))
        setActiveStep((prev) => (prev === 1 ? prev + 1 : prev))
      }

      if (organization.policies) {
        const policiesArray: Policies[] = JSON.parse(organization.policies)

        const termsDoc = policiesArray?.find((obj) => obj.name === 'terms')
        const privacyDoc = policiesArray?.find((obj) => obj.name === 'privacy')
        const returnsAndRefundsDoc = policiesArray?.find(
          (obj) => obj.name === 'returnsAndRefunds'
        )
        const shippingDoc = policiesArray?.find(
          (obj) => obj.name === 'shipping'
        )

        if (termsDoc && privacyDoc && returnsAndRefundsDoc && shippingDoc) {
          setCompleted((prev) => ({ ...prev, 'policy-pages': true }))
          setActiveStep((prev) => (prev === 2 ? prev + 1 : prev))
        }
      }

      if (organization.onboardingStatus === 'COMPLETED') {
        setCompleted((prev) => ({ ...prev, 'add-product': true }))
        if (organization.currentPricingPlanId === 1) {
          setActiveStep((prev) => (prev === 3 ? prev + 2 : prev))
        } else {
          setActiveStep((prev) => (prev === 3 ? prev + 1 : prev))
        }
      }

      if (
        organization.customDomain &&
        organization.customDomain.trim() !== ''
      ) {
        setCompleted((prev) => ({ ...prev, 'connect-domain': true }))
        setActiveStep((prev) => (prev === 4 ? prev + 1 : prev))
      }

      if (organization.paymentSetupStatus) {
        setCompleted((prev) => ({ ...prev, 'setup-payments': true }))
        setActiveStep((prev) => (prev === 5 ? prev + 1 : prev))
      }

      if ((organization.users ?? []).length > 1) {
        setCompleted((prev) => ({
          ...prev,
          'invite-staff': true,
        }))
        setActiveStep((prev) => (prev === 7 ? prev + 1 : prev))
      }
    }
  }, [organization])

  const handleStepClick = (stepId: string) => {
    const step = steps.find((s) => s.id === stepId)
    if (!step) return

    switch (step.id) {
      case 'policy-pages':
        navigate(`/dashboard/${step.path}`)
        setTimeout(() => {
          const element = document.getElementById('policy-section')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }, 1000)
        break

      case 'test-order':
        if (organization && organization.onboardingStatus !== 'COMPLETED') {
          showSnackbar(
            'Please add a product first before testing the order.',
            'info'
          )
        } else if (organization && !organization.paymentSetupStatus) {
          showSnackbar(
            'Please setup payments before testing the order.',
            'info'
          )
        } else if (organization && organization.website) {
          window.location.href = organization.website
        }
        break

      case 'connect-domain':
        if (organization?.currentPricingPlanId === 1) {
          showSnackbar(
            'Please upgrade your plan to connect to a custom domain',
            'info'
          )
        } else if (step.path) {
          navigate(`/dashboard/${step.path}`)
        }
        break

      case 'share-website':
        if (navigator.share) {
          navigator
            .share({
              title: organization?.name,
              text: organization?.description ?? '',
              url: organization?.website ?? '',
            })
            .then(() => {})
            .catch(() => {})
        } else {
          showSnackbar('Share not supported on this browser.', 'info')
        }
        break

      default:
        if (step.path) {
          navigate(`/dashboard/${step.path}`)
        }
        break
    }
  }

  const completedSteps = Object.values(completed).filter(Boolean).length
  const completePercentage = Math.round((completedSteps / totalSteps) * 100)

  return (
    <>
      <Box ml={2} className=" mr-5">
        <h2 className="text-xl font-bold">Set up your store</h2>
        <Typography
          sx={{
            fontSize: '0.75rem',
            color: 'text.secondary',
            marginTop: '0.1rem',
          }}
        >
          Feel free to click on any step below to jump ahead and complete your
          setup!
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, mx: 1 }}>
          Setup Complete: {completePercentage}%
        </Typography>
        <LinearProgress
          variant="determinate"
          value={completePercentage}
          sx={{ mt: 1, mx: 1, height: 10 }}
        />
      </Box>

      <Card>
        <Box>
          <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
            {steps.slice(0, -1).map((step) => (
              <Step key={step.id} completed={completed[step.id] || false}>
                <StepLabel>
                  <Typography variant="body1" textAlign="left">
                    <b>{step.label}</b>
                  </Typography>

                  <Typography variant="body2">{step.description}</Typography>
                  <Button
                    sx={{
                      marginTop: 2,
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                    size="small"
                    variant="outlined"
                    onClick={() => handleStepClick(step.id)}
                    disabled={completed[step.id] || false}
                  >
                    {step.buttonText}
                  </Button>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Card>
    </>
  )
}

export default CheckList
